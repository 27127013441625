export default {
  fitnessAgent: require('./chatIcons/agent-fit.png'),
  nutritionAgent: require('./chatIcons/agent-nutri.png'),
  psychologyAgent: require('./chatIcons/agent-psico.png'),

  bluefitLogo: require('./chatIcons/Bluefit-logo.png'),

  home: require('./chatIcons/home.png'),
  keiken1: require('./chatIcons/Keiken_Marca.png'),
  keiken2: require('./chatIcons/Keiken_round.png'),
  premium: require('./chatIcons/Keiken.png'),
  lucas: require('./chatIcons/Lucas.png'),

  logo: require('./chatIcons/Logo.png'),
  message: require('./chatIcons/message.png'),
  send: require('./chatIcons/send_1.png'),

  stars1: require('./chatIcons/stars_1.png'),
  stars2: require('./chatIcons/stars_2.png'),
  user_2: require('./chatIcons/user_2.png'),
  user_3: require('./chatIcons/user_3.png'),
};
