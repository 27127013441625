import React from 'react';
import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import './styles.scss';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/Stores';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface Props extends WithTranslation { }

const TermsAndConditions: React.FC<Props> = ({ t }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const partner = useSelector(
    (state: IRootState) => state.authentication.partner,
  );

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const shownDate = moment(partner.acceptedTermDate).format('DD/MM/YYYY');
  const shownTime = moment(partner.acceptedTermDate).format('HH:mm');

  return (
    <>
      <div className="card-model">
        <div className="terms-conditions-header">
          <div className="pt-10">
            <span className="value-title-password-change">
              {t('profileCards.terms.title')}
            </span>
          </div>
          <div className="flex-center">
            <button onClick={handleToggle}>
              {t('profileCards.terms.btn')}
            </button>
          </div>
        </div>
        <hr />
        <div>
          <p>
            {t('profileCards.terms.p1', { date: shownDate, time: shownTime })}
          </p>
          <p>
            {t('profileCards.terms.p2', { email: partner.acceptedTermEmail })}
          </p>
          <p>{t('profileCards.terms.p3', { ip: partner.acceptedTermIp })}</p>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={isOpen}
        onClose={handleToggle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 800 }}>
        <Fade in={isOpen}>
          <div className="AcceptTerms--modal">
            <header className="AcceptTerms--header">
              <h2>{t('profileCards.terms.modal.title')}</h2>
              <button onClick={handleToggle}>
                <CloseIcon />
              </button>
            </header>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <div className="pdf-container">
                <Viewer fileUrl="/Termo_de_Adesão_Keiken.pdf" />
              </div>
            </Worker>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default withTranslation()(TermsAndConditions);
