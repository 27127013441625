import { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AUTHORITIES, COMPANY_APP, CUSTOMER_APP } from 'src/Config/Constants';
import { Authentication } from 'src/Models/authentication';
import { Partner } from 'src/Models/Partner';
import { User } from 'src/Models/user';
import {
  HttpResponseError,
  HttpResponseTypeError,
} from 'src/Models/Utils/HttpReponseError';
import authenticationApi from 'src/Services/Api/AuthenticationApi';
import { error, success } from 'src/Services/ToastService';
import AuthUtils from 'src/Utils/AuthUtils';
import { getPartnerProfile } from '../profile/actions';
import {
  getSessionError,
  getSessionRequest,
  getSessionSuccess,
  loginError,
  loginRequest,
  loginSuccess,
  passwordChange,
  passwordChangeSuccess,
  passwordChangeError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
} from './actions';
import { AuthenticationActionTypes } from './types';

const mapToHttpError = (
  status: number,
  result?: AxiosResponse<Authentication>,
) => {
  if (status === 400 && result?.data?.fieldErros != null) {
    const fields = result.data.fieldErros?.map((it) => it.field);
    if (fields.includes('password') && fields.includes('username')) {
      return {
        status,
        type: HttpResponseTypeError.LOGIN_ERROR_USER_AND_PASSWORD,
      };
    }
    return {
      status,
      type: fields.includes('password')
        ? HttpResponseTypeError.LOGIN_ERROR_PASSWORD
        : HttpResponseTypeError.LOGIN_ERROR_USER,
    };
  }
  if (status === 401) {
    return {
      status,
      type: HttpResponseTypeError.LOGIN_ERROR_PASSWORD,
    };
  }

  if (status === 404) {
    return {
      status,
      type: HttpResponseTypeError.LOGIN_ERROR_USER,
    };
  }

  return {
    type: HttpResponseTypeError.UNKNOWN,
    message: 'login.error.unknown',
  };
};

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  let result: AxiosResponse<Authentication> | null;
  try {
    result = yield call(authenticationApi.login, action.payload);
    if (result?.status !== 200 && result?.data.token != null) {
      const error: HttpResponseError = mapToHttpError(result?.status, result);
      console.log('error on sagas');
      yield put(loginError(error));
      return;
    }
    const jwt: string = result?.data.tokenId!;
    const decodeJwt: any = jwt_decode(jwt);
    if (decodeJwt.auth.includes(AUTHORITIES.PARTNER)) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(loginSuccess());
      return;
    }

    if (decodeJwt.auth.includes(AUTHORITIES.CUSTOMER)) {
      AuthUtils.setToken(jwt);
      yield put(
        loginError({
          type: HttpResponseTypeError.INVALID_AUTH,
          redirect: CUSTOMER_APP,
        }),
      );
      return;
    }
    if (decodeJwt.auth.includes(AUTHORITIES.COMPANY)) {
      AuthUtils.setToken(jwt);
      yield put(
        loginError({
          type: HttpResponseTypeError.INVALID_AUTH,
          redirect: COMPANY_APP,
        }),
      );
      return;
    }
  } catch (err) {
    if (err?.response?.status != null) {
      yield put(loginError(mapToHttpError(err.response.status)));
    } else if (err instanceof Error && err.stack) {
      yield put(loginError({ message: err.stack }));
    } else {
      yield put(loginError({ message: 'An unknown error occured.' }));
    }
  }
}

function* handleGetSession() {
  try {
    const result: AxiosResponse<Partner> = yield call(
      authenticationApi.getPartnerSession,
    );
    if (result.status === 200 && result.data != null) {
      yield put(getSessionSuccess(result.data));
      yield put(getPartnerProfile());
    } else {
      yield put(getSessionError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSessionError(err.stack));
    } else {
      yield put(getSessionError('An unknown error occured.'));
    }
  }
}

function* handlePasswordChange(action: ReturnType<typeof passwordChange>) {
  try {
    const result: AxiosResponse = yield call(
      authenticationApi.passwordChange,
      action?.payload,
    );

    if (result.status === 200 && result.data != null) {
      yield put(passwordChangeSuccess());
      success('Senha alterada com sucesso!');
    } else error('Não foi possível alterar a senha');
  } catch (err) {
    console.log(err);

    if (err instanceof Error && err.stack) {
      yield put(passwordChangeError(err.stack));
    } else {
      yield put(passwordChangeError('An unknown error occured.'));
    }

    error('A senha atual não está correta!');
  }
}

function* handleForgotPassword(action: ReturnType<typeof forgotPassword>) {
  try {
    const result: AxiosResponse = yield call(
      authenticationApi.forgotPassword,
      action?.payload,
    );

    if (
      (result.status === 200 || result.status === 204) &&
      result.data != null
    ) {
      yield put(forgotPasswordSuccess());
      success('Email para redefinição de senha foi enviado!');
    } else error('Não foi possivel enviar email');
  } catch (err) {
    console.log(err);

    if (err instanceof Error && err.stack) {
      yield put(forgotPasswordError());
    } else {
      yield put(passwordChangeError('An unknown error occured.'));
    }

    error('Não foi possivel enviar email');
  }
}

function* watchPasswordChange() {
  yield takeEvery(
    AuthenticationActionTypes.CHANGE_PASSWORD,
    handlePasswordChange,
  );
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchGetSessionRequest() {
  yield takeEvery(
    AuthenticationActionTypes.GET_SESSION_REQUEST,
    handleGetSession,
  );
}
function* watchForgotPassword() {
  yield takeEvery(
    AuthenticationActionTypes.FORGOT_PASSWORD,
    handleForgotPassword,
  );
}

function* authenticationSaga() {
  yield all([
    fork(watchLoginRequest),
    fork(watchGetSessionRequest),
    fork(watchPasswordChange),
    fork(watchForgotPassword),
  ]);
}

export default authenticationSaga;
