import { Reducer } from 'redux';
import { Activity } from 'src/Models/Activity';
import { ActivityActionTypes, ActivityState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: ActivityState = {
  activities: [],
};

const reducer: Reducer<ActivityState> = (state = initialState, action) => {
  switch (action.type) {
    /*
    Implementar atualização de uma unica atividade posteriormente
    case ActivityActionTypes.POST_ACTIVITY: {
      const activity = action.payload as Activity;

      return { ...state, activity};
    };
    */
   case ActivityActionTypes.GET_ACTIVITIES_REQUEST: {
      return {...state, activitiesStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityActionTypes.GET_ACTIVITIES_REQUEST_SUCCESS:{
     const activities = action?.payload as Activity[];

     return {...state, activities, activitiesStatus: HttpRequestStatus.SUCCESS};
   }
   case ActivityActionTypes.GET_ACTIVITIES_REQUEST_ERROR: {
    return { ...state, activitiesStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }
   case ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID: {
      return {...state, activitiyStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID_SUCCESS:{
     const activity = action?.payload as Activity;
     return {...state, activity, activitiyStatus: HttpRequestStatus.SUCCESS};
   }
   case ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID_ERROR: {
    return { ...state, activitiyStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }
   case ActivityActionTypes.GET_SCHEDULE_HISTORY: {
      return {...state, historyStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityActionTypes.GET_SCHEDULE_HISTORY_SUCCESS:{
     const scheduleHistory = action?.payload;

     return {...state, scheduleHistory, historyStatus: HttpRequestStatus.SUCCESS};
   }
   case ActivityActionTypes.GET_SCHEDULE_HISTORY_ERROR: {
    return { ...state, historyStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }

   case ActivityActionTypes.PUT_ACTIVITY:
    case ActivityActionTypes.POST_ACTIVITY: {
      return {...state, postPutStatus: HttpRequestStatus.ONGOING }
    }
    case ActivityActionTypes.PUT_ACTIVITY_SUCCESS:
    case ActivityActionTypes.POST_ACTIVITY_SUCCESS: {
      return {...state, postPutStatus: HttpRequestStatus.SUCCESS }
    }
    case ActivityActionTypes.PUT_ACTIVITY_ERROR:
    case ActivityActionTypes.POST_ACTIVITY_ERROR: {
      return {
        ...state,
        postPutStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case ActivityActionTypes.RESET_POST_PUT_STATUS: {
      return {
        ...state,
        postPutStatus: HttpRequestStatus.NOOP,
      };
    }

   case ActivityActionTypes.DELETE_ACTIVITY: {
    return {...state, activitiesStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityActionTypes.DELETE_ACTIVITY_SUCCESS: {
    return {...state, activitiesStatus: HttpRequestStatus.SUCCESS};
   }
   case ActivityActionTypes.DELETE_ACTIVITY_ERROR: {
    return { ...state, activitiesStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }
    default: {
      return state;
    }
  }
}

export { reducer as activityReducer };