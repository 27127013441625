import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';

import AddIcon from '@material-ui/icons/Add';

import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import InputClassic from '../../PlanFormat/InputClassic';
import Button from '@mui/material/Button';
import { postBankData, getBankData } from 'src/Stores/bankAccount/actions';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { useWidth } from 'src/Hooks/useWidth';
import { cnpjMask, cpfMask } from 'src/Utils/Masks';
import { useHistory, useLocation } from 'react-router-dom';
import { PixType } from 'src/Models/BankAccount';
import PixUtils from 'src/Utils/PixAccount/PixUtils';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #fff',
    borderRadius: 15,
    padding: 15,
    width: '45rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: '#4A4A4A',
    margin: 0,
  },
  modalAlign: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalIcon: {
    cursor: 'pointer',
  },
  underline: {
    width: '100%',
    borderBottom: '2px solid #F1ECEC',
  },
}));

export interface BankDataProps extends DispatchProps, StateProps {}

const BankData: React.FC<BankDataProps> = ({
  bankData,
  getBankData,
  postBankData,
}) => {
  const classes = useStyles();
  const [pix, setPix] = useState('');
  const [open, setOpen] = useState(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [pixType, setPixType] = useState<PixType>(PixType.PHONE);
  let widthSize = useWidth();
  const { search } = useLocation();
  const pixModalRef = useRef(null);
  const history = useHistory();

  const params = new URLSearchParams(search);
  const editModalpix = params.get('editModalpix');
  useEffect(() => {
    if (editModalpix) {
      pixModalRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    setTimeout(() => {
      editModalpix && setOpen(true);
    }, 800);
  }, []);

  const placeholders = {
    PHONE: '(12) 9456-7890',
    CPF: '123.456.789-12',
    EMAIL: 'example@gmail.com',
    CNPJ: '12.345.678/0001-90',
    RANDOM_KEY: '',
  };

  useEffect(() => {
    getBankData();
  }, [getBankData]);

  useEffect(() => {
    if (!bankData?.id) return;

    setPix(bankData?.pixKey);

    if (bankData?.pixType === PixType.DOCUMENT) {
      const pixType = PixUtils.isPixTypeCpfOrCnpj(bankData?.pixKey);

      setPixType(pixType);
    } else {
      setPixType(bankData?.pixType);
    }
  }, [bankData, setPix]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePix = (e) => {
    setPix(e?.target?.value);

    if (!enableSave) setEnableSave(true);
  };

  const handleSave = () => {
    if (editModalpix) {
      postBankData({ pixKey: pix, pixType: pixType });
      setEnableSave(false);
      history.push('/finances?openModalWithDraw=true');
    } else {
      postBankData({ pixKey: pix, pixType: pixType });
      setEnableSave(false);
      handleClose();
    }
  };

  const disabledSave = !enableSave;

  const saveButtonColor = !disabledSave ? '#25B379 !important' : 'light-grey';

  const handleMask = (e) => {
    let input = e.target;

    switch (pixType) {
      case PixType.CPF:
        input.value = cpfMask(input.value);
        break;
      case PixType.CNPJ:
        input.value = cnpjMask(input.value);
        break;
      // case 'celular':
      //   input.value = phoneMask(input.value);
      //   break;
    }
  };
  return (
    <div className="card-model">
      <div className="space-beetween">
        <div>
          <span className="value-title-bank-data">Dados bancários</span>
        </div>
        <div>
          <button onClick={handleOpen}>
            <AddIcon /> &nbsp;{' '}
            <span className="spanEditAddPix">Adicionar Pix</span>
          </button>
        </div>
      </div>
      <div ref={pixModalRef} className="setSpace">
        <div className="flexit">
          <div>
            <div className="flexx">
              <div className="userData2 width-100 ">
                <span className="letraCinza text-initial mb-10">
                  Pix {bankData?.pixKey}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className="paper-bank">
            <div className={classes.modalAlign}>
              <div>
                <h2 id="transition-modal-title" className={classes.modalTitle}>
                  Dados Bancários
                </h2>
              </div>
              <div>
                <CloseIcon
                  className={classes.modalIcon}
                  onClick={handleClose}
                />
              </div>
            </div>
            <div className="mb-20" />
            <div className={classes.underline}></div>
            <div className="mb-20" />
            <div className="selectInput__container mt-20">
              <select
                className="selectTypePix mb-20 col-8"
                value={pixType}
                onChange={(e) => setPixType(e.target.value as PixType)}>
                <option defaultChecked={true} value="">
                  Escolha a chave:
                </option>
                <option value={PixType.PHONE}>Celular</option>
                <option value={PixType.EMAIL}>Email</option>
                <option value={PixType.CNPJ}>CNPJ</option>
                <option value={PixType.CPF}>CPF</option>
                <option value={PixType.RANDOM_KEY}>Aleatória</option>
              </select>
              <div className=" containerPixInput mb-20 col-9 ">
                {pixType === PixType.PHONE ? (
                  <InputClassic
                    className="inputPix"
                    disabled={!pixType}
                    title="Chave Pix"
                    placeholder={'Digite seu número'}
                    value={pix}
                    international
                    onChange={handleChangePix}
                  />
                ) : (
                  <InputClassic
                    className="inputPix"
                    onKeyUp={(e) => handleMask(e)}
                    disabled={!pixType}
                    title="Chave Pix"
                    placeholder={placeholders[pixType]}
                    value={pix}
                    onChange={handleChangePix}
                  />
                )}
              </div>
            </div>
            <div className="passwordFooter">
              <span onClick={handleClose} className="cancel">
                Cancelar
              </span>
              <Button
                sx={{
                  background: saveButtonColor,
                  borderRadius: '22px',
                }}
                variant="contained"
                onClick={handleSave}
                disabled={disabledSave}>
                {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { bankData } = state?.bankAccount;

  return { bankData };
};

const mapDispatchToProps = {
  getBankData,
  postBankData,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankData) as React.ComponentType<any>;
