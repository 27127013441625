import { api } from '.';
import { AxiosResponse } from 'axios';
import { BankAccount, PixType } from 'src/Models/BankAccount';

export const BankAccountApi = () => {
  const getBankData = (): Promise<AxiosResponse> => {
    return api.get<BankAccount>('/pix-accounts/partner');
  };

  const postBankData = (bankData: BankAccount) => {
    if (bankData.pixType === PixType.CNPJ || bankData.pixType === PixType.CPF) {
      bankData.pixType = PixType.DOCUMENT;
    }
    return api.post<BankAccount>('/pix-accounts/partner', bankData);
  };

  return {
    getBankData,
    postBankData,
  };
};

export default BankAccountApi();
