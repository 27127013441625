import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Document } from 'src/Models/Document';
import { error } from 'src/Services/ToastService';

const LoadImage = ({
  image,
  onSave,
  onCrop,
  minWidth = 0,
  cropAspect = 2,
  showSaveButton = true,
  editImageText = 'Editar imagem',
}) => {
  const { t } = useTranslation();

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<any>({
    unit: '%',
    width: 100,
    aspect: cropAspect || 2,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [document, setDocument] = useState<Document>({});

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const i = new Image();

      const file = e.target.files[0];

      i.onload = () => {
        const reader = new FileReader();
        // @ts-ignore
        reader.addEventListener('load', () => setUpImg(reader?.result));
        reader.readAsDataURL(file);

        if (minWidth > 0 && i?.width < minWidth) {
          setDocument({});
          onCrop(false);

          return error(
            `A largura mínima da imagem dever ser ${minWidth} pixels`,
          );
        }
        setDocument({
          width: i?.width,
          fileName: file.name,
          contentType: file.type,
        });

        onCrop(true);
      };

      i.src = URL.createObjectURL(file);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const handleOnSave = useCallback(async () => {
    const file = previewCanvasRef.current?.toDataURL()?.split(',')?.[1];

    onSave({
      ...document,
      file,
    });

    if (showSaveButton) {
      setDocument({});
      onCrop(false);
    }

    setCompletedCrop(null);
  }, [onSave, showSaveButton, onCrop, setDocument, setCompletedCrop]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    !showSaveButton && handleOnSave();
  }, [completedCrop, showSaveButton, handleOnSave]);

  const hiddenFileInput = React.useRef(null);

  return (
    <div>
      {image != null && document?.fileName == null && (
        <div onClick={() => hiddenFileInput?.current?.click()}>
          <input
            ref={hiddenFileInput}
            type="file"
            onChange={onSelectFile}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <span className="link">{editImageText}</span>
        </div>
      )}
      {document?.fileName != null && (
        <>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            imageStyle={{ maxHeight: 350 }}
          />
          <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
          {showSaveButton && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{ justifyContent: 'center', alignContent: 'center' }}
                onClick={handleOnSave}
                className={'BusinessCard--button'}>
                {t('plans.image.save')}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LoadImage;
