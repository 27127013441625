import { EnterpriseData } from 'src/Models/Profile';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum ProfileTypes {

  GET_IMAGE_ADDRESS_SUCCESS = '@@profile/GET_IMAGE_ADDRESS_SUCCESS',

  GET_PROFILE = '@@profile/GET_PROFILE',
  GET_PROFILE_ERROR = '@@profile/GET_PROFILE_ERROR',
  GET_PROFILE_SUCCESS = '@@profile/GET_PROFILE_SUCCESS',

  PUT_ENTERPRISE_DATA = '@@profile/PUT_ENTERPRISE_DATA',
  PUT_ENTERPRISE_DATA_ERROR = '@@profile/PUT_ENTERPRISE_DATA_ERROR',
  PUT_ENTERPRISE_DATA_SUCCESS = '@@profile/PUT_ENTERPRISE_DATA_SUCCESS',

  PUT_ENTERPRISE_PAGE = "@@profile/PUT_ENTERPRISE_PAGE",
  PUT_ENTERPRISE_PAGE_ERROR = "@@profile/PUT_ENTERPRISE_PAGE_ERROR",
  PUT_ENTERPRISE_PAGE_SUCCESS = "@@profile/PUT_ENTERPRISE_PAGE_SUCCESS",

  PUT_RESPONSIBLE_DATA = "@@profile/PUT_RESPONSIBLE_DATA",
  PUT_RESPONSIBLE_DATA_ERROR = "@@profile/PUT_RESPONSIBLE_DATA_ERROR",
  PUT_RESPONSIBLE_DATA_SUCCESS = "@@profile/PUT_RESPONSIBLE_DATA_SUCCESS",
}

export interface ProfileState {
  readonly profile?: EnterpriseData;
  readonly error?: HttpResponseError;
  readonly status?: HttpRequestStatus;
  readonly pageStatus?: HttpRequestStatus;
  readonly dataStatus?: HttpRequestStatus;
  readonly responsibleStatus?: HttpRequestStatus;
  
}
