import React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import StringUtils from 'src/Utils/StringUtils';
import CurrencyInput from 'react-currency-input';

interface PriceInputProps {
  inputTitle: string;
  formVariableName: string;
  value: number;
  isEditInfo: string;
  tootltipInfo: string;
  isEdit: boolean;
  handleChange: (name: string, value: any) => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

function PriceInput({
  isEdit,
  inputTitle,
  isEditInfo,
  formVariableName,
  value,
  tootltipInfo,
  error,
  errorMessage,
  disabled = false,
  handleChange,
}: PriceInputProps) {
  const useStyles = makeStyles((theme) => ({
    tooltipFont: {
      fontSize: 14,
    },
  }));

  const classes = useStyles();
  return (
    <div className="col-md-4 col-sm-12 col-xs-12 ">
      <span className="value-title inputTitle">
        {inputTitle}
        <span style={{ marginLeft: '10px' }}>
          <Tooltip
            classes={{ tooltip: classes.tooltipFont }}
            title={tootltipInfo}>
            <Info />
          </Tooltip>
        </span>
      </span>
      {isEdit ? (
        <Tooltip title={isEditInfo}>
          <span className="price-title inputTitle">
            {StringUtils.currencyPtBr(value)}
          </span>
        </Tooltip>
      ) : (
        <CurrencyInput
          className={'input'}
          placeholder={'Valor'}
          value={value}
          onChangeEvent={(e, formatted, value) =>
            handleChange(formVariableName, value)
          }
          prefix="R$ "
          disabled={disabled}
          decimalSeparator=","
          thousandSeparator="."
          precision="2"
          style={error ? { border: '1px solid red' } : {}}
        />
      )}
      {error && (
        <div
          className="errorPrice"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

export default PriceInput;
