import {PartnerClient} from "../../Models/PartnerClient";
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum PartnerClientTypes {
  GET_PARTNER_CLIENTS = '@@partnerClients/PARTNER_CLIENTS',
  GET_PARTNER_CLIENTS_ERROR = '@@partnerClients/PARTNER_CLIENTS_ERROR',
  GET_PARTNER_CLIENTS_SUCCESS = '@@partnerClients/PARTNER_CLIENTS_SUCCESS',
}

export interface PartnerClientState {
  readonly error?: HttpResponseError;
  readonly status?: HttpRequestStatus;
  readonly partnerClients?: PartnerClient[];
}
