import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, TitleStatus } from './styles';

interface Props {
  title: string;
}

const Status = ({ title }: Props) => {
  const { t } = useTranslation();

  return (
    <Container status={title}>
      <TitleStatus>{t(`makePurchase.history.${title}Status`)}</TitleStatus>
    </Container>
  );
};

export default Status;
