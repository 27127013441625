import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import Countries from 'src/Utils/Countries';
import 'react-phone-number-input/style.css';

import './styles.scss';

export interface PlanFormatProps {
  title?: string;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  value?: string;
  onChange?: (event) => void;
  international?: boolean;
  borderColor?: string;
  className?: string;
  type?: string;
  disabled?: boolean;
  adornmentText?: string;
  onKeyUp?: (e) => void;
}

const InputClassic: React.FC<PlanFormatProps> = ({
  title,
  placeholder,
  name,
  value,
  onChange,
  onKeyUp,
  international,
  maxLength,
  borderColor,
  type = 'text',
  className = '',
  disabled,
  adornmentText = false,
}) => {
  const disabledClassName = disabled ? 'field-disabled' : '';
  const containerClassName = `customInputMaster ${className} ${disabledClassName}`;

  const handleInterChange = (newValue) => {
    const e = { target: { name: 'phone', value: newValue } };
    return onChange(e);
  };

  let [countrySelected, setCountrySelected] = useState('');
  let [numberPlaceholder, setNumberPlaceholder] = useState('');

  useEffect(() => {
    let numberPlaceholder = Countries?.filter(
      (country) => country.code === countrySelected,
    );
    return setNumberPlaceholder(numberPlaceholder[0]?.mask);
  }, [countrySelected, numberPlaceholder]);

  return (
    <>
      {international ? (
        <PhoneInput
          onCountryChange={(e) => setCountrySelected(e)}
          placeholder={numberPlaceholder || placeholder}
          value={value}
          defaultCountry="BR"
          onChange={handleInterChange}
          className="inputTel"
          name={name}
          onKeyUp={onKeyUp}
          type={type}
          disabled={disabled}
          maxLength={maxLength}
        />
      ) : (
        <div
          className={international ? '' : containerClassName}
          style={{ borderColor }}>
          <span className="inputTitle">{title}</span>
          <br />
          <input
            onKeyUp={onKeyUp}
            type={type}
            className={`no-border ${disabledClassName}`}
            placeholder={placeholder}
            name={name}
            maxLength={maxLength}
            disabled={disabled}
            value={value}
            onChange={onChange}
          />

          {adornmentText && (
            <div className="adornmentText">
              <span className="inputTitle">{adornmentText}</span>
            </div>
          )}
        </div>
      )}{' '}
    </>
  );
};

export default InputClassic;
