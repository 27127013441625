import {ActivityCategory} from "src/Models/ActivityCategory";
import { action } from 'typesafe-actions';
import { CategoryActionTypes } from './types';

export const getCategories = () => action(CategoryActionTypes.GET_CATEGORIES_REQUEST);
export const getCategoriesSuccess = (activities: ActivityCategory[]) => action(CategoryActionTypes.GET_CATEGORIES_REQUEST_SUCCESS, activities);
export const getCategoriesError = (message: string) => action(CategoryActionTypes.GET_CATEGORIES_REQUEST_ERROR, message);

export const getSubCategories = () => action(CategoryActionTypes.GET_SUBCATEGORIES_REQUEST);
export const getSubCategoriesSuccess = (activities: ActivityCategory[]) => action(CategoryActionTypes.GET_SUBCATEGORIES_REQUEST_SUCCESS, activities);
export const getSubCategoriesError = (message: string) => action(CategoryActionTypes.GET_SUBCATEGORIES_REQUEST_ERROR, message);

