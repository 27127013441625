import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Agenda } from 'src/Models/Agenda';
import '../styles.scss';

interface AvailableAgendaProps extends WithTranslation {
  item?: Agenda;
  selected?: boolean;
  onAgendaSelected?: (selected: Agenda) => void;
}

const AvailableAgendaCard: React.FC<AvailableAgendaProps> = ({
  t,
  item,
  selected,
  onAgendaSelected,
}) => {
  function activeItem() {
    onAgendaSelected(item);
  }

  const transform = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0,
  };

  return (
    <div className="AvailableAgendaCard">
      <div
        className={`col-md-12 col-md-offset-1 flex ${
          selected ? 'plr-1' : 'plr-0'
        }`}
        onClick={activeItem}>
        <div className={'p-2'}>
          <div className={`circle ${selected ? 'circle-color' : ''}`} />
          <div className={'d-flex flex-column'}>
            <span className="title-card">{item?.name}</span>
            <p className="available-agenda-card-days">
              {item?.presetAgendaActivitySchedules
                .reduce((acc, curr) => {
                  if (!acc.some((it) => it == curr?.dayOfWeek)) {
                    acc.push(curr?.dayOfWeek);
                  }
                  return acc;
                }, [])
                .map((it) => transform[it])
                .sort()
                .map((it) => moment().day(it))
                .map((it) => moment(it).format('ddd').toUpperCase())
                .join(' • ')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AvailableAgendaCard);
