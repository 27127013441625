import React, { useState, useEffect, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import MaskedInput from 'react-input-mask';
import './styles.scss';

import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as Pencil } from '../../../Images/Icons/pencil.svg';
import { ReactComponent as Arrow } from '../../../Images/Icons/arrowGreen.svg';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import Cards from './Cards';
import SimpleModal from 'src/Components/SimpleModal';
import { FormControlLabel, Radio } from '@material-ui/core';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import {
  ActivityPlaceTypeUtils,
  PresetActivityPlanSchedules,
  PresetActivityPlanSchedulesUtils,
} from 'src/Models/Plan';
import { useHistory } from 'react-router-dom';
import { Agenda } from 'src/Models/Agenda';
import { Activity } from 'src/Models/Activity';
import _, { isEmpty } from 'lodash';
import { ConsoleView } from 'react-device-detect';
import { ActivityPlaceType } from 'src/Models/Plan';
import { Tooltip, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';

export interface PlanGridProps
  extends WithTranslation,
    StateProps,
    DispatchProps {
  title: string;
  watch: any;
  type?: string;
  setValue: any;
  verifyAndEnableSave?: () => void;
  hideGridFields?: { duration?: boolean };
  register: (Object, requirements?: Object) => void;
  unregister?: (Object, requirements?: Object) => void;
  resetField?: (Object, requirements?: Object) => void;
  onCloseModal?: () => void;
  presetActivityPlanSchedules: PresetActivityPlanSchedules[];
  agendas: Agenda[];
}

const daysOfWeek = [
  {
    day: 'Segunda-Feira',
    name: 'monday',
    hasBorder: true,
  },
  {
    day: 'Terça-Feira',
    name: 'tuesday',
  },
  {
    day: 'Quarta-Feira',
    hasBorder: true,
    name: 'wednesday',
  },
  {
    day: 'Quinta-Feira',
    name: 'thursday',
  },
  {
    day: 'Sexta-Feira',
    name: 'friday',
    hasBorder: true,
  },
  {
    day: 'Sábado',
    name: 'saturday',
  },
  { day: 'Domingo', name: 'sunday' },
];

const PlanGrid: React.FC<PlanGridProps> = ({
  t,
  title,
  watch,
  type,
  setValue,
  register,
  resetField,
  verifyAndEnableSave = () => null,
  hideGridFields,
  presetActivityPlanSchedules,
  agendas,
}) => {
  const [open, setOpen] = useState(false);
  const presetActivitySchedules = watch('presetActivitySchedules');
  const watchAgendas = watch('agendas');
  const typePlace = watch('type');
  const typeDescription = watch('typeDescription');
  const history = useHistory();

  useEffect(() => {
    register('presetActivitySchedules');
    register('agendas');
    register('type', { required: true });
    register('typeDescription');
  }, [register]);

  const [openAttendanceModal, setOpenAttendanceModal] = useState(false);

  const isActivity = type === 'activity';

  function handleChange(status) {
    setOpen(!status);
  }

  const handleTimeChange = (
    weekDay = 'monday',
    fieldName = 'startAt',
    value,
    e?,
  ) => {
    e?.preventDefault();

    verifyAndEnableSave();
    setValue('presetActivitySchedules', {
      ...presetActivitySchedules,
      [weekDay]: {
        ...presetActivitySchedules[weekDay],
        [fieldName]: value,
      },
    });
  };

  const genericMaskedInput = (weekday, fieldName, disabled?) => {
    return (
      <MaskedInput
        value={presetActivitySchedules[weekday][fieldName] || ''}
        name={fieldName}
        maskChar="0"
        placeholder="00:00"
        mask="99:99"
        disabled={disabled}
        alwaysShowMask
        onChange={(e) => handleTimeChange(weekday, fieldName, e.target.value)}
      />
    );
  };

  const attendanceDay = ({ day, hasBorder = false, name }) => {
    const borderRight = hasBorder && '1px solid #D3D3D3';

    const dontWork = presetActivitySchedules[name]['dontWork'] ?? true;

    return (
      <div key={day} className="col-md-6 dayContainer" style={{ borderRight }}>
        <div className="weekDayLine">
          <span className="weekDay">{day}</span>
          {!isActivity && (
            <FormControlLabel
              checked={dontWork}
              name={name}
              control={<Radio color="secondary" />}
              label="Não funciona"
              style={{ color: '#696969' }}
              onClick={(e) => handleTimeChange(name, 'dontWork', !dontWork, e)}
            />
          )}
        </div>
        {!dontWork && (
          <div style={{ marginLeft: '10px' }}>
            <div className="flex">
              <span className="col-md-6 openAndClose">
                Abertura e fechamento
              </span>
              <span className="col-md-6 flex lunchPause">Pausa do almoço</span>
            </div>
            <div className="row height-40">
              <div className="col-md-6 pl-0 pr-0 inputBox">
                {genericMaskedInput(name, 'startAt')}
                {genericMaskedInput(name, 'endAt', isActivity)}
              </div>
              <div className="col-md-6 pr-0 inputBox">
                {genericMaskedInput(name, 'startLunch', isActivity)}
                {genericMaskedInput(name, 'endLunch', isActivity)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getDaysOfWeekObject = () => {
    return {
      segunda: presetActivitySchedules['monday'] || {},
      terca: presetActivitySchedules['tuesday'] || {},
      quarta: presetActivitySchedules['wednesday'] || {},
      quinta: presetActivitySchedules['thursday'] || {},
      sexta: presetActivitySchedules['friday'] || {},
      sabado: presetActivitySchedules['saturday'] || {},
      domingo: presetActivitySchedules['sunday'] || {},
    };
  };

  const { segunda, terca, quarta, quinta, sexta, sabado, domingo } =
    getDaysOfWeekObject();

  const handleCancelAttendance = () => {
    if (presetActivityPlanSchedules == undefined) {
      return setOpenAttendanceModal(false);
    }
    resetField('presetActivitySchedules', {
      defaultValue: PresetActivityPlanSchedulesUtils.transform(
        presetActivityPlanSchedules,
      ),
    });
    setOpenAttendanceModal(false);
  };

  const handleOnSelectAgenda = (itens: Agenda[]) => {
    verifyAndEnableSave();
    setValue('agendas', itens);
  };

  const handleOnSelectType = (event) => {
    verifyAndEnableSave();
    setValue('type', event?.target?.value);
  };

  const handleOnChangeTypeDescription = (event) => {
    verifyAndEnableSave();
    setValue('typeDescription', event?.target?.value);
  };

  const useStyles = makeStyles((theme) => ({
    tooltipFont: {
      fontSize: 12,
    },
  }));

  const classes = useStyles();

  return (
    <Container className="mt-40 center-cards-name-PlanGrid">
      <div className="col-12">
        <div className="card-model">
          <div className="align-header">
            <span className="value-title">
              {isActivity ? 'Agenda da atividade' : 'Agenda do plano'}
            </span>
            {isActivity ? (
              <button
                className="addNewAgenda"
                onClick={() => history.push('/new-schedule')}>
                + Criar nova agenda
              </button>
            ) : (
              <div></div>
            )}
          </div>
          {isActivity ? (
            <Cards onSubmit={handleOnSelectAgenda} values={watchAgendas} />
          ) : (
            <></>
          )}
          <div className="setSpace">
            <div className="width-100">
              <SimpleModal
                isOpen={openAttendanceModal}
                labelConfirm={'Definir'}
                labelCancel={'Cancelar'}
                modalTitle={'Horário de Atendimento'}
                onCancel={() => {
                  handleCancelAttendance();
                }}
                onConfirm={() => {
                  setOpenAttendanceModal(false);
                }}>
                <div className="attendanceHour">
                  <div className="row">
                    {daysOfWeek?.map((info) => attendanceDay(info))}
                  </div>
                </div>
              </SimpleModal>
              {open && (
                <div className="width-100">
                  <div className="flex-items2">
                    <Cards />
                  </div>
                  <div className="submit-div">
                    <button
                      className="PartnerDefaultButton align-flex-center"
                      onClick={() => handleChange(open)}>
                      <CheckOutlinedIcon /> &nbsp; Incluir grades
                    </button>
                  </div>
                </div>
              )}
              <div>
                <div className="inputWrapper">
                  {!hideGridFields?.duration && (
                    <div className="inputStyle col-md-2 col-sm-12 col-xs-12">
                      <span className="value-title">Duração da Sessão (Minutos)</span>
                      <input
                        //@ts-ignore
                        {...register('duration', {
                          required: true,
                          pattern: { value: /^[0-9]*$/ },
                        })}
                        type="text"
                        placeholder="Duração"
                      />
                    </div>
                  )}
                  {isActivity && (
                    <div className="inputStyle col-md-3 col-sm-12 col-xs-12">
                      <span className="value-title">
                        Tempo de Segurança (Horas)

                        <div className='tooltip__container'>
                          <Tooltip
                            classes={{ tooltip: classes.tooltipFont }}
                            title={"Quanto tempo de antecedência o cliente precisa para agendar um horário? Ex: O agendamento deve ser feito com no mínimo 1 hora de antecedência do início da sessão."}>
                            <Info />
                          </Tooltip>
                        </div>

                      </span>
                      <input
                        //@ts-ignore
                        {...register('safetyTime', {
                          required: true,
                          pattern: { value: /^[0-9]*$/ },
                        })}
                        type="number"
                        placeholder="Tempo de Segurança"
                      />
                    </div>
                  )}
                </div>
                {type != 'activity' && (
                  <div className="workTime">
                    <div className="header">
                      <span className="days">Horário de funcionamento</span>
                      <div
                        className="icon-calendar"
                        onClick={() => setOpenAttendanceModal(true)}>
                        <CalendarTodayOutlinedIcon className="icon-calendar" />
                        <span className="green-txt">
                          Editar horário de funcionamento
                        </span>
                      </div>
                    </div>
                    <div className="dates">
                      <div className="date-columns">
                        <PresetDay day={segunda} title={'Segunda-feira'} />
                        <PresetDay day={terca} title={'Terça-feira'} />
                        <PresetDay day={quarta} title={'Quarta-feira'} />
                        <PresetDay day={quinta} title={'Quinta-feira'} />
                      </div>
                      <div className="date-columns">
                        <PresetDay day={sexta} title={'Sexta-feira'} />
                        <PresetDay day={sabado} title={'Sábado'} />
                        <PresetDay day={domingo} title={'Domingo'} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="customInput ">
                  {/* TODO: i18n */}
                  <span className="value-title">
                    {isActivity ? 'Local da atividade' : 'Local do Plano'}
                  </span>
                  <select
                    onChange={handleOnSelectType}
                    className="customInput"
                    value={typePlace ?? ''}>
                    {Object.values(ActivityPlaceType).map((it, idx) => (
                      <option key={idx} value={it}>
                        {ActivityPlaceTypeUtils.getLabel(it)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="inputWrapper">
                  {typePlace === ActivityPlaceType.TO_CUSTOMER && (
                    <div className="inputStyle col-md-2 col-sm-12 col-xs-12">
                      <span className="value-title">
                        {isActivity
                          ? 'Descrição do tipo da atividade'
                          : 'Descrição do tipo do plano'}
                      </span>
                      <input
                        onChange={handleOnChangeTypeDescription}
                        value={typeDescription}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const PresetDay = ({
  day,
  title,
}: {
  day: {
    dontWork?: boolean;
    startAt?: string;
    endAt?: string;
    startLunch?: string;
    endLunch?: string;
  };
  title: string;
}) => {
  if (day?.dontWork === true) {
    return <div />;
  }

  return (
    <div className="date-div">
      <span className="week-days">{title}</span>
      <span className="days">
        {isEmpty(day?.startLunch) ||
        (day?.startLunch == '00:00' && day?.endLunch == '00:00')
          ? `${day.startAt} - ${day?.endAt}`
          : `${day?.startAt} - ${day?.startLunch} - ${day?.endLunch} - ${day?.endAt}`}
      </span>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    presetActivityPlanSchedules: state?.plan?.plan?.presetActivityPlanSchedules,
    agendas: state?.activity?.activity?.agendas,
  };
};

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PlanGrid),
) as React.ComponentType<any>;
