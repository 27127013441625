import styled from 'styled-components';

interface StatusColor {
  status: string;
}

export const Container = styled.div<StatusColor>`
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ status }) =>
    status == 'active'
      ? '#25B379'
      : status == 'pending'
      ? '#FE3C4C'
      : '#8D979E'};
  border-radius: 4px;
`;

export const TitleStatus = styled.span`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
`;
