import { isNil } from 'lodash';

class StringUtils {
  static isStringEmpty = (event?: string) => {
    return isNil(event) || event === '';
  };

  static limitString = (event: string, limit: number) => {
    if (event.length <= limit) return event;
    return event!.substring(0, limit!) + '...';
  };

  static isPasswordInvalid = (event: string) => {
    return (
      StringUtils.isStringEmpty(event) || event.length < 5 || event.length > 250
    );
  };

  static isSyntaxEmpty = (event?: string) => {
    return event === '${}' || event === '#{}';
  };

  static isSizeInvalid = (size: number, event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    return event?.length !== size;
  };

  static generateFileUrl = (file: string, type: string) => {
    const blobObject = StringUtils.b64toBlob(file, type, null);
    return URL.createObjectURL(blobObject);
  };

  static b64toBlob = (b64Data, contentType, size) => {
    const sliceSize = size ?? 512;

    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType ?? '' });
  };

  static isEmailInvalid = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    //  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const emailRegex = /\S+@\S+\.\S+/;
    return !emailRegex.test(event!);
  };

  static isCpfInvalid = (cpf?: string) => {
    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
    return !cpfRegex.test(cpf!);
  };

  static toObject = (key: string, event: any) => {
    const result = {};
    result[key] = event;
    return result;
  };

  static randomColor = () => {
    const colors: string[] = ['#14b071', '#4f35a5', '#1e98d6'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  static randomString = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  static b64EncodeUnicode = (str: string) => {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(Number(`0x${p1}`));
        },
      ),
    );
  };

  static removeAccentToLowerCase = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return '';

    let withoutAccent;

    withoutAccent = event!.replace(new RegExp('[Ç]', 'gi'), 'c');
    withoutAccent = withoutAccent.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    withoutAccent = withoutAccent.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    withoutAccent = withoutAccent.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    withoutAccent = withoutAccent.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    withoutAccent = withoutAccent.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');

    return withoutAccent.toLowerCase();
  };

  static currencyPtBr = (value?: number): string => {
    return (
      value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) ??
      'R$ 0.00'
    );
  };

  static currencyTwoZeros = (value?: number): string => {
    return value?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) ?? '';
  };

  static cnpjFormatter = (value: string) => {
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  };

  static newPhoneFormatter = (value?: string) => {
    if (!value) return '';

    let numbers = value?.replace(/\D/g, '');
    if (!numbers?.startsWith('55')) {
      numbers = '55' + numbers;
    }

    numbers = numbers.replace(/^(\d)/, '+$1');
    numbers = numbers.replace(/^(.{3})(\d)/, '$1($2');
    numbers = numbers.replace(/(.{6})(\d)/, '$1) $2');
    if (numbers.length == 12) {
      numbers = numbers.replace(/(.{1})$/, '-$1');
    } else if (numbers.length == 13) {
      numbers = numbers.replace(/(.{2})$/, '-$1');
    } else if (numbers.length == 14) {
      numbers = numbers.replace(/(.{3})$/, '-$1');
    } else if (numbers.length == 15) {
      numbers = numbers.replace(/(.{4})$/, '-$1');
    } else if (numbers.length > 15) {
      numbers = numbers.replace(/(.{4})$/, '-$1');
    }
    return numbers;
  };

  static cpfFormatter = (value) => {
    return value
      ?.replace(/\D/g, '')
      ?.replace(/(\d{3})(\d)/, '$1.$2')
      ?.replace(/(\d{3})(\d)/, '$1.$2')
      ?.replace(/(\d{3})(\d{2})/, '$1-$2')
      ?.replace(/(-\d{2})\d+?$/, '$1');
  };

  static CepFormatter = (value) => {
    return value.replace(/^([\d]{2})\.?([\d]{3})\-?([\d]{3})/, '$1.$2-$3');
  };

  static maskOnlyLetters = (value) => {
    return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');
  };

  static maskOnlyNumbers = (value) => {
    return value.replace(/\D/g, '');
  };

  static birthDateFormatter = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{4})(\d{2})(\d{2})+?$/, '$1-$2-$3');
  };

  static calcPercent(total: number, amount: number) {
    const percentage = (amount / total) * 100;
    return percentage.toFixed(1) + '%';
  }
}
export default StringUtils;
