import React, { FormEvent, useEffect, useState } from 'react';
import Icon from './resources/icons';
import Switch from '@mui/material/Switch';
// import ReactMarkdown from 'react-markdown';

import { IMessage } from './models/message.model';
import { setTheme } from './useTheme';
import './styles.scss';
import { IAgentChatBot } from './models/agent-chat-bot.model';
import ChatApi from './api';
import { IChat } from './models/chat.model';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/Stores';
import { AxiosResponse } from 'axios';
import { error, success } from 'src/Services/ToastService';
import { IAnswer } from './models/answer.model';
import { suggestions, initialMessage } from './resources/initialMessages';
import useWaitingMessage from './resources/hooks/useWaitingMessage';
import useTypingEffect from './resources/hooks/useTypingEffect';

import LoadingDots from './components/LoadingDots';

const ChatPage = () => {
  const [isBluefitMode, setIsBluefitMode] = useState(false);

  const [agents, setAgents] = useState<IAgentChatBot[]>([]);
  const [currentAgent, setCurrentAgent] = useState<IAgentChatBot>();
  const [chatSelectOpen, setChatSelectOpen] = useState(false);

  const [isAgentSelected, setIsAgentSelected] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [currentChatId, setCurrentChatId] = useState<number>(null);
  const [chats, setChats] = useState<IChat[]>([]);

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const messagesContainerRef = React.useRef<HTMLDivElement>(null);

  const [lastMessage, setLastMessage] = useState<IAnswer>(null);

  const [chatSuggestions, setChatSuggestions] = useState<string[]>([]);
  const [isChatSugestionsLoading, setIsChatSugestionsLoading] = useState(false);

  const [agentBotSuggestions, setAgentBotSuggestions] = useState<string[]>([]);
  const [isAgentSugestionsLoading, setIsAgentSugestionsLoading] =
    useState(false);

  const waitingMessage = useWaitingMessage();
  const [writingAnswer, isTyping] = useTypingEffect({
    stringToType: lastMessage?.content ?? '',
  });

  const profileImage = useSelector(
    (state: IRootState) => state?.profile?.profile?.image?.imageUrl,
  );

  useEffect(() => {
    const textElement = textAreaRef?.current;

    const scrollCallback = (e: KeyboardEvent) => {
      const target = e.target as HTMLTextAreaElement;
      if (!target.scrollHeight || target.scrollHeight < 65) {
        textElement.style.height = '40px';
        textElement.style.height = target.scrollHeight + 'px';
        return;
      }
      textElement.style.height = 'auto';
      textElement.style.height = target.scrollHeight + 'px';
      if (target.scrollHeight > 300) {
        textElement.style.overflowY = 'scroll';
      }
    };

    if (textElement) {
      textElement.addEventListener('keyup', scrollCallback);
    }

    return () => {
      if (textElement) {
        textElement.removeEventListener('keyup', scrollCallback);
      }
    };
  }, [textAreaRef]);

  useEffect(() => {
    if (isTyping || !currentChatId) return;
    let shouldUpdateState = true;
    setIsChatSugestionsLoading(true);
    ChatApi.getQuestionsSuggestions(currentChatId).then((response) => {
      if (response.status === 200 && shouldUpdateState) {
        setChatSuggestions(response.data);
      }
      setIsChatSugestionsLoading(false);
    });

    return () => {
      setIsChatSugestionsLoading(false);
      shouldUpdateState = false;
    };
  }, [isTyping, currentChatId]);

  useEffect(() => {
    const message = inputMessage.split('\n');
    if (message.length > 1 && message[0] === '') {
      setInputMessage(message[0]);
      textAreaRef.current.style.height = '40px';
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + 'px';
    }
  }, [inputMessage]);

  const getAgents = async () => {
    const response = await ChatApi.getAgents();
    if (response.status === 200) {
      setAgents([...response.data]);
      setCurrentAgent(response.data[0]);
    } else {
      error('Erro ao carregar agentes');
    }
  };

  const getChats = async () => {
    const response: AxiosResponse<IChat[]> = await ChatApi.getChats();
    if (response.status !== 200) {
      error('Erro ao carregar chats');
      return;
    }
    setChats(response.data.reverse());
  };

  const getAgentSuggestions = async () => {
    setIsAgentSugestionsLoading(true);
    const response = await ChatApi.getChatBotSuggestions(currentAgent?.id);
    if (response.status === 200) {
      setAgentBotSuggestions([...response.data]);
    } else {
      error('Erro ao carregar sugestões');
    }
    setIsAgentSugestionsLoading(false);
  };

  useEffect(() => {
    if (!currentAgent?.id) return;
    getAgentSuggestions();
  }, [currentAgent, isAgentSelected]);

  useEffect(() => {
    getChats();
    getAgents();
  }, []);

  useEffect(() => {
    if (messagesContainerRef?.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messagesContainerRef, currentChatId, writingAnswer, chatSuggestions]);

  const handleChangeAgent = (agent: IAgentChatBot) => {
    setCurrentAgent(agent);
    setChatSelectOpen(false);
  };

  useEffect(() => {
    if (!lastMessage?.content) return;
    setMessages((prev) => {
      if (!prev.length) return prev;
      const newMessages = [...prev];
      newMessages[newMessages.length - 1].answer.content = writingAnswer;
      return newMessages;
    });
  }, [writingAnswer]);

  const sendMessage = (content: string) => {
    if (content === '') {
      error('Digite uma mensagem');
      return;
    }

    setChatSuggestions([]);
    setMessages((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        question: {
          id: prev.length + 1,
          content,
        },
      },
    ]);
    if (messages.length > 0) {
      ChatApi.createChatCompletion(currentChatId, content)
        .then((response) => {
          setMessages((prev) => {
            const newMessages = [...prev];
            newMessages[newMessages.length - 1].answer = {
              id: response.data.id,
              content: '',
            };
            return newMessages;
          });

          setLastMessage({ content: response.data.answer.content });
        })
        .catch(() => {
          error('Erro ao enviar mensagem, tente novamente mais tarde');
        });
    } else {
      setIsAgentSelected(true);

      ChatApi.createChat({
        agentBot: {
          id: currentAgent.id,
        },
        question: {
          content,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            error('Erro ao criar chat, tente novamente mais tarde');
            return;
          }
          const { id, question, answer } = response.data.messages[0];
          setMessages([
            {
              id,
              question,
              answer: {
                id: answer.id,
                content: '',
              },
            },
          ]);
          setCurrentChatId(response.data.id);
          setLastMessage({ content: answer.content });
          getChats();
        })
        .catch(() => {
          error('Erro ao enviar mensagem, tente novamente mais tarde');
        });
    }
  };

  const handleSendMessage = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    setIsAgentSelected(true);

    sendMessage(inputMessage);
    setInputMessage('');
  };

  const handleNewChat = () => {
    setCurrentChatId(null);
    setCurrentAgent(agents[0]);
    setIsAgentSelected(false);
    setMessages([]);
  };

  // const handleChangeTheme = () => {
  //   setTheme(isBluefitMode ? 'keiken' : 'bluefit');
  //   setIsBluefitMode(!isBluefitMode);
  //   setCurrentAgent(4);
  // };

  const stringWithLessThan = (string: string, limit: number) => {
    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    }
    return string;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSendMessage();
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    sendMessage(suggestion);
  };

  const handleChatSelectClick = (chat: IChat) => {
    setCurrentChatId(chat.id);
    setMessages(chat.messages);
    setCurrentAgent(chat.agentChatBot);
    setIsAgentSelected(true);
    setChatSuggestions([]);
  };

  return (
    <div className="keiken-chat-container">
      {/* <nav>
        {!isBluefitMode ? (
          <img className="logo-img" src={Icon.logo} alt="" />
        ) : (
          <img
            className="logo-img"
            src="https://bluefit.com.br/themes/bluefit-20/assets/images/icons/logo-white.svg"
            alt="Logo Bluefit"
          />
        )}
        { <Switch checked={isBluefitMode} onChange={handleChangeTheme} /> }
      </nav> */}
      <section>
        <aside>
          <div className="new-btn-div">
            <button onClick={handleNewChat} className="new-chat-btn">
              + Novo Chat
            </button>
            <button className="new-chat-btn">
              <img src={Icon.home} alt="" />
            </button>
          </div>
          <div className="day-section">
            <p className="tag">Conversas antigas:</p>
            {chats?.map((chat) => (
              <button
                key={chat.id}
                onClick={() => {
                  handleChatSelectClick(chat);
                }}
                className={`aside-chat-btn ${
                  currentChatId === chat.id && 'active'
                }`}>
                <img src={Icon.message} alt="" />
                {stringWithLessThan(chat.messages[0].question.content, 30)}
              </button>
            ))}
          </div>
        </aside>
        <main>
          {!isAgentSelected ? (
            <div
              className="chat-selector-container"
              onMouseEnter={() => setChatSelectOpen(true)}
              onMouseLeave={() => setChatSelectOpen(false)}>
              <div className="chat-selector">
                <button className="active">
                  {isBluefitMode && currentAgent?.id === 6 ? (
                    <>
                      <img src={Icon.bluefitLogo} alt="" />
                      Bluefit Agent
                    </>
                  ) : (
                    <>
                      <img src={currentAgent?.iconUrl} alt="" />
                      {currentAgent?.name}
                    </>
                  )}
                </button>
                <button onClick={() => setChatSelectOpen(!chatSelectOpen)}>
                  <img src={Icon.user_3} alt="" />
                  agentes
                </button>
              </div>
              {chatSelectOpen && (
                <div className="agents-btns">
                  <h3>Escolha o agente mais adequado a sua necessidade</h3>
                  {agents?.map((agent) => (
                    <AgentBtn
                      key={agent.id}
                      agent={agent}
                      handleChangeAgent={handleChangeAgent}
                      isBluefitMode={isBluefitMode}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="chat-header">
              <img src={currentAgent?.iconUrl} alt="" />
              <h4>{currentAgent?.name}</h4>
            </div>
          )}
          {isAgentSelected ? (
            <div
              ref={messagesContainerRef}
              className="messages-container"
              style={{ scrollBehavior: 'smooth' }}>
              {messages?.map((message, idx) => (
                <div className="messages" key={message.id}>
                  <div className="question-with-icon">
                    <div className="message question-content">
                      {/* <ReactMarkdown
                        children={message.question?.content.replace(
                          '\n',
                          '  \n',
                        )}
                        components={{
                          a: ({ href, children }) => (
                            <a
                              href={href}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="markdown-link">
                              {children}
                            </a>
                          ),
                        }}
                      /> */}
                    </div>
                    <img src={profileImage} alt="" />
                  </div>

                  <div className="answer-with-icon">
                    <img
                      src={!isBluefitMode ? Icon.keiken2 : Icon.bluefitLogo}
                      alt=""
                    />
                    <div className="message answer-content">
                      {/* {message.answer?.content ? (
                        <ReactMarkdown
                          children={message.answer.content.replace(
                            '\n',
                            '  \n',
                          )}
                          components={{
                            a: ({ href, children }) => (
                              <a
                                href={href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="markdown-link">
                                {children}
                              </a>
                            ),
                          }}
                        />
                      ) : (
                        <p>{waitingMessage}</p>
                      )} */}
                    </div>
                  </div>
                  {isChatSugestionsLoading && idx === messages.length - 1 && (
                    <div className="questionSuggestions">
                      <LoadingDots />
                    </div>
                  )}
                  <div
                    className={`questionSuggestions ${
                      isChatSugestionsLoading ? 'opacity0' : ''
                    }`}>
                    {chatSuggestions.length > 0 &&
                      idx === messages.length - 1 &&
                      !isTyping &&
                      chatSuggestions.map((suggestion, idx) => (
                        <button
                          className="suggestionBtn"
                          key={idx}
                          onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion}
                        </button>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          ) : isBluefitMode ? (
            <div className="center-img">
              <img
                src="https://bluefit.com.br/themes/bluefit-20/assets/images/icons/logo-white.svg"
                alt=""
              />
            </div>
          ) : (
            <div ref={messagesContainerRef} className="messages-container">
              <div className="messages">
                <div className="answer-with-icon">
                  <img
                    src={!isBluefitMode ? Icon.keiken2 : Icon.bluefitLogo}
                    alt=""
                  />
                  <div className="message answer-content">
                    <p>{initialMessage}</p>
                  </div>
                </div>
                {isAgentSugestionsLoading && (
                  <div className={`questionSuggestions`}>
                    <LoadingDots />
                  </div>
                )}
                <div
                  className={`questionSuggestions ${
                    isAgentSugestionsLoading ? 'opacity0' : ''
                  }`}>
                  {agentBotSuggestions.map((suggestion, idx) => (
                    <button
                      className="suggestionBtn"
                      key={idx}
                      onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="message-input">
            <form onSubmit={handleSendMessage}>
              <div className="ask-input-container">
                <textarea
                  required
                  ref={textAreaRef}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Pergunte algo para nós!"
                />
                <button disabled={inputMessage === ''}>
                  <img src={Icon.send} alt="" />
                </button>
              </div>
            </form>
          </div>
          <div className="shadow-white" />
        </main>
      </section>
    </div>
  );
};

interface AgentBtnProps {
  isBluefitMode: boolean;
  agent: IAgentChatBot;
  handleChangeAgent: (agent: IAgentChatBot) => void;
}

const AgentBtn = ({
  isBluefitMode,
  agent,
  handleChangeAgent,
}: AgentBtnProps) => {
  // if (isBluefitMode && agent.id === 7) {
  //   return (
  //     <button onClick={() => handleChangeAgent(6)} className="agent-btn">
  //       <img src={Icon.bluefitLogo} alt="" />
  //       Agente Bluefit
  //     </button>
  //   );
  // }

  return (
    <button onClick={() => handleChangeAgent(agent)} className="agent-btn">
      <img src={agent.iconUrl} alt="" />
      {agent.name}
    </button>
  );
};

export default ChatPage;
