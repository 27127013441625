import { AxiosResponse } from 'axios';
import {
  put,
  all,
  call,
  fork,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import {
  getByToken,
  postUseToken,
  getByTokenError,
  getByTokenSuccess,
  postUseTokenError,
  postUseTokenSuccess,
  postUseTokenPlanError,
  postUseTokenPlanSuccess,
  postUseTokenPlan,
} from './actions';
import { CustomerTokenTypes } from './types';
import { error, success } from 'src/Services/ToastService';
import CustomerTokenApi from 'src/Services/Api/CustomerTokenApi';

export function* handleGetByToken(action) {
  try {
    const result: AxiosResponse = yield call(
      CustomerTokenApi.getByToken, action.payload
    );

    if (result.status === 200 && result.data !== null)
      yield put(getByTokenSuccess(result.data));
    else {
      yield put(getByTokenError(result.statusText));
      error("Token Inválido!!!");
    }
  } catch (err) {
    if (err instanceof Error && err.stack) yield put(getByTokenError(err.stack));
    else yield put(getByTokenError('An unknown error occured.'));

    error("Token Inválido!!!");
  }
}

export function* handlePostUseToken(action: ReturnType<typeof postUseToken>) {
  try {
    const result: AxiosResponse = yield call(
      CustomerTokenApi.postUseToken, action.payload
    );

    if (result.status === 200 && result.data !== null){
      yield put(postUseTokenSuccess(result?.data));
      success("Token validado com sucesso!");
    }else  {
      yield put(postUseTokenError(result.statusText));
      error("Erro ao validar token")
    }
  } catch (err) {
    if (err instanceof Error && err.stack){
      yield put(postUseTokenError(err.stack));
    }else {
      yield put(postUseTokenError('An unknown error occured.'));
    }
    error("Token expirado!");
  }
};

export function* handlePostUsePlanToken(action: ReturnType<typeof postUseTokenPlan>) {
  try {
    const result: AxiosResponse = yield call(
      CustomerTokenApi.postUsePlanToken, action.payload
    );
    
    if (result.status === 200 && result.data !== null){
      yield put(postUseTokenPlanSuccess(result?.data));
      success("Token validado com sucesso!");
    }else {
      yield put(postUseTokenPlanError(result.statusText));
      error("Erro ao validar token do plano");
    } 
  } catch (err) {
    if (err instanceof Error && err.stack){
      yield put(postUseTokenPlanError(err.stack));
    }else {
      yield put(postUseTokenPlanError('An unknown error occured.'));
    } 
    error("Token expirado!");
  }
};

function* watchGetByToken() {
  yield takeEvery(CustomerTokenTypes.GET_BY_TOKEN, handleGetByToken);
}

function* watchPostUseToken() {
  yield takeEvery(CustomerTokenTypes.POST_USE_TOKEN, handlePostUseToken);
}

function* watchPostUseTokenPlan() {
  yield takeEvery(CustomerTokenTypes.POST_USE_PLAN_TOKEN, handlePostUsePlanToken);
}


function* customerTokenSaga() {
  yield all([
    fork(watchGetByToken),
    fork(watchPostUseToken),
    fork(watchPostUseTokenPlan)
  ]);
}

export default customerTokenSaga;
