import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import AuthUtils from '../../Utils/AuthUtils';
import { error, success } from 'src/Services/ToastService';

const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  // tslint:disable-next-line: no-shadowed-variable
  instance.interceptors.request.use(async (config) => {
    const token = AuthUtils.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err: AxiosError) => {
      if (err.response?.config?.url != '/authenticate') {
        if (err.response?.data?.detail != null) {
          error(err.response.data.detail);
        }
      }
      const urlToBeCatchedRegex =
        /.*partner-place-financial-areas\/\d+\/withdraw/;

      const billingsHistoryUrl = /billings\/history/;

      if (err.response?.config?.url.match(urlToBeCatchedRegex)) {
        return Promise.reject(err);
      } else if (err.response?.config?.url.match(/.*\/authenticate/)) {
        return Promise.reject(err);
      } else if (err.response?.config?.url.match(billingsHistoryUrl)) {
        return error(
          'Ops! Tivemos um problema ao carregar o extrato. Tente novamente mais tarde, ou fale com o suporte.',
        );
      } else {
        return Promise.reject(
          'Ops! Tivemos um problema ao conectar com o servidor.',
        );
      }
    },
  );

  return instance;
};

export const api = createApiInstance();
export default {
  api,
};
