import './styles.scss';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/*
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';
import Name from 'src/Components/PartnerComponents/Name';
import Description from 'src/Components/PartnerComponents/Description';
import PlanGrid from 'src/Components/PartnerComponents/PlanGrid';
import PlanPrice from 'src/Components/PartnerComponents/PlanPrice';
import PlanFormat from 'src/Components/PartnerComponents/PlanFormat';
import UploadComponent from 'src/Components/PartnerComponents/UploadComponent';
import PlanCategory from 'src/Components/PartnerComponents/PlanCategory';
*/
export interface AddPack extends WithTranslation {}

const AddPack: React.FC<AddPack> = ({ t }) => {
  return <> </>;
  /*
  return (
    <div className="AddPack">
      <BaseHeaderBenefits
        step="create"
        color="green"
        title="Adicionar pacote"
        to="activities"
      />
      <div className="mt-100">
        <Name title="Nome do pacote" placeholder="Nome do pacote" /> 
        <Description title="Descrição do pacote" />
        <PlanGrid title="Agenda do pacote" subtitle="Agenda Manhã" />
        <PlanPrice title="Preço do pacote" />
        <PlanFormat title="Formato do pacote" />
        <UploadComponent title="Imagem do pacote" />
        <PlanCategory title="Categoria do pacote" />
        <div className="adjustPades"></div>
      </div>
      <BaseFooterBenefits
        text="Adicionar pacote"
        hasBackButton="true"
        linkTo="overview"
        textBack="Cancelar"
      />
    </div>
  );
  */
};

export default withTranslation()(AddPack);
