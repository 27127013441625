import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import './styles.scss';

import DefaultButton from '../DefaultButton';

export interface PageTitleProps extends WithTranslation {
  title: string;
  rightElement?: React.ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({ t, title, rightElement }) => {
  return (
    <div className="TitleRow">
      <Container className="title-row-center">
        <div className="Title">
          {t(`header.${title}`)}
          {rightElement != null && (
            <div className="right-element">{rightElement}</div>
          )}
        </div>
        <DefaultButton
          type="button"
          title={t('partner.validate_token')}
          action="isModal"
          iconModel="check"
          hasIcon={true}
        />
      </Container>
    </div>
  );
};

export default withTranslation()(PageTitle);
