import TextField from '@material-ui/core/TextField';
import { Add, Edit } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SimpleModal from '../SimpleModal';
import './styles.scss';

export interface ChildrenCardsProps extends WithTranslation {}

const dadosFantasia = {
  0: {
    nome_fantasia: 'Academia bem estar - cwb',
    razao_social: 'Academia',
    cnpj: '02.929.734/0001-30',
    endereco: 'Rua XV, 751 - Curitiba - PR',
  },
  1: {
    nome_fantasia: 'Academia bem estar - cwb2',
    razao_social: 'Academia',
    cnpj: '02.929.735/0001-30',
    endereco: 'Rua 24 de maio, 1942 - Curitiba - PR',
  },
};

// const dadosFantasia = {};

const EmptyCard: any = ({ t, onEdit }) => {
  return (
    <div className={'BusinessCard--center'}>
      <svg
        width="89"
        height="88"
        viewBox="0 0 89 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M84.0186 76.7963C84.0186 74.8838 82.4681 73.3333 80.5556 73.3333H77.0926V14.463C77.0926 12.5504 75.5422 11 73.6297 11H18.2223C16.3097 11 14.7593 12.5504 14.7593 14.463V73.3333H11.2963C9.38379 73.3333 7.83337 74.8838 7.83337 76.7963C7.83337 78.7088 9.3838 80.2593 11.2963 80.2593H80.5556C82.4681 80.2593 84.0186 78.7088 84.0186 76.7963ZM70.1667 17.9259V73.3333H21.6852V17.9259H70.1667ZM32.074 42.6667C32.074 42.3905 32.2979 42.1667 32.574 42.1667H41.9629C42.2391 42.1667 42.4629 42.3905 42.4629 42.6667V48.5926C42.4629 48.8687 42.2391 49.0926 41.9629 49.0926H32.574C32.2979 49.0926 32.074 48.8687 32.074 48.5926V42.6667ZM42.4629 28.8148C42.4629 28.5387 42.2391 28.3148 41.9629 28.3148H32.574C32.2979 28.3148 32.074 28.5387 32.074 28.8148V34.7408C32.074 35.0169 32.2979 35.2408 32.574 35.2408H41.9629C42.2391 35.2408 42.4629 35.0169 42.4629 34.7408V28.8148ZM32.074 56.5185C32.074 56.2424 32.2979 56.0185 32.574 56.0185H41.9629C42.2391 56.0185 42.4629 56.2424 42.4629 56.5185V62.4444C42.4629 62.7206 42.2391 62.9444 41.9629 62.9444H32.574C32.2979 62.9444 32.074 62.7206 32.074 62.4444V56.5185ZM59.7778 56.5185C59.7778 56.2424 59.5539 56.0185 59.2778 56.0185H49.8889C49.6128 56.0185 49.3889 56.2424 49.3889 56.5185V62.4444C49.3889 62.7206 49.6128 62.9444 49.8889 62.9444H59.2778C59.5539 62.9444 59.7778 62.7206 59.7778 62.4444V56.5185ZM49.3889 42.6667C49.3889 42.3905 49.6128 42.1667 49.8889 42.1667H59.2778C59.5539 42.1667 59.7778 42.3905 59.7778 42.6667V48.5926C59.7778 48.8687 59.5539 49.0926 59.2778 49.0926H49.8889C49.6128 49.0926 49.3889 48.8687 49.3889 48.5926V42.6667ZM59.7778 28.8148C59.7778 28.5387 59.5539 28.3148 59.2778 28.3148H49.8889C49.6128 28.3148 49.3889 28.5387 49.3889 28.8148V34.7408C49.3889 35.0169 49.6128 35.2408 49.8889 35.2408H59.2778C59.5539 35.2408 59.7778 35.0169 59.7778 34.7408V28.8148Z"
          fill="#8D979E"
        />
      </svg>
      <h4 className={'empty'}>{t('cards.children.empty')}</h4>
      <div
        onClick={() => {
          onEdit();
        }}
        className={'BusinessCard--button'}>
        <i>+</i>
        {t('cards.children.create')}
      </div>
    </div>
  );
};

const DataCard: any = ({ t, data, onEdit }) => {
  const elements = [];
  Object.keys(data).forEach((key) => {
    elements.push(
      <div className={'BusinessCard'}>
        <div className={'BusinessCard--header'}>
          <h4>{data[key].nome_fantasia}</h4>
          <div
            onClick={() => {
              onEdit();
            }}
            className={'BusinessCard--button--text'}>
            <Edit />
            {t('cards.children.edit')}
          </div>
        </div>
        <hr className={'hr'} />
        <p>{data[key].razao_social}</p>
        <p>{data[key].cnpj}</p>
        <p>{data[key].endereco}</p>
      </div>,
    );
  });

  return <>{elements}</>;
};

const EditChildren: any = ({ t }) => {
  return (
    <>
      <TextField
        label={t('cards.business.childName')}
        className={'BusinessCard--input'}
      />
      <TextField
        label={t('cards.business.fantasy')}
        className={'BusinessCard--input'}
      />
      <TextField
        label={t('cards.business.social')}
        className={'BusinessCard--input'}
      />
      <TextField
        label={t('cards.business.cnpj')}
        className={'BusinessCard--input'}
      />
      <hr className={'hr'} />
      <div className={'BusinessCard--header'}>
        <h3>{t('location.childrenTitle')}</h3>
      </div>
      <TextField label={t('location.zip')} className={'BusinessCard--input'} />
      <TextField
        label={t('location.street')}
        className={'BusinessCard--input'}
      />
      <TextField
        label={t('location.number')}
        className={'BusinessCard--input'}
      />
      <TextField
        label={t('location.neighboorhood')}
        className={'BusinessCard--input'}
      />
      <TextField label={t('location.city')} className={'BusinessCard--input'} />
      <TextField
        label={t('location.state')}
        className={'BusinessCard--input'}
      />
    </>
  );
};

export const BusinessChildrenCards: React.FC<ChildrenCardsProps> = ({ t }) => {
  const [createChildrenModal, setCreateChildrenModal] = useState(false);
  const [editChildrenModal, setEditChildrenModal] = useState(false);

  return (
    <>
      <div className={'BusinessCard'}>
        <div className={'BusinessCard--header'}>
          <h3>{'Filiais'}</h3>
          <div
            onClick={() => {
              setCreateChildrenModal(true);
            }}
            className={'BusinessCard--button'}>
            <Add />
            {t('cards.children.create')}
          </div>
        </div>
        <hr className={'hr'} />
        {(isEmpty(dadosFantasia) && (
          <>
            <EmptyCard
              t={t}
              onEdit={() => {
                setCreateChildrenModal(true);
              }}
            />
          </>
        )) || (
          <>
            <DataCard
              t={t}
              data={dadosFantasia}
              onEdit={() => {
                setEditChildrenModal(true);
              }}
            />
          </>
        )}
      </div>

      <SimpleModal
        isOpen={createChildrenModal}
        labelConfirm={'Salvar Alterações'}
        labelCancel={'Cancelar'}
        modalTitle={t('cards.children.create')}
        onCancel={() => {
          setCreateChildrenModal(false);
        }}>
        <EditChildren t={t} />
      </SimpleModal>

      <SimpleModal
        isOpen={editChildrenModal}
        labelConfirm={'Salvar Alterações'}
        labelCancel={'Cancelar'}
        modalTitle={t('cards.children.edit')}
        onCancel={() => {
          setEditChildrenModal(false);
        }}>
        <EditChildren t={t} />
      </SimpleModal>
    </>
  );
};

export default withTranslation()(BusinessChildrenCards);
