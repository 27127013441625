import _ from 'lodash';
import ProfileApi from 'src/Services/Api/ProfileApi';
import { Document } from './Document';
import { GeoPoint } from './GeoPoint';
import PartnerClientApi from 'src/Services/Api/PartnerClientApi';

export interface Address {
  city?: City;
  id?: number;
  street?: string;
  number?: string;
  zipcode?: string;
  district?: string;
  geoPoint?: GeoPoint;
  complement?: string;
  geolocated?: boolean;
  image?: Document;
}

export const defaultValue: Readonly<Address> = {
  geolocated: false,
};

export interface City {
  id?: number;
  name?: string;
  state?: State;
  active?: boolean;
}

export interface State {
  id?: number;
  name?: string;
  acronym?: string;
  active?: boolean;
}

const findByGoogleType = (result, type, variation = 'long_name') =>
  _.find(result, (it) => it.types.some((v) => v == type))?.[variation];
export class AddressUtils {
  static async fromViaCep(address: Address, cepObj: any) {
    const currentCity = (
      await ProfileApi.getCityByName({
        name: cepObj?.localidade,
        state: {
          acronym: cepObj?.uf,
        },
      })
    )?.data?.content?.[0];
    return {
      id: address?.id,
      city: currentCity,
      number: '',
      zipcode: cepObj?.cep?.replace?.(/\D/g, ''),
      district: cepObj?.bairro,
      street: cepObj?.logradouro,
      complement: cepObj?.complemento,
    };
  }
  static async getGeoPointFromApi(newAddress: Address) {
    const result = await PartnerClientApi.getAddressPartner(
      AddressUtils.format(newAddress),
    );
    const response = result.data;
    return response?.[0]?.geoPoint;
    // const response = await Geocode.fromAddress(AddressUtils.format(newAddress));
    // const location = response?.results?.[0]?.geometry?.location;
    // if (!location) return null;
    // return this.builderGeoPoint(location?.lat, location?.lng);
  }

  static async fromGoogleComponents(result: any) {
    const cityName = findByGoogleType(result, 'administrative_area_level_2');
    const stateAcronym = findByGoogleType(
      result,
      'administrative_area_level_1',
      'short_name',
    );
    const city = (
      await ProfileApi.getCityByName({
        name: cityName,
        state: { acronym: stateAcronym },
      })
    )?.data?.content?.[0];
    return {
      street: findByGoogleType(result, 'route'),
      number: findByGoogleType(result, 'street_number'),
      zipcode: findByGoogleType(result, 'postal_code')?.replace(/\D/g, ''),
      district: findByGoogleType(result, 'sublocality_level_1'),
      city,
    };
  }
  static format(address: Address) {
    const values = [
      address?.street,
      address?.number,
      address?.complement,
      address?.district,
      address?.city?.name,
      address?.city?.state?.name,
      address?.zipcode,
    ].filter((it) => it != null && it != '');

    return values.join(', ');
  }

  static builderGeoPoint(lat: any, lng: any): GeoPoint {
    return {
      latitude: lat ?? '',
      longitude: lng ?? '',
      point: {
        coordinates: [lng ?? '', lat ?? ''],
        type: 'Point',
      },
    };
  }
}
