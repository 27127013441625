import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PieDataPartner, SubCategory } from 'src/Models/FinancialDashboard';
import StringUtils from 'src/Utils/StringUtils';

interface TopCategoryCardProps {
  category: PieDataPartner;
}

const TopCategoryCard = ({ category }: TopCategoryCardProps) => {
  const { t } = useTranslation();

  const orderedSubCategories = _.orderBy(category?.subCategoriesData, 'count', [
    'desc',
    'asc',
  ]);

  const firstSubCategoryCount = orderedSubCategories[0]?.count ?? 0;
  const secondSubCategoryCount = orderedSubCategories[1]?.count ?? 0;
  const othersSubCategoriesCount =
    category?.count - (firstSubCategoryCount + secondSubCategoryCount);

  return (
    <div className="card-model" style={{ marginBottom: '20px' }}>
      <span className="moneyText">
        {t('finances.activities.subCategories')}
      </span>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="value-title">{category?.name}</span>
        <span className="moneyText">
          {t('finances.activities.total')}: {category?.count}
        </span>
      </div>
      <div className="alignTags">
        {orderedSubCategories?.slice(0, 2).map((subCategory) => (
          <span
            className="tag-green"
            style={{
              color: category?.color ?? '#25b379',
            }}
            key={subCategory?.id}>
            {subCategory?.name}
            <span style={{ marginLeft: '6px' }}>
              {StringUtils?.calcPercent(category?.count, subCategory?.count) +
                `  (${subCategory?.count})`}
            </span>
          </span>
        ))}
        {othersSubCategoriesCount > 0 && (
          <div
            className="tag-green"
            style={{ color: category?.color ?? '#25b379' }}>
            <span>Outras</span>
            <span style={{ marginLeft: '6px' }}>
              {StringUtils?.calcPercent(
                category?.count,
                othersSubCategoriesCount,
              ) + `  (${othersSubCategoriesCount})`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopCategoryCard;
