import { api } from '.';
import { AxiosResponse } from 'axios';
import { ActivityCategory } from '../../Models/ActivityCategory';
import { ActivitySubCategory } from '../../Models/ActivitySubCategory';

export const CategoryApi = () => {
  const getCategories = (): Promise<AxiosResponse<ActivityCategory[]>> => {
    return api.get<ActivityCategory[]>(
      '/activity-categories/all?size=50&type=REGULAR',
    );
  };

  const getSubcategories = (): Promise<
    AxiosResponse<ActivitySubCategory[]>
  > => {
    return api.get<ActivitySubCategory[]>(
      '/activity-sub-categories/list?size=50',
    );
  };

  return {
    getCategories,
    getSubcategories,
  };
};

export default CategoryApi();
