import { Partner } from 'src/Models/Partner';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { User } from '../../Models/user';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
  LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@authentication/LOGIN_ERROR',

  GET_SESSION_REQUEST = '@@authentication/GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS = '@@authentication/GET_SESSION_SUCCESS',
  GET_SESSION_ERROR = '@@authentication/GET_SESSION_ERROR',

  LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST',

  LOGIN_RESET_STATUS = '@@authentication/LOGIN_RESET_STATUS',

  CHANGE_PASSWORD = '@@authentication/CHANGE_PASSWORD',
  CHANGE_PASSWORD_ERROR = '@@authentication/CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_SUCCESS = '@@authentication/CHANGE_PASSWORD_SUCCESS',

  FORGOT_PASSWORD = '@@authentication/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = '@@authentication/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = '@@authentication/FORGOT_PASSWORD_ERROR',

  FORGOT_PASSWORD_RESET_STATUS = '@@authentication/FORGOT_PASSWORD_RESET_STATUS',
}

export interface AuthenticationState {
  readonly loginStatus: HttpRequestStatus;
  readonly getSessionStatus: HttpRequestStatus;
  readonly isAuthenticated: boolean;
  readonly account?: User;
  readonly error?: HttpResponseError;
  readonly partner?: Partner;
  readonly forgotPasswordStatus?: HttpRequestStatus;
}
