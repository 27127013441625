import React from 'react';
import PageTitle from 'src/Components/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import Management from 'src/Components/PartnerComponents/ActivityManagement';

export interface ActivitiesProps extends WithTranslation {}

const Activities: React.FC<ActivitiesProps> = () => {
  return (
    <>
      <PageTitle title={'activity'} type="services" />
      <Management />
    </>
  );
};

export default withTranslation()(Activities);
