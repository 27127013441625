import { AxiosResponse } from 'axios';
import { api } from '.';
import { Activity } from '../../Models/Activity';

export const ActivityApi = () => {
  const postActivity = (
    activity: Activity,
  ): Promise<AxiosResponse<Activity>> => {
    return api.post<Activity>('/activities', activity);
  };

  const putActivity = (
    activity: Activity,
  ): Promise<AxiosResponse<Activity>> => {
    return api.put<Activity>('/activities', activity);
  };

  const getActivities = (): Promise<AxiosResponse<Activity[]>> => {
    return api.get<Activity[]>('/activities/partner-place');
  };

  const deleteActivity = (activityId: number): Promise<AxiosResponse<any>> => {
    return api.delete<any>(`/activities/${activityId}`);
  };

  const getActivity = (
    activityId: number,
  ): Promise<AxiosResponse<Activity>> => {
    if (activityId == null) {
      throw new Error('ActivityId is null');
    }
    return api.get<Activity>(`/activities/${activityId}/partner-place`);
  };

  const getScheduleHistory = (offset = 0) => {
    return api.get(
      `/activities/partner-place/actives/history?offset=${offset}`,
    );
  };

  return {
    getActivity,
    putActivity,
    postActivity,
    getScheduleHistory,
    getActivities,
    deleteActivity,
  };
};

export default ActivityApi();
