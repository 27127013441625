import jwt_decode from 'jwt-decode';
import { AUTHORITIES } from 'src/Config/Constants';
import { DEFAULT_ROUTE_PARTNER } from 'src/Routes/AppRoutes';

class AuthUtils {
  static AUTH_TOKEN_KEY = 'jhi-authenticationToken';

  static isAuthenticated = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY) !== null;
  };

  static isPartner = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.PARTNER);
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ADMIN);
  };

  static getToken = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static setToken = (token: string) => {
    localStorage.setItem(AuthUtils.AUTH_TOKEN_KEY, token);
  };

  static removeToken = () => {
    localStorage.removeItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static getMainPath = () => {
    // return AuthUtils.isAdmin() ? '/admin' : AuthUtils.isUser() ? '/home' : '/';
    return AuthUtils.isPartner() ? DEFAULT_ROUTE_PARTNER : '/logout';
  };
}

export default AuthUtils;
