import { AxiosResponse } from 'axios';
import { IAgentChatBot } from '../models/agent-chat-bot.model';
import { IChat } from '../models/chat.model';
import { IMessage } from '../models/message.model';
import { api } from 'src/Services/Api';

interface QChat {
  agentBot: {
    id: number;
  };
  question: {
    content: string;
  };
}

export const ChatApi = () => {
  const getAgents = () => {
    return api.get<IAgentChatBot[]>('/keiken-chat/agent-bots');
  };

  const getChat = (chatId: number) => {
    return api.get<IChat>(`/keiken-chat/chats/${chatId}`);
  };

  const getChats = () => {
    return api.get<IChat[]>('/keiken-chat/chats');
  };

  const createChat = (chat: QChat) => {
    return api.post<IChat>('/keiken-chat/chats', chat);
  };

  const createChatCompletion = (
    chatId: number,
    question: string,
  ): Promise<AxiosResponse<IMessage>> => {
    return api.post<any>(`/keiken-chat/chats/${chatId}/completions`, {
      question: {
        content: question,
      },
    });
  };

  const getQuestionsSuggestions = (chatId: number) => {
    return api.get<string[]>(`/keiken-chat/chats/${chatId}/suggestions`);
  };

  const getChatBotSuggestions = (agentId: number) => {
    return api.get<string[]>(`/keiken-chat/agent-bots/${agentId}/suggestions`);
  };

  return {
    getAgents,
    getChat,
    getChats,
    createChat,
    createChatCompletion,
    getQuestionsSuggestions,
    getChatBotSuggestions,
  };
};

export default ChatApi();
