import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum CustomerTokenTypes {
  GET_BY_TOKEN = '@@customerToken/GET_BY_TOKEN',
  GET_BY_TOKEN_ERROR = '@@customerToken/GET_BY_TOKEN_ERROR',
  GET_BY_TOKEN_SUCCESS = '@@customerToken/GET_BY_TOKEN_SUCCESS',

  POST_USE_TOKEN = '@@customerToken/POST_USE_TOKEN',
  POST_USE_TOKEN_ERROR = '@@customerToken/POST_USE_TOKEN_ERROR',
  POST_USE_TOKEN_SUCCESS = '@@customerToken/POST_USE_TOKEN_SUCCESS',
    
  POST_USE_PLAN_TOKEN = '@@customerToken/POST_USE_PLAN_TOKEN',
  POST_USE_PLAN_TOKEN_ERROR = '@@customerToken/POST_USE_PLAN_TOKEN_ERROR',
  POST_USE_PLAN_TOKEN_SUCCESS = '@@customerToken/POST_USE_PLAN_TOKEN_SUCCESS',
}

export interface CustomerTokenState {
  readonly tokenObj?: any;
  readonly customerActivity?: any;
  readonly error?: HttpResponseError;
  readonly status?: HttpRequestStatus; 
}
