import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerAll,
  ContainerBenefits,
  ContainerImgAndStatus,
  ContainerInfoUser,
  ContainerLastActivities,
  ContainerTop,
  ContainerUserData,
  Divider,
  DividerInfos,
  HeaderStyled,
  ImgHeader,
  ImgStyled,
  ImgUser,
  TitleEmailPhone,
  TitleHeader,
  TitleInfos,
  TitleName,
} from './styles';
import Close from 'src/Images/Icons/fechar-7.svg';
import { useHistory, useParams } from 'react-router-dom';
import UserSvg from '../../Images/Icons/avatarPaymentsPending.svg';
import Status from '../Status';
import LastActivities from '../LastActivities';
import PartnerClientApi from 'src/Services/Api/PartnerClientApi';
import { HistoryPaymentsUser } from 'src/Models/historyPayments';
import PartnerClientService from 'src/Services/PartnerClient.service';
import StringUtils from 'src/Utils/StringUtils';
import { useTranslation } from 'react-i18next';
import { PartnerStatus } from 'src/Models/Customer';

const HistoryUserPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [historyPaymentsUser, setHistoryPaymentsUser] =
    useState<HistoryPaymentsUser[]>();
  const [userStatus, setUserStatus] = useState<string>();

  useEffect(() => {
    let id = '';
    if (history.location.search.includes('&')) {
      id = history.location.search
        .slice(
          history.location.search.indexOf('='),
          history.location.search.indexOf('&'),
        )
        .replace('=', '');
      setUserStatus(
        history.location.search
          .slice(
            history.location.search.indexOf('&'),
            history.location.search.length,
          )
          .replace('&status=', ''),
      );
    } else {
      id = history.location.search.replace('?id=', '');
    }
    (async () => {
      const res = await PartnerClientService.getHistoryPaymentsUser(id);
      setHistoryPaymentsUser(res);
    })();
  }, []);

  return (
    <ContainerAll>
      <HeaderStyled>
        <ImgHeader src={Close} onClick={() => history.goBack()} />
        <TitleHeader>{t('makePurchase.history.collaboratorData')}</TitleHeader>
      </HeaderStyled>
      <Container>
        <ContainerTop>
          <ContainerUserData>
            <ImgUser src={UserSvg} />
            <ContainerInfoUser>
              <TitleName>
                {historyPaymentsUser && historyPaymentsUser[0].customer.name}
              </TitleName>
              <ContainerImgAndStatus>
                <ImgStyled src={UserSvg} />
                <Divider />
                <Status title={userStatus && userStatus.toLowerCase()} />
              </ContainerImgAndStatus>
              <TitleEmailPhone>
                {historyPaymentsUser &&
                  historyPaymentsUser[0].customer.user.email}
              </TitleEmailPhone>
              <TitleEmailPhone>
                {historyPaymentsUser &&
                  StringUtils.newPhoneFormatter(
                    historyPaymentsUser[0].customer.phones[0].number,
                  )}
              </TitleEmailPhone>
            </ContainerInfoUser>
          </ContainerUserData>
        </ContainerTop>
        <ContainerLastActivities>
          <TitleInfos>{t('makePurchase.history.lastActivities')}</TitleInfos>
          <DividerInfos />
          {historyPaymentsUser &&
            historyPaymentsUser.length > 0 &&
            historyPaymentsUser.map((element, index) => {
              if (index < 4) {
                return (
                  <LastActivities
                    payments={element}
                    key={index}
                    index={index}
                  />
                );
              }
            })}
        </ContainerLastActivities>
      </Container>
    </ContainerAll>
  );
};

export default HistoryUserPage;
