import React, { useEffect, useState } from 'react';
import { Fade, Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import BaseModalTokenStyles from '../../BaseModalToken/BaseModalTokenStyles';
import { CheckCircle, Cancel } from '@material-ui/icons';
import moment from 'moment';
import StringUtils from 'src/Utils/StringUtils';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { getCustomerActivityPlanById } from 'src/Stores/customerActivityPlan/actions';

export interface EnrolmentModalProps
  extends WithTranslation,
    DispatchProps,
    StateProps {
  id?: number;
  customerPlan?: CustomerActivityPlan;
  openModal?: boolean;
  closeModal?: () => void;
  denyCustomerActivityPlan?: (id?: number) => void;
  approveCustomerActivityPlan?: (id?: number) => void;
}

const EnrolmentModal: React.FC<EnrolmentModalProps> = ({
  t,
  id,
  customerPlan,
  openModal,
  closeModal,
  denyCustomerActivityPlan,
  approveCustomerActivityPlan,
  getCustomerActivityPlanById,
  customerActivity,
}) => {
  const useModalStyles = BaseModalTokenStyles;
  const classesModal = useModalStyles();

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      className={classesModal.modal}>
      <Fade in={openModal}>
        <div className={classesModal.enrolmentModal}>
          <div className={classesModal.align}>
            <h2 className={classesModal.title}>
              {customerPlan?.activityPlan?.name}
            </h2>
            <CloseIcon onClick={closeModal} className={classesModal.icon} />
          </div>
          <div className={classesModal.underline} />
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <span className={classesModal.modalTitle}>
              {customerPlan?.customer?.name}
            </span>
            <br />
            <span className={classesModal.modalSubtitle}>
              {customerPlan?.customer?.user?.email}
            </span>
            <br />
            <h2 className={classesModal.modalTitle}>CPF</h2>
            <span className={classesModal.modalSubtitle}>
              {StringUtils?.cpfFormatter(customerPlan?.customer?.cpf)}
            </span>
            <br />
            <h2 className={classesModal.modalTitle}>Telefone</h2>
            <span className={classesModal.modalSubtitle}>
              {StringUtils.newPhoneFormatter(
                customerPlan?.customer?.phones[0]?.number,
              )}
            </span>
            <br />
            <h2 className={classesModal.modalTitle}>Data de nascimento</h2>
            <span className={classesModal.modalSubtitle}>
              {moment(customerPlan?.customer?.birthDate).format('DD/MM/YYYY')}
            </span>
            <br />
            <h2 className={classesModal.modalTitle}>Endereço</h2>
            <span className={classesModal.modalSubtitle}>
              {customerPlan?.customer?.address?.street +
                ',' +
                customerPlan?.customer?.address?.number}
            </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div>
              <span className={classesModal.modalTitle}>Datas de Início</span>
              <div className={classesModal.enabledCard}>
                <ScheduleIcon className="mr-5px" />
                <span>
                  {moment(customerPlan?.startPeriodDate).format(
                    'ddd D MMM YYYY',
                  )}
                </span>
              </div>
            </div>
            <div>
              <span className={classesModal.modalTitle}>Data de término</span>
              <div
                className={classesModal.enabledCard}
                style={{ borderColor: 'red', color: 'red' }}>
                <ScheduleIcon className="mr-5px" />
                <span>
                  {moment(customerPlan?.endPeriodDate).format('ddd D MMM YYYY')}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}>
            <div>
              <CheckCircle
                color="secondary"
                className="approve"
                style={{ cursor: 'pointer' }}
                onClick={() => approveCustomerActivityPlan(customerPlan?.id)}
              />
              <span
                className={classesModal.modalTitle}
                style={{ marginRight: '20px' }}>
                Aprovar
              </span>
            </div>
            <div>
              <Cancel
                color="error"
                className="deny"
                style={{ cursor: 'pointer' }}
                onClick={() => denyCustomerActivityPlan(customerPlan?.id)}
              />
              <span className={classesModal.modalTitle}>Negar</span>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const customerActivity = state.customerActivityPlan.customerActivityPlan;
  return {
    customerActivity,
  };
};

const mapDispatchToProps = {
  getCustomerActivityPlanById,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EnrolmentModal),
) as React.ComponentType<any>;
