import { Reducer } from 'redux';
import { FinancialDashboard } from 'src/Models/FinancialDashboard';
import { PartnerDashboard } from 'src/Models/PartnerDashboard';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { PartnerDashboardActionTypes, PartnerDashboardState } from './types';

export const initialState: PartnerDashboardState = {
  partnerDashboard: null,
  getPartnerDashboardStatus: HttpRequestStatus.NOOP,
  financialDashboard: null,
  getFinancialDashboardStatus: HttpRequestStatus.NOOP,
  pieDataList: [],
  getPieDataStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<PartnerDashboardState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD: {
      return { ...state, getPartnerDashboardStatus: HttpRequestStatus.ONGOING };
    }
    case PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD_SUCCESS: {
      const partnerDashboard = action?.payload as PartnerDashboard;

      return {
        ...state,
        getPartnerDashboardStatus: HttpRequestStatus.SUCCESS,
        partnerDashboard: partnerDashboard,
      };
    }
    case PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD_ERROR: {
      return {
        ...state,
        getPartnerDashboardStatus: HttpRequestStatus.ERROR,
      };
    }

    case PartnerDashboardActionTypes.GET_PIE_DATA: {
      return {
        ...state,
        getPieDataStatus: HttpRequestStatus.ONGOING,
      };
    }
    case PartnerDashboardActionTypes.GET_PIE_DATA_SUCCESS: {
      const pieDataList = action?.payload;
      return {
        ...state,
        pieDataList: pieDataList,
        getPieDataStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case PartnerDashboardActionTypes.GET_PIE_DATA_ERROR: {
      return {
        ...state,
        getPieDataStatus: HttpRequestStatus.ERROR,
      };
    }

    case PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD: {
      return {
        ...state,
        getFinancialDashboardStatus: HttpRequestStatus.ONGOING,
      };
    }
    case PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD_SUCCESS: {
      const financialDashboard = action?.payload as FinancialDashboard;
      return {
        ...state,
        financialDashboard: financialDashboard,
        getFinancialDashboardStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD_ERROR: {
      return {
        ...state,
        getFinancialDashboardStatus: HttpRequestStatus.ERROR,
      };
    }
    case PartnerDashboardActionTypes.RESET_FINANCIAL_DASHBOARD_STATUS: {
      return {
        ...state,
        financialStatus: HttpRequestStatus.NOOP,
      };
    }
    case PartnerDashboardActionTypes.RESET_STATUS: {
      return {
        ...state,
        getPartnerDashboardStatus: HttpRequestStatus.NOOP,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as partnerDashboardReducer };
