import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { Customer } from 'src/Models/Customer';
import { Address } from 'src/Models/Address';
import PartnerClientApi from './Api/PartnerClientApi';
import HttpStatus from 'src/Models/Enums/HttpStatus';
import { PartnerClient } from 'src/Models/PartnerClient';
import { PageableResponse } from 'src/Models/pageable';
import { Filters } from './Plan.service';
import { HistoryPaymentsUser } from 'src/Models/historyPayments';
import { Billing } from 'src/Models/Billing';

interface Response {
  content: Customer[];
}

interface SaveEdit {
  id: number;
  cpf?: string;
  address?: Address;
}

class PartnerClientService {
  static getCustomers = async (
    size?: number,
    page?: number,
    filters?: Filters,
  ): Promise<PageableResponse<Customer>> => {
    try {
      const partners = await PartnerClientApi.getPartnerClients(
        size,
        page,
        filters,
      );
      return Promise.resolve(partners.data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e.response as AxiosResponse);
    }
  };

  static saveEditCustomers = async (obj: SaveEdit) => {
    try {
      const response = await PartnerClientApi.saveEditPartnerClient(obj);
      return Promise.resolve(HttpStatus.OK);
    } catch (e) {
      console.error(e);
      return e.response.data.fieldErrors;
    }
  };

  static getAddressCustomers = async (street: string): Promise<Address> => {
    try {
      const response = await PartnerClientApi.getAddressPartner(street);
      return Promise.resolve(response.data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e.response as AxiosResponse);
    }
  };

  static getHistoryPaymentsUser = async (
    userId: string,
  ): Promise<HistoryPaymentsUser[]> => {
    try {
      const response = await PartnerClientApi.getPaymentsHistory(userId);
      return Promise.resolve(response.data.content);
    } catch (e) {
      console.error(e);
      return Promise.reject(e.response as AxiosResponse);
    }
  };

  static requestPartnerWithDraw = async (params: {
    id: number;
    value: number;
  }): Promise<any> => {
    try {
      const response: AxiosResponse<any> =
        await PartnerClientApi.requestPartnerWithDraw(params);
    } catch (e) {
      if (e.response.data.code === 'error.pix.account.not.found') {
        return Promise.reject({
          code: 'pix.account.not.found',
        });
      } else {
        return Promise.reject(e.response as AxiosResponse);
      }
    }
  };
}

export default PartnerClientService;
