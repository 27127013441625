import { BillingState } from './billing/types';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { authenticationReducer } from './authentication/reducer';
import { activityScheduleReducer } from './schedule/reducer';
import { customerTokenReducer } from './customerToken/reducer';
import { bankAccountReducer } from './bankAccount/reducer';
import { activityReducer } from './activity/reducer';
import { categoryReducer } from './category/reducer';
import { partnerClientReducer } from './client/reducer';
import { profileReducer } from './profile/reducer';
import { planReducer } from './plan/reducer';
import profileSaga from './profile/sagas';
import authenticationSaga from './authentication/sagas';
import activitySaga from './activity/sagas';
import conferenceSaga from './conference/sagas';
import { ProfileState } from './profile/types';
import { AuthenticationState } from './authentication/types';
import { CustomerTokenState } from './customerToken/types';
import { BankAccountState } from './bankAccount/types';
import { conferenceReducer } from './conference/reducer';
import { ConferenceState } from './conference/types';
import { ActivityState } from './activity/types';
import { CategoryState } from './category/types';
import { ActivityScheduleState } from './schedule/types';
import { PlanState } from './plan/types';
import { PartnerClientState } from './client/types';
import categorySaga from './category/sagas';
import scheduleSaga from './schedule/sagas';
import partnerClientSaga from './client/sagas';
import customerTokenSaga from './customerToken/sagas';
import planSaga from './plan/sagas';
import bankSaga from './bankAccount/sagas';
import { AgendaState } from './agenda/types';
import { agendaReducer } from './agenda/reducer';
import agendaSaga from './agenda/sagas';
import { imageReducer } from './image/reducer';
import imageSaga from './image/sagas';
import { ImageState } from './image/types';
import { CustomerActivtiyPlanState } from './customerActivityPlan/types';
import { customerActivityPlanReducer } from './customerActivityPlan/reducer';
import customerActivityPlanSaga from './customerActivityPlan/sagas';
import billingReducer from './billing/reducer';
import billingsSaga from './billing/sagas';
import { PartnerDashboardState } from './partnerDashboard/types';
import { partnerDashboardReducer } from './partnerDashboard/reducer';
import partnerDashboardSaga from './partnerDashboard/sagas';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly customerToken: CustomerTokenState;
  readonly partnerClient: PartnerClientState;
  readonly schedule: ActivityScheduleState;
  readonly bankAccount: BankAccountState;
  readonly conference: ConferenceState;
  readonly activity: ActivityState;
  readonly category: CategoryState;
  readonly profile: ProfileState;
  readonly plan: PlanState;
  readonly agenda: AgendaState;
  readonly image: ImageState;
  readonly customerActivityPlan: CustomerActivtiyPlanState;
  readonly billings: BillingState;
  readonly partnerDashboard: PartnerDashboardState;
}

const rootReducer = combineReducers<IRootState>({
  authentication: authenticationReducer,
  partnerClient: partnerClientReducer,
  customerToken: customerTokenReducer,
  schedule: activityScheduleReducer,
  bankAccount: bankAccountReducer,
  conference: conferenceReducer,
  activity: activityReducer,
  category: categoryReducer,
  profile: profileReducer,
  plan: planReducer,
  agenda: agendaReducer,
  image: imageReducer,
  customerActivityPlan: customerActivityPlanReducer,
  billings: billingReducer,
  partnerDashboard: partnerDashboardReducer,
});

export function* rootSaga() {
  yield all([
    fork(authenticationSaga),
    fork(activitySaga),
    fork(planSaga),
    fork(categorySaga),
    fork(scheduleSaga),
    fork(partnerClientSaga),
    fork(conferenceSaga),
    fork(profileSaga),
    fork(bankSaga),
    fork(customerTokenSaga),
    fork(agendaSaga),
    fork(imageSaga),
    fork(customerActivityPlanSaga),
    fork(billingsSaga),
    fork(partnerDashboardSaga),
  ]);
}

export default rootReducer;
