import {
  FinancialDashboard,
  PieDataPartner,
} from 'src/Models/FinancialDashboard';
import { PartnerDashboard } from 'src/Models/PartnerDashboard';
import { action } from 'typesafe-actions';
import { PartnerDashboardActionTypes } from './types';

export const getPartnerDashboard = () =>
  action(PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD);
export const getPartnerDashboardSuccess = (dashboardInfo: PartnerDashboard) =>
  action(
    PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD_SUCCESS,
    dashboardInfo,
  );
export const getPartnerDashboardError = (message: string) =>
  action(PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD_ERROR, message);

export const getFinancialDashboard = () =>
  action(PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD);
export const getFinancialDashboardSuccess = (
  dashboardInfo: FinancialDashboard,
) =>
  action(
    PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD_SUCCESS,
    dashboardInfo,
  );
export const getFinancialDashboardError = (message: string) =>
  action(PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD_ERROR, message);

export const getPieData = () =>
  action(PartnerDashboardActionTypes.GET_PIE_DATA);
export const getPieDataSuccess = (pieDataList: PieDataPartner[]) =>
  action(PartnerDashboardActionTypes.GET_PIE_DATA_SUCCESS, pieDataList);
export const getPieDataError = (message: string) =>
  action(PartnerDashboardActionTypes.GET_PIE_DATA_ERROR, message);

export const resetStatus = () =>
  action(PartnerDashboardActionTypes.RESET_STATUS);
export const resetFinancialStatus = () =>
  action(PartnerDashboardActionTypes.RESET_FINANCIAL_DASHBOARD_STATUS);
