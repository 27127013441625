import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';

import { Container } from 'reactstrap';
import './styles.scss';
import Name from 'src/Components/PartnerComponents/Name';
import Description from 'src/Components/PartnerComponents/Description';
import PlanGrid from 'src/Components/PartnerComponents/PlanGrid';
import PlanPrice from 'src/Components/PartnerComponents/PlanPrice';
import UploadComponent from 'src/Components/PartnerComponents/UploadComponent';
import PlanCategory from 'src/Components/PartnerComponents/PlanCategory';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';
import { IRootState } from 'src/Stores';
import { postActivity, putActivity } from 'src/Stores/activity/actions';
import ActivityApi from 'src/Services/Api/ActivityApi';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { error } from 'src/Services/ToastService';
import { Activity } from '../../../Models/Activity';
import { CommonLoading } from 'react-loadingg';
import {
  defaultCategories,
  ActivityCategories,
  presetScheduleBody,
  defaultActivityBody,
  presetScheduleObject,
} from 'src/Utils/Activity/constants';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { useRouteMatch } from 'react-router-dom';
import { resetImageStatus } from 'src/Stores/image/action';
import VideoTutorialIcon from 'src/Components/PartnerComponents/VideoTutorialIcon';
import VideoTutorial, {
  VideoType,
} from 'src/Components/PartnerComponents/VideoTutorial';
import { PriceTableActivity } from 'src/Models/PriceTableActivity';
export interface AddActivity
  extends WithTranslation,
    StateProps,
    DispatchProps {
  subtitle: string;
  location: { state };
}

const AddActivity: React.FC<AddActivity> = ({
  t,
  putActivity,
  postActivity,
  subtitle,
  postPutStatus,
  resetImageStatus,
  addressId,
}) => {
  const match = useRouteMatch();
  // @ts-ignore
  let activityId = match?.params?.activityId;

  const {
    watch,
    reset,
    setError,
    register,
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      ...defaultActivityBody,
      image: null,
    },
  });

  const [activityCategories, setActivityCategories] =
    useState<ActivityCategories>(defaultCategories);

  const [editingState, setEditingState] = useState({
    isEditing: false,
    activity: {},
  });

  const [disabledAdd, setDisabledAdd] = useState(true);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (isDirty && disabledAdd === true) setDisabledAdd(false);
  }, [isDirty, disabledAdd]);

  const verifyAndEnableSave = () => {
    if (disabledAdd === true) setDisabledAdd(false);
  };

  const errorHandler = () => {
    if (!isSubmitting) return;

    if (errors?.name) error('Nome da Atividade inválido');
    else if (errors?.teacher) error('Nome do Profissional inválido');
    else if (errors?.description) error('Descrição inválida');
    else if (errors?.duration) error('Duração inválida');
    else if (errors?.price) error('Preço inválido');
    else if (errors?.slots) error('Limite de Vagas inválido');
    else if (errors?.image) error('Insira uma imagem');
    else if (errors?.priceWithDiscount)
      error(
        'O preço Keiken deve ser maior que zero e menor que o preço de balcão/particular',
      );
  };

  errorHandler();

  useEffect(() => {
    if (activityId == null) return;
    ActivityApi.getActivity(activityId).then(({ data }) => {
      setValuesByActivity(data);
    });
  }, [activityId]);

  useEffect(() => {
    if (postPutStatus === HttpRequestStatus.SUCCESS) {
      history.push('/activities');
      reset();
    }
  }, [postPutStatus]);

  const setValuesByActivity = (activity) => {
    setEditingState({ isEditing: true, activity });

    const {
      id,
      name,
      free,
      slots,
      image,
      price,
      priceTableActivities,
      teacher,
      duration,
      enabledB2b,
      safetyTime,
      description,
      type,
      activityCategories = [],
      activitySubCategories = [],
      presetActivitySchedules = [],
      agendas = [],
      typeDescription,
    } = activity;

    let realImage;

    if (image?.imageUrl) {
      realImage = { ...image, preview: image?.imageUrl };
    } else realImage = { ...image };

    let presetObject: any = presetScheduleObject;
    let agendaObject: any = agendas;

    presetActivitySchedules.forEach((schedule: typeof presetScheduleBody) => {
      const { dayOfWeek = '' } = schedule;

      presetObject = {
        ...presetObject,
        [dayOfWeek.toLowerCase()]: { ...schedule },
      };
    });

    const priceTableActivityToSet: PriceTableActivity =
      priceTableActivities.find(
        (priceTableActivity: PriceTableActivity) =>
          (priceTableActivity.priceTable.type = 'DEFAULT'),
      ) ?? priceTableActivities[0];

    //@ts-ignore
    setValue('id', id);
    setValue('name', name);
    setValue('priceWithFee', priceTableActivityToSet?.priceWithFee);
    setValue('priceWithDiscount', priceTableActivityToSet?.price);
    setValue('partnerProfit', price);
    setValue('slots', slots);
    setValue('isFree', free);
    setValue('teacher', teacher);
    setValue('image', realImage);
    setValue('duration', duration);
    setValue('safetyTime', safetyTime / 60);
    setValue('enabledB2b', enabledB2b);
    setValue('description', description);
    setActivityCategories({
      categories: activityCategories,
      subCategories: activitySubCategories,
    });
    setValue('presetActivitySchedules', presetObject);
    setValue('agendas', agendaObject);
    setValue('type', type);
    setValue('typeDescription', typeDescription);
  };

  const imageStatus = useSelector(
    (root: IRootState) => root?.image?.imageStatus,
  );
  const image = useSelector((root: IRootState) => root?.image?.image);
  useEffect(() => {
    if (imageStatus === HttpRequestStatus.ONGOING) {
      setImageLoading(true);
      setValue('image', image);
    }
    if (imageStatus === HttpRequestStatus.SUCCESS) {
      setImageLoading(false);
      resetImageStatus();
    }
    if (imageStatus === HttpRequestStatus.ERROR) {
      setImageLoading(true);
      resetImageStatus();
    }
  }, []);
  const apiFormatData = (data = { presetActivitySchedules: {} }) => {
    const { presetActivitySchedules = {} } = data;

    let presetSchedule = [];
    let presetEmpty = true;

    Object.keys(presetActivitySchedules).forEach((weekday) => {
      const currentDay = presetActivitySchedules[weekday] || {};

      const { startAt = '' } = currentDay;

      if (startAt !== '') {
        presetEmpty = false;

        presetSchedule = [
          ...presetSchedule,
          {
            startAt,
            dayOfWeek: weekday.toUpperCase(),
          },
        ];
      }
    });

    return { ...data, presetActivitySchedules: presetSchedule, presetEmpty };
  };

  const onSubmit = (data) => {
    const { priceWithDiscount, partnerProfit, priceWithFee } = data;
    const { image, free } = data;
    const { isEditing } = editingState;

    const newDataFormat = apiFormatData(data);

    const imageBody = {
      id: image?.file === null ? image.id : undefined,
      file: image?.file,
      fileName: image?.fileName || image?.name,
      contentType: image?.contentType || image?.type,
    };

    const { categories = [], subCategories: activitySubCategories = [] } =
      activityCategories;

    const submitData: Activity = {
      ...defaultActivityBody,
      ...data,
      ...newDataFormat,
      safetyTime: data.safetyTime * 60,
      address: {
        id: addressId,
      },
      image: imageBody,
      free: free === 'true',
      activitySubCategories,
      activityCategories: categories,

      price: partnerProfit,
      priceTableActivities: [
        {
          price: priceWithDiscount ?? 0,
          priceWithFee: priceWithFee ?? 0,
        },
      ],
    };

    isEditing ? putActivity(submitData) : postActivity(submitData);
  };

  const essentialProps = {
    watch,
    register,
    setError,
    setValue,
    setDisabledAdd,
    errors,
    clearErrors,
    type: 'activity',
    verifyAndEnableSave,
    hideFields: { period: true, enrolmentFee: true, maintenanceFee: true },
  };

  const Names = () => {
    return (
      <Container className="center-cards-name-add">
        <Name
          {...essentialProps}
          inputName="name"
          title="Nome da atividade"
          placeholder="Nome da atividade"
        />
        <Name
          {...essentialProps}
          inputName="teacher"
          title="Nome do profissional"
          placeholder="Nome do profissional"
        />
      </Container>
    );
  };

  const preventEnterSubmit = (e) => {
    e.key === 'Enter' && e.preventDefault();
  };

  const getHeaderName = () => {
    return editingState?.isEditing ? 'Editar atividade' : 'Adicionar atividade';
  };

  const headerName = getHeaderName();

  if (postPutStatus === HttpRequestStatus.ONGOING) {
    return (
      <div className="AddActivity">
        <CommonLoading />
      </div>
    );
  }

  return (
    <div className="AddActivity">
      <BaseHeaderBenefits
        step="create"
        color="green"
        to="/activities"
        title={headerName}
        rightElement={
          <VideoTutorialIcon color="white">
            <VideoTutorial type={VideoType.OVERVIEW_CONFIG} />
          </VideoTutorialIcon>
        }
      />
      <form
        onKeyPress={preventEnterSubmit}
        className="form-mt-100 formContainer"
        onSubmit={handleSubmit(onSubmit)}>
        {Names()}
        <Description title="Descrição da atividade" {...essentialProps} />
        <PlanGrid title="" {...essentialProps} />
        <PlanPrice
          title="Preço da atividade"
          {...essentialProps}
          isEdit={editingState?.isEditing}
        />
        <UploadComponent
          title="Imagem da atividade"
          loading={imageLoading}
          {...essentialProps}
        />
        <PlanCategory
          subtitle="Subcategorias da atividade"
          {...essentialProps}
          title="Categoria da atividade"
          activityCategories={activityCategories}
          setActivityCategories={setActivityCategories}
        />
        <div className="adjustPades"></div>
        <BaseFooterBenefits
          text={headerName}
          disabled={disabledAdd}
          textBack="Cancelar"
          hasBackButton="true"
          typeSubmit
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    postPutStatus: state.activity.postPutStatus,
    addressId: state.profile.profile.address.id,
  };
};

const mapDispatchToProps = {
  putActivity,
  postActivity,
  resetImageStatus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AddActivity),
) as React.ComponentType<any>;
