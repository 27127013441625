import { action } from 'typesafe-actions';
import { CustomerTokenTypes } from './types';

export const getByToken = (token) => action(CustomerTokenTypes.GET_BY_TOKEN, token);
export const getByTokenSuccess = (tokenSuccess) =>
  action(CustomerTokenTypes.GET_BY_TOKEN_SUCCESS, tokenSuccess);
export const getByTokenError = (message: string) =>
  action(CustomerTokenTypes.GET_BY_TOKEN_ERROR, message);

export const postUseTokenSuccess = (tokenSuccess) => action(CustomerTokenTypes.POST_USE_TOKEN_SUCCESS, tokenSuccess);
export const postUseTokenError = (message: string) => action(CustomerTokenTypes.POST_USE_TOKEN_ERROR, message);
export const postUseToken = (customerActivityId) => action(CustomerTokenTypes.POST_USE_TOKEN, customerActivityId);

export const postUseTokenPlanSuccess = (tokenSuccess) => action(CustomerTokenTypes.POST_USE_PLAN_TOKEN_SUCCESS, tokenSuccess);
export const postUseTokenPlanError = (message: string) => action(CustomerTokenTypes.POST_USE_PLAN_TOKEN_ERROR, message);
export const postUseTokenPlan = (id) => action(CustomerTokenTypes.POST_USE_PLAN_TOKEN, id);
