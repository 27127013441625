import { ActivitySchedule } from 'src/Models/ActivitySchedule';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum ActivityScheduleActionTypes {
  GET_ACTIVE_ACTIVITIES_SCHEDULES = '@@schedule/ACTIVE_ACTIVITIES_SCHEDULES',
  GET_ACTIVE_ACTIVITIES_SCHEDULES_ERROR = '@@schedule/ACTIVE_ACTIVITIES_SCHEDULES_ERROR',
  GET_ACTIVE_ACTIVITIES_SCHEDULES_SUCCESS = '@@schedule/ACTIVE_ACTIVITIES_SCHEDULES_SUCCESS',

  GET_ALL_ACTIVITIES_SCHEDULES = '@@schedule/ALL_ACTIVITIES_SCHEDULES',
  GET_ALL_ACTIVITIES_SCHEDULES_ERROR = '@@schedule/ALL_ACTIVITIES_SCHEDULES_ERROR',
  GET_ALL_ACTIVITIES_SCHEDULES_SUCCESS = '@@schedule/ALL_ACTIVITIES_SCHEDULES_SUCCESS',

  POST_ACTIVITY_SCHEDULE = '@@activitySchedule/POST_ACTIVITY_SCHEDULE',
  POST_ACTIVITY_SCHEDULE_SUCCESS = '@@activitySchedule/POST_ACTIVITY_SCHEDULE_SUCCESS',
  POST_ACTIVITY_SCHEDULE_ERROR = '@@activitySchedule/POST_ACTIVITY_SCHEDULE_ERROR',

  POST_SCHEDULE_RECURRENCE = '@@schedule/POST_SCHEDULE_RECURRENCE',
  POST_SCHEDULE_RECURRENCE_ERROR = '@@schedule/POST_SCHEDULE_RECURRENCE_ERROR',
  POST_SCHEDULE_RECURRENCE_SUCCESS = '@@schedule/POST_SCHEDULE_RECURRENCE_SUCCESS',

  POST_SCHEDULE_NO_RECURRENCE = '@@schedule/POST_SCHEDULE_NO_RECURRENCE',
  POST_SCHEDULE_NO_RECURRENCE_ERROR = '@@schedule/POST_SCHEDULE_NO_RECURRENCE_ERROR',
  POST_SCHEDULE_NO_RECURRENCE_SUCCESS = '@@schedule/POST_SCHEDULE_NO_RECURRENCE_SUCCESS',

  POST_MANUAL_SCHEDULING = '@@schedule/POST_MANUAL_SCHEDULING',
  POST_MANUAL_SCHEDULING_ERROR = '@@schedule/POST_MANUAL_SCHEDULING_ERROR',
  POST_MANUAL_SCHEDULING_SUCCESS = '@@schedule/POST_MANUAL_SCHEDULING_SUCCESS',

  PUT_SCHEDULE_STATUS = '@@schedule/PUT_SCHEDULE_STATUS',
  PUT_SCHEDULE_STATUS_ERROR = '@@schedule/PUT_SCHEDULE_STATUS_ERROR',
  PUT_SCHEDULE_STATUS_SUCCESS = '@@schedule/PUT_SCHEDULE_STATUS_SUCCESS',

  GET_RE_SCHEDULE_AVAILABLE = '@@schedule/GET_RE_SCHEDULE_AVAILABLE',
  GET_RE_SCHEDULE_AVAILABLE_ERROR = '@@schedule/GET_RE_SCHEDULE_AVAILABLE_ERROR',
  GET_RE_SCHEDULE_AVAILABLE_SUCCESS = '@@schedule/GET_RE_SCHEDULE_AVAILABLE_SUCCESS',
  
  RESET_RE_SCHEDULE = '@@schedule/RESET_RE_SCHEDULE',
  RESET_ACTIVITY_SCHEDULE_STATUS = '@@activitySchedule/RESET_ACTIVITY_SCHEDULE_STATUS'
}

export interface ActivityScheduleState {
  readonly activeActivitiesSchedules?: ActivitySchedule[];
  readonly activitySchedule?:ActivitySchedule;
  readonly allActivitiesSchedules?: ActivitySchedule[];
  readonly reSchedules?: ActivitySchedule[];
  readonly reSchedulesGroupped?: any;
  readonly reScheduleStatus?: HttpRequestStatus;
  readonly scheduleStatus?: HttpRequestStatus;
  readonly activityScheduleStatus?: HttpRequestStatus;
  readonly error?: HttpResponseError;
}
