import { Container } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { Theme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { styles } from '../../../BaseMegaCardCss/styles';
import '../../styles.scss';
import moment, { Moment } from 'moment';
import StringUtils from 'src/Utils/StringUtils';
import { IRootState } from 'src/Stores';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import {
  getCustomerActivityPlanById,
  getCustomerActivityPlans,
  resetApproveEnrolmentStatus,
} from 'src/Stores/customerActivityPlan/actions';
import FilterHeader, { DateProps } from '../FilterHeader';
import { ActivityType } from 'src/Models/Enums/ActivityType';
import { format } from 'date-fns';
import { getBillingHistory } from 'src/Stores/billing/actions';
import { Pagination } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import i18n from 'src/Config/I18n';
import CircleLoading from 'src/Components/circleLoading';
import PartnerClientApi from 'src/Services/Api/PartnerClientApi';
import Images from 'src/Themes/Constants/Images';
import { DownloadButton } from '../FilterHeader/styles';
import { RotateCircleLoading } from 'react-loadingg';
import PlanApi from 'src/Services/Api/PlanApi';
export interface ExtractTableProps
  extends WithTranslation,
    DispatchProps,
    StateProps {
  dateProps: { fromDate: Moment; toDate: Moment };
}

export enum FilterDateType {
  PURCHASE_DATE = 'PURCHASE_DATE',
  SCHEDULE_DATE = 'SCHEDULE_DATE',
}

const ScheduleHistory: React.FC<ExtractTableProps> = ({
  t,
  historyStatus,
  billingsHistory,
  profile,
  getBillingHistory: propsGetHistory,
  dateProps,
}) => {
  const [pagination, setPagination] = useState(1);
  const useStyles = makeStyles((theme: Theme) => styles);
  const [filterText, setFilterText] = useState<string>();
  const [sortTo, setSortTo] = useState<string>();
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);
  const [whichDateToFilter, setWhichDateToFilter] = useState<FilterDateType>(
    FilterDateType.SCHEDULE_DATE,
  );
  const [formatedDate, setFormatedDate] = useState<DateProps>({
    startDate: null,
    endDate: null,
  });
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState<number>(undefined);

  const handleOnChangeDate = (item) => {
    setDate([item.selection]);
    setFormatedDate({
      startDate: format(item.selection.startDate, 'dd/MM/yyyy'),
      endDate: format(item.selection.endDate, 'dd/MM/yyyy'),
    });
    setPagination(0);
  };
  const handleOnChangeText = (event) => {
    setFilterText(event?.target?.value);
    setPagination(0);
  };
  const handleOnChangeType = (type?: ActivityType) => {
    setSortTo(type);
    setPagination(0);
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    const startDateToSend =
      moment(formatedDate?.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') ??
      moment(dateProps.fromDate).format('YYYY-MM-DD');
    const endDateToSend =
      moment(formatedDate?.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') ??
      moment(dateProps.toDate).format('YYYY-MM-DD');

    await PartnerClientApi.getExtractTableInExcel(profile.profile.id, {
      page: 0,
      size: billingsHistory?.totalElements,
      search: debouncedFilterText,
      type: sortTo,
      fromDate: startDateToSend,
      toDate: endDateToSend,
      whichDateToFilter: whichDateToFilter,
    });

    setIsDownloading(false);
  };

  const handleDownloadReceipt = async (id: number, date: string) => {
    setIsDownloadingPDF(id);
    await PlanApi.generateReceiptPdf(id, date);
    setIsDownloadingPDF(undefined);
  };

  const [debouncedFilterText, setDebouncedFilterText] = useState<string>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [filterText]);

  useEffect(() => {
    propsGetHistory({
      page: pagination - 1 ?? 0,
      size: billingsHistory?.size ?? 20,
      fromDate:
        formatedDate.endDate !== null
          ? moment(formatedDate?.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : dateProps
          ? dateProps.fromDate.format('YYYY-MM-DD')
          : undefined,
      search: debouncedFilterText,
      toDate:
        formatedDate.endDate !== null
          ? moment(formatedDate?.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : dateProps
          ? dateProps.toDate.format('YYYY-MM-DD')
          : undefined,
      type: sortTo,
      whichDateToFilter: whichDateToFilter,
    });
  }, [
    pagination,
    propsGetHistory,
    formatedDate,
    debouncedFilterText,
    sortTo,
    whichDateToFilter,
  ]);

  // @ts-ignore
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#25b379',
      },
    },
  });

  const displayEmptyHistory = () => {
    return (
      <tr>
        <td className="noHistory">Não há histórico de vendas</td>
      </tr>
    );
  };
  const displayHistory = () => {
    return billingsHistory?.content?.map?.((billing) => {
      const billingDate = billing.date;

      const customerActivity = billing.customerActivities?.[0];
      const activityDates = billing.customerActivities?.map(
        (it) => it?.activitySchedule?.date,
      );

      const customerActivityPlan = billing.customerActivityPlan;
      const start = customerActivityPlan?.startPeriodDate;
      const end = customerActivityPlan?.endPeriodDate;

      const customer =
        customerActivity?.customer ?? customerActivityPlan?.customer;

      const activityName =
        customerActivity?.activitySchedule?.activity?.name ??
        customerActivityPlan?.activityPlan?.name;

      const isWithDraw = billing?.paymentMethod === 'WITHDRAW_BY_PARTNER';

      const isPlan = !customerActivity;
      const scheduleDate = moment(customerActivity?.activitySchedule?.date)
        .add(1, 'month')
        .date(15)
        .format('DD/MM/YY');
      const planScheduleDate = moment(customerActivityPlan?.startPeriodDate)
        .add(1, 'month')
        .date(15)
        .format('DD/MM/YY');

      return (
        <tr key={customerActivity?.id} style={{ height: '90px' }}>
          <td>
            <div className="c1">
              <span className="c1-title">
                {moment(billingDate).format('DD/MM')}
              </span>
              <span className="c1-sub">
                {moment(billingDate).format('HH:mm')}
              </span>
            </div>
          </td>
          <td>
            <div className="c1">
            <DownloadButton onClick={() => handleDownloadReceipt(billing.id, billingDate)}>
            {isDownloadingPDF === billing.id ? (
              <>
                <RotateCircleLoading
                  size="small"
                  style={{
                    position: 'static',
                    marginInline: 'auto',
                  }}
                />
                Exportando...
              </>
            ) : (
              <>
                <img src={Images.icons.downloadIcon} alt="export table" />
                recibo
              </>
            )}
          </DownloadButton>
            </div>
          </td>
          <td>
            <span className="text-c2">
              {isWithDraw ? 'Retirada' : activityName}
            </span>
            <span className="c1-title">
              {isWithDraw
                ? 'Pix'
                : customerActivity
                ? activityDates.map((it) => (
                    <>
                      {t('billings.history.date.activity', {
                        time: moment(it).format('DD/MM/YY - HH:mm'),
                      })}
                    </>
                  ))
                : t('billings.history.date.activityPlan', {
                    start: moment(start).format('DD/MM/YY'),
                    end: moment(end).format('DD/MM/YY'),
                  })}
            </span>
          </td>
          <td>
            <div className="c3">
              <div className="c1">
                <span className="text-c2">
                  {isWithDraw ? '' : customer?.name}
                </span>
                <Tooltip title="Clique para falar com o usuário no WhatsApp">
                  <div className="c3-phone-container">
                    {isWithDraw ? (
                      ''
                    ) : (
                      <WhatsAppIcon style={{ color: '#25D366' }} />
                    )}
                    <a
                      className="c3-sub"
                      href={`https://wa.me/${customer?.phones[0]?.number}`}
                      target="_blank">
                      {isWithDraw ? '' : customer?.phones[0]?.number}
                    </a>
                  </div>
                </Tooltip>
                <div className="c3-sub">
                  <span>
                    {isWithDraw
                      ? profile.profile.fantasyName
                      : customer?.user?.email}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="c1">
              <div className="c3-sub" style={{ color: '#005fa3' }}>
                {StringUtils.currencyPtBr(billing?.pricePaid)}
              </div>
            </div>
          </td>
          <td>
            <div className="c1">
              <div
                className="c3-sub"
                style={{ color: 'rgb(233, 0, 58)', whiteSpace: 'nowrap' }}>
                {'- ' + StringUtils.currencyPtBr(billing?.keikenTax)}
              </div>
            </div>
          </td>
          <td>
            <div className="c1">
              <div className="c3-sub-price">
                <span
                  style={{
                    width: isWithDraw && '100%',
                    textAlign: (isWithDraw && 'left') || null,
                  }}>
                  {isWithDraw ? (
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                      }}>
                      {StringUtils.currencyPtBr(0)}
                    </span>
                  ) : (
                    StringUtils.currencyPtBr(billing?.partnerProfit)
                  )}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div className="c1 alignCenter">
              <div className="c3-sub-price">
                <BillingStatus status={billing?.status} />
              </div>
            </div>
          </td>
          <td>
            <div className="c1 alignCenter">
              {!isWithDraw ? (
                <span className="c1-title textAlignCenter">
                  {billing?.partnerProfitTransaction?.scheduledDate
                    ? moment(
                        billing?.partnerProfitTransaction?.scheduledDate,
                      ).format('DD/MM/YY')
                    : isPlan
                    ? planScheduleDate
                    : scheduleDate}
                </span>
              ) : (
                <span className="c1-title textAlignCenter">
                  {i18n.t('billings.history.table.waitingApproval')}
                </span>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const BillingStatus = ({ status }) => {
    const { border, color, text, backgroundColor } = getStatusStyles(status);

    return (
      <span
        style={{
          border,
          color,
          backgroundColor,
          borderRadius: '8px',
          padding: '1px 16px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
        }}>
        {text}
      </span>
    );
  };

  const getStatusStyles = (status) => {
    const statusMap = {
      PAID: {
        border: '2px solid #25b379',
        color: 'white',
        text: 'Pago',
        backgroundColor: '#25b379',
      },
      FUTURE_CHARGE_ERROR: {
        border: '1px solid rgb(233, 0, 58)',
        color: 'white',
        text: 'Erro',
        backgroundColor: 'rgb(233, 0, 58)',
      },
      default: {
        border: '1px solid orange',
        color: 'white',
        text: 'Pendente',
        backgroundColor: 'orange',
      },
    };

    return statusMap[status] || statusMap.default;
  };

  const LoadingContainer = () => {
    return (
      <tr
        style={{
          height: '800px',
        }}>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircleLoading size={80} />
          </div>
        </td>
      </tr>
    );
  };

  const handleHistory = () => {
    if (historyStatus === HttpRequestStatus.ONGOING) return LoadingContainer();

    if (billingsHistory?.content?.length === 0) return displayEmptyHistory();

    return (
      <>
        <tr>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.date')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.receipt')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.activityName')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.userData')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.value')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.keikenTax')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.toReceive')}
          </td>
          <td className="c1-title-table textAlignCenter">
            {i18n.t('billings.history.table.status')}
          </td>
          <td className="c1-title-table textAlignCenter">
            {i18n.t('billings.history.table.paymentDate')}
          </td>
        </tr>
        {displayHistory()}
      </>
    );
  };

  const handleChangePagination = (e, p) => {
    setPagination(p);
  };

  const displayPaginationNumbers = () => {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Pagination
            shape="rounded"
            size="large"
            color="primary"
            variant="outlined"
            count={billingsHistory?.totalPages}
            onChange={handleChangePagination}
          />
        </ThemeProvider>
      </>
    );
  };

  const displayPagination = () => {
    // if (billingsHistory?.content?.length === 0) return;

    return (
      <div className="pagination center mb-10">
        {displayPaginationNumbers()}
      </div>
    );
  };

  const headerProps = {
    filterText: filterText,
    filterType: sortTo,
    formatedDate: formatedDate,
    date: date,
    dateProps: dateProps,
    setFormatedDate,
    whichDateToFilter: whichDateToFilter,
    handleDownload: handleDownload,
    isDownloading: isDownloading,
    setWhichDateToFilter: setWhichDateToFilter,
    handleOnChangeDate: handleOnChangeDate,
    handleOnChangeText: handleOnChangeText,
    handleOnChangeType: handleOnChangeType,
  };

  return (
    <Container className="HistoryContainer">
      <div className={classes.baseBorder}>
        <div>
          <FilterHeader {...headerProps} />
          <table>
            <tbody>{handleHistory()}</tbody>
          </table>
          {displayPagination()}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: IRootState) => {
  const customerActivitiesPlan =
    state.customerActivityPlan.customerActivitiesPlan;
  const enrolmentStatus = state.customerActivityPlan.approveEnrolmentStatus;
  const customerActivityPlan = state.customerActivityPlan.customerActivityPlan;
  const billingsHistory = state.billings.page;
  const profile = state.profile;
  return {
    billingsHistory,
    historyStatus: state.billings.status,
    customerActivitiesPlan,
    enrolmentStatus,
    customerActivityPlan,
    profile,
  };
};

const mapDispatchToProps = {
  getCustomerActivityPlans,
  resetApproveEnrolmentStatus,
  getCustomerActivityPlanById,
  getBillingHistory,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleHistory),
) as React.ComponentType<any>;
