import React, { useEffect, useState, useCallback, useRef } from 'react';
import SimpleModal from 'src/Components/SimpleModal';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
import wifiIcon from '../../../Images/Keiken/Partner/wifiIcon.png';
import rectangle from '../../../Images/Keiken/Partner/Rectangle.png';
import activitySmile from '../../../Images/Keiken/Partner/activitie_smile.png';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { I18nContext, WithTranslation, withTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Activity } from 'src/Models/Activity';
import { Customer } from 'src/Models/Customer';
import { ActivityCategory } from 'src/Models/ActivityCategory';
import { SuggestionSchedulePayload } from 'src/Models/SuggestionSchedulePayload';
import { ActivitySubCategory } from 'src/Models/ActivitySubCategory';
import ActivityApi from '../../../Services/Api/ActivityApi';
import ActivityScheduleApi from '../../../Services/Api/ActivitySchedule';
import { postScheduleNoRecurrence } from 'src/Stores/schedule/actions';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GradientButtonSC from 'src/Components/GradientButtonSC';
import StringUtils from 'src/Utils/StringUtils';
import { ActivityPlaceTypeUtils } from 'src/Models/Plan';
import { debounce } from 'lodash';
import { CommonLoading } from 'react-loadingg';

import './styles.scss';
import LoadingContainerScreen from 'src/Components/loading-container-screen/loading-container-screen';
export interface NewScheduleModalProps
  extends StateProps,
    DispatchProps,
    WithTranslation {
  openScheduleModal: { open; schedule };
  setOpenScheduleModal: ({ open, schedule }) => void;
  postScheduleNoRecurrence: (schedule: SuggestionSchedulePayload) => null;
}

export interface CustomerList {
  checked?: boolean;
  customer: Customer;
  intervalWeek?: number;
}

export const NewScheduleModal: React.FC<NewScheduleModalProps> = ({
  t,
  activity,
  agendas,
  openScheduleModal,
  setOpenScheduleModal,
  postScheduleNoRecurrence,
}) => {
  const [confirmEnabled, setConfirmEnabled] = useState(false);
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity>({});
  const [selectedCategory, setSelectedCategory] = useState<ActivityCategory>(
    {},
  );
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<ActivitySubCategory>({});
  const [customerSearch, setCustomerSearch] = useState<string>();
  const [customerSearchLoading, setCustomerSearchLoading] =
    useState<boolean>(true);

  useEffect(() => {
    const categoryConditions =
      selectedCategory?.hasOwnProperty('id') &&
      selectedSubCategory?.hasOwnProperty('id');

    const checkedCustomers = customers?.filter(
      (customer) => customer?.checked === true,
    );

    const customerConditions = checkedCustomers.length > 0;

    if (categoryConditions && customerConditions && !confirmEnabled)
      return setConfirmEnabled(true);

    if (confirmEnabled) setConfirmEnabled(false);
  }, [selectedCategory, selectedSubCategory, customers]);

  useEffect(() => {
    if (openScheduleModal?.open === false) resetSelectedActivity();
  }, [openScheduleModal]);

  const resetModalState = () => {
    setCustomers([]);
    setSelectedCategory({});
    resetSelectedActivity();
    setSelectedSubCategory({});
  };

  const fetchActivities = (activityInfo) => {
    ActivityApi.getActivity(activityInfo?.id).then(({ data: activity }) => {
      const { activityCategories = [], activitySubCategories = [] } =
        activity || {};

      setSelectedCategory({ id: activityCategories[0]?.id || null });
      setSelectedSubCategory({ id: activitySubCategories[0]?.id || null });
      setSelectedActivity({
        ...activityInfo,
        activityCategories,
        activitySubCategories,
      });
    });
  };

  const debouncedFetchActivities = useRef(
    debounce((activityInfo) => fetchActivities(activityInfo), 1000),
  ).current;

  const fetchPartnerCustomerHistory = (value) => {
    setCustomerSearchLoading(true);
    const search = value != '' ? value : null;
    ActivityScheduleApi.getPartnerHistory(search)
      .then(({ data }) => {
        const { content: customers = [] } = data;

        let newCustomers = [];

        customers.forEach((customer) => {
          newCustomers = [...newCustomers, { customer, intervalWeek: 0 }];
        });

        setCustomers([...newCustomers]);
      })
      .finally(() => setCustomerSearchLoading(false));
  };

  const debouncedHandleCustomerSearch = useRef(
    debounce(async (value) => {
      fetchPartnerCustomerHistory(value);
    }, 1000),
  ).current;

  const handleSelectActivity = (activityInfo) => {
    if (activityInfo?.id == null) return;
    if (activityInfo?.id === selectedActivity?.id) return;

    debouncedFetchActivities(activityInfo);
    debouncedHandleCustomerSearch('');
  };

  const handleCancelSchedule = () => {
    resetModalState();
    setOpenScheduleModal({ open: false, schedule: {} });
  };

  const getSelectedCustomers = () => {
    return customers.filter((customer) => customer?.checked);
  };

  const getSchedulePayloadBody = () => {
    const { schedule } = openScheduleModal;
    const selectedCustomer = getSelectedCustomers()[0];

    const presetRenewalActivitySchedule =
      selectedCustomer?.intervalWeek == null ||
      selectedCustomer?.intervalWeek == 0
        ? undefined
        : {
            intervalWeek: selectedCustomer?.intervalWeek,
            activity: { id: selectedActivity?.id },
          };

    const result: SuggestionSchedulePayload = {
      billing: {
        activitySchedules: [
          { id: schedule?.id, activity: { id: selectedActivity?.id } },
        ],
      },
      activityCategory: { id: selectedCategory?.id },
      activitySubCategory: { id: selectedSubCategory?.id },
      customer: { id: selectedCustomer?.customer?.id },
      presetRenewalActivitySchedule,
    };

    return result;
  };

  const recurrenceAdapter = () => {
    const selectedCustomer = getSelectedCustomers()[0];

    let recurrenceFunction = (recurrenceBody: SuggestionSchedulePayload) =>
      null;

    if (selectedCustomer?.intervalWeek === 0) {
      recurrenceFunction = postScheduleNoRecurrence;

      return {
        recurrenceBody: getSchedulePayloadBody(),
        recurrenceFunction,
      };
    }

    recurrenceFunction = postScheduleNoRecurrence;

    return {
      recurrenceBody: getSchedulePayloadBody(),
      recurrenceFunction,
    };
  };

  const getSchedulingBody = () => {
    const selectedCustomer = getSelectedCustomers()[0];

    if (selectedCustomer?.intervalWeek === 0) return getSchedulePayloadBody();

    const {
      billing,
      activityCategory,
      activitySubCategory,
      customer,
      presetRenewalActivitySchedule,
    } = getSchedulePayloadBody();

    return {
      customer,
      billing,
      activityCategory: { id: activityCategory?.id },
      activitySubCategory: { id: activitySubCategory?.id },
      presetRenewalActivitySchedule,
    };
  };

  const handleConfirmSchedule = () => {
    const { recurrenceBody, recurrenceFunction } = recurrenceAdapter();
    let schedulingBody = getSchedulingBody();

    recurrenceFunction(recurrenceBody);

    handleCancelSchedule();
  };

  const resetSelectedActivity = () => setSelectedActivity({});

  const handleCheckCustomer = (customer) => {
    setCustomers((prev) => {
      let newState = prev?.map?.((it) => {
        let prevIntervalWeek =
          prev.find((o) => o.checked == true)?.intervalWeek ?? 0;
        if (it.customer.id != customer?.id) {
          return {
            ...it,
            checked: false,
            intervalWeek: 0,
          };
        }
        return {
          ...it,
          checked: !it.checked,
          intervalWeek: Number(prevIntervalWeek),
        };
      });

      return newState;
    });
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleRecurrenceValue = (intervalWeek, customer) => {
    let currentIndex = 0;

    let currentCustomer =
      customers.filter((line, index) => {
        if (line?.customer?.id === customer?.id) {
          currentIndex = index;

          return true;
        }

        return false;
      })[0] || false;

    if (!currentCustomer) return;

    let updatedCustomers = customers;

    updatedCustomers[currentIndex] = { ...currentCustomer, intervalWeek };

    setCustomers([...updatedCustomers]);
  };

  const displayCustomerLine = (line) => {
    return (
      <div className="line" key={line?.customer?.id}>
        <div className="checkBoxNameContainer">
          <div className="checkBox">
            <Checkbox
              {...label}
              checked={line?.checked || false}
              color="secondary"
              onClick={() => handleCheckCustomer(line?.customer)}
            />
          </div>
          <p className="customerContainer">
            <span className="customerName">{line?.customer?.name}</span>
            <span className="customerEmail">{line?.customer?.user?.email}</span>
          </p>
        </div>
        <div className="selectImgContainer">
          {/* <select
            placeholder="recorrência"
            value={line?.intervalWeek}
            onChange={(e) =>
              handleRecurrenceValue(e.target.value, line?.customer)
            }>
            <option value={0}>Sem recorrência</option>
            <option value={1}>Semanal</option>
            <option value={2}>Quinzenal</option>
          </select> 
          */}
        </div>
      </div>
    );
  };

  const { activities = [] } = activity || {};

  const getServiceCard = (activityInfo, customClassName) => {
    if (!activityInfo?.id) return <> </>;

    const selected = activityInfo?.id === selectedActivity?.id;

    const color = selected ? '#25B379' : '#E7E9EA';
    const borderWidth = selected ? 3 : 1;
    const stylez = { borderColor: color, borderWidth };

    const { partnerPlace, type } = activityInfo;

    const convertType = ActivityPlaceTypeUtils.getLabel(type);

    return (
      <div
        key={activityInfo?.id}
        className={`serviceCard ${customClassName}`}
        style={stylez}
        onClick={() => handleSelectActivity(activityInfo)}>
        <div className="serviceImgContainer">
          <img src={rectangle} alt="online" className="serviceImgBackground" />
          <img src={activitySmile} alt="online" className="serviceImgIcon" />
        </div>
        <div className="rightContainer" title={activityInfo?.name}>
          <div className="serviceTitle">{activityInfo?.name}</div>
          <div className="statusContainer">
            <img src={wifiIcon} alt="online" className="photo" />
            <span className="serviceStatus">{convertType}</span>
          </div>
          <div className="userContainer">
            <div>
              <img src={partnerPlace.image.imageUrl} alt="online" />
            </div>
            <span className="fantasyName">
              {StringUtils.currencyPtBr(activityInfo.price)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const getScheduledCards = () => {
    const agendaId = openScheduleModal?.schedule?.agenda?.id;
    const agenda = agendas.find((it) => it.id == agendaId);

    const agendaActivities =
      agenda?.activities
        .map((it) => activities.find((o) => o.id == it.id))
        .filter((it) => it) ?? [];

    let scheduledCards: JSX.Element[] =
      agendaActivities.map((activityInfo, index) => {
        // if (index % 2 !== 1) {
        // const nextActivityInfo = activities[index + 1] || false;

        return (
          <div style={{ display: 'flex' }} key={`scheduled-cards-${index}`}>
            {getServiceCard(activityInfo, 'leftServiceCard')}
            {/* {getServiceCard(nextActivityInfo, 'rightServiceCard')} */}
          </div>
        );
        // }
      }) ?? [];

    if (agendaActivities.length == 1) {
      handleSelectActivity(agendaActivities?.[0]);
    }

    return scheduledCards;
  };

  const displayActivityCards = () => {
    const scheduledCards: JSX.Element[] = getScheduledCards();

    return (
      <Carousel
        centerMode
        centerSlidePercentage={40}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}>
        {scheduledCards?.map((scheduleCard) => scheduleCard)}
      </Carousel>
    );
  };

  const displayPrice = () => {
    const { price = 0 } = selectedActivity || {};
    const color = selectedActivity?.id ? '#25B379' : 'lightgrey';

    return (
      <span style={{ color }} className="price">
        {StringUtils.currencyPtBr(price)}
      </span>
    );
  };

  const displayCustomers = () => {
    if (customers?.length === 0) {
      return (
        <div style={{ height: '100%', fontWeight: 'bolder', color: 'grey' }}>
          <div
            style={{
              height: '75%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <AccountCircleIcon
              sx={{
                fontSize: '100px',
                color: '	#C0C0C0',
              }}
            />
          </div>
          Clientes não encontrados
        </div>
      );
    }
    return customers?.map((customer) => displayCustomerLine(customer));
  };

  const handleSelectedCategory = (event) => {
    const value = event?.target?.value || 0;

    setSelectedCategory({ id: value });
  };

  const handleSelectedSubCategory = (event) => {
    const value = event?.target?.value || 0;

    setSelectedSubCategory({ id: value });
  };

  const displaySelectCategories = () => {
    const { activityCategories = [] } = selectedActivity || {};

    return (
      <div className="col-md-6 categorySelect">
        <select
          value={selectedCategory?.id}
          placeholder="Categorias"
          onChange={handleSelectedCategory}>
          <option disabled>Categoria</option>
          {activityCategories?.map((category) => (
            <option key={category?.id} value={category?.id}>
              {category?.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const displaySelectSubCategories = () => {
    const { activitySubCategories = [] } = selectedActivity || {};

    // TODO: filter sub-categories of selectedCategory

    return (
      <div className="col-md-6 subcategorySelect">
        <select
          value={selectedSubCategory?.id}
          placeholder="Sub Categorias"
          onChange={handleSelectedSubCategory}>
          <option disabled>Sub Categoria</option>
          {activitySubCategories?.map((category) => (
            <option key={category?.id} value={category?.id}>
              {category?.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handleCustomerSearch = (value = '') => {
    setCustomerSearch(value);
    debouncedHandleCustomerSearch(value);
  };

  const handleEnableConfirm = () => {
    const categoryConditions =
      selectedCategory?.hasOwnProperty('id') &&
      selectedSubCategory?.hasOwnProperty('id');

    const checkedCustomers = customers?.filter(
      (customer) => customer?.checked === true,
    );

    const customerConditions = checkedCustomers.length > 0;

    if (categoryConditions && customerConditions) return true;

    return false;
  };

  const ModalTitle = () => {
    return (
      <div className="modalTitle">
        <div className="title">
          <div>Realizar Agendamento</div>
        </div>
        <div
          style={{ cursor: 'pointer', marginTop: 10 }}
          onClick={() => handleCancelSchedule()}>
          <CloseIcon />
        </div>
      </div>
    );
  };

  const ModalFooter = () => {
    return (
      <div className="modalFooter">
        <div className="footerConfirm">
          <GradientButtonSC
            isSecundary
            text
            onClick={handleConfirmSchedule}
            title="Confirmar"
          />
        </div>
        <div className="footerCancel">
          <GradientButtonSC
            isSecundary
            text
            onClick={handleCancelSchedule}
            title="Cancelar"
          />
        </div>
      </div>
    );
  };

  const agenda = openScheduleModal?.schedule?.agenda;
  return (
    <SimpleModal
      isOpen={openScheduleModal?.open}
      ComponentTitle={ModalTitle}
      ComponentFooter={ModalFooter}
      onConfirm={handleConfirmSchedule}
      confirmEnabled={handleEnableConfirm()}
      width="750px">
      <div className="scheduleModal">
        <span className="service" style={{ padding: '0 75px' }}>
          {t('suggestedSchedule.serviceWithAgenda')}
          {agenda?.name != null && agenda?.color && (
            <div style={{ color: agenda.color }}>{agenda.name}</div>
          )}
        </span>
        <div className="carouselContainer">{displayActivityCards()}</div>
        <div className="row">
          <div className="col-md-6">
            <span className="service">Categoria</span>
          </div>
          <div className="col-md-6">
            <span className="service">Subcategoria</span>
          </div>
        </div>
        <div className="row categoriesInput">
          {displaySelectCategories()}
          {displaySelectSubCategories()}
        </div>
        <span className="valueToReceive">Valor a Receber</span>
        {displayPrice()}
        <div className="inputGroup">
          <div className="flexSpaceBetween">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                disabled
                label="Data"
                onChange={() => null}
                value={openScheduleModal?.schedule?.date}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopTimePicker
                disabled
                label="Horário"
                views={['hours', 'minutes']}
                value={openScheduleModal?.schedule?.date}
                onChange={() => null}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div>
            <FormControl
              sx={{ m: 1, width: '100%', margin: '15px 0 0 0' }}
              variant="outlined">
              <OutlinedInput
                id="outlined-adornment-customer"
                type="text"
                color="secondary"
                value={customerSearch}
                placeholder="Colaborador"
                onChange={(e) => handleCustomerSearch(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="noCustomers">
              <div className="customersContent">
                {customerSearchLoading && (
                  <CommonLoading
                    style={{
                      position: 'relative',
                      left: '45%',
                      top: '30%',
                    }}
                  />
                )}
                {!customerSearchLoading && displayCustomers()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { activity = {}, agenda = {} } = state;

  return {
    activity,
    agendas: agenda?.agendas ?? [],
  };
};

const mapDispatchToProps = {
  postScheduleNoRecurrence,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NewScheduleModal),
) as React.ComponentType<any>;
