import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import {
  FinancialDashboard,
  PieDataPartner,
} from 'src/Models/FinancialDashboard';
import { PartnerDashboard } from 'src/Models/PartnerDashboard';

export type ApiResponse = Record<string, any>;

export enum PartnerDashboardActionTypes {
  GET_PARTNER_DASHBOARD = '@@partnerDashboard/GET_PARTNER_DASHBOARD',
  GET_PARTNER_DASHBOARD_SUCCESS = '@@partnerDashboard/GET_PARTNER_DASHBOARD_SUCCESS',
  GET_PARTNER_DASHBOARD_ERROR = '@@partnerDashboard/GET_PARTNER_DASHBOARD_ERROR',

  GET_FINANCIAL_DASHBOARD = '@@partnerDashboard/GET_FINANCIAL_DASHBOARD',
  GET_FINANCIAL_DASHBOARD_SUCCESS = '@@partnerDashboard/GET_FINANCIAL_DASHBOARD_SUCCESS',
  GET_FINANCIAL_DASHBOARD_ERROR = '@@partnerDashboard/GET_FINANCIAL_DASHBOARD_ERROR',

  GET_PIE_DATA = '@@partnerDashboard/GET_PIE_DATA',
  GET_PIE_DATA_SUCCESS = '@@partnerDashboard/GET_PIE_DATA_SUCCESS',
  GET_PIE_DATA_ERROR = '@@partnerDashboard/GET_PIE_DATA_ERROR',

  RESET_STATUS = '@@partnerDashboard/RESET_PARTNER_DASHBOARD_STATUS',
  RESET_FINANCIAL_DASHBOARD_STATUS = '@@partnerDashboard/RESET_FINANCIAL_DASHBOARD_STATUS',
}

export interface PartnerDashboardState {
  readonly partnerDashboard?: PartnerDashboard;
  readonly getPartnerDashboardStatus?: HttpRequestStatus;

  readonly financialDashboard?: FinancialDashboard;
  readonly getFinancialDashboardStatus?: HttpRequestStatus;
  readonly pieDataList?: PieDataPartner[];
  readonly getPieDataStatus?: HttpRequestStatus;
}
