import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';

interface Props {
  initialDate?: Moment;
  interval?: number;
  currentTimeRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const DisplayCurrentTime: React.FC<Props> = ({
  initialDate,
  interval,
  currentTimeRef,
}) => {
  useEffect(() => {
    currentTimeRef.current = document.querySelector('.current-time');
  }, []);

  const currentMoment = moment();
  const currentMinutes = moment().parseZone().minutes();
  const totalMinutes = currentMoment.hours() * 60 + currentMoment.minutes();

  const gridRow = Math.floor(totalMinutes / interval);
  const gridColumn =
    3 +
    Number(moment().format('DDD')) -
    Number(moment(initialDate).format('DDD'));

  const top =
    currentMinutes == 0 ? 0 : `${_.divide(currentMinutes, 60) * 100}%`;

  const sameWeek = initialDate.isSame(moment().startOf('isoWeek'), 'week');
  if (!sameWeek) {
    return null;
  }
  return (
    <div
      className="current-time"
      style={{ gridColumn, gridRow: gridRow + 2, top }}>
      <div className="circle" />
    </div>
  );
};

export default DisplayCurrentTime;
