import { AxiosResponse } from 'axios';
import _ from 'lodash';
import HttpStatus from 'src/Models/Enums/HttpStatus';
import { PriceComposite, ResponsePreScheduleByPartner } from 'src/Models/Plan';
import { SendSuggest } from 'src/Models/SuggestPayment';
import PlanApi from './Api/PlanApi';
import { error } from './ToastService';

export interface Filters {
  order?: string;
}

export interface DeleteBody {
  id: number;
  type: string;
}
class PlanService {
  static sendSuggestPurchasePlan = async (obj: SendSuggest) => {
    try {
      const response = await PlanApi.postSuggestedPurchasePlan(obj);
      if (response.status === HttpStatus.OK) {
        return Promise.resolve(response.data);
      }
    } catch (e) {
      console.error(e);
      error('erro ao enviar a sugestão de compra');
      return e.response.data.fieldErrors;
    }
  };

  static getDiscountPlan = async (
    idPlan: number,
    customerId: number,
    date: string,
  ): Promise<PriceComposite> => {
    try {
      const response = await PlanApi.getDiscountOfPlan(
        idPlan,
        customerId,
        date,
      );
      if (response.status === HttpStatus.OK) {
        return Promise.resolve(response.data);
      }
    } catch (e) {
      console.error(e);
      error('erro ao pegar descontos');
      return e.response.data.fieldErrors;
    }
  };

  static getPreScheduleByPartner = async (
    size: number,
    page: number,
    filters?: Filters,
  ): Promise<ResponsePreScheduleByPartner> => {
    try {
      const response = await PlanApi.getPreScheduleByPartner(
        size,
        page,
        filters,
      );
      if (response.status === HttpStatus.OK) {
        return Promise.resolve(response.data);
      }
    } catch (e) {
      console.error(e);
      error('erro ao pegar descontos');
      return e.response.data.fieldErrors;
    }
  };

  static getCancelGetPreScheduleByPartner = async (
    deleteBody: DeleteBody,
  ): Promise<ResponsePreScheduleByPartner> => {
    try {
      const response = await PlanApi.cancelGetPreScheduleByPartner(deleteBody);
      if (response.status === HttpStatus.OK) {
        return Promise.resolve(response.data);
      }
    } catch (e) {
      console.error(e);
      error('erro ao pegar descontos');
    }
  };
}

export default PlanService;
