import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Schedules from 'src/Components/PartnerComponents/Schedules';
import PageTitle from '../../Components/PartnerComponents/PageTitle';
import ScheduleHistoryTable from 'src/Components/PartnerComponents/ScheduleHistoryTable';
import OverviewCards from 'src/Components/OverviewCards';
import VideoTutorial, {
  VideoType,
} from 'src/Components/PartnerComponents/VideoTutorial';
import VideoTutorialIcon from 'src/Components/PartnerComponents/VideoTutorialIcon';

export interface PartnerOverviewProps extends WithTranslation {}

const PartnerOverview: React.FC<PartnerOverviewProps> = ({ t }) => {
  return (
    <>
      <PageTitle
        title={'overview'}
        rightElement={
          <VideoTutorialIcon>
            <VideoTutorial type={VideoType.OVERVIEW_CONFIG} />
          </VideoTutorialIcon>
        }
      />
      {/* <OverviewCards /> */}
      <Schedules />
      <ScheduleHistoryTable />
    </>
  );
};

export default withTranslation()(PartnerOverview);
