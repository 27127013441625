import _ from 'lodash';
import moment from 'moment';

const daysArray = [
  {
    dayIndex: 0,
    title: 'DOM.',
    name: 'sunday',
  },
  {
    dayIndex: 1,
    title: 'SEG.',
    name: 'monday',
  },
  {
    dayIndex: 2,
    title: 'TER.',
    name: 'tuesday',
  },
  {
    dayIndex: 3,
    title: 'QUA.',
    name: 'wednesday',
  },
  {
    dayIndex: 4,
    title: 'QUI.',
    name: 'thursday',
  },
  {
    dayIndex: 5,
    title: 'SEX.',
    name: 'friday',
  },
  {
    dayIndex: 6,
    title: 'SAB.',
    name: 'saturday',
  },
];

export const daysOfWeek = {
  sunday: {},
  monday: {},
  friday: {},
  tuesday: {},
  thursday: {},
  saturday: {},
  wednesday: {},
};

export const timeHour = [
  {
    hour: '01:00',
  },
  {
    hour: '02:00',
  },
  {
    hour: '03:00',
  },
  {
    hour: '04:00',
  },
  {
    hour: '05:00',
  },
  {
    hour: '06:00',
  },
  {
    hour: '07:00',
  },
  {
    hour: '08:00',
  },
  {
    hour: '09:00',
  },
  {
    hour: '10:00',
  },
  {
    hour: '11:00',
  },
  {
    hour: '12:00',
  },
  {
    hour: '13:00',
  },
  {
    hour: '14:00',
  },
  {
    hour: '15:00',
  },
  {
    hour: '16:00',
  },
  {
    hour: '17:00',
  },
  {
    hour: '18:00',
  },
  {
    hour: '19:00',
  },
  {
    hour: '20:00',
  },
  {
    hour: '21:00',
  },
  {
    hour: '22:00',
  },
  {
    hour: '23:00',
  },
  {
    hour: '00:00',
  },
];

export const startAtHoursFromInterval = (interval: number) => {
  const stringHours = _.range(0, 24).flatMap((hours) =>
    _.range(0, 60, interval).map(
      (minutes) =>
        `${hours.toString().padStart(2, '0 ')}:${minutes
          .toString()
          .padStart(2, '0')}`,
    ),
  );
  return stringHours;
};

export const getScheduleWeekRange = (initialDate) => {
  const daysOfWeek = getDaysOfWeek(initialDate);

  return {
    firstDay: daysOfWeek[0] || {},
    lastDay: daysOfWeek[6] || {},
  };
};

export const getDaysOfWeek = (startDate?) => {
  let date = moment();
  const day = startDate ? startDate?.day() : moment()?.day();
  const today = startDate ? startDate : moment().format('YYYY-MM-DD');

  let finalWeekArray = [];
  let alteranativeIndex = 0;

  for (let index = 0; index < 7; index++) {
    date = moment(today).add(index, 'days');

    const daySum = day + index;
    let searchIndex = daySum;

    if (daySum > 6) {
      searchIndex = alteranativeIndex;
      alteranativeIndex++;
    }

    const currentDay = daysArray.filter(
      (day) => day.dayIndex === searchIndex,
    )[0] || { name: 0 };

    finalWeekArray = [
      ...finalWeekArray,
      {
        ...currentDay,
        dayNumber: moment(date).format('D'),
        date: date.format('YYYY-MM-DD'),
      },
    ];
  }

  return finalWeekArray;
};

export const getScheduleFilters = () => {
  const filterValue = localStorage.getItem('schedulesFilter');

  const schedulesFilter = filterValue === 'null' ? null : filterValue;

  return {
    schedulesFilter,
    toDate: localStorage.getItem('toDate'),
    fromDate: localStorage.getItem('fromDate'),
  };
};

export const getCustomerNameLetters = (name) => {
  const fullName = name.split(' ');

  if (fullName.length < 2) return name[0] || '';

  const initials = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
  return initials?.toUpperCase();
};
