import { PageableRequest, PageableResponse } from 'src/Models/pageable';
import { AxiosResponse } from 'axios';
import { api } from '.';
import { Billing } from 'src/Models/Billing';
import qs from 'qs';

const baseUrl = 'billings';

class BillingApi {
  static getPartnerHistory = (payload?: {
    size?: number;
    page?: number;
    type?: string;
    search?: string;
    fromDate?: string;
    toDate?: string;
    whichDateToFilter?: string;
  }): Promise<AxiosResponse<PageableResponse<Billing>>> => {
    const params = qs.stringify(payload, { skipNull: true });
    return api.get(`${baseUrl}/history?${params}`);
  };
}

export default BillingApi;
