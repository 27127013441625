import classNames from 'classnames';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AddPack from 'src/Pages/Partner/AddPack';
import AddActivity from 'src/Pages/Partner/AddActivity';
import AddPlan from 'src/Pages/Partner/AddPlan';
import Finances from 'src/Pages/Partner/Finances';
import Profile from 'src/Pages/Partner/Profile';
import Footer from '../Components/Footer';
import Login from '../Pages/Login';
import Logout from '../Pages/Logout';
import PartnerOverview from '../Pages/Partner';
import Activities from '../Pages/Partner/Activities';
import NewGrid from '../Pages/Partner/NewGrid';
import NewSchedule from '../Pages/Partner/NewSchedule';
import PageNotFound from './Components/Error/PageNotFound';
import PrivateRoutes, { AccessLevel } from './Components/PrivateRoutes';
import HistoryUserPage from 'src/Components/HistoryPageUser';
import PaymentsPending from 'src/Components/PaymentsPending';
import HistoryPayments from 'src/Components/historyPayments';
import ChatPage from 'src/Pages/Chat';

const AppRoutes = ({ match, isAuthenticated }) => {
  const history = useHistory();
  const appRoutesClassName = isAuthenticated ? 'has-header' : '';

  return (
    <div className={classNames([appRoutesClassName, 'App-routes-wrapper'])}>
      <div className={'App-routes-wrap'}>
        <Switch>
          <Route exact path={'/'} component={Login} />
          <Route exact path={'/logout'} component={Logout} />
          <PrivateRoutes
            exact
            path={'/overview'}
            component={PartnerOverview}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/new-schedule'}
            component={NewSchedule}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/new-schedule/:id'}
            component={NewSchedule}
            accessLevel={AccessLevel.PARTNER}
          />
          {/* <PrivateRoutes
            exact
            path={'/makepurchase'}
            component={MakePurchase}
            accessLevel={AccessLevel.PARTNER}
          /> */}
          <PrivateRoutes
            exact
            path={'/makepurchase/pending'}
            component={PaymentsPending}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/makepurchase/history'}
            component={HistoryPayments}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/makepurchase/history/user'}
            component={HistoryUserPage}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/new-grid'}
            component={NewGrid}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/activities'}
            component={Activities}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/plans'}
            component={AddPlan}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/plans/:id'}
            component={AddPlan}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/add-pack'}
            component={AddPack}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/activity'}
            component={AddActivity}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/activity/:activityId'}
            component={AddActivity}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/finances'}
            component={Finances}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/profile'}
            component={Profile}
            accessLevel={AccessLevel.PARTNER}
          />
          <PrivateRoutes
            exact
            path={'/chat'}
            component={ChatPage}
            accessLevel={AccessLevel.PARTNER}
          />
          <Route path={'*'} component={PageNotFound} />
        </Switch>
      </div>
      {history.location.pathname !== '/makepurchase' ? <Footer /> : null}
    </div>
  );
};

export default AppRoutes;
export const DEFAULT_ROUTE_PARTNER = '/overview';
