import React from 'react';

import './styles.scss';
import moment from 'moment';
import SimpleModal from 'src/Components/SimpleModal';
import GradientButtonSC from 'src/Components/GradientButtonSC';

export interface DeleteTimeModal {
  openDeleteTimeModal?: boolean;
  handleCancelDeleteTime?: () => void;
  handleConfirmDeleteTime?: () => void;
  currentSchedule?: { id: number; date: string; status: string };
}

export const DeleteTimeModal: React.FC<DeleteTimeModal> = ({
  currentSchedule,
  openDeleteTimeModal,
  handleCancelDeleteTime,
  handleConfirmDeleteTime,
}) => {
  let title = 'Bloquear horário';

  if (currentSchedule?.status === 'BLOCK') title = 'Desbloquear horário';

  const ModalFooter = () => {
    return (
      <div className="modalFooter">
        <div className="footerConfirm">
          <GradientButtonSC
            isSecundary
            text
            onClick={handleConfirmDeleteTime}
            title={
              currentSchedule?.status === 'BLOCK' ? 'Desbloquear' : 'Bloquear'
            }
          />
        </div>
        <div className="footerCancel">
          <GradientButtonSC
            isSecundary
            text
            onClick={handleCancelDeleteTime}
            title="Cancelar"
          />
        </div>
      </div>
    );
  };

  return (
    <SimpleModal
      isOpen={openDeleteTimeModal}
      ComponentFooter={ModalFooter}
      modalTitle={title}
      width="620px">
      <div className="timeModal">
        <span className="date">
          {moment(currentSchedule?.date).format('dddd D MMMM YYYY - H:mm')}
        </span>
      </div>
    </SimpleModal>
  );
};

export default DeleteTimeModal;
