import React, { useEffect, useState } from 'react';
import './styles.scss';

import { ReactComponent as Pencil } from '../../../Images/Icons/pencil.svg';
import { ReactComponent as Arrow } from '../../../Images/Icons/arrowGreen.svg';
import { Agenda, AgendaStatus } from 'src/Models/Agenda';
import { useDispatch } from 'react-redux';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getAgenda, getAgendaById } from 'src/Stores/agenda/actions';
import { Check } from '@material-ui/icons';
import { NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import AvailableAgendaCard from '../PlanGrid/AvailableAgendaCard';
import { Container } from 'reactstrap';
import { Activity } from 'src/Models/Activity';
import { getActivities, getActivityById } from 'src/Stores/activity/actions';
import StringUtils from 'src/Utils/StringUtils';
import { ActivityPlaceTypeUtils } from 'src/Models/Plan';
import { action } from 'typesafe-actions';

export interface SelectActivitiesProps
  extends WithTranslation,
    DispatchProps,
    StateProps {
  location: { state };
  onSubmit?: (item?: Activity[]) => void;
  values?: Activity[];
}

interface AvailableActivitiesProps {
  item?: Activity;
  selected?: boolean;
  onActivitySelected?: (selected: Activity) => void;
}

const AvailableActivitiesCard: React.FC<AvailableActivitiesProps> = ({
  item,
  selected,
  onActivitySelected,
}) => {
  function activeItem() {
    onActivitySelected(item);
  }

  return (
    <div className="AvailableActivityCard">
      <div
        className={`col-md-12 col-md-offset-1 flex ${
          selected ? 'plr-1' : 'plr-0'
        }`}
        onClick={activeItem}>
        <div className={'p-2'}>
          <div className={`circle ${selected ? 'circle-color' : ''}`} />
          <div className={'d-flex flex-column'}>
            <span className="title-card">{item?.name}</span>
            <span className="subtitle-price">
              {StringUtils.currencyPtBr(item?.price)}
            </span>
            <span className="subtitles">
              {ActivityPlaceTypeUtils.getLabel(item?.type)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectActivities: React.FC<SelectActivitiesProps> = ({
  getActivities,
  activities,
  onSubmit,
  values,
}) => {
  const [showPartnerActivities, setshowPartnerActivities] =
    useState<boolean>(false);
  const [availableActivities, setAvailableActivities] = useState<Activity[]>(
    [],
  );
  const [selectedActivities, setSelectedActivities] = useState<Activity[]>([]);

  function handleShowAgenda() {
    setshowPartnerActivities(!showPartnerActivities);
  }

  function handleAgendaSelected(item: Agenda) {
    let newValue = [];
    if (selectedActivities.some((it) => it?.id === item?.id)) {
      newValue = selectedActivities.filter((it) => it.id != item.id);
    } else {
      newValue = selectedActivities.concat([item]);
    }
    setSelectedActivities(newValue);
    onSubmit?.(newValue);
  }

  function handleIncludeAgenda() {
    setshowPartnerActivities(false);
    onSubmit?.(selectedActivities);
  }

  useEffect(() => {
    getActivities();
    return () => {
      setSelectedActivities([]);
      setAvailableActivities([]);
      setshowPartnerActivities(false);
    };
  }, []);

  useEffect(() => {
    if (activities != null) {
      setAvailableActivities(activities);
    }
  }, [activities]);

  useEffect(() => {
    if (values != null) {
      setSelectedActivities(values);
    }
  }, [values]);

  if (availableActivities == null) {
    return <div />;
  }

  return (
    <Container className="mt-40 card-activity">
      <span className="value-title">Vincular atividade</span>
      {!showPartnerActivities ? (
        <div className="include-activity">
          {selectedActivities?.map((activity) => (
            <div className="activity-card">
              <span className="activity-title">Atividade</span>
              <span className="activity-name">{activity.name}</span>
              <span className="activity-price">
                {StringUtils.currencyPtBr(activity?.price)}
              </span>
              <span className="activity-type">
                {ActivityPlaceTypeUtils.getLabel(activity?.type)}
              </span>
              <NavLink
                to={{
                  pathname: `/activity/${activity.id}`,
                }}>
                <div className="activity-edit">
                  <span className="activity-edit-text">Editar</span>
                  <Arrow />
                </div>
              </NavLink>
            </div>
          ))}
          <div className="add-button" onClick={handleShowAgenda}>
            <Pencil />
            <span>Incluir atividade</span>
          </div>
        </div>
      ) : (
        <>
          <div className="available-agenda">
            {availableActivities.map((it) => (
              <AvailableActivitiesCard
                item={it}
                onActivitySelected={handleAgendaSelected}
                selected={selectedActivities?.some?.(
                  (selectedActivity) => it.id === selectedActivity.id,
                )}
              />
            ))}
          </div>
          <div>
            <button
              className="include-activity-button"
              onClick={handleIncludeAgenda}>
              <Check fontSize="medium" />
              Incluir atividade
            </button>
          </div>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    activities: state.activity.activities,
  };
};

const mapDispatchToProps = {
  getActivities,
  getActivityById,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SelectActivities),
) as React.ComponentType<any>;
