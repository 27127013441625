import styled from  'styled-components'

interface Number {
  isSelect?: boolean;
}

export const ContainerAll = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
`

export const Container = styled.div`
width: 995px;
height: 625px;
margin-top: 40px;
margin-bottom: 44px;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 20px 0px;
background: #FFFFFF;
border-radius: 8px;
`

export const ContainerTitleAndFilters = styled.div`
  width: 955px;
  height: 44px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TitleAndTootip = styled.div`
  width: 220px;
  display: flex;
  align-items: center;
`
export const Title = styled.span`
width: 220px;
margin-right: 6px;
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 100%;
color: #455561;
`

export const ImageTooltip = styled.img`
  
`

export const ContainerTooltip = styled.div`
position: relative;`

export const ContainerFiltersAndButton = styled.div`
  min-width: 620px;
  display: flex;
  margin-left: 280px;
`

export const ContainerInputAndImg = styled.div`
width: 269px;
height: 44px;
display: flex;
align-items: center;
padding: 3px 12px;
border: 2px solid #8D979E;
border-radius: 22px;
img{
  width: 20px;
  height: 20px;
}
`

export const InputStyled = styled.input`
  width: 220px;
  background-color: transparent;
  border: none;
  ::placeholder{
    font-family: 'Muller';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #8D979E;
  }
`

export const ButtonSort = styled.button`
min-width: 108px;
height: 44px;
border: 2px solid #25B379;
border-radius: 22px;
padding: 3px 12px;
color: #25B379;
background-color: transparent;
margin-left: 20px;
`

export const TitleButtonSort = styled.span`
margin-left: 7px;
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 14px;
color: #25B379;
`

export const ButtonContinue = styled.button`
width: 170px;
height: 44px;
padding: 3px 12px;
background: #25B379;
border: 2px solid #25B379;
border-radius: 22px;
`

export const TitleButtonContinue = styled.span`
margin-right: 10px;
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 14px;
color: #FFFFFF;
`

export const Divider = styled.div`
width: 955px;
height: 1px;
background: #F1ECEC;
margin-top: 20px;
`

export const ContainerPaymentsPending = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const ContainerPagination = styled.div`
  padding: 20px;
  height: 72px;
  display: flex;
  img{
    cursor: pointer;
  }
`

export const ContainerNumbers = styled.div`
  height: 32px;
  margin-left: 22px;
  margin-right: 22px;
  display: flex;
`

export const ContainerNumber = styled.div<Number>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ isSelect }) => isSelect ? "2px solid #25B379" : "none"};
  border-radius: 4px;
`

export const TitleNumber = styled.span<Number>`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ isSelect }) => isSelect ? "#25B379" : "#455561"};
`

export const Tooltip = styled.div`
width: 247px;
height: 72px;
padding: 8px;
background: #FFFFFF;
box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
border-radius: 8px;
position: absolute;
`

export const TitleTooltip = styled.span`
  width: 231px;
  max-height: 50px;
  position: absolute;
  left: 8px;
  top: 10px;
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #455561;
`


export const ContainerDropDown = styled.div`
width: 144px;
height: 141px;
padding-top: 8px;
display: flex;
flex-direction: column;
align-items: center;
background: #FFFFFF;
border-radius: 8px;
position: absolute;
box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
`

export const ContainerOptions = styled.div`
width: 127px;
height: 32px;
display: flex;
align-items: center;
border-bottom: 1px solid #F1ECEC;
cursor: pointer;
`

export const TitleDropDown = styled.span`
margin: 0 10px 0 8px;
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 14px;
text-align: center;
color: #25B379;
`

export const TitleOptions = styled.span`
margin: 0 0 0 8px;
font-family: 'Muller';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 14px;
display: flex;
align-items: center;
text-align: center;
color: #455561;
`