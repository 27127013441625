import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import InputClassic from '../../../PlanFormat/InputClassic';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import './styles.scss';
import { AcademicEducation, ResponsibleData } from 'src/Models/Profile';
import StringUtils from 'src/Utils/StringUtils';
import moment from 'moment';
import { PartnerCreate } from 'src/Models/Partner';
import { putResponsibleData } from 'src/Stores/profile/actions';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import Button from '@mui/material/Button';
import { useWidth } from 'src/Hooks/useWidth';
import { cpfMask, dateMask } from 'src/Utils/Masks';

export interface ModalProps extends StateProps, DispatchProps {
  open: boolean;
  partner: PartnerCreate;
  setOpen: (open: boolean) => void;
}

const defaultData = {
  birthDate: '',
  cpf: '',
  id: 0,
  name: '',
  email: '',
  concilType: 'crp',
  concilNumber: '',
  partnerPlaces: [],
};

const defaultError = {
  cpf: false,
  email: false,
  birthDate: false,
};

const defaultFormation = {
  index: 0,
  course: '',
  institution: '',
  conclusionYear: '',
};

const ResponsibleModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  profile,
  partner,
  putResponsibleData,
}) => {
  const [errors, setErrors] = useState(defaultError);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [data, setData] = useState<ResponsibleData>(defaultData);
  let [newFormations, setNewFormations] = useState<AcademicEducation[]>([]);

  let widthSize = useWidth();

  // setting inital data
  useEffect(() => {
    let concilType = '';
    let concilNumber = '';

    if (profile?.id) {
      if (profile?.hasOwnProperty('crp')) concilType = 'crp';
      else if (profile?.hasOwnProperty('crn')) concilType = 'crn';
      else if (profile?.hasOwnProperty('cref')) concilType = 'cref';

      concilNumber = profile[concilType] || '';
    }

    const dataObj = {
      ...data,
      concilType,
      concilNumber,
      cpf: partner?.cpf,
      name: partner.name,
      email: partner?.user?.email,
      birthDate: partner?.birthDate,
    };

    if (concilType === '') setData(dataObj);
    else setData({ ...dataObj, [concilType]: profile[concilType] || '' });
  }, [partner, profile]);

  // setting initial formations
  useEffect(() => {
    if (profile?.academicEducation?.length === 0) return;

    let formations = [];

    profile?.academicEducation?.forEach((formation, index) => {
      formations = [...formations, { index, ...formation }];
    });

    setNewFormations(formations);
  }, [profile]);

  const handleClose = () => {
    let formations = [];

    profile?.academicEducation?.forEach((formation, index) => {
      formations = [...formations, { index, ...formation }];
    });

    setNewFormations(formations);

    handleAfterSave();
  };

  const handleAfterSave = () => {
    setOpen(false);
    setEnableSave(false);
  };

  const handleChange = (e, formation?) => {
    const { name = '', value = '' } = e?.target;

    const formatter = ['cpf'];

    if (formatter.includes(name)) handleChangeWithFormatter(e);
    else if (defaultFormation.hasOwnProperty(name))
      handleChangeFormation(e, formation);
    else setData({ ...data, [name]: value });

    setEnableSave(true);
  };

  const handleChangeWithFormatter = (e) => {
    const { value, name } = e?.target;

    const formatterFunc = {
      cpf: StringUtils.cpfFormatter,
    };

    setData({ ...data, [name]: formatterFunc[name](value) });
  };

  const handleChangeFormation = (e, formation) => {
    const { name, value } = e?.target;

    let updatedFormations = [];

    newFormations?.forEach((currentFormation) => {
      if (currentFormation?.index === formation?.index) {
        updatedFormations = [
          ...updatedFormations,
          { ...currentFormation, [name]: value },
        ];
      } else
        updatedFormations = [...updatedFormations, { ...currentFormation }];
    });

    setNewFormations(updatedFormations);
  };

  const displayNewFormation = (formation: AcademicEducation) => {
    return (
      <div key={formation?.index}>
        <div className="space-beetween--formation mt-20">
          <h2 className="modalTitle-ResponsibleData">Formação</h2>
          <div
            className="flex txt-verde addFormation"
            onClick={() => handleRemoveFormation(formation)}>
            <DeleteOutlineIcon />
            <span>Excluir formação</span>
          </div>
        </div>

        <div className="flex mt-20">
          <div
            className={`${widthSize > 450 ? 'mb-20 col-12' : 'mb-20 col-16'}`}>
            <InputClassic
              title="Curso"
              placeholder="Curso"
              name="course"
              value={formation?.course}
              onChange={(e) => handleChange(e, formation)}
            />
          </div>
        </div>
        <div className="flex">
          <div
            className={`${widthSize > 450 ? 'mb-20 col-4' : 'mb-20 col-16'}`}>
            <InputClassic
              title="Ano"
              placeholder="2009"
              name="conclusionYear"
              value={formation?.conclusionYear}
              onChange={(e) => handleChange(e, formation)}
            />
          </div>
          <div
            className={`${widthSize > 450 ? 'mb-20 col-8' : 'mb-20 col-16'}`}>
            <InputClassic
              title="Instituição"
              placeholder="Instituição"
              name="institution"
              value={formation?.institution}
              onChange={(e) => handleChange(e, formation)}
            />
          </div>
        </div>
      </div>
    );
  };

  const displayFormations = () => {
    return (
      <div className="formationsContainer">
        {newFormations
          ?.sort((a, b) => b.index - a.index)
          .map((formation) => {
            return displayNewFormation(formation);
          })}
      </div>
    );
  };

  const handleAddFormation = () => {
    setNewFormations([
      ...newFormations,
      { ...defaultFormation, index: newFormations.length + 1 },
    ]);
  };

  const handleRemoveFormation = (formation: AcademicEducation) => {
    const filteredFormations = newFormations.filter(
      (line) => line?.index !== formation?.index,
    );

    setNewFormations([...filteredFormations]);
  };

  const handleSave = () => {
    const validFormations = newFormations?.filter(
      (formation) => formation?.course?.length > 0,
    );

    const putObj = {
      ...data,
      id: profile?.id,
      partnerPlaces: [
        {
          [data?.concilType]: data?.concilNumber,
          academicEducation: [...validFormations],
        },
      ],
    };

    putResponsibleData(putObj);
    handleClose();
  };

  const disabledSave = !enableSave || errors?.cpf || errors?.email;
  const saveButtonColor = !disabledSave ? '#25B379 !important' : 'light-grey';

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="paper-responsible__formation">
          <div className="modalAlign-Responsible--formation">
            <div>
              <h2
                id="transition-modal-title"
                className="modalTitle-principal--Responsible--formation">
                Editar Formação Acadêmica
              </h2>
            </div>
            <div>
              <CloseIcon className="modalIcon" onClick={handleClose} />
            </div>
          </div>
          <div className="flex">
            <div className="underline"></div>
            <div className="col-12 p-0"></div>
          </div>
          <div className="add__formation--btn--container ">
            <span className="modalTitle-Responsible--formation remove-low-450">
              Formação Acadêmica
            </span>
            <div
              className=" txt-verde addFormation--btn"
              onClick={() => handleAddFormation()}>
              <AddIcon />
              <span>Adicionar formação</span>
            </div>
          </div>
          <div className="underline" />

          <div className="col-12 formations__bigger--container">
            {displayFormations()}
            <div className="footerContainer">
              <span onClick={handleClose} className="cancel">
                Cancelar
              </span>
              <Button
                sx={{ background: saveButtonColor, borderRadius: '22px' }}
                variant="contained"
                onClick={handleSave}
                disabled={disabledSave}>
                {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile } = state?.profile;
  const { partner } = state?.authentication;

  return { profile, partner };
};

const mapDispatchToProps = {
  putResponsibleData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleModal);
