import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PageTitle from 'src/Components/PageTitle';
import Finances from 'src/Components/PartnerComponents/Finances';

export interface ActivitiesProps extends WithTranslation {}

const Activities: React.FC<ActivitiesProps> = ({ t }) => {
  return (
    <>
      <PageTitle title={'finances'} />
      <Finances />
      <div className="adjustPades"></div>
    </>
  );
};

export default withTranslation()(Activities);
