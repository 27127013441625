import './styles.scss';
import React from 'react';
import LoggedIn from './LoggedIn';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router';
import Header from '../PartnerComponents/Header';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface FixedHeaderProps extends RouteComponentProps, WithTranslation {}

const FixedHeader: React.FC<FixedHeaderProps> = () => {
  const history = useHistory();

  if (history?.location?.pathname == '/conference') {
    return <div />;
  }

  return (
    <div className="fixed-header">
      <Container>
        <div className="fixed-header__items">
          <div className="insideContainer fixed-header__items--menu">
            <div
              className="fixed-header__items--logo"
              onClick={() => history.push('/overview')}
            />
            <Header />
            <LoggedIn />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(withRouter(FixedHeader));
