import React, { useEffect, useState } from 'react';
import './styles.scss';

import { ReactComponent as Pencil } from '../../../../Images/Icons/pencil.svg';
import { ReactComponent as Arrow } from '../../../../Images/Icons/arrowGreen.svg';
import AvailableAgendaCard from '../AvailableAgendaCard';
import { Agenda, AgendaStatus } from 'src/Models/Agenda';
import { useDispatch } from 'react-redux';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getAgenda, getAgendaById } from 'src/Stores/agenda/actions';
import { Check } from '@material-ui/icons';
import { NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import StringUtils from 'src/Utils/StringUtils';

export interface CardsProps extends WithTranslation, DispatchProps, StateProps {
  location: { state };
  onSubmit?: (item?: Agenda[]) => void;
  values?: Agenda[];
}

const Cards: React.FC<CardsProps> = ({
  getAgenda,
  agendas,
  onSubmit,
  values,
}) => {
  const [showPartnerAgenda, setShowPartnerAgenda] = useState<boolean>(false);
  const [availableAgendas, setAvailableAgendas] = useState<Agenda[]>([]);
  const [selectedAgendas, setSelectedAgendas] = useState<Agenda[]>([]);

  function handleShowAgenda() {
    setShowPartnerAgenda(!showPartnerAgenda);
  }

  function handleAgendaSelected(item: Agenda) {
    if (selectedAgendas.some((it) => it.id === item.id)) {
      const newValue = selectedAgendas.filter((it) => it.id != item.id);
      setSelectedAgendas(newValue);
      return;
    }
    const newValue = selectedAgendas.concat([item]);
    setSelectedAgendas(newValue);
  }

  function handleIncludeAgenda() {
    setShowPartnerAgenda(false);
    onSubmit?.(selectedAgendas);
  }

  useEffect(() => {
    getAgenda();
  }, []);

  useEffect(() => {
    if (agendas != null) {
      setAvailableAgendas(agendas);
    }
  }, [agendas]);

  useEffect(() => {
    if (values != null) {
      setSelectedAgendas(values);
    }
  }, [values]);

  const transform = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0,
  };

  return (
    <>
      {!showPartnerAgenda ? (
        <div className="include-agenda">
          {selectedAgendas?.map((agenda, idx) => (
            <div key={agenda.id} className="agenda-card">
              <span className="agenda-title">Agenda</span>
              <span className="agenda-name">
                {StringUtils.limitString(agenda?.name, 15)}
              </span>
              <div className="agenda-days-of-week">
                <p>
                  {agenda?.presetAgendaActivitySchedules
                    .reduce((acc, curr) => {
                      if (!acc.some((it) => it == curr?.dayOfWeek)) {
                        acc.push(curr?.dayOfWeek);
                      }
                      return acc;
                    }, [])
                    .map((it) => transform[it])
                    .sort()
                    .map((it) => moment().day(it))
                    .map((it) => moment(it).format('ddd').toUpperCase())
                    .join(' • ')}
                </p>
              </div>
              <NavLink
                to={{
                  pathname: `/new-schedule/${agenda.id}`,
                }}>
                <div className="agenda-edit">
                  <span className="agenda-edit-text">Editar</span>
                  <Arrow />
                </div>
              </NavLink>
            </div>
          ))}
          <div className="add-button" onClick={handleShowAgenda}>
            <Pencil />
            <span>Incluir agenda</span>
          </div>
        </div>
      ) : (
        <>
          <div className="available-agenda">
            {availableAgendas?.map((it) => (
              <AvailableAgendaCard
                key={it.id}
                item={it}
                onAgendaSelected={handleAgendaSelected}
                selected={selectedAgendas?.some?.(
                  (selectedAgenda) => it.id === selectedAgenda.id,
                )}
              />
            ))}
          </div>
          <div>
            <button
              className="include-agenda-button"
              onClick={handleIncludeAgenda}>
              <Check fontSize="medium" />
              Incluir agenda
            </button>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    agendas: state.agenda.agendas,
  };
};

const mapDispatchToProps = {
  getAgenda,
  getAgendaById,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Cards),
) as React.ComponentType<any>;
