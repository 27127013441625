import { PageableResponse } from 'src/Models/pageable';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import BillingApi from 'src/Services/Api/BillingApi';
import {
  getBillingHistory,
  getBillingHistoryError,
  getBillingHistorySuccess,
} from './actions';
import { BillingTypes } from './types';
import { Billing } from 'src/Models/Billing';

function* getPartnerBillingHistory(
  action: ReturnType<typeof getBillingHistory>,
) {
  let result: AxiosResponse<PageableResponse<Billing>> | null;
  try {
    result = yield call(BillingApi.getPartnerHistory, action?.payload);
    if (result?.status === 200) {
      yield put(getBillingHistorySuccess(result.data));
    } else {
      yield put(getBillingHistoryError());
    }
  } catch (err) {
    console.log(err);

    yield put(getBillingHistoryError());
  }
}

function* billingsSaga() {
  yield all([
    takeLatest(BillingTypes.GET_BILLING_HISTORY, getPartnerBillingHistory),
  ]);
}

export default billingsSaga;
