import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import './styles.scss';
import moment from 'moment';
import {
  defaultPlanBody,
  useFormDefaultValues,
} from 'src/Utils/Plan/constants';
import { isEmpty, isNumber } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { Plan, PresetActivityPlanSchedulesUtils } from 'src/Models/Plan';
import { IRootState } from 'src/Stores';
import { CommonLoading } from 'react-loadingg';
import { error } from 'src/Services/ToastService';
import Name from 'src/Components/PartnerComponents/Name';
import { useHistory, useRouteMatch } from 'react-router';
import PlanGrid from 'src/Components/PartnerComponents/PlanGrid';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';
import PlanPrice from 'src/Components/PartnerComponents/PlanPrice';
import Description from 'src/Components/PartnerComponents/Description';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { postPlan, putPlan, getPlanById } from 'src/Stores/plan/actions';
import PlanCategory from 'src/Components/PartnerComponents/PlanCategory';
import UploadComponent from 'src/Components/PartnerComponents/UploadComponent';
import { getImageById, resetImageStatus } from 'src/Stores/image/action';
import RenewalInfo from 'src/Components/PartnerComponents/RenewalInfo';
import { PriceTableActivity } from 'src/Models/PriceTableActivity';

export interface AddPlan extends WithTranslation, DispatchProps, StateProps {
  location: { state };
}

const AddPlan: React.FC<AddPlan> = ({
  t,
  plan,
  putPlan,
  postPlan,
  location,
  planById,
  getPlanById,
  postPutStatus,
  statusPlanById,
  getImageById,
  resetImageStatus,
}) => {
  const { planId = false } = location?.state || {};
  const {
    watch,
    reset,
    resetField,
    register,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    defaultValues: { ...useFormDefaultValues, image: null },
  });
  const [disabledAdd, setDisabledAdd] = useState(true);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const match = useRouteMatch();
  useEffect(() => {
    // @ts-ignore
    const routeId = match?.params?.id;

    if (routeId != null && isNumber(parseInt(routeId)))
      getPlanById(parseInt(routeId));
  }, [getPlanById, match]);

  useEffect(() => {
    if (isDirty && disabledAdd) setDisabledAdd(false);
  }, [isDirty, disabledAdd]);

  const updateState = (isEditing: boolean, currentPlan: Plan) => {
    setEditingState({ isEditing, plan: currentPlan });

    const {
      id,
      free,
      name,
      slots,
      price,
      priceTableActivities,
      image,
      period,
      teacher,
      enabledB2b,
      description,
      typeOfRecurrenceOff,
      isRecurrenceNeeded,
      enrolmentFee,
      maintenanceFee,
      activityCategories = [],
      activitySubCategories = [],
      presetActivityPlanSchedules = [],
      type,
      isEnrolmentNeeded,
      typeDescription,
      minTimeTurnRecurrenceOff,
    } = currentPlan;

    let realImage;

    if (image?.imageUrl) {
      realImage = { ...image, preview: image?.imageUrl };
    } else realImage = { ...image };

    const activityCategoriesBody = {
      categories: activityCategories,
      subCategories: activitySubCategories,
    };

    const priceTableActivityToSet: PriceTableActivity =
      priceTableActivities.find(
        (priceTableActivity: PriceTableActivity) =>
          (priceTableActivity.priceTable.type = 'DEFAULT'),
      ) ?? priceTableActivities[0];

    setValue('id', id);
    setValue('name', name);
    setValue('priceWithFee', priceTableActivityToSet?.priceWithFee);
    setValue('priceWithDiscount', priceTableActivityToSet?.price);
    setValue('partnerProfit', price);
    setValue('slots', slots);
    setValue('isRecurrenceNeeded', isRecurrenceNeeded);
    setValue('typeOfRecurrenceOff', typeOfRecurrenceOff);
    setValue('minTimeTurnRecurrenceOff', minTimeTurnRecurrenceOff);
    setValue('free', free);
    setValue('period', period);
    setValue('image', realImage);
    setValue('teacher', teacher);
    setValue('enabledB2b', enabledB2b);
    setValue('description', description);
    setValue('enrolmentFee', enrolmentFee);
    setValue('maintenanceFee', maintenanceFee);
    setActivityCategories(activityCategoriesBody);
    setValue('type', type);
    setValue(
      'presetActivitySchedules',
      PresetActivityPlanSchedulesUtils.transform(presetActivityPlanSchedules),
    );
    setValue('isEnrolmentNeeded', isEnrolmentNeeded);
    setValue('typeDescription', typeDescription);
  };

  useEffect(() => {
    if (!planById || statusPlanById !== HttpRequestStatus.SUCCESS) return;

    updateState(true, planById);
  }, [statusPlanById, planById]);

  const verifyAndEnableSave = () => {
    if (disabledAdd === true) setDisabledAdd(false);
  };

  const [activityCategories, setActivityCategories] = useState({
    categories: [],
    subCategories: [],
  });

  const [editingState, setEditingState] = useState({
    isEditing: false,
    plan: {},
  });

  const history = useHistory();

  const errorHandler = () => {
    if (!isSubmitting) return;

    if (errors?.name) error('Nome da Atividade inválido');
    if (errors?.typeOfRecurrenceOff) error('Modelo de aviso prévio inválido');
    if (errors?.minTimeTurnRecurrenceOff) error('Escolha um período válido');
    // else if (errors?.teacher) error('Nome do Profissional inválido');
    else if (errors?.type) error('Local da atividade inválido');
    else if (errors?.description) error('Descrição inválida');
    else if (errors?.enrolmentFee) error('Taxa de matricula inválida');
    else if (errors?.maintenanceFee) error('Taxa de manutenção inválida');
    else if (errors?.image) error('Insira uma imagem');
    else if (errors?.priceWithFee) error('Preço inválido');
    else if (errors?.slots) error('Limite de Vagas inválido');
    else if (errors?.priceWithDiscount)
      error(
        'O preço Keiken deve ser maior que zero e menor que o preço de balcão/particular',
      );
  };

  errorHandler();

  useEffect(() => {
    if (!planId) return;

    const { plans = [] } = plan;

    const currentPlan: Plan = plans.filter((plan) => plan.id === planId)[0];

    if (!currentPlan?.id) return;

    updateState(true, currentPlan);
  }, [planId]);

  const imageStatus = useSelector(
    (root: IRootState) => root?.image?.imageStatus,
  );

  const image = useSelector((root: IRootState) => root?.image?.image);
  useEffect(() => {
    if (imageStatus === HttpRequestStatus.ONGOING) {
      setImageLoading(true);
      setValue('image', image);
    }
    if (imageStatus === HttpRequestStatus.SUCCESS) {
      setImageLoading(false);
      resetImageStatus();
    }
    if (imageStatus === HttpRequestStatus.ERROR) {
      setImageLoading(true);
      resetImageStatus();
    }
  }, []);

  const getDuration = (start = '', end = '') => {
    const startHour = moment(start, 'HH:mm');
    const endHour = moment(end, 'HH:mm');
    if (endHour.isBefore(startHour)) {
      endHour.add(1, 'day');
    }
    const diff = endHour.diff(startHour);

    const minutesDiff = moment.duration(diff).asMinutes();
    //return Math.floor(d.asHours()) + moment.utc(diff).format(':mm');
    return Math.floor(minutesDiff);
  };

  const apiFormatData = (data = { presetActivitySchedules: {} }) => {
    const { presetActivitySchedules = {} } = data;

    let presetSchedule = [];
    let presetEmpty = true;

    Object.keys(presetActivitySchedules).forEach((weekday) => {
      const currentDay = presetActivitySchedules[weekday] || {};
      const {
        startAt,
        endAt,
        startLunch = '',
        endLunch = '',
        dontWork,
      } = currentDay;

      const isWorkDay = dontWork != true;
      const isStopToLunch = !isEmpty(startLunch) && !isEmpty(endLunch);

      if (isWorkDay) {
        presetEmpty = false;
        const dayOfWeek = weekday.toUpperCase();

        if (isStopToLunch) {
          const firstDuration = getDuration(startAt, startLunch);
          const secondDuration = getDuration(endLunch, endAt);

          presetSchedule = presetSchedule.concat([
            {
              dayOfWeek,
              duration: firstDuration,
              startAt,
              zoneId: 'America/Sao_Paulo',
            },
            {
              dayOfWeek,
              duration: secondDuration,
              startAt: endLunch,
              zoneId: 'America/Sao_Paulo',
            },
          ]);
        } else {
          const duration = getDuration(startAt, endAt);

          presetSchedule.push({
            dayOfWeek,
            duration,
            startAt,
            zoneId: 'America/Sao_Paulo',
          });
        }
      }
    });

    return {
      ...data,
      presetEmpty,
      presetActivityPlanSchedules: presetSchedule,
    };
  };

  const onSubmit = (data) => {
    const { isRecurrenceNeeded } = data;
    const { minTimeTurnRecurrenceOff } = data;
    const { typeOfRecurrenceOff } = data;

    if (
      isRecurrenceNeeded === true &&
      (!minTimeTurnRecurrenceOff || !typeOfRecurrenceOff)
    ) {
      return;
    }

    const minTime =
      isRecurrenceNeeded === true ? minTimeTurnRecurrenceOff : null;

    const typeOfRecurrence =
      isRecurrenceNeeded === true ? typeOfRecurrenceOff : null;

    const { image, free, enrolmentContract, isEnrolmentNeeded } = data;
    const { isEditing = false } = editingState;

    if (isEnrolmentNeeded && !enrolmentContract?.file) {
      error(t('plan.contract.missing'));
      return;
    }

    const imageBody = {
      id: image?.file === null ? image.id : undefined,
      file: image?.file,
      fileName: image?.fileName || image?.name,
      contentType: image?.contentType || image?.type,
    };

    const { priceWithDiscount, partnerProfit, priceWithFee } = data;

    const contractBody = {
      id: enrolmentContract?.file === null ? enrolmentContract.id : undefined,
      file: enrolmentContract?.file,
      fileName: enrolmentContract?.fileName || enrolmentContract?.name,
      contentType: enrolmentContract?.contentType || enrolmentContract?.type,
    };

    const newDataFormat = apiFormatData(data);

    const { categories = [], subCategories: activitySubCategories = [] } =
      activityCategories;

    if (typeOfRecurrenceOff === 'DAILY' && minTimeTurnRecurrenceOff > 20) {
      error('Aviso prévio de cancelamento em dias é no máximo 20 dias.');
      return;
    }

    const submitData: Plan = {
      ...defaultPlanBody,
      isRurrenceNeeded: isRecurrenceNeeded,
      minTimeTurnRecurrenceOff: minTime,
      typeOfRecurrenceOff: typeOfRecurrence,
      ...newDataFormat,
      activityCategories: categories,
      activitySubCategories,
      image: imageBody,
      enrolmentContract: contractBody,
      priceTableActivities: [
        {
          price: priceWithDiscount ?? 0,
          priceWithFee: priceWithFee ?? 0,
        },
      ],
      price: partnerProfit ?? 0,
      free: free === 'true',
    };

    if (!newDataFormat.presetEmpty) {
      const sendData = isEditing ? putPlan : postPlan;
      sendData(submitData);
      reset();
    } else {
      error('Horário de funcionamento inválido');
    }
  };

  const essentialProps = {
    watch,
    register,
    resetField,
    setError,
    setValue,
    errors,
    setDisabledAdd,
    verifyAndEnableSave,
    clearErrors,
    hideGridFields: { duration: true },
  };

  const Names = (
    <Container className="center-cards-name-add">
      <Name
        {...essentialProps}
        inputName="name"
        title="Nome do plano"
        placeholder="Nome do plano"
      />
      <Name
        {...essentialProps}
        inputName="teacher"
        title="Nome do profissional"
        placeholder="Nome do profissional"
        required={false}
      />
    </Container>
  );

  const preventEnterSubmit = (e) => {
    e.key === 'Enter' && e.preventDefault();
  };

  const getHeaderName = () => {
    return editingState?.isEditing ? 'Editar plano' : 'Adicionar plano';
  };

  const headerName = getHeaderName();

  if (postPutStatus === HttpRequestStatus.ONGOING) {
    return (
      <div className="AddPlan">
        <CommonLoading />
      </div>
    );
  } else if (postPutStatus === HttpRequestStatus.SUCCESS) {
    history.push('/overview');
  }
  return (
    <div className="AddPlan">
      <BaseHeaderBenefits
        step="create"
        color="green"
        to="/activities"
        title={headerName}
      />
      <form
        onKeyPress={preventEnterSubmit}
        className="form-mt-100 formContainer"
        onSubmit={handleSubmit(onSubmit)}>
        {Names}
        <Description title="Descrição do plano" {...essentialProps} />
        <PlanGrid title="Agenda do plano" {...essentialProps} />
        <PlanPrice
          title="Preço do plano"
          {...essentialProps}
          isEdit={editingState?.isEditing}
        />
        <RenewalInfo
          title="Renovação do plano"
          {...essentialProps}
          isEdit={editingState?.isEditing}
        />
        {/*<PlanFormat title="Formato do Plano" />*/}
        <UploadComponent
          title="Imagem do plano"
          {...essentialProps}
          loading={imageLoading}
        />
        <PlanCategory
          subtitle="Subcategorias do plano"
          title="Categoria do plano"
          activityCategories={activityCategories}
          setActivityCategories={setActivityCategories}
          {...essentialProps}
        />
        <div className="adjustPades"></div>
        <BaseFooterBenefits
          text={headerName}
          disabled={disabledAdd}
          textBack="Cancelar"
          hasBackButton="true"
          typeSubmit
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { plan } = state;
  const { plan: planById, statusPlan: statusPlanById, postPutStatus } = plan;

  return {
    plan,
    planById,
    postPutStatus,
    statusPlanById,
  };
};

const mapDispatchToProps = {
  postPlan,
  putPlan,
  getPlanById,
  getImageById,
  resetImageStatus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AddPlan),
) as React.ComponentType<any>;
