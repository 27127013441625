import qs from 'qs';
import { AxiosResponse } from 'axios';
import { CustomerActivity } from 'src/Models/CustomerActivity';
import { defaultPageable, PageableRequest } from 'src/Models/pageable';
import { ResultPage } from 'src/Models/ResultPage';
import { api } from '.';

const baseUrl = '/mobile/customer-activities';

class CustomerActivityApi {
  static getCustomerActivitiesAvaliable = (
    page?: PageableRequest,
  ): Promise<AxiosResponse<ResultPage<CustomerActivity>>> => {
    const pageParams = page
      ? qs.stringify({ ...page })
      : qs.stringify(defaultPageable);
    return api.get<ResultPage<CustomerActivity>>(
      `${baseUrl}/avaliable?${pageParams}`,
    );
    // return new Mocker<ResultPage<CustomerActivity>>().mock(customerApiHistory);
  };

  static getById = (id: number): Promise<AxiosResponse<CustomerActivity>> => {
    return api.get<CustomerActivity>(`${baseUrl}/${id}`);
  };

  static getCustomerActivitiesHistory = (
    page?: PageableRequest,
  ): Promise<AxiosResponse<ResultPage<CustomerActivity>>> => {
    const pageParams = page
      ? qs.stringify({ ...page })
      : qs.stringify(defaultPageable);
    return api.get<ResultPage<CustomerActivity>>(
      `/customer-activities/partner-history?${pageParams}`,
    );
  };

  static deleteCustomerActivity = (id: number): Promise<AxiosResponse> => {
    return api.delete(`${baseUrl}/${id}`);
  };
}

export default CustomerActivityApi;
