import './styles.scss';
import { Image } from 'react-shimmer';
import { useDropzone } from 'react-dropzone';
import LoadImage from '../LoadImage/LoadImage';
import { CommonLoading } from 'react-loadingg';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useWidth } from 'src/Hooks/useWidth';
export interface Dropzone extends WithTranslation {
  watch: any;
  setValue: any;
  minWidth?: number;
  cropAspect?: number;
  editImageText?: string;
  showSaveButton?: boolean;
  verifyAndEnableSave?: () => void;
  register: (name: string, requirements: Object) => void;
}

const thumbStyle = {
  padding: 4,
  maxHeight: 180,
  display: 'flex',
  borderRadius: 2,
  marginBottom: 8,
  maxWidth: 'auto',
  overflow: 'hidden',
  justifyContent: 'center',
};

const Dropzone: React.FC<Dropzone> = ({
  t,
  watch,
  register,
  setValue,
  cropAspect,
  minWidth = 0,
  showSaveButton = true,
  editImageText = 'Editar imagem',
  verifyAndEnableSave = () => null,
}) => {
  const image = watch('image');
  const [isCrop, setIsCrop] = useState<boolean>(false);

  useEffect(() => {
    register('image', { required: true });
  }, [register]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 10485760,
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }

        const newFileImage = acceptedFiles?.map((file) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
              file: encoded
            })
        })[0] as File;

        const newObjectTosetValue = {
          ...image,
          ...newFileImage,
          contentType: newFileImage.type,
          fileName: newFileImage.name,
          isEdited: true 
        }

        setValue(
          'image',
          newObjectTosetValue
        );

        verifyAndEnableSave();
      };
    },
  });

  useEffect(
    () => () => {
      URL.revokeObjectURL(image?.preview);
    },
    [image],
  );

  let widthSize = useWidth();

  const displayBlankImage = () => {
    return (
      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} type="file" />
        <div>
          <span className="title dragImg">Arraste aqui uma imagem de capa</span>
          <span className="dragImg">ou</span>
          <span className="link">
            {widthSize > 450 ? 'Procurar um arquivo' : 'Adicionar foto de capa'}
          </span>
        </div>
      </div>
    );
  };

  const displayImagePreview = () => {
    return (
      <div className="preview" style={thumbStyle} key={image.name}>
        <Image src={image?.preview} fallback={<CommonLoading />} />
      </div>
    );
  };

  const handleEditImage = (newImage) => {
    setValue('image', {
      ...image,
      ...newImage,
      preview: `data:image/png;base64,${newImage?.file}`,
      isEdited: true,
    });

    verifyAndEnableSave();
  };

  const displayLoadImage = () => {
    return (
      <LoadImage
        image={image}
        minWidth={minWidth}
        editImageText={editImageText}
        onCrop={(value) => setIsCrop(value)}
        onSave={(newImage) => handleEditImage(newImage)}
        cropAspect={cropAspect}
        showSaveButton={showSaveButton}
      />
    );
  };

  const displayImageContainer = () => {
    if (!image?.preview || image?.preview?.length === 0) {
      return displayBlankImage();
    }

    return (
      <div className="img-container">
        {isCrop === false && displayImagePreview()}

        {displayLoadImage()}
      </div>
    );
  };

  return <div className="Dropzone">{displayImageContainer()}</div>;
};

export default withTranslation()(Dropzone);
