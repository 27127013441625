import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { Plan } from 'src/Models/Plan';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum PlanActionTypes {
  GET_PLANS_REQUEST = '@@plan/PLANS_REQUEST',
  GET_PLANS_REQUEST_ERROR = '@@plan/PLANS_REQUEST_ERROR',
  GET_PLANS_REQUEST_SUCCESS = '@@plan/PLANS_REQUEST_SUCCESS',

  GET_PLANS_APPROVED_REQUEST = '@@plan/PLANS_APPROVED_REQUEST',
  GET_PLANS_APPROVED_REQUEST_ERROR = '@@plan/PLANS_APPROVED_REQUEST_ERROR',
  GET_PLANS_APPROVED_REQUEST_SUCCESS = '@@plan/PLANS_APPROVED_REQUEST_SUCCESS',
  
  GET_PLAN_BY_ID_REQUEST = '@@plan/PLAN_BY_ID_REQUEST',
  GET_PLAN_BY_ID_REQUEST_ERROR = '@@plan/PLAN_BY_ID_REQUEST_ERROR',
  GET_PLAN_BY_ID_REQUEST_SUCCESS = '@@plan/PLAN_BY_ID_REQUEST_SUCCESS',
  
  POST_PLAN = '@@plan/POST_PLAN',
  POST_PLAN_ERROR = '@@plan/POST_PLAN_ERROR',
  POST_PLAN_SUCCESS = '@@plan/POST_PLAN_SUCCESS',

  PUT_PLAN = '@@plan/PUT_PLAN',
  PUT_PLAN_ERROR = '@@plan/PUT_PLAN_ERROR',
  PUT_PLAN_SUCCESS = '@@plan/PUT_PLAN_SUCCESS',

  RESET_POST_PUT_STATUS = '@@plan/RESET_POST_PUT_STATUS',

  DELETE_PLAN = '@@plan/DELETE_PLAN',
  DELETE_PLAN_ERROR = '@@plan/DELETE_PLAN_ERROR',
  DELETE_PLAN_SUCCESS = '@@plan/DELETE_PLAN_SUCCESS',
}

export interface PlanState {
  readonly plan?: Plan;
  readonly plans?: Plan[];
  readonly error?: HttpResponseError;
  readonly statusPlan?: HttpRequestStatus;
  readonly postPutStatus?: HttpRequestStatus;
}