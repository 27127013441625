import React from 'react';

import './styles.scss';

import bg from 'src/Images/Keiken/Partner/CardCategoria.png';
import { ActivitySubCategory } from 'src/Models/ActivitySubCategory';
import { ActivityCategory } from 'src/Models/ActivityCategory';
import CardSubCategory from '../CardSubCategory.tsx';
import { Container } from 'reactstrap';

export interface CardsCategoryProps {
  color: string;
  handleSelect: (category: ActivityCategory, select: boolean) => void;
  handleSelectSubCategory: (
    subCategory: ActivitySubCategory,
    select: boolean,
  ) => void;
  category: ActivityCategory;
  subcategories: ActivitySubCategory[];
  activityCategories?: {
    categories: ActivityCategory[];
    subCategories: ActivitySubCategory[];
  };
}

const CardsCategory: React.FC<CardsCategoryProps> = ({
  color,
  category,
  handleSelect,
  subcategories = [],
  activityCategories,
  handleSelectSubCategory,
}) => {
  function handleChange(select) {
    handleSelect(category, select);
  }

  const { name = '' } = category;

  const selected =
    activityCategories.categories.filter((line) => line?.id === category?.id)
      .length > 0;

  return (
    <>
      <div className="CardsCategory">
        {selected && (
          <div onClick={() => handleChange(true)}>
            <span className="texto">{name}</span>
            <div className="">
              <div className={`collor`}></div>
              <img
                src={category.image.imageUrl}
                alt="card"
                className="imagem active"
              />
            </div>
          </div>
        )}

        {!selected && (
          <div onClick={() => handleChange(false)}>
            <span className="texto">{name}</span>
            <div>
              <div className={`collor`}></div>
              <img
                src={category.image.imageUrl}
                alt="card"
                className="imagem no--active"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CardsCategory;
