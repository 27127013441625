import { action } from 'typesafe-actions';
import { ProfileTypes } from './types';
import { EnterpriseData, ResponsibleData } from 'src/Models/Profile';
import { Document } from 'src/Models/Document';

export const getPartnerProfile = () => action(ProfileTypes.GET_PROFILE);
export const getPartnerProfileSuccess = (profile: EnterpriseData) =>
  action(ProfileTypes.GET_PROFILE_SUCCESS, profile);
export const getPartnerProfileError = (message: string) =>
  action(ProfileTypes.GET_PROFILE_ERROR, message);

export const putEnterpriseDataSuccess = () => action(ProfileTypes.PUT_ENTERPRISE_DATA_SUCCESS);
export const putEnterpriseData = (enterpriseData: EnterpriseData) => action(ProfileTypes.PUT_ENTERPRISE_DATA, enterpriseData);
export const putEnterpriseDataError = (message: string) => action(ProfileTypes.PUT_ENTERPRISE_DATA_ERROR, message);

export const putEnterprisePageSuccess = () => action(ProfileTypes.PUT_ENTERPRISE_PAGE_SUCCESS);
export const putEnterprisePage = (id: number, enterprisePage: EnterpriseData) => action(ProfileTypes.PUT_ENTERPRISE_PAGE, {id, enterprisePage});
export const putEnterprisePageError = (message: string) => action(ProfileTypes.PUT_ENTERPRISE_PAGE_ERROR, message);

export const putResponsibleDataSuccess = () => action(ProfileTypes.PUT_RESPONSIBLE_DATA_SUCCESS);
export const putResponsibleData = (data: ResponsibleData) => action(ProfileTypes.PUT_RESPONSIBLE_DATA, data);
export const putResponsibleDataError = (message: string) => action(ProfileTypes.PUT_RESPONSIBLE_DATA_ERROR, message);

export const getAddressImageSuccess = (data: Document) => action(ProfileTypes.GET_IMAGE_ADDRESS_SUCCESS);