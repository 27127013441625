import { action } from 'typesafe-actions';
import { BankAccountTypes } from './types';
import { BankAccount } from 'src/Models/BankAccount';

export const getBankData = () => action(BankAccountTypes.GET_BANK_DATA);
export const getBankDataSuccess = (bankData: BankAccount) =>
  action(BankAccountTypes.GET_BANK_DATA_SUCCESS, bankData);
export const getBankDataError = (message: string) =>
  action(BankAccountTypes.GET_BANK_DATA_ERROR, message);

export const postBankDataSuccess = () => action(BankAccountTypes.POST_BANK_DATA_SUCCESS);
export const postBankData = (bankData: BankAccount) => action(BankAccountTypes.POST_BANK_DATA, bankData);
export const postBankDataError = (message: string) => action(BankAccountTypes.POST_BANK_DATA_ERROR, message);