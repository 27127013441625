import { api } from '.';
import { AxiosResponse } from 'axios';
import { Customer } from '../../Models/Customer';
import { CustomerRecurrence } from '../../Models/CustomerRecurrence';
import { SuggestionSchedulePayload } from '../../Models/SuggestionSchedulePayload';
import { ActivitySchedule } from '../../Models/ActivitySchedule';

export interface ScheduleParams {
  toDate?: string;
  fromDate?: string;
  activityType?: string;
}

const ActivityScheduleApi = () => {
  const getActiveActivitiesSchedules = (): Promise<
    AxiosResponse<ActivitySchedule[]>
  > => {
    return api.get<ActivitySchedule[]>('/activities/partner-place/actives');
  };

  const getAllActivitiesSchedules = ({
    activityType,
    fromDate,
    toDate,
  }: ScheduleParams): Promise<AxiosResponse<ActivitySchedule[]>> => {
    if (activityType && fromDate && toDate)
      return api.get<ActivitySchedule[]>(
        `/activity-schedules/agenda/all?activityType=${activityType}&fromDate=${fromDate}&toDate=${toDate}`,
      );

    if (fromDate && toDate)
      return api.get<ActivitySchedule[]>(
        `/activity-schedules/agenda/all?fromDate=${fromDate}&toDate=${toDate}`,
      );

    return api.get<ActivitySchedule[]>(`/activity-schedules/agenda/all`);
  };

  const getPartnerHistory = (
    search?: string,
  ): Promise<AxiosResponse<{ content: Customer[] }>> => {
    const param = search ? `search=${search}&size=10` : 'size=10';
    return api.get<{ content: Customer[] }>(
      `/customer/find-partner-history?${param}`,
    );
  };

  const postManualScheduling = ({
    schedule,
    customerId,
  }): Promise<AxiosResponse<ActivitySchedule>> => {
    return api.post<ActivitySchedule>(
      `/billings/suggested-activity-schedule/${customerId}`,
      schedule,
    );
  };

  const postScheduleRecurrence = (
    customerRecurrence?: CustomerRecurrence,
  ): Promise<AxiosResponse> => {
    return api.post(
      `/preset-renewal-activity-schedule/by-partner`,
      customerRecurrence,
    );
  };

  const postScheduleNoRecurrence = (
    customerNoRecurrence?: SuggestionSchedulePayload,
  ): Promise<AxiosResponse> => {
    return api.post(`/billings/suggested-by-partner`, customerNoRecurrence);
  };

  const postSingleActivitySchedule = (
    activitySchedule?: ActivitySchedule,
  ): Promise<AxiosResponse> => {
    return api.post(`/activity-schedules`, activitySchedule);
  };

  const putScheduleStatus = ({
    scheduleId,
    status,
  }): Promise<AxiosResponse<any>> => {
    return api.put<any>(`/activity-schedules/${scheduleId}/status`, { status });
  };

  const getReScheduleAvailables = ({
    scheduleId,
  }): Promise<AxiosResponse<ActivitySchedule[]>> => {
    return api.get<any>(`/mobile/activity-schedules/${scheduleId}/reschedule`);
  };

  const postReSchedule = (payload: {
    activityScheduleId: number;
    customerActivityId: number;
  }): Promise<AxiosResponse<ActivitySchedule[]>> => {
    return api.post<any>(`/billings/rescheduling-by-partner`, payload);
  };

  return {
    getPartnerHistory,
    putScheduleStatus,
    postManualScheduling,
    postScheduleRecurrence,
    postScheduleNoRecurrence,
    getAllActivitiesSchedules,
    getActiveActivitiesSchedules,
    getReScheduleAvailables,
    postReSchedule,
    postSingleActivitySchedule,
  };
};

export default ActivityScheduleApi();
