import { Reducer } from 'redux';
import { Plan } from 'src/Models/Plan';
import { PlanActionTypes, PlanState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: PlanState = {
  plan: null,
  plans: [],
};

const reducer: Reducer<PlanState> = (state = initialState, action) => {
  switch (action.type) {
    case PlanActionTypes.GET_PLANS_REQUEST: {
      return { ...state, plansStatus: HttpRequestStatus.ONGOING };
    }
    case PlanActionTypes.GET_PLANS_REQUEST_SUCCESS: {
      const plans = action?.payload?.content as Plan[];

      return { ...state, plans, plansStatus: HttpRequestStatus.SUCCESS };
    }
    case PlanActionTypes.GET_PLANS_REQUEST_ERROR: {
      return {
        ...state,
        plansStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case PlanActionTypes.GET_PLANS_APPROVED_REQUEST: {
      return { ...state, plansStatus: HttpRequestStatus.ONGOING };
    }
    case PlanActionTypes.GET_PLANS_APPROVED_REQUEST_SUCCESS: {
      const plans = action?.payload as Plan[];

      return { ...state, plans, plansStatus: HttpRequestStatus.SUCCESS };
    }
    case PlanActionTypes.GET_PLANS_APPROVED_REQUEST_ERROR: {
      return {
        ...state,
        plansStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case PlanActionTypes.GET_PLAN_BY_ID_REQUEST: {
      return { ...state, statusPlan: HttpRequestStatus.ONGOING };
    }
    case PlanActionTypes.GET_PLAN_BY_ID_REQUEST_SUCCESS: {
      const plan = action?.payload as Plan;

      return { ...state, plan, statusPlan: HttpRequestStatus.SUCCESS };
    }
    case PlanActionTypes.GET_PLAN_BY_ID_REQUEST_ERROR: {
      return {
        ...state,
        statusPlan: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case PlanActionTypes.PUT_PLAN:
    case PlanActionTypes.POST_PLAN: {
      return {...state, postPutStatus: HttpRequestStatus.ONGOING }
    }
    case PlanActionTypes.PUT_PLAN_SUCCESS:
    case PlanActionTypes.POST_PLAN_SUCCESS: {
      return {...state, postPutStatus: HttpRequestStatus.SUCCESS }
    }
    case PlanActionTypes.PUT_PLAN_ERROR:
    case PlanActionTypes.POST_PLAN_ERROR: {
      return {
        ...state,
        postPutStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case PlanActionTypes.RESET_POST_PUT_STATUS: {
      return {
        ...state,
        postPutStatus: HttpRequestStatus.NOOP,
      };
    }
    case PlanActionTypes.DELETE_PLAN: {
      return { ...state, plansStatus: HttpRequestStatus.ONGOING };
    }
    case PlanActionTypes.DELETE_PLAN_SUCCESS: {
      return { ...state, plansStatus: HttpRequestStatus.SUCCESS };
    }
    case PlanActionTypes.DELETE_PLAN_ERROR: {
      return {
        ...state,
        plansStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as planReducer };
