import React, { useState, useEffect } from 'react';
import './styles.scss';

import SyncAltIcon from '@material-ui/icons/SyncAlt';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import HiddenPassword from '@mui/icons-material/VisibilityOff';
import VisiblePassword from '@mui/icons-material/RemoveRedEyeOutlined';
import InputClassic from '../../PlanFormat/InputClassic';
import Button from '@mui/material/Button';
import { passwordChange } from 'src/Stores/authentication/actions';
import { connect } from 'react-redux';
import { useWidth } from 'src/Hooks/useWidth';

const defaultErrorData = {
  samePasswords: false,
  oldPassword: { activeError: '' },
  newPassword: { activeError: '' },
  differentNewPasswords: false,
  confirmationPassword: { activeError: '' },
};

const defaultPasswordData = {
  oldPassword: '',
  newPassword: '',
  confirmationPassword: '',
};

export interface ErrorData {
  samePasswords?: boolean;
  differentNewPasswords?: boolean;
  oldPassword?: { activeError: string };
  newPassword?: { activeError: string };
  confirmationPassword?: { activeError: string };
}
export interface PasswordData {
  oldPassword?: string;
  newPassword?: string;
  confirmationPassword?: string;
}

export interface ModalProps extends DispatchProps {}

const PasswordData: React.FC<ModalProps> = ({ passwordChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    newPassword: false,
    confirmationPassword: false,
  });
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorData>(defaultErrorData);
  const [data, setData] = useState<PasswordData>(defaultPasswordData);

  const { oldPassword, newPassword, confirmationPassword } = data;

  useEffect(() => {
    const validNewPassword = newPassword?.length > 0;
    const validConfirmation = confirmationPassword.length > 0;

    const sameNewPassword = oldPassword === newPassword;
    const sameConfirmation = newPassword === confirmationPassword;

    if (validNewPassword) {
      if (sameNewPassword)
        return setErrors({
          ...errors,
          samePasswords: true,
          oldPassword: { activeError: 'activeError' },
          newPassword: { activeError: 'activeError' },
        });

      if (errors?.samePasswords) setErrors(defaultErrorData);
    }

    if (validConfirmation) {
      if (sameConfirmation) return setErrors(defaultErrorData);

      if (!errors?.differentNewPasswords)
        setErrors({
          ...errors,
          differentNewPasswords: true,
          newPassword: { activeError: 'activeError' },
          confirmationPassword: { activeError: 'activeError' },
        });
    }
  }, [oldPassword, newPassword, confirmationPassword, errors]);

  useEffect(() => {
    if (
      oldPassword.length === 0 ||
      newPassword.length === 0 ||
      confirmationPassword.length === 0
    )
      return;

    if (currentErrors().length === 0 && !enableSave) setEnableSave(true);
    else if (currentErrors().length > 0 && enableSave) setEnableSave(false);
  }, [errors]);

  const resetData = () => {
    setErrors(defaultErrorData);
    setData(defaultPasswordData);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetData();
    setOpen(false);
  };

  const handleSave = () => {
    passwordChange({ currentPassword: oldPassword, newPassword });
    handleClose();
  };

  const showPassword = (field) => {
    const currentPassword = showPasswords[field];

    setShowPasswords({ ...showPasswords, [field]: !currentPassword });
  };
  const currentErrors = () => {
    let errorArray = [];

    Object.keys(errors).map((error) => {
      if (errors[error]?.activeError?.length > 0)
        errorArray = [...errorArray, error];
      else if (errors[error] === true) errorArray = [...errorArray, error];
    });

    return errorArray;
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;

    setData({ ...data, [name]: value });
  };

  const filedsMaxLength = 18;

  const displayEyeByClick = (field = '') => {
    if (field === 'oldPassword') {
      if (!showPasswords[field])
        return (
          <VisiblePassword
            className="right-adornment"
            onClick={() => showPassword('oldPassword')}
          />
        );

      return (
        <HiddenPassword
          className="right-adornment"
          onClick={() => showPassword('oldPassword')}
        />
      );
    } else if (field === 'newPassword') {
      if (!showPasswords[field])
        return (
          <VisiblePassword
            className="right-adornment"
            onClick={() => showPassword('newPassword')}
          />
        );

      return (
        <HiddenPassword
          className="right-adornment"
          onClick={() => showPassword('newPassword')}
        />
      );
    } else if (field === 'confirmationPassword') {
      if (!showPasswords[field])
        return (
          <VisiblePassword
            className="right-adornment"
            onClick={() => showPassword('confirmationPassword')}
          />
        );

      return (
        <HiddenPassword
          className="right-adornment"
          onClick={() => showPassword('confirmationPassword')}
        />
      );
    }
  };

  const disabledSave = !enableSave;
  const saveButtonColor = !disabledSave ? '#25B379 !important' : 'light-grey';

  const widthSize = useWidth();

  return (
    <div className="card-model">
      <div className="space-beetween">
        <div className="pt-10">
          <span className="value-title-password-change">Senha</span>
        </div>
        <div className="flex-center">
          <button onClick={handleOpen}>
            <SyncAltIcon /> &nbsp; Trocar senha
          </button>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 800 }}>
        <Fade in={open}>
          <div className="passwordContainer">
            <div className="modalAlign-passwordData">
              <h2
                id="transition-modal-title"
                className="modalTitle-passwordData">
                Trocar senha
              </h2>
              <CloseIcon className="modalIcon" onClick={handleClose} />
            </div>
            <div className="underline" />
            <div className="col-12 p-0 inputLine">
              <InputClassic
                name="oldPassword"
                title="Senha antiga"
                onChange={handleChange}
                value={data?.oldPassword}
                maxLength={filedsMaxLength}
                type={!showPasswords?.oldPassword ? 'password' : 'text'}
                className={`${errors?.oldPassword?.activeError}`}
              />
              {displayEyeByClick('oldPassword')}
            </div>
            <div className="secondRow">
              <div
                className={`${
                  widthSize > 450 ? 'col-6 leftPass' : 'col-12 leftPass'
                }`}>
                <div className="newPassword">
                  <InputClassic
                    name="newPassword"
                    title="Nova senha"
                    onChange={handleChange}
                    value={data?.newPassword}
                    maxLength={filedsMaxLength}
                    type={!showPasswords?.newPassword ? 'password' : 'text'}
                    className={`${errors?.newPassword?.activeError}`}
                  />
                  {displayEyeByClick('newPassword')}
                </div>
                {errors?.samePasswords && (
                  <p className="error">Senhas iguais!</p>
                )}
              </div>
              <div
                className={`${
                  widthSize > 450 ? 'col-6 rightPass' : 'col-12 rightPass'
                }`}>
                <div className="confirmation">
                  <InputClassic
                    onChange={handleChange}
                    name="confirmationPassword"
                    title="Confirme nova senha"
                    maxLength={filedsMaxLength}
                    value={data?.confirmationPassword}
                    type={
                      !showPasswords?.confirmationPassword ? 'password' : 'text'
                    }
                    disabled={errors?.samePasswords}
                    className={`${errors?.confirmationPassword?.activeError}`}
                  />
                  {displayEyeByClick('confirmationPassword')}
                </div>
                {errors?.differentNewPasswords && (
                  <p className="error">Senhas diferentes!</p>
                )}
              </div>
            </div>
            <div className="passwordFooter">
              <span onClick={handleClose} className="cancel">
                Cancelar
              </span>
              <Button
                sx={{
                  background: saveButtonColor,
                  borderRadius: '22px',
                }}
                variant="contained"
                onClick={handleSave}
                disabled={disabledSave}>
                {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  passwordChange,
};

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(PasswordData);
