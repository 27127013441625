class Images {
  static background = {
    login: require('../../Images/Keiken/Background/bg-login.jpg'),
  };

  static icons = {
    mailGrey: require('../../Images/Icons/ic-email-grey.png'),
    lockGrey: require('../../Images/Icons/ic-lock-grey.png'),
    trashGrey: require('../../Images/Icons/trash-icon.svg'),

    accessDenied: require('../../Images/Icons/access-denied.png'),
    pageNotFound: require('../../Images/Icons/page-not-found.png'),

    company: require('../../Images/Icons/company.svg'),
    location: require('../../Images/Icons/location.svg'),
    wifi: require('../../Images/Icons/wifi.svg'),

    thumbnail: require('../../Images/Icons/thumbnail-card.svg'),
    calendar: require('../../Images/Icons/calendar.svg'),

    defaultProfile: require('../../Images/Icons/ic-user-partner-header.png'),

    downloadIcon: require('../../Images/Icons/download-arrow.svg'),
  };
}

export default Images;
