import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { CustomerActivity } from 'src/Models/CustomerActivity';
import { PageableRequest } from 'src/Models/pageable';
import { ResultPage } from 'src/Models/ResultPage';
import { checkApiStatus } from 'src/Utils/ApiUtils';
import CustomerActivityApi from './Api/CustomerActivityApi';

class CustomerActivityService {
  static getNextsAvaliable = async (): Promise<CustomerActivity[]> => {
    try {
      const available = await CustomerActivityService.getAvaliables({
        size: 9,
      });
      return Promise.resolve(available?.content);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static getAvaliables = async (
    page?: PageableRequest,
  ): Promise<ResultPage<CustomerActivity>> => {
    try {
      const result: AxiosResponse<ResultPage<CustomerActivity>> =
        await CustomerActivityApi.getCustomerActivitiesAvaliable(page);
      if (!checkApiStatus(result.data != null, result.status)) {
        return Promise.reject({
          status: result.status,
          statusText: result.statusText,
        } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static getById = async (id: number): Promise<CustomerActivity> => {
    try {
      const result: AxiosResponse<CustomerActivity> =
        await CustomerActivityApi.getById(id);
      if (!checkApiStatus(result.data != null, result.status)) {
        return Promise.reject({
          status: result.status,
          statusText: result.statusText,
        } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static getHistory = async (page?: PageableRequest) => {
    try {
      const result = await CustomerActivityApi.getCustomerActivitiesHistory(
        page,
      );
      if (!checkApiStatus(result.data != null, result.status)) {
        return Promise.reject({
          status: result.status,
          statusText: result.statusText,
        } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  static deleteCustomerActivity = async (id: number) => {
    try {
      const result = await CustomerActivityApi.deleteCustomerActivity(id);
      if (!checkApiStatus(result.data != null, result.status)) {
        return Promise.reject({
          status: result.status,
          statusText: result.statusText,
        } as AxiosResponse);
      }
      return Promise.resolve(result.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
}

export default CustomerActivityService;
