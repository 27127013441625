import React from 'react';
import { CommonLoading } from 'react-loadingg';
import './loading-container-screen.scss';

export interface LoadingContainerScreenProps {
  isLoading?: boolean;
  children: JSX.Element | JSX.Element[] | any;
}

const LoadingContainerScreen: React.FC<LoadingContainerScreenProps> = ({
  children,
  isLoading,
}) => {
  if (!isLoading) return children;

  return (
    <div className="loading-container-screen">
      <CommonLoading />
    </div>
  );
};

export default LoadingContainerScreen;
