import { api } from '.';

interface CustomerActivity {
  id: number;
  tokenUsed: boolean;
}

export const CustomerTokenApi = () => {
  const getByToken = (token) => {
    return api.get(`/customer-activities/find-by-token?token=${token}`);
  };

  const postUseToken = (activityId) => {
    return api.post('/customer-activities/use-token', { id: activityId });
  };

  const postUsePlanToken = (id) => {
    return api.post('/customer-activity-plan/use-token', { id: id });
  };

  const updateTokenUsed = (customerActivity: CustomerActivity) => {
    return api.put('/customer-activities/token-used', customerActivity);
  };

  return {
    getByToken,
    postUseToken,
    postUsePlanToken,
    updateTokenUsed,
  };
};

export default CustomerTokenApi();
