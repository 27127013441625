import { action } from 'typesafe-actions';
import { AgendaActionTypes } from './types';
import { Agenda } from 'src/Models/Agenda';

export const getAgenda = () => action(AgendaActionTypes.GET_AGENDA);
export const getAgendaSuccess = (agenda: Agenda[]) => action(AgendaActionTypes.GET_AGENDA_SUCCESS, agenda)
export const getAgendaError = (message: string) => action(AgendaActionTypes.GET_AGENDA_ERROR, message);

export const getAgendaById = (id: number) => action(AgendaActionTypes.GET_AGENDA_BY_ID, id);
export const getAgendaByIdSuccess = (agenda: Agenda) => action(AgendaActionTypes.GET_AGENDA_BY_ID_SUCCESS, agenda)
export const getAgendabyIdError = (message: string) => action(AgendaActionTypes.GET_AGENDA_BY_ID_ERROR, message);

export const postAgenda = (agenda: Agenda) => action(AgendaActionTypes.POST_AGENDA, agenda);
export const postAgendaSuccess = () => action(AgendaActionTypes.POST_AGENDA_SUCCESS)
export const postAgendaError = (message: string) => action(AgendaActionTypes.POST_AGENDA_ERROR);

export const putAgenda = (agenda: Agenda) => action(AgendaActionTypes.PUT_AGENDA, agenda);
export const putAgendaSuccess = () => action(AgendaActionTypes.PUT_AGENDA_SUCCESS)
export const putAgendaError = (message:string) => action(AgendaActionTypes.PUT_AGENDA_ERROR, message);

export const deleteAgenda = (agendaId: number) => action(AgendaActionTypes.DELETE_AGENDA, agendaId);
export const deleteAgendaSuccess = () => action(AgendaActionTypes.DELETE_AGENDA_SUCCESS)
export const deleteAgendaError = (message: string) => action(AgendaActionTypes.DELETE_AGENDA_ERROR, message);

export const resetAgenda = () => action(AgendaActionTypes.RESET_AGENDA_STATUS);
export const resetEditAgenda = () => action(AgendaActionTypes.RESET_EDIT_AGENDA_STATUS);
export const resetCreateAgenda = () => action(AgendaActionTypes.RESET_CREATE_AGENDA_STATUS);