import '../styles.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Image } from 'react-shimmer';
import React, { useState } from 'react';
import { IRootState } from 'src/Stores';
import { NavLink } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as Sair } from '../../../Images/Icons/sair.svg';
import { PARTNER_LINK } from 'src/Config/Constants';

export interface LoggedInProps extends WithTranslation, StateProps {}

const LoggedIn: React.FC<LoggedInProps> = ({
  t,
  partner,
  profile,
  profileImage,
}) => {
  const [dropDown, setDropDown] = useState<boolean>(false);

  const HeaderDropDown = () => {
    if (!dropDown) return <> </>;
    const customUrl = profile?.profile?.customUrl;
    return (
      <div className="fixed-header__items--menu--headquarters--dropdown">
        <div className="fixed-header__items--menu--headquarters--dropdown__card">
          {customUrl && (
            <div
              className={`fixed-header__items--menu--headquarters--dropdown__card--title`}>
              <a
                href={`${PARTNER_LINK}/${customUrl}`}
                target="_blank"
                style={{ width: '100%' }}>
                <div className="profile-button">
                  <PersonIcon fontSize={'small'} />
                  {t('header.profileWeb')}
                </div>
              </a>
            </div>
          )}

          <div
            className={`fixed-header__items--menu--headquarters--dropdown__card--title`}>
            <NavLink to={'/profile'} style={{ width: '100%' }}>
              <div className="profile-button">
                <SettingsIcon fontSize={'small'} />
                {t('header.config')}
              </div>
            </NavLink>
          </div>

          <div
            className={`fixed-header__items--menu--headquarters--dropdown__card--title`}
            style={{ borderBottom: 'none' }}>
            <NavLink to={'/logout'} style={{ width: '100%' }}>
              <div className="logout-button">
                <Sair />
                {t('header.account.logout')}
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  };

  const ImageLoading = () => (
    <Image
      src={profileImage || './Images/Icons/step-progress.svg'}
      fallback={
        <div className="image-avatar-header">
          <CommonLoading />
        </div>
      }
    />
  );

  return (
    <li
      className="fixed-header__items--menu--headquarters"
      onMouseLeave={() => setDropDown(!dropDown)}
      onMouseEnter={() => setDropDown(!dropDown)}>
      <div
        className="namePhotoContainer"
        onClick={() => setDropDown(!dropDown)}>
        <div className="fixed-header__items--menu--headquarters--text">
          {partner?.name}
        </div>
        <div className="image-avatar-header">
          <ImageLoading />
        </div>
      </div>
      <HeaderDropDown />
    </li>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    partner: state?.authentication?.partner,
    profile: state?.profile,
    profileImage: state?.profile?.profile?.image?.imageUrl,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  compose(connect(mapStateToProps, {}))(LoggedIn),
) as React.ComponentType<any>;
