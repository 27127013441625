import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import '../PlanPrice/styles.scss';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TypeOfRecurrenceOff } from 'src/Models/Plan';
import i18n from 'src/Config/I18n';
import DropzonePdf from 'src/Components/DropzonePdf';

export interface RenewalInfoProps extends WithTranslation {
  watch: any;
  title: string;
  setValue: any;
  hideFields?: {
    price?: boolean;
    period?: boolean;
    enrolmentFee?: boolean;
    maintenanceFee?: boolean;
  };
  verifyAndEnableSave?: () => void;
  register: (name: string, requirements?: Object) => void;
  isEdit?: boolean;
  type?: string;
  errors?: any;
}

const RenewalInfo: React.FC<RenewalInfoProps> = ({
  t,
  watch,
  title,
  register,
  setValue,
  verifyAndEnableSave = () => null,
  type,
  errors,
}) => {
  const isActivity = type === 'activity';

  const dropzoneProps = {
    watch,
    register,
    setValue,
    verifyAndEnableSave,
  };

  useEffect(() => {
    register('isRecurrenceNeeded');
    register('minTimeTurnRecurrenceOff');
    register('isEnrolmentNeeded');
    register('typeOfRecurrenceOff');
  }, [register]);
  const isRecurrenceNeeded = watch('isRecurrenceNeeded');
  const minTimeTurnRecurrenceOff = watch('minTimeTurnRecurrenceOff');
  const isEnrolmentNeeded = watch('isEnrolmentNeeded');
  const typeOfRecurrenceOff = watch('typeOfRecurrenceOff');

  const handleChange = (field, value) => {
    setValue(field, value);
    verifyAndEnableSave();
  };

  const isDayorDays = minTimeTurnRecurrenceOff == 1 ? 'dia' : 'dias';
  const isMensalidadesOrMensalidade =
    minTimeTurnRecurrenceOff == 1 ? 'mensalidade' : 'mensalidades';

  return (
    <Container
      className="mt-40 center-cards-name-PlanPrice"
      style={{ marginTop: '15px' }}>
      <div className="col-12">
        <div className="card-model">
          <span className="value-title title">{title}</span>
          <div className="setSpace">
            <div
              className="flexit"
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                marginBottom: '0px',
                paddingBottom: '25px',
              }}>
              <Tooltip title={t('plans.enrolmentNeeded')}>
                <FormGroup
                  style={{
                    marginBottom: '20px',
                    position: 'relative',
                    width: '250px',
                  }}>
                  <FormControlLabel
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      paddingLeft: '25px',
                      alignSelf: 'flex-start',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                    control={
                      <Checkbox
                        style={{
                          marginRight: 'auto',
                          marginLeft: '-10px',
                        }}
                        color="secondary"
                        name="isEnrolmentNeeded"
                        checked={isEnrolmentNeeded}
                        onChange={(e) =>
                          handleChange('isEnrolmentNeeded', e?.target?.checked)
                        }
                      />
                    }
                    label={i18n.t('plans.createPlanRenewalInfo.withEnrollment')}
                  />
                  <span
                    style={{
                      marginTop: '-40px',
                      marginLeft: isEnrolmentNeeded ? '45px' : '45px',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                      alignSelf: 'flex-start',
                      color: isEnrolmentNeeded ? '#25B379' : '#fe374c',
                    }}>
                    {isEnrolmentNeeded
                      ? i18n.t('plans.createPlanRenewalInfo.activated')
                      : i18n.t('plans.createPlanRenewalInfo.disabled')}
                  </span>
                  <InfoOutlinedIcon
                    style={{
                      position: 'absolute',
                      left: '175px',
                    }}
                  />
                </FormGroup>
              </Tooltip>

              <FormGroup>
                {!isActivity && (
                  <FormGroup
                    style={{
                      borderTop: '1px solid #F1ECEC',
                      paddingTop: '20px',
                    }}>
                    <FormControlLabel
                      style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        paddingLeft: '25px',
                        alignSelf: 'flex-start',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      control={
                        <Checkbox
                          style={{
                            marginRight: 'auto',
                            marginLeft: '-10px',
                          }}
                          color="secondary"
                          name="isRecurrenceNeeded"
                          checked={isRecurrenceNeeded}
                          onChange={(e) =>
                            handleChange(
                              'isRecurrenceNeeded',
                              e?.target?.checked,
                            )
                          }
                        />
                      }
                      label={i18n.t(
                        'plans.createPlanRenewalInfo.saleWithRenew',
                      )}
                    />
                    <span
                      style={{
                        marginTop: '-40px',
                        marginLeft: isRecurrenceNeeded ? '45px' : '45px',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        alignSelf: 'flex-start',
                        color: isRecurrenceNeeded ? '#25B379' : '#fe374c',
                      }}>
                      {isRecurrenceNeeded
                        ? i18n.t('plans.createPlanRenewalInfo.activated')
                        : i18n.t('plans.createPlanRenewalInfo.disabled')}
                    </span>
                  </FormGroup>
                )}
              </FormGroup>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '5px',
                }}>
                <div
                  className="col-md-4 col-sm-12 col-xs-12"
                  style={{
                    marginTop: '20px',
                    opacity: isRecurrenceNeeded ? 1 : 0.58,
                  }}>
                  <span className="value-title inputTitle">
                    {i18n.t('plans.createPlanRenewalInfo.earlyWarningModel')}
                    <Tooltip
                      title={
                        typeOfRecurrenceOff === '' ||
                          typeOfRecurrenceOff === null
                          ? i18n.t(
                            'plans.createPlanRenewalInfo.defaultExplanation',
                          )
                          : typeOfRecurrenceOff === TypeOfRecurrenceOff.DAILY
                          ? `Caso o usuário cancele a matrícula faltando menos de ${
                              minTimeTurnRecurrenceOff != '' &&
                              minTimeTurnRecurrenceOff != null
                              ? minTimeTurnRecurrenceOff
                              : '[Preencha o campo "aviso prévio de cancelamento"]'
                            } ${isDayorDays} pra renovação, será cobrada uma mensalidade a mais`
                          : `Caso o usuário cancele a matrícula, será cobrado o pagamento de mais ${
                              minTimeTurnRecurrenceOff != '' &&
                              minTimeTurnRecurrenceOff != null
                              ? minTimeTurnRecurrenceOff
                              : '[Preencha o campo "aviso prévio de cancelamento"]'
                            } ${isMensalidadesOrMensalidade}.`
                      }>
                      <InfoOutlinedIcon style={{ marginLeft: '10px' }} />
                    </Tooltip>
                  </span>
                  <select
                    style={{
                      marginBottom: '0px',
                      borderColor: errors?.typeOfRecurrenceOff ? 'red' : '',
                    }}
                    value={typeOfRecurrenceOff}
                    disabled={!isRecurrenceNeeded}
                    onChange={(e) =>
                      handleChange('typeOfRecurrenceOff', e?.target?.value)
                    }
                    type="number"
                    placeholder={i18n.t(
                      'plans.createPlanRenewalInfo.daysOfEarlyWarning',
                    )}
                    {...register('typeOfRecurrenceOff', {
                      required: isRecurrenceNeeded,
                    })}>
                    <option value={TypeOfRecurrenceOff.DAILY}>
                      {i18n.t('plans.createPlanRenewalInfo.daily')}
                    </option>
                    <option value={TypeOfRecurrenceOff.MONTHLY}>
                      {i18n.t('plans.createPlanRenewalInfo.monthly')}
                    </option>
                    <option selected value={''}>
                      {i18n.t('plans.createPlanRenewalInfo.PickAModel')}
                    </option>
                  </select>
                </div>

                <div
                  className="col-md-4 col-sm-12 col-xs-12"
                  style={{
                    marginTop: '20px',
                    opacity:
                      !isRecurrenceNeeded ||
                        typeOfRecurrenceOff === '' ||
                        typeOfRecurrenceOff === null
                        ? 0.58
                        : 1,
                  }}>
                  <span className="value-title inputTitle">
                    {i18n.t('plans.createPlanRenewalInfo.noticeOfCancellation')}
                  </span>
                  <input
                    style={{
                      marginBottom: '0px',
                      borderColor: errors?.minTimeTurnRecurrenceOff
                        ? 'red'
                        : '',
                    }}
                    value={minTimeTurnRecurrenceOff}
                    disabled={!isRecurrenceNeeded}
                    onChange={(e) =>
                      handleChange('minTimeTurnRecurrenceOff', e?.target?.value)
                    }
                    type="number"
                    min={1}
                    max={20}
                    placeholder={
                      typeOfRecurrenceOff === '' || typeOfRecurrenceOff === null
                        ? i18n.t(
                          'plans.createPlanRenewalInfo.PickAEarlyWarningModel',
                        )
                        : typeOfRecurrenceOff === TypeOfRecurrenceOff.DAILY
                          ? i18n.t(
                            'plans.createPlanRenewalInfo.daysOfEarlyWarning',
                          )
                          : i18n.t(
                            'plans.createPlanRenewalInfo.monthlyEarlyWarning',
                          )
                    }
                    {...register('minTimeTurnRecurrenceOff', {
                      required: isRecurrenceNeeded,
                    })}
                  />
                </div>
                <div
                  className="col-md-4 col-sm-12 col-xs-12"
                  style={{
                    opacity: isEnrolmentNeeded ? 1 : 0.48,
                    marginTop: '20px',
                  }}>
                  <DropzonePdf {...dropzoneProps} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(RenewalInfo);
