import { Activity } from 'src/Models/Activity';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum ActivityActionTypes {
  GET_ACTIVITIES_REQUEST = '@@activity/ACTIVITIES_REQUEST',
  GET_ACTIVITIES_REQUEST_ERROR = '@@activity/ACTIVITIES_REQUEST_ERROR',
  GET_ACTIVITIES_REQUEST_SUCCESS = '@@activity/ACTIVITIES_REQUEST_SUCCESS',

  GET_ACTIVITIES_REQUEST_BY_ID = '@@activity/ACTIVITIES_REQUEST_BY_ID',
  GET_ACTIVITIES_REQUEST_BY_ID_ERROR = '@@activity/ACTIVITIES_REQUEST_BY_ID_ERROR',
  GET_ACTIVITIES_REQUEST_BY_ID_SUCCESS = '@@activity/ACTIVITIES_REQUEST_BY_ID_SUCCESS',

  GET_SCHEDULE_HISTORY = '@@activity/GET_SCHEDULE_HISTORY',
  GET_SCHEDULE_HISTORY_ERROR = '@@activity/GET_SCHEDULE_HISTORY_ERROR',
  GET_SCHEDULE_HISTORY_SUCCESS = '@@activity/GET_SCHEDULE_HISTORY_SUCCESS',
  
  POST_ACTIVITY = '@@activity/POST_ACTIVITY',
  POST_ACTIVITY_ERROR = '@@activity/POST_ACTIVITY_ERROR',
  POST_ACTIVITY_SUCCESS = '@@activity/POST_ACTIVITY_SUCCESS',

  PUT_ACTIVITY = '@@activity/PUT_ACTIVITY',
  PUT_ACTIVITY_ERROR = '@@activity/PUT_ACTIVITY_ERROR',
  PUT_ACTIVITY_SUCCESS = '@@activity/PUT_ACTIVITY_SUCCESS',

  RESET_POST_PUT_STATUS = '@@activity/RESET_POST_PUT_STATUS',

  DELETE_ACTIVITY = '@@activity/DELETE_ACTIVITY',
  DELETE_ACTIVITY_ERROR = '@@activity/DELETE_ACTIVITY_ERROR',
  DELETE_ACTIVITY_SUCCESS = '@@activity/DELETE_ACTIVITY_SUCCESS',
}

export interface ActivityState {
  readonly activity?: Activity;
  readonly activities?: Activity[];
  readonly scheduleHistory?: any[];
  readonly error?: HttpResponseError;
  readonly historyStatus?: HttpRequestStatus;
  readonly postPutStatus?: HttpRequestStatus;
}
