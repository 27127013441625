import { Checkbox } from '@mui/material';
import React from 'react';
import {
  Container,
  ContainerUser,
  TitleInfos,
  TitleName,
  TitlePhone,
} from './styles';
import UserSvg from '../../Images/Icons/avatarPaymentsPending.svg';
import Status from '../Status';
import { useHistory } from 'react-router-dom';
import { Customer } from 'src/Models/Customer';
import StringUtils from 'src/Utils/StringUtils';

interface Props {
  customer: Customer;
}

const ContainerInfoHistory = ({ customer }: Props) => {
  const history = useHistory();

  const handleChoseUser = () => {
    history.push(
      `/makepurchase/history/user?id=${customer.id}&status=${customer.customerPartnerHistoryStatus}`,
    );
  };

  return (
    <Container onClick={() => handleChoseUser()}>
      <ContainerUser>
        <img src={UserSvg} />
        <TitleName>{customer.name}</TitleName>
      </ContainerUser>
      <TitleInfos>{customer.user.email}</TitleInfos>
      <TitlePhone>
        {customer.phones &&
          customer?.phones[0]?.number &&
          StringUtils.newPhoneFormatter(customer.phones[0].number)}
      </TitlePhone>
      <Status title={customer.customerPartnerHistoryStatus.toLowerCase()} />
    </Container>
  );
};

export default ContainerInfoHistory;
