import './styles.scss';
import React, { useState } from 'react';
import EnterpriseModal from './Modal';
import { connect } from 'react-redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { IRootState } from 'src/Stores';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { CommonLoading } from 'react-loadingg';
import { Image } from 'react-shimmer';

export interface ComponentProps extends stateProps {
  profile;
  pageStatus;
}

const EnterprisePage: React.FC<ComponentProps> = ({ profile, pageStatus }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const modalProps = {
    open,
    setOpen,
  };

  const LoadingContainer = () => {
    return (
      <div className="card-model" style={{ height: '300px' }}>
        <CommonLoading />
      </div>
    );
  };

  const ImageLoading = () => (
    <Image
      src={logoUrl}
      fallback={
        <div className="imageLoadingContainer">
          <CommonLoading />
        </div>
      }
    />
  );

  var largura = window.screen.width;

  const defaultImage = '/capaCompanyPageDefault.png';

  if (pageStatus === HttpRequestStatus.ONGOING) return LoadingContainer();

  const logoUrl = profile?.coverImage?.imageUrl || defaultImage;

  return (
    <div className="card-model EnterprisePage">
      <div className="space-beetween profileCardHeader">
        <span className="value-title-company-page">Página da empresa</span>
        <button onClick={handleOpen}>
          <CreateOutlinedIcon /> &nbsp;{' '}
          <span className="spanEditDataCompanyPage">Editar dados</span>
        </button>
      </div>
      <div className="pageBody">
        <div className="container__Img--EnterprisePage">
          <ImageLoading />
        </div>
        <div className="aboutContainer">
          <span className="super-title">Sobre sua empresa</span>
          <span className="letraCinza text-initial">
            {profile?.description}
          </span>
        </div>
      </div>
      <EnterpriseModal {...modalProps} />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile, pageStatus } = state?.profile;

  return { profile, pageStatus };
};

type stateProps = typeof mapStateToProps;

export default connect(mapStateToProps, {})(EnterprisePage);
