import React, { ComponentType, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import AuthenticationApi from 'src/Services/Api/AuthenticationApi';
import { getSessionSuccess } from 'src/Stores/authentication/actions';
import { IRootState } from '../../../Stores';
import AuthUtils from '../../../Utils/AuthUtils';
import AcceptTermsModal from '../AcceptTermsModal';

interface IPrivateRouteProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  path: string;
  exact?: boolean;
  accessLevel?: AccessLevel;
  accessCondition?: boolean;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

interface IPrivateRouteState {
  isPreLoading: boolean;
  accessCondition?: boolean;
  timerId?: NodeJS.Timeout;
  acceptTermsModal?: boolean;
}

export enum AccessLevel {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
  CUSTOMER = 'ROLE_CUSTOMER',
  COMPANY = 'ROLE_COMPANY',
  PARTNER = 'ROLE_PARTNER',
}

class PrivateRoute extends React.Component<
  IPrivateRouteProps,
  IPrivateRouteState
> {
  constructor(props) {
    super(props);
    this.state = {
      isPreLoading: true,
      accessCondition: props.accessCondition ?? true,
      acceptTermsModal: props.partner?.acceptedTerm ? false : true,
    };
  }

  componentDidMount() {
    const timerId = setTimeout(() => this.isPreLoading(false), 350);
    this.setState({ timerId });
  }

  componentWillUnmount(): void {
    if (this.state.timerId) {
      clearTimeout(this.state.timerId);
    }
  }

  isPreLoading = (isPreLoading: boolean) => {
    this.setState({
      isPreLoading,
    });
  };

  checkIfAuthoritiesIncludes = () => {
    const { account, accessLevel } = this.props;

    if (!this.state.accessCondition) {
      return false;
    }

    return accessLevel
      ? account?.authorities?.some((it) => it.name == accessLevel)
      : true;
  };

  handleAcceptTerms = async () => {
    const result = await AuthenticationApi.acceptTermsAndConditions();
    if (result.status !== 200) return;
    this.props.updateSession(result.data);
  };

  renderNonAuthenticatedRoute = () => {
    const { history } = this.props;
    const redirectProps = {
      pathname: '/',
      state: { from: history.location.pathname },
    };
    return <Redirect to={redirectProps} />;
  };

  renderAuthenticatedRoute = () => {
    const { isPreLoading, acceptTermsModal } = this.state;
    const { exact, path, component } = this.props;

    if (isPreLoading) return;

    return (
      <Fragment>
        {this.checkIfAuthoritiesIncludes() && (
          <Fragment>
            <AcceptTermsModal
              isOpen={acceptTermsModal}
              toggle={this.handleAcceptTerms}
            />
            <Route exact={exact} path={path} component={component} />
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        {AuthUtils.isAuthenticated()
          ? this.renderAuthenticatedRoute()
          : this.renderNonAuthenticatedRoute()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
  partner: authentication.partner,
});

const mapDispatchToProps = {
  updateSession: getSessionSuccess,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withRouter(PrivateRoute),
);
