import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import './styles.scss';
import PriceInput from '../PriceInput';

export interface PlanPriceProps extends WithTranslation {
  watch: any;
  title: string;
  setValue: any;
  setError: any;
  clearErrors: any;
  errors?: any;
  hideFields?: {
    price?: boolean;
    period?: boolean;
    enrolmentFee?: boolean;
    maintenanceFee?: boolean;
    priceWithDiscount?: boolean;
    partnerProfit?: boolean;
  };
  verifyAndEnableSave?: () => void;
  register: (name: string, requirements?: Object) => void;
  isEdit?: boolean;
  type?: string;
}

const PlanPrice: React.FC<PlanPriceProps> = ({
  t,
  watch,
  title,
  register,
  setValue,
  setError,
  clearErrors,
  errors,
  hideFields = {},
  verifyAndEnableSave = () => null,
  isEdit,
  type,
}) => {
  const priceWithFee = watch('priceWithFee');
  const priceWithDiscount = watch('priceWithDiscount');
  const partnerProfit = watch('partnerProfit');
  const enrolmentFee = watch('enrolmentFee');
  const maintenanceFee = watch('maintenanceFee');
  const isActivity = type === 'activity';

  useEffect(() => {
    register('priceWithFee', { required: true });
    register('priceWithDiscount', {
      required: true,
      validate: (value: number) => {
        return value === 0
          ? 'O valor da sua atividade na plataforma não pode ser zero.'
          : value > watch('priceWithFee')
          ? 'O preço Keiken deve ser menor que o preço de balcão/particular.'
          : true;
      },
    });
    register('partnerProfit');
    register('slots');
    register('enrolmentFee');
  }, [register]);

  useEffect(() => {
    if (!isEdit) {
      setValue('partnerProfit', priceWithDiscount * 0.7);
      checkIfpriceWithDiscountIsBiggerThanPrice();
    }
  }, [priceWithDiscount, priceWithFee]);

  function checkIfpriceWithDiscountIsBiggerThanPrice() {
    if (priceWithFee !== 0 && priceWithDiscount === 0) {
      setError('priceWithDiscount', {
        message: 'O valor da sua atividade na plataforma não pode ser zero',
      });
    } else if (priceWithDiscount > priceWithFee) {
      setError('priceWithDiscount', {
        message:
          'O preço Keiken deve ser menor que o preço de balcão/particular',
      });
    } else {
      clearErrors('priceWithDiscount');
    }
  }

  const handleChange = (field: string, value: number) => {
    setValue(field, value);
    verifyAndEnableSave();
  };

  return (
    <Container className="mt-40 center-cards-name-PlanPrice">
      <div className="col-12">
        <div className="card-model">
          <span className="value-title title">{title}</span>
          <div className="setSpace">
            <div className="flexit">
              {!hideFields.price && (
                <PriceInput
                  value={priceWithFee}
                  formVariableName="priceWithFee"
                  isEditInfo="Não é possivel editar o preço"
                  inputTitle="Preço de Balcão/Particular"
                  tootltipInfo="Esse é o valor que você costuma cobrar nessa atividade normalmente. Seu preço padrão de tabela."
                  handleChange={handleChange}
                  isEdit={isEdit}
                />
              )}
              {!hideFields.priceWithDiscount && (
                <PriceInput
                  value={priceWithDiscount}
                  formVariableName="priceWithDiscount"
                  isEditInfo="Não é possivel editar o preço"
                  inputTitle="Preço Keiken"
                  tootltipInfo="Esse é o valor do seu serviço no aplicativo da Keiken. IMPORTANTE: o preço Keiken deve ser menor do que o preço de balcão/particular."
                  error={errors.priceWithDiscount}
                  errorMessage={errors.priceWithDiscount?.message}
                  handleChange={handleChange}
                  isEdit={isEdit}
                />
              )}
              {!hideFields.partnerProfit && (
                <PriceInput
                  value={partnerProfit}
                  formVariableName="partnerProfit"
                  isEditInfo="Não é possivel editar o preço"
                  inputTitle="Valor de Repasse"
                  tootltipInfo="Esse é o valor que você vai receber por cada venda do serviço. Ele leva em consideração a taxa de comissionamento da plataforma, o que possibilita que a rede de usuários cresça cada vez mais."
                  handleChange={handleChange}
                  isEdit={isEdit}
                  disabled
                />
              )}
              {!hideFields.enrolmentFee && (
                <PriceInput
                  value={enrolmentFee}
                  formVariableName="enrolmentFee"
                  isEditInfo="Não é possivel editar o preço"
                  inputTitle="Taxa de matricula"
                  tootltipInfo="Esse valor é figurativo e não resultará em nenhuma cobrança a mais pro cliente. É apenas para o cliente observar um valor a mais em comprar pela Keiken."
                  handleChange={handleChange}
                  isEdit={isEdit}
                />
              )}
              {!hideFields.maintenanceFee && (
                <PriceInput
                  value={maintenanceFee}
                  formVariableName="maintenanceFee"
                  isEditInfo="Não é possivel editar o preço"
                  inputTitle="Taxa de manutenção"
                  tootltipInfo="Esse valor é figurativo e não resultará em nenhuma cobrança a mais pro cliente. É apenas para o cliente observar um valor a mais em comprar pela Keiken."
                  handleChange={handleChange}
                  isEdit={isEdit}
                />
              )}
              {hideFields.period && (
                <div className="col-md-4 col-sm-12 col-xs-12 ">
                  <span className="value-title inputTitle">
                    Limite de vendas (vagas)
                  </span>
                  <input
                    type="text"
                    placeholder="Limite de vendas (vagas)"
                    {...register('slots', {
                      required: true,
                      pattern: { value: /^[0-9]*$/ },
                    })}
                  />
                </div>
              )}
              {!hideFields.period && (
                <>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    {/* RenewingPeriod */}
                    <span className="value-title inputTitle">
                      Duração do plano
                    </span>
                    <select
                      style={{ marginTop: '15px' }}
                      placeholder="Mensalidade"
                      {...register('period', { required: true })}>
                      <option value="BI_WEEKLY_PERIOD" disabled>
                        Duração do plano
                      </option>
                      <option value={'MONTH_PERIOD'}>{'Mensal'}</option>
                      {/* <option value="BI_WEEKLY_PERIOD">Bimestral</option>
                        <option value="THREE_MONTHS_PERIOD">Trimestral</option>
                        <option value="SIX_MONTHS_PERIOD">Semestral</option>
                        <option value="YEARLY">Anual</option> */}
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12 ">
                    <span className="value-title inputTitle">
                      Limite de vendas (vagas)
                    </span>
                    <input
                      style={{ marginTop: '15px' }}
                      type="text"
                      placeholder="Limite de vendas (vagas)"
                      {...register('slots', {
                        required: true,
                        pattern: { value: /^[0-9]*$/ },
                      })}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(PlanPrice);
