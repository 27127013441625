import { api } from '.'

export const ImageApi = () => {
  const getImageById = (id: number) => {
    return api.get(`/images/${id}`);
  }

  return {
    getImageById,
  }
}

export default ImageApi();