import { Reducer } from 'redux';
import { BankAccountTypes, BankAccountState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: BankAccountState = {
  error: null,
  status: null,
  bankData: null,
};

const reducer: Reducer<BankAccountState> = (state = initialState, action) => {
  switch (action.type) {
    case BankAccountTypes.GET_BANK_DATA:
      return { ...state, status: HttpRequestStatus.ONGOING };
    
    case BankAccountTypes.GET_BANK_DATA_SUCCESS: 
      return {
        ...state,
        bankData: action?.payload,
        status: HttpRequestStatus.SUCCESS,
      };
    
    case BankAccountTypes.GET_BANK_DATA_ERROR:
      return {
        ...state,
        error: action?.payload,
        status: HttpRequestStatus.ERROR,
      };
    
    case BankAccountTypes.POST_BANK_DATA:
      return {
        ...state,
        status:  HttpRequestStatus.ONGOING
      }

    case BankAccountTypes.POST_BANK_DATA_SUCCESS:
      return {
        ...state,
        status:  HttpRequestStatus.SUCCESS
      }

    case BankAccountTypes.POST_BANK_DATA_ERROR:
      return {
        ...state,
        status:  HttpRequestStatus.ERROR
      }
    default: return state;
  }
};

export { reducer as bankAccountReducer };
