import { api } from '.';
import { AxiosResponse } from 'axios';
import { PriceComposite , Plan } from '../../Models/Plan';
import { PageableResponse } from 'src/Models/pageable';
import { SendSuggest } from 'src/Models/SuggestPayment';
import { DeleteBody, Filters } from '../Plan.service';
import moment from 'moment';

export const PlanApi = () => {
  const postPlan = (plan: Plan): Promise<AxiosResponse<Plan>> => {
    return api.post<Plan>('/activity-plan', plan);
  }

  const putPlan = (plan: Plan): Promise<AxiosResponse<Plan>> => {
    return api.put<Plan>('/activity-plan', plan);
  }

  const getPlans = (): Promise<AxiosResponse<Plan[]>> => {
    return api.get<Plan[]>('/activity-plan');
  };

  const getPlansApproved = (): Promise<AxiosResponse<PageableResponse<Plan>>> => {
    return api.get<PageableResponse<Plan>>('/activity-plan?approved=true&status=ENABLED');
  };

  const getPlanById = (id: number): Promise<AxiosResponse<Plan>> => {
    return api.get<Plan>('/activity-plan/' + id);
  };

  const deletePlan = (planId: number): Promise<AxiosResponse<any>> => {
    return api.delete<any>(`/activity-plan/${planId}`);
  };

  const postSuggestedPurchasePlan = (obj: SendSuggest): Promise<AxiosResponse<SendSuggest>> => {
    return api.post<SendSuggest>('/billings/suggested-by-partner', obj);
  }

  const getDiscountOfPlan = (idPlan: number, customerId: number, date: string): Promise<AxiosResponse<PriceComposite >> => {
    return api.get(`/activity-plan/${idPlan}/customer/${customerId}/price-composite?date=${date}`);
  }

  const getPreScheduleByPartner = (size: number, page: number, filters?: Filters): Promise<AxiosResponse> => {
    return api.get(`customers/pre-schedule-by-partner?size=${size}&page=${page}${filters?.order ?? ''}`);
  }

  const cancelGetPreScheduleByPartner = (data: DeleteBody): Promise<AxiosResponse> => {
    return api.delete(`/billings/cancel-suggested-by-partner`, {data: data});
  }

  const generateReceiptPdf = async (id: number, date: string) => {
    const response = await api.get(`/billings/${id}/payment-receipt`, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data]),
    );

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `recibo_${date}.pdf`
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(url);
  }

  return {
    postPlan,
    putPlan,
    getPlans,
    getPlanById,
    deletePlan,
    getPlansApproved,
    postSuggestedPurchasePlan,
    getDiscountOfPlan,
    getPreScheduleByPartner,
    cancelGetPreScheduleByPartner,
    generateReceiptPdf,
  };
};

export default PlanApi();