import React, { useEffect, useRef, useState } from 'react';
import ViaCep from 'react-via-cep';
import '../styles.scss';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { CommonLoading } from 'react-loadingg';
import InputClassic from '../../../PlanFormat/InputClassic';
import { Address, AddressUtils } from 'src/Models/Address';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
import { GEOCODE_API } from 'src/Config/Constants';
import _ from 'lodash';
import { useWidth } from 'src/Hooks/useWidth';

const ModalAddressForm = ({ data, isOpen, onClose, onConfirm }) => {
  const [address, setAddress] = useState<Address>();
  const [fetchedCep, setFetchedCep] = useState<string>('');
  const [errors, setErrors] = useState({ cep: false });
  const [loading, setLoading] = useState<boolean>(false);
  let widthSize = useWidth();

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setAddress(data);
  }, [data]);

  const zipcode = address?.zipcode;
  useEffect(() => {
    if (zipcode !== fetchedCep) setFetchedCep(zipcode);
  }, [zipcode, fetchedCep]);

  const handleCepSuccess = async (cepObj) => {
    const { erro = false } = cepObj;

    if (erro) setErrors((prev) => ({ ...prev, cep: true }));

    if (cepObj?.cep) {
      const newAddress = await AddressUtils.fromViaCep(address, cepObj);
      const geoPoint = await AddressUtils.getGeoPointFromApi(newAddress);


      setAddress({
        ...newAddress,
        geoPoint,
      });

      setErrors((prev) => ({ ...prev, cep: false }));
    }
  };

  const handleChange = (e) => {
    const { name = '', value = '' } = e?.target;
    if (name == 'zipcode') {
      const digits = value?.replace(/\D/g, '')?.slice?.(0, 8);
      setAddress((prev) => ({ ...prev, [name]: digits }));
    } else if (address.hasOwnProperty(name)) {
      handleChangeAddress(e);
    } else {
      setAddress((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeAddress = (e) => {
    const { name = '', value = '' } = e?.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault?.();
    onConfirm?.(address);
    setLoading(true);
  };

  const handleClose = () => {
    if (!loading) {
      onClose?.();
      setAddress(data);
    }
  };

  const handleChangeMapAddress = (newAddress: Address) => {
    setAddress(newAddress);
  };

  const saveButtonColor = '#25B379 !important'; // : 'light-grey';

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <div className="paper-modalAddress">
          <div className="modalAlign">
            <div className="modalTitleSmall">
              <h2 className="title">Endereço da empresa</h2>
            </div>
            <CloseIcon className="modalIcon" onClick={handleClose} />
          </div>
          <div className="mb-20" />
          <div className="underline" />
          <div className="lowerContainer ">
            <ViaCep cep={address?.zipcode} lazy onSuccess={handleCepSuccess}>
              {({ loading, error, fetch }) => {
                if (loading) {
                  return (
                    <div className="mb-20 col-3 ">
                      <p>Carregando...</p>
                    </div>
                  );
                }

                if (
                  !error &&
                  address?.zipcode?.length === 8 &&
                  fetchedCep !== address?.zipcode
                ) {
                  fetch();
                }

                return (
                  <div
                    className={`${
                      widthSize > 450 ? 'mb-20 col-3' : 'mb-20 col-16'
                    }`}>
                    <InputClassic
                      title="CEP"
                      value={address?.zipcode}
                      name="zipcode"
                      placeholder="CEP"
                      onChange={handleChange}
                    />
                    {(error || errors.cep) && (
                      <p className="error"> CEP Inválido</p>
                    )}
                  </div>
                );
              }}
            </ViaCep>
            <div
              className={`${widthSize > 450 ? 'mb-20 col-6' : 'mb-20 col-16'}`}>
              <InputClassic
                name="street"
                title="Rua"
                placeholder="Rua"
                value={address?.street}
                onChange={handleChange}
              />
            </div>
            <div
              className={`${widthSize > 450 ? 'mb-20 col-3' : 'mb-20 col-16'}`}>
              <InputClassic
                name="number"
                title="Número"
                value={address?.number}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="lowerContainer">
            <div
              className={`${widthSize > 450 ? 'mb-20 col-4' : 'mb-20 col-16'}`}>
              <InputClassic
                name="district"
                title="Bairro"
                value={address?.district}
                placeholder="Bairro"
                onChange={handleChange}
              />
            </div>
            <div
              className={`${widthSize > 450 ? 'mb-20 col-4' : 'mb-20 col-16'}`}>
              <InputClassic
                name="complement"
                title="Complemento (Opcional)"
                placeholder=""
                value={address?.complement}
                onChange={handleChange}
              />
            </div>
            <div
              className={`${widthSize > 450 ? 'mb-20 col-4' : 'mb-20 col-16'}`}>
              <InputClassic
                name="state"
                title="Cidade/Estado"
                disabled={true}
                placeholder="Cidade/Estado"
                value={`${address?.city?.name}/${address?.city?.state?.acronym}`}
              />
            </div>
          </div>
          <div className="underline" />
          <div className="mb-20" />
          <div className="addressMap__modal-form">
            {loading == true ? (
              <div className="loading">
                <CommonLoading />
              </div>
            ) : address?.image?.imageUrl ? (
              <img
                className="image__modal--adress"
                src={address?.image?.imageUrl}
              />
            ) : (
              <AddressMap address={address} onChange={handleChangeMapAddress} />
            )}
          </div>

          <div className="footerContainer mt-20">
            <span onClick={handleClose} className="txt-verde">
              Cancelar
            </span>
            <Button
              type="button"
              onClick={handleSubmit}
              sx={{ background: saveButtonColor, borderRadius: '22px' }}
              variant="contained"
              disabled={loading}>
              {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

interface AddressMapProps {
  address: Address;
  onChange: (item: Address) => void;
}
const AddressMap: React.FC<AddressMapProps> = ({ address, onChange }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GEOCODE_API,
  });

  const [zoom, setZoom] = useState(16);
  const [position, setPosition] = useState({ lat: undefined, lng: undefined });
  let widthSize = useWidth();

  useEffect(() => {
    if (address == null) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else if (address?.geoPoint?.latitude && address?.geoPoint?.longitude) {
      setPosition({
        lat: address?.geoPoint?.latitude,
        lng: address?.geoPoint?.longitude,
      });
    } else {
      console.log('address wihout position', address);
    }
  }, [address]);

  if (!isLoaded) {
    return <div style={{ height: 300 }}></div>;
  }

  const handleChangePosition = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setPosition({ lat, lng });
    setZoom(16);

    onChange?.({
      ...address,
      geoPoint: AddressUtils.builderGeoPoint(lat, lng),
    });
  };

  return (
    <div style={{ height: 300 }}>
      {position?.lat == null ? (
        <div
          style={{
            alignContent: 'center',
            justifyContent: 'center',
          }}>
          <CommonLoading />
        </div>
      ) : (
        <GoogleMap
          zoom={zoom}
          center={position}
          clickableIcons={false}
          options={{
            streetViewControl: false,
            disableDefaultUI: true,
            zoomControl: true,
          }}
          onRightClick={handleChangePosition}
          onDblClick={handleChangePosition}
          mapContainerStyle={
            widthSize > 450
              ? { width: 440, height: 300 }
              : { width: 290, height: 250 }
          }>
          <Marker
            draggable={true}
            icon={{
              url: 'https://i.imgur.com/pmUI4nF.png',
              scale: 5,
              anchor: new google.maps.Point(25, 46),
            }}
            onDragEnd={handleChangePosition}
            position={position}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default ModalAddressForm;
