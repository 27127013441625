import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { CommonLoading } from 'react-loadingg';
import './styles.scss';

import Dropzone from 'src/Components/Dropzone';

export interface UploadProps extends WithTranslation {
  watch: any;
  setValue: any;
  title?: string;
  register: (Object) => void;
  verifyAndEnableSave?: () => void;
  loading?: boolean;
}

const Upload: React.FC<UploadProps> = ({
  t,
  title,
  watch,
  register,
  setValue,
  verifyAndEnableSave = () => null,
  loading,
}) => {
  const dropzoneProps = {
    watch,
    register,
    setValue,
    cropAspect: 2,
    minWidth: 1000,
    verifyAndEnableSave,
  };

  if (loading) {
    return (
      <Container className="mt-40 center-cards-name-Upload">
        <div className="col-12">
          <div className="card-model">
            {title && <span className="value-title">{title}</span>}
            <div className="setSpace">
              <div className="paddings2">
                <div className="loading ">
                  <CommonLoading />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container className="mt-40 center-cards-name-Upload">
      <div className="col-12">
        <div className="card-model">
          {title && <span className="value-title">{title}</span>}
          <div className="setSpace">
            <div className="paddings2">
              <Dropzone {...dropzoneProps} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(Upload);
