import './styles.scss';
import ResponsibleModal from './Modal';
import React, { useState } from 'react';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { PartnerCreate } from '../../../../Models/Partner';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { CommonLoading } from 'react-loadingg';

export interface ResponsibleProps extends StateToProps {
  partner: PartnerCreate;
  responsibleStatus: HttpRequestStatus;
}

const ResponsibleFormation: React.FC<ResponsibleProps> = ({
  profile,
  partner,
  responsibleStatus,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const modalProps = {
    open,
    setOpen,
  };

  const DisplayAcademicEducations = () => {
    return profile?.academicEducation?.map((education, index) => (
      <div
        key={index}
        className={`${
          largura > 450 ? 'mb-20 col-12' : 'mb-20 col-18'
        } width-100 form-acad-container`}>
        <div className="flex-only form-container--circle">
          <FiberManualRecordIcon className="greenClr bordaCirculo" />
          <div className="flex-span">
            <span className="letraCinzaBold text-initial mb-10 instituicao">
              {education?.conclusionYear} - {education?.institution}
            </span>
            <span className="letraCinza text-initial mb-10">
              {education?.course}
            </span>
          </div>
        </div>
      </div>
    ));
  };

  const LoadingContainer = () => {
    return (
      <div className="card-model" style={{ height: '300px' }}>
        <CommonLoading />
      </div>
    );
  };

  if (responsibleStatus === HttpRequestStatus.ONGOING)
    return LoadingContainer();

  var largura = window.screen.width;

  return (
    <div className="card-model">
      <div className="space-beetween profileCardHeader">
        <span className="value-title-responsible-data">Formação Acadêmica</span>
        <button onClick={handleOpen}>
          <CreateOutlinedIcon /> &nbsp;{' '}
          <span className="spanEditResponsibleData">Editar dados</span>
        </button>
      </div>
      <div className="setSpace"></div>

      <div className="setSpace">
        <div className="flexit mb-20">{DisplayAcademicEducations()}</div>
      </div>
      <ResponsibleModal {...modalProps} />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile, responsibleStatus } = state?.profile;
  const { partner } = state?.authentication;

  return { profile, partner, responsibleStatus };
};

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, {})(ResponsibleFormation);
