import { Reducer } from 'redux';
import { Document } from 'src/Models/Document';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { ImageState, ImageTypes } from './types';

export const initialState: ImageState = {
  image: null,
  imageStatus: HttpRequestStatus.NOOP
}

const reducer: Reducer<ImageState> = ( state = initialState, action) => {
  switch (action.type) {

    case ImageTypes.GET_IMAGE_BY_ID : {
      return {...state, imageStatus: HttpRequestStatus.ONGOING};
    };

    case ImageTypes.GET_IMAGE_BY_ID_SUCCESS : {
      const image = action?.payload as Document;

      return {...state, image, imageStatus: HttpRequestStatus.SUCCESS};
    };

    case ImageTypes.GET_IMAGE_BY_ID_ERROR : {
      return {...state, imageStatus: HttpRequestStatus.ERROR};
    };
      
    case ImageTypes.RESET_IMAGE_STATUS : {
      return {...state, imageStatus: HttpRequestStatus.NOOP};
    }

    default: {
      return state;
    }
  }
};

export {reducer as imageReducer};