import React, { useEffect, useState } from 'react';
import './styles.scss';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Address, AddressUtils } from 'src/Models/Address';
import { CommonLoading } from 'react-loadingg';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import ModalAddressForm from './components/ModalAddressForm';
import { putEnterpriseData } from 'src/Stores/profile/actions';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { isEmpty } from 'lodash';

interface Props extends StateProps, DispatchProps {}

const AddressData: React.FC<Props> = ({
  address,
  profile,
  putEnterpriseData,
  updateStatus,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSaveAddress = (item: Address) => {
    if (isEmpty(profile.customUrl)) {
      putEnterpriseData({
        ...profile,
        customUrl: undefined,
        address: item,
      });
    } else {
      putEnterpriseData({
        ...profile,
        address: item,
      });
    }
  };

  useEffect(() => {
    if (
      updateStatus == HttpRequestStatus.SUCCESS ||
      updateStatus == HttpRequestStatus.ERROR
    ) {
      setIsOpen(false);
    }
  }, [updateStatus]);

  return (
    <div className="card-model AddressData">
      <div className="space-beetween AddressDataHeader">
        <span className="value-title-address">Endereço da empresa</span>
        <button onClick={handleOpen}>
          <CreateOutlinedIcon />
          &nbsp; <span className="spanEditAddressCompany">Editar Endereço</span>
        </button>
      </div>

      {address == null ? (
        <div className="loading">
          <CommonLoading />
        </div>
      ) : (
        <>
          <div className="addressImage">
            {address?.image?.imageUrl ? (
              <img src={address?.image?.imageUrl} />
            ) : (
              <div className="loading borderError">
                <span className="labelError mt-3">Imagem não cadastrada</span>
              </div>
            )}
          </div>
          <span className="letraCinza mt-3">
            {AddressUtils.format(address)}
          </span>
        </>
      )}
      <ModalAddressForm
        isOpen={isOpen}
        data={address}
        onClose={() => setIsOpen(false)}
        onConfirm={handleSaveAddress}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile: profileStore = {} } = state;

  return {
    address: profileStore?.profile?.address,
    profile: profileStore?.profile,
    updateStatus: profileStore?.dataStatus,
  };
};

const mapDispatchToProps = {
  putEnterpriseData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressData);
