import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

  export const info = (message: string): void => {
    toast.info(message);
  };

  export const success = (message: string): void => {
    toast.success(message);
  };

  export const error = (message: string): void => {
    toast.error(message);
  };

  export const  errorFromResponse = (t: (string) => string, err: AxiosResponse): void => {
    const errorCode = err?.data?.code;
    if (errorCode == null) {
      toast.error(t('errors.default'));
      return;
    }
    const message = t(`errors.${errorCode.replaceAll('.', '_')}`);
    toast.error(message);
  };