import { PageableResponse } from 'src/Models/pageable';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { Billing } from 'src/Models/Billing';

export type ApiResponse = Record<string, any>;

export enum BillingTypes {
  GET_BILLING_HISTORY = '@@billing/GET_HISTORY',
  GET_BILLING_HISTORY_ERROR = '@@billing/GET_HISTORY_ERROR',
  GET_BILLING_HISTORY_SUCCESS = '@@billing/GET_HISTORY_SUCCESS',
  RESET_STATE = '@@billing/RESET_STATE',
}

export interface BillingState {
  readonly page?: PageableResponse<Billing>;
  readonly status?: HttpRequestStatus;
}
