import React, { useEffect, useState } from 'react';
import {
  ButtonSort,
  Container,
  ContainerAll,
  ContainerDropDown,
  ContainerFiltersAndButton,
  ContainerInputAndImg,
  ContainerNumber,
  ContainerNumbers,
  ContainerOptions,
  ContainerPagination,
  ContainerPaymentsPending,
  ContainerTitleAndFilters,
  ContainerTooltip,
  Divider,
  InputStyled,
  Title,
  TitleAndTootip,
  TitleButtonSort,
  TitleDropDown,
  TitleNumber,
  TitleOptions,
} from './styles';

import SearchSvg from '../../Images/Icons/search.svg';
import SortSvg from '../../Images/Icons/sortGreen.svg';
import ArrowDownSortSvg from '../../Images/Icons/ArrowDownSort.svg';
import ArrowUpSortSvg from '../../Images/Icons/ArrowUpSort.svg';
import { useTranslation } from 'react-i18next';
import ContainerInfoHistory from '../ContainerInfoHistory';
import PartnerClientService from 'src/Services/PartnerClient.service';
import { Customer, PartnerStatus } from 'src/Models/Customer';
import ArrowLeftGrey from '../../Images/Icons/arrowLeftGrey.svg';
import ArrowLeftGreen from '../../Images/Icons/arrowLeftGreen.svg';
import ArrowRightGreen from '../../Images/Icons/arrowRightGreen.svg';
import ArrowRightGrey from '../../Images/Icons/arrowRightGrey.svg';
import Loading from '../loading/loading';

let timeout: NodeJS.Timer;

const HistoryPayments = () => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [sortTo, setSortTo] = useState<PartnerStatus>();
  const [historyCollaborators, setHistoryCollaborators] =
    useState<Customer[]>();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState<number>(1);
  const [auxNumber, setAuxNumber] = useState<number>(0);
  const [pageToSearch, setPageToSearch] = useState<number>(0);
  const [searchEmailOrName, setSearchEmailOrName] = useState<string>();

  const handleChooseSortTo = async (type?: PartnerStatus) => {
    if (sortTo == null) {
      const res = await PartnerClientService.getCustomers(10, pageToSearch);
      setHistoryCollaborators(res.content);
      setTotalPages(res.totalPages);
    } else {
      const res = await PartnerClientService.getCustomers(10, pageToSearch, {
        order: searchEmailOrName
          ? `&search=${searchEmailOrName}filter=${sortTo}`
          : `&filter=${sortTo}`,
      });
      setHistoryCollaborators(res.content);
      setTotalPages(res.totalPages);
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    if (auxNumber === 1) {
      setPageToSearch(totalPages - auxNumber - 2);
    } else if (auxNumber === 2) {
      setPageToSearch(totalPages - auxNumber);
    } else if (auxNumber > 2) {
      setPageToSearch(totalPages - 1);
    } else {
      setPageToSearch(page - 1);
    }
  }, [page, auxNumber]);

  useEffect(() => {
    const getHistoryCollaboratorsSearch = async () => {
      const res = await PartnerClientService.getCustomers(10, pageToSearch, {
        order:
          searchEmailOrName && sortTo
            ? `&search=${searchEmailOrName}&filter=${sortTo}`
            : searchEmailOrName !== '' && searchEmailOrName
            ? `&search=${searchEmailOrName}`
            : `&filter=${sortTo}`,
      });
      setHistoryCollaborators(res.content);
      setTotalPages(res.totalPages);
    };

    const getHistoryCollaborators = async () => {
      const res = await PartnerClientService.getCustomers(10, pageToSearch);

      setHistoryCollaborators(res.content);
      setTotalPages(res.totalPages);
    };

    if (searchEmailOrName) {
      clearTimeout(timeout);
      timeout = setTimeout(() => getHistoryCollaboratorsSearch(), 500);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => getHistoryCollaborators(), 500);
    }
  }, [searchEmailOrName, pageToSearch]);

  const handleNextPage = () => {
    if (
      page < totalPages - 3 ||
      (totalPages <= 9 && page < 9 && totalPages > page)
    ) {
      setPage(page + 1);
    } else if (auxNumber <= totalPages - 2 && totalPages > 9) {
      setAuxNumber(
        auxNumber === 2 ? auxNumber + totalPages - 3 : auxNumber + 1,
      );
    }
  };

  const handleBackPage = () => {
    if (auxNumber >= 1) {
      setAuxNumber(
        auxNumber === totalPages - 1
          ? auxNumber - (totalPages - 3)
          : auxNumber - 1,
      );
    } else if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    setHistoryCollaborators(undefined);
    setShowDropDown(false);
    handleChooseSortTo();
  }, [sortTo]);

  return (
    <ContainerAll>
      <Container>
        <ContainerTitleAndFilters>
          <TitleAndTootip>
            <Title>{t('makePurchase.history.title')}</Title>
          </TitleAndTootip>
          <ContainerFiltersAndButton>
            <ContainerInputAndImg>
              <InputStyled
                placeholder={t('makePurchase.history.placeholderInputSearch')}
                onChange={(e) => setSearchEmailOrName(e.target.value)}
              />
              <img src={SearchSvg} />
            </ContainerInputAndImg>
            <ContainerTooltip>
              <ButtonSort onClick={() => setShowDropDown(!showDropDown)}>
                <img src={SortSvg} />
                <TitleButtonSort>
                  {t('makePurchase.history.order')}{' '}
                  {sortTo
                    ? `${t('makePurchase.history.by')} ${t(
                        `makePurchase.history.${sortTo}`,
                      )}`
                    : ''}
                </TitleButtonSort>
              </ButtonSort>
              {showDropDown && (
                <ContainerDropDown>
                  <ContainerOptions>
                    <TitleDropDown>
                      {t('makePurchase.history.OrderBy')}
                    </TitleDropDown>
                  </ContainerOptions>
                  <ContainerOptions
                    onClick={() =>
                      setSortTo(
                        sortTo === PartnerStatus.Active
                          ? undefined
                          : PartnerStatus.Active,
                      )
                    }>
                    <TitleOptions>
                      {t('makePurchase.history.active')}
                    </TitleOptions>
                  </ContainerOptions>
                  <ContainerOptions
                    onClick={() =>
                      setSortTo(
                        sortTo === PartnerStatus.Pending
                          ? undefined
                          : PartnerStatus.Pending,
                      )
                    }>
                    <TitleOptions>
                      {t('makePurchase.history.pending')}
                    </TitleOptions>
                  </ContainerOptions>
                  <ContainerOptions
                    onClick={() =>
                      setSortTo(
                        sortTo === PartnerStatus.Inactive
                          ? undefined
                          : PartnerStatus.Inactive,
                      )
                    }>
                    <TitleOptions>
                      {t('makePurchase.history.inactive')}
                    </TitleOptions>
                  </ContainerOptions>
                </ContainerDropDown>
              )}
            </ContainerTooltip>
          </ContainerFiltersAndButton>
        </ContainerTitleAndFilters>
        <Divider />
        <ContainerPaymentsPending>
          {historyCollaborators &&
            historyCollaborators.map((element, index) => {
              return <ContainerInfoHistory customer={element} key={index} />;
            })}
          {!historyCollaborators && <Loading />}
        </ContainerPaymentsPending>
        {historyCollaborators && (
          <ContainerPagination>
            <img
              src={page === 1 ? ArrowLeftGrey : ArrowLeftGreen}
              onClick={() => handleBackPage()}
            />
            <ContainerNumbers>
              {totalPages <= 10
                ? Array(totalPages)
                    .fill('a')
                    .map((element, index) => {
                      return (
                        <ContainerNumber
                          isSelect={index === page - 1}
                          key={index}
                          onClick={() => setPage(index + 1)}>
                          <TitleNumber isSelect={index === page - 1}>
                            {index + 1}
                          </TitleNumber>
                        </ContainerNumber>
                      );
                    })
                : Array(totalPages)
                    .fill('a')
                    .map((element, index) => {
                      if (
                        index >= 3 &&
                        index < 4 &&
                        page != totalPages - 3 &&
                        page < totalPages
                      ) {
                        return (
                          <ContainerNumber key={index}>
                            <TitleNumber>...</TitleNumber>
                          </ContainerNumber>
                        );
                      } else if (index === totalPages - 1 || index < 3) {
                        return (
                          <ContainerNumber
                            isSelect={
                              page < totalPages - 3
                                ? index === page - page
                                : index === auxNumber
                            }
                            key={index}>
                            <TitleNumber
                              isSelect={
                                page < totalPages - 3
                                  ? index === page - page
                                  : index === auxNumber
                              }
                              onClick={() =>
                                page != totalPages - 3 && page != totalPages
                                  ? setPage(index + page)
                                  : setAuxNumber(
                                      index <= 2 ? index : totalPages - 1,
                                    )
                              }>
                              {index === totalPages - 1
                                ? index + 1
                                : page != totalPages
                                ? index + page
                                : index + page - 3}
                            </TitleNumber>
                          </ContainerNumber>
                        );
                      }
                    })}
            </ContainerNumbers>
            <img
              src={
                page === totalPages || auxNumber === totalPages - 1
                  ? ArrowRightGrey
                  : ArrowRightGreen
              }
              onClick={() => handleNextPage()}
            />
          </ContainerPagination>
        )}
      </Container>
    </ContainerAll>
  );
};

export default HistoryPayments;
