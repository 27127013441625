import React, { useState } from 'react';
import './styles.scss';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import LinkIcon from '@mui/icons-material/Link';
import EnterpriseModal from './Modal/EnterpriseModal';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { EnterpriseData as EnterpriseDataModel } from 'src/Models/Profile';
import { CommonLoading } from 'react-loadingg';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { Image } from 'react-shimmer';
import { cnpjMask, cpfMask } from 'src/Utils/Masks';
import { PARTNER_LINK } from 'src/Config/Constants';

export interface ComponentProps {
  profile: EnterpriseDataModel;
  dataStatus: HttpRequestStatus;
  putEnterpriseDataSuccess?: () => void;
}

const EnterpriseData: React.FC<ComponentProps> = ({ profile, dataStatus }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const { image, fantasyName, socialReason, cnpj, cpf, phone, customUrl } =
    profile || {};

  const LoadingContainer = () => {
    return (
      <div className="card-model" style={{ height: '300px' }}>
        <CommonLoading />
      </div>
    );
  };

  const ImageLoading = () => (
    <Image
      src={image?.imageUrl ?? '/CompanyLogoDefault.png'}
      fallback={
        <div className="imageLoadingContainer">
          <CommonLoading />
        </div>
      }
    />
  );

  if (dataStatus === HttpRequestStatus.ONGOING) return LoadingContainer();

  return (
    <div className="card-model EnterpriseData">
      <div className="space-beetween profileCardHeader">
        <span className="value-title-company">Dados da empresa</span>
        <button onClick={handleOpen}>
          <CreateOutlinedIcon /> &nbsp;
          <span className="spanEditData">Editar dados</span>
        </button>
      </div>
      <div className="setSpace">
        <div className="flex mb-20">
          <div className="panel-enterprise-photo">
            <ImageLoading />
          </div>
          <div className="userData">
            {fantasyName && <span className="super-title">{fantasyName}</span>}

            {customUrl != null ? (
              <a
                className="letraCinza mb-10 customUrl"
                target={'_blank'}
                href={`${PARTNER_LINK}/${customUrl}`}>
                #{customUrl}
                <LinkIcon />
              </a>
            ) : (
              <span className="labelError mb-10">{'#url não cadastrado'}</span>
            )}
            {socialReason && (
              <span className="letraCinza mb-10 socialReason">
                {socialReason}
              </span>
            )}
            {phone?.number && (
              <span className="letraCinza mb-10">{phone?.number}</span>
            )}
            {(cnpj || cpf) && (
              <span className="letraCinza mb-10">
                {cnpjMask(cnpj) || cpfMask(cpf)}
              </span>
            )}
          </div>
        </div>
      </div>
      <EnterpriseModal open={open} setOpen={setOpen} />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile, dataStatus } = state?.profile;

  return { profile, dataStatus };
};

export default connect(mapStateToProps, {})(EnterpriseData);
