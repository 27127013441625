export const styles = {
  baseBorder: {
    padding: 20,
    backgroundColor: '#FFF',
    borderRadius: '15px',
    border: '1px solid #F1ECEC',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.08)',
    marginTop: '40px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleMegaCard: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    color: '#25B379',
    fontWeight: 700,
    cursor: 'pointer',
    marginBottom: 20,
  },
  underline: {
    width: '100%',
    borderBottom: '2px solid #F1ECEC',
  },
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #fff',
    borderRadius: 15,
    padding: 15,
    width: '30rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: '#4A4A4A',
    margin: 0,
  },
  modalAlign: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalIcon: {
    cursor: 'pointer',
  },
  modalCircleBlue: {
    width: '25px',
    background: '#0179FF',
    borderRadius: '30px',
    marginRight: '10px',
  },
  input: {
    width: '100%',
    border: '1px solid #8D979E',
    borderRadius: 3,
    display: 'grid',
    padding: 4,
    marginTop: 18,
    minWidth: '120px',
  },
  spanInput: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    color: '#8D979E',
    paddingBottom: '0 !important',
  },
};
