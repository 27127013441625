import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PageTitle from 'src/Components/PageTitle';
import ProfilePage from 'src/Components/PartnerComponents/Profile';

export interface ProfileProps extends WithTranslation {}

const Profile: React.FC<ProfileProps> = ({ t }) => {
  return (
    <>
      <PageTitle title={'profile'} />
      <ProfilePage />
    </>
  );
};

export default withTranslation()(Profile);
