import { action } from 'typesafe-actions';
import { PartnerClientTypes } from './types';
import { PartnerClient } from '../../Models/PartnerClient';

export const getPartnerClients = (size: number) =>
  action(PartnerClientTypes.GET_PARTNER_CLIENTS, size);
export const getPartnerClientsSuccess = (partnerClients: PartnerClient[]) =>
  action(PartnerClientTypes.GET_PARTNER_CLIENTS_SUCCESS, partnerClients);
export const getPartnerClientsError = (message: string) =>
  action(PartnerClientTypes.GET_PARTNER_CLIENTS_ERROR, message);
