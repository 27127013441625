import './styles.scss';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Modal from '@material-ui/core/Modal';
import Dropzone from 'src/Components/Dropzone';
import StringUtils from 'src/Utils/StringUtils';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';
import InputClassic from '../../../PlanFormat/InputClassic';
import { EnterpriseData } from '../../../../../Models/Profile';
import ProfileApi from '../../../../../Services/Api/ProfileApi';
import {
  defaultData,
  defaultError,
  defaultImage,
  getEnterpriseData,
} from 'src/Utils/EnterpriseData';
import Fade from '@material-ui/core/Fade';
import { putEnterpriseData } from 'src/Stores/profile/actions';
import _, { isEmpty } from 'lodash';
import { useWidth } from 'src/Hooks/useWidth';
import { cnpjMask, cpfMask } from 'src/Utils/Masks';
import LoopIcon from '@mui/icons-material/Loop';
export interface ModalProps extends DispatchToProps {
  open: boolean;
  profile: EnterpriseData;
  setOpen: (open: boolean) => void;
}
const EnterpriseModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  profile,
  putEnterpriseData,
}) => {
  const [errors, setErrors] = useState(defaultError);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [data, setData] = useState<EnterpriseData>(defaultData);
  const [cnpjCpf, setCnpjCpf] = useState({ cpf: false, cnpj: false });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [typeOfCnpjCpf, setTypeofCnpjCpf] = useState(
    profile.cnpj ? 'cnpj' : profile.cpf ? 'cpf' : '' || '',
  );

  const { register, watch, setValue, handleSubmit } = useForm(defaultImage);

  const { cnpj, customUrl, cpf } = data;

  const setInvalidState = (field: string, value: boolean) => {
    setErrors({ ...errors, [field]: value });
  };

  useEffect(() => {
    if (!cnpj || cnpj?.length === 0) return;

    const isValidCnpj = cnpjValidator.isValid(cnpj);
    const isValidCpf = cpfValidator.isValid(cpf);
    if (isValidCnpj) setCnpjCpf({ cpf: false, cnpj: true });
    else if (isValidCpf) setCnpjCpf({ cpf: true, cnpj: false });
  }, [cnpj, setCnpjCpf]);

  useEffect(() => {
    if (!profile?.id) return;

    setValue('image', { preview: profile?.image?.imageUrl });

    const enterpriseData = getEnterpriseData(profile);

    if(enterpriseData.cpf) setCnpjCpf({ cpf: true, cnpj: false });
    else if(enterpriseData.cnpj) setCnpjCpf({ cpf: false, cnpj: true });
    setData(enterpriseData);
  }, [profile, setValue]);

  const handleClose = () => {
    const enterpriseData = getEnterpriseData(profile);

    setData(enterpriseData);
    handleSave();
  };

  const handleSave = () => {
    setOpen(false);
    setEnableSave(false);
  };

  const setImageValue = (field, value) => {
    setValue(field, value);
    setEnableSave(true);
  };

  const handleChange = (e) => {
    const { name = '', value = '' } = e?.target;

    if (name === 'cnpj') handleChangeCnpj(e);
    if (name === 'cpf') handleChangeCpf(e);
    else if (name === 'phone') handleChangePhone(e);
    else if (name === 'customUrl') {
      const lower = value?.toLowerCase();
      setData((prev) => ({ ...prev, [name]: lower }));
      setErrors((prev) => ({
        ...prev,
        customUrl: !/^[a-z]{4}[a-z0-9\-]*$/.test(lower),
      }));
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }

    setEnableSave(true);
  };

  const handleChangeCnpj = (e) => {
    const { value = '' } = e?.target;

    setData((prev) => ({ ...prev, cnpj: value }));
  };
  const handleChangeCpf = (e) => {
    const { value = '' } = e?.target;

    setData((prev) => ({ ...prev, cpf: value }));
  };

  const handleChangePhone = (e) => {
    const { value: number = '' } = e?.target;

    if (number?.length <= 14) {
      setData((prev) => ({ ...prev, phone: { ...prev?.phone, number } }));
    }
  };

  const onSubmit = ({ image: imageObj }) => {
    const image = imageObj?.isEdited ? imageObj : data.image;

    const newEnterpriseData = {
      ...data,
      customUrl: customUrl ? customUrl : undefined,
      cpf: cnpjCpf?.cpf ? data?.cpf : undefined,
      cnpj: cnpjCpf?.cnpj ? data?.cnpj : undefined,
      image,
    }
    putEnterpriseData(newEnterpriseData);
    handleSave();
  };

  const disabledSave =
    !enableSave || errors?.cnpj || errors?.cep || errors?.customUrl;

  const saveButtonColor = !disabledSave ? '#25B379 !important' : 'light-grey';

  var widthSize = useWidth();

  const handleChangeSelect = (e) => {
    setTypeofCnpjCpf(e.target.value);

    if (e.target.value === 'cpf') {
      setData({ ...data, cnpj: undefined });
      setCnpjCpf({ cnpj: false, cpf: true });
    }
    if (e.target.value === 'cnpj') {
      setData({ ...data, cpf: undefined });
      setCnpjCpf({ cnpj: true, cpf: false });
    }
  };

  const returnSelect = () => {
    if (typeOfCnpjCpf === 'cpf') {
      setData({ ...data, cpf: undefined });
      setCnpjCpf({ cnpj: true, cpf: false });
      setTypeofCnpjCpf('cnpj');
    } else if (typeOfCnpjCpf === 'cnpj') {
      setData({ ...data, cnpj: undefined });
      setCnpjCpf({ cnpj: false, cpf: true });
      setTypeofCnpjCpf('cpf');
    }
  };

  const dropzoneProps = {
    watch,
    register,
    cropAspect: 1,
    showSaveButton: false,
    setValue: setImageValue,
    editImageText: 'Trocar logotipo',
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      style={{ overflow: 'scroll', borderRadius: 15, padding: 15 }}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 2000,
      }}>
      <Fade in={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="paper__enterprise--data">
            <div className="modalAlign">
              <div className="modalTitleSmall">
                <h2 id="transition-modal-title" className="title">
                  Editar dados da empresa
                </h2>
              </div>
              <div>
                <CloseIcon className="modalIcon" onClick={handleClose} />
              </div>
            </div>
            <div className="mb-20" />
            <div className="underline"></div>
            <div className="mb-20" />
            <div className="upperContainer">
              <div className="enterprise-photo" ref={wrapperRef}>
                <Dropzone {...dropzoneProps} />
              </div>
              <div
                className={`${
                  widthSize > 450 ? 'col-8' : 'col-10'
                } container-modal`}>
                <div className="mb-20 ">
                  <InputClassic
                    name="fantasyName"
                    title="Nome fantasia"
                    onChange={handleChange}
                    value={data?.fantasyName}
                    placeholder="Nome fantasia"
                    disabled={true} 
                  />
                </div>
                <div className={errors?.customUrl ? '' : 'mb-20'}>
                  <InputClassic
                    name="customUrl"
                    title="#url do perfil"
                    placeholder="#url-do-perfil"
                    value={customUrl}
                    onChange={handleChange}
                    borderColor={errors?.customUrl && 'red'}
                  />
                  {errors?.customUrl && (
                    <p className="error">
                      mínimo 4 caracteres e apenas letras minúsculas e hífen
                    </p>
                  )}
                </div>
                <div className="mb-20">
                  <InputClassic
                    title="Razão social"
                    placeholder="Razão social"
                    onChange={handleChange}
                    name="socialReason"
                    value={data?.socialReason}
                  />
                </div>
                {!typeOfCnpjCpf && (
                  <div className="mb-20">
                    <select
                      className="select--type__cpf--cnpj"
                      value={typeOfCnpjCpf}
                      onChange={(e) => handleChangeSelect(e)}>
                      <option disabled value="">
                        CPF/CNPJ:
                      </option>
                      <option value="cpf">CPF</option>
                      <option value="cnpj">CNPJ</option>
                    </select>
                  </div>
                )}
                {typeOfCnpjCpf && typeOfCnpjCpf === 'cpf' && (
                  <div className="mb-20">
                    <InputClassic
                      name="cpf"
                      title="CPF"
                      placeholder="CPF"
                      className="input__select--cpfCnpj"
                      value={cpfMask(data?.cpf) || ''}
                      onChange={handleChange}
                      borderColor={errors.cnpj && 'red'}
                      disabled={true}
                    />
                    {/* <LoopIcon onClick={returnSelect} className="arrowBack" /> */}
                    {errors.cnpj && <p className="error"> CNPJ Inválido</p>}
                  </div>
                )}{' '}
                {typeOfCnpjCpf && typeOfCnpjCpf === 'cnpj' && (
                  <div className="mb-20 ">
                    <InputClassic
                      name="cnpj"
                      title="CNPJ"
                      placeholder="CNPJ"
                      className="input__select--cpfCnpj"
                      value={cnpjMask(data?.cnpj) || ''}
                      onChange={handleChange}
                      borderColor={errors.cnpj && 'red'}
                    />
                    <LoopIcon onClick={returnSelect} className="arrowBack" />
                    {errors.cnpj && <p className="error"> CNPJ Inválido</p>}
                  </div>
                )}
                <div className="mb-20 ">
                  <InputClassic
                    type="tel"
                    name="phone"
                    placeholder="Digite seu número"
                    onChange={handleChange}
                    value={data?.phone?.number}
                    international
                  />
                </div>
              </div>
            </div>
            <div className="mb-20" />
            <div className="mb-20" />
            <div className="footerContainer mt-20">
              <span onClick={handleClose} className="txt-verde">
                Cancelar
              </span>
              <Button
                type="submit"
                sx={{ background: saveButtonColor, borderRadius: '22px' }}
                variant="contained"
                disabled={disabledSave}>
                {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
              </Button>
            </div>
          </div>
        </form>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile: profileObj = {} } = state;

  return { profile: profileObj?.profile };
};

const mapDispatchToProps = {
  putEnterpriseData,
};

type DispatchToProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseModal);