import {ActivityCategory} from "src/Models/ActivityCategory";
import {ActivitySubCategory} from "src/Models/ActivitySubCategory";
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum CategoryActionTypes {
  GET_CATEGORIES_REQUEST = '@@category/CATEGORIES_REQUEST',
  GET_CATEGORIES_REQUEST_ERROR = '@@category/CATEGORIES_REQUEST_ERROR',
  GET_CATEGORIES_REQUEST_SUCCESS = '@@category/CATEGORIES_REQUEST_SUCCESS',

  GET_SUBCATEGORIES_REQUEST = '@@category/SUBCATEGORIES_REQUEST',
  GET_SUBCATEGORIES_REQUEST_ERROR = '@@category/SUBCATEGORIES_REQUEST_ERROR',
  GET_SUBCATEGORIES_REQUEST_SUCCESS = '@@category/SUBCATEGORIES_REQUEST_SUCCESS',
  
}

export interface CategoryState {
  readonly categories?: ActivityCategory[];
  readonly subcategories?: ActivitySubCategory[];
  readonly categoriesError?: HttpResponseError;
  readonly subCategoriesError?: HttpResponseError;
}
