import { Cancel, CheckCircle, Visibility } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CustomerActivity, {
  TokenUsedUpdatedByType,
} from 'src/Models/CustomerActivity';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import CustomerActivityApi from 'src/Services/Api/CustomerActivityApi';
import CustomerTokenApi from 'src/Services/Api/CustomerTokenApi';
import { IRootState } from 'src/Stores';
import StringUtils from 'src/Utils/StringUtils';

interface IServiceHistoryProps extends StateProps, DispatchProps {
  handleGetCustomerActivities: () => void;
}

const ServiceHistory = ({
  customerActivities,
  handleGetCustomerActivities,
  customerActivitiesStatus,
}: IServiceHistoryProps) => {
  const [isUpdatingTokenUsed, setIsUpdatingTokenUsed] = useState(false);

  const updateTokenUsed = (id: number, tokenUsed: boolean) => {
    if (customerActivitiesStatus === HttpRequestStatus.ONGOING) return;
    setIsUpdatingTokenUsed(true);
    CustomerTokenApi.updateTokenUsed({ id, tokenUsed })
      .then(() => {
        handleGetCustomerActivities();
        setIsUpdatingTokenUsed(false);
      })
      .catch(() => {
        setIsUpdatingTokenUsed(false);
      });
  };

  return (
    <table>
      <tbody>
        {customerActivities?.content?.map((customerActivity) => {
          const { tokenUsedUpdatedBy, tokenUsedUpdatedDate, tokenUsed } =
            customerActivity;
          const isActivityNewThan10days = moment(
            customerActivity.activitySchedule.date,
          ).isAfter(moment().subtract(10, 'days'));
          return (
            <tr key={customerActivity.id}>
              <td>
                <div className="c1">
                  <span className="c1-title">
                    {moment(customerActivity.activitySchedule.date).format(
                      'DD/MM',
                    )}
                  </span>
                  <span className="c1-sub">
                    {moment(customerActivity.activitySchedule.date).format(
                      'HH:mm',
                    )}
                  </span>
                </div>
              </td>
              <td>
                <span className="text-c2">
                  {customerActivity.activitySchedule?.activity?.name}
                </span>
              </td>
              <td>
                <div className="c3">
                  <div className="c1">
                    <span className="text-c2">
                      {customerActivity?.customer?.name}
                    </span>
                    {/* <span className="c3-sub">
                    {StringUtils.currencyPtBr(
                      customerActivity.activitySchedule?.pricePaid,
                    )}
                  </span> */}
                  </div>
                </div>
              </td>
              {/* <td>
                <div className="c1">
                  <div className="c3-sub">
                    <span style={{ marginLeft: '7px' }}>
                      {customerActivity?.customer?.user?.email}
                    </span>
                  </div>
                </div>
              </td> */}
              <td>
                <div className="c1">
                  <div className="c3-sub-enrolment">
                    {!tokenUsedUpdatedBy && isActivityNewThan10days ? (
                      <>
                        <button
                          disabled={
                            customerActivitiesStatus ===
                              HttpRequestStatus.ONGOING || isUpdatingTokenUsed
                          }
                          className="confirm-btn"
                          onClick={() =>
                            updateTokenUsed(customerActivity?.id, true)
                          }>
                          Confirmar Presença
                          <CheckCircle color="secondary" />
                        </button>
                        <button
                          disabled={
                            customerActivitiesStatus ===
                              HttpRequestStatus.ONGOING || isUpdatingTokenUsed
                          }
                          className="deny-btn"
                          onClick={() =>
                            updateTokenUsed(customerActivity?.id, false)
                          }>
                          Indicar falta
                          <Cancel color="error" />
                        </button>
                      </>
                    ) : (
                      <div>
                        {tokenUsed ? (
                          <span style={{ color: 'green' }} className="text-c2">
                            Presente
                          </span>
                        ) : (
                          <span style={{ color: 'red' }} className="text-c2">
                            Ausente
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state: IRootState) => {
  const customerActivities = state.customerActivityPlan.customerActivityHistory;
  const customerActivitiesStatus =
    state.customerActivityPlan.customerActivityHistoryStatus;
  return { customerActivities, customerActivitiesStatus };
};

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ServiceHistory),
) as React.ComponentType<any>;
