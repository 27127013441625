import styled from 'styled-components';

export const Container = styled.div`
  width: 955px;
  height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1ecec;
  cursor: pointer;
`;

export const ContainerUser = styled.div`
  width: 246px;
  height: 35px;
  margin-right: 100px;
  display: flex;
`;

export const TitleName = styled.div`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #455561;
  margin-left: 16px;
  text-align: left;
`;

export const TitleInfos = styled.div`
  width: 220px;
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #8d979e;
  margin-right: 130px;
`;

export const TitlePhone = styled.div`
  width: 150px;
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #8d979e;
  margin-right: 80px;
`;
