import { AxiosResponse } from 'axios';
import { Activity } from 'src/Models/Activity';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import ActivityScheduleApi from 'src/Services/Api/ActivitySchedule';
import { getActiveActivitiesSchedulesSuccess, getActiveActivitiesSchedulesError, getAllActivitiesSchedulesSuccess,getAllActivitiesSchedules, getAllActivitiesSchedulesError, postScheduleRecurrence, putScheduleStatus, putScheduleStatusSuccess, putScheduleStatusError, postScheduleNoRecurrence, postScheduleRecurrenceError, postScheduleNoRecurrenceError, getReScheduleAvailablesSuccess, getReScheduleAvailablesError, postSingleActivityScheduleSuccess, postSingleActivityScheduleError, postSingleActivitySchedule } from './actions';
import { ActivityScheduleActionTypes } from './types';
import { error, success } from 'src/Services/ToastService';
import {getScheduleFilters} from "../../Utils/Schedule/utils";
import { ActivitySchedule } from 'src/Models/ActivitySchedule';
import _ from 'lodash';
import moment from 'moment';


function* handleGetActiveSchedules() {
  try{
    const result: AxiosResponse<Activity[]> = yield call(ActivityScheduleApi.getActiveActivitiesSchedules);

    if(result.status === 200 && result.data !== null){
      yield put(getActiveActivitiesSchedulesSuccess(result.data));
    } else {
      yield put(getActiveActivitiesSchedulesError(result.statusText));
    }
  }catch(err) {
    if(err instanceof Error && err.stack) {
      yield put(getActiveActivitiesSchedulesError(err.stack));
    }else {
      yield put(getActiveActivitiesSchedulesError('An unknown error occured.'));
    }
  }
}

function* handlePutScheduleStatus(action: ReturnType<typeof putScheduleStatus>){
  let result: AxiosResponse<any> | null;

  const {putScheduleStatus} = ActivityScheduleApi;

  
  const {schedulesFilter, fromDate, toDate} = getScheduleFilters();
  
  try{
    result = yield call(putScheduleStatus, action.payload);
    if (result?.status === 200) {
      yield put(putScheduleStatusSuccess());
      yield put(getAllActivitiesSchedules(schedulesFilter, fromDate, toDate));
      success('Horário alterado!');
    }else{
      yield put(putScheduleStatusError('Erro durante a alteração!'));
      error('Erro durante a alteração!');
    }
  }catch (err){
    yield put(putScheduleStatusError(err.stack));
    error('Erro durante a alteração!');
  }
}

function* handlePostScheduleRecurence(action: ReturnType<typeof postScheduleRecurrence>){
  let result: AxiosResponse<Activity> | null;

  const {schedulesFilter, fromDate, toDate} = getScheduleFilters();

  try{
    result = yield call(ActivityScheduleApi.postScheduleRecurrence, action.payload);
    if (result?.status === 200) {
        yield put(getAllActivitiesSchedules(schedulesFilter, fromDate, toDate));
      success('Agendamento concluído!');
    }else{
      postScheduleRecurrenceError('Erro no agendamento!');
      error('Erro no agendamento!');
    }
  }catch (err){
    postScheduleRecurrenceError(err.stack);
    error('Erro no agendamento!');
  }
}

function* handlePostScheduleNoRecurence(action: ReturnType<typeof postScheduleNoRecurrence>){
  let result: AxiosResponse<Activity> | null;

  const {schedulesFilter, fromDate, toDate} = getScheduleFilters();
  
  try{
    result = yield call(ActivityScheduleApi.postScheduleNoRecurrence, action.payload);
    if (result?.status === 200) {
      yield put(getAllActivitiesSchedules(schedulesFilter, fromDate, toDate));
      success('Agendamento concluído!');
    }else{
      yield put(postScheduleNoRecurrenceError('Erro no agendamento!'));
      error('Erro no agendamento!');
    }
  }catch (err){
    yield put(postScheduleNoRecurrenceError(err.stack));
    error('Erro no agendamento!');
  }
}


function* handleGetAllSchedules(action) {
  try{
    let params = action.payload;
    if (params?.fromDate == null) {
      params = getScheduleFilters();
    }

    const result: AxiosResponse<Activity[]> = yield call(ActivityScheduleApi.getAllActivitiesSchedules, params);

    if(result.status === 200 && result.data !== null){
      yield put(getAllActivitiesSchedulesSuccess(result.data));
    } else {
      yield put(getAllActivitiesSchedulesError(result.statusText));
    }
  }catch(err) {
    if(err instanceof Error && err.stack) {
      yield put(getAllActivitiesSchedulesError(err.stack));
    }else {
      yield put(getAllActivitiesSchedulesError('An unknown error occured.'));
    }
  }
}

function* handleGetReScheduleAvailable(action) {
  try{
    const result: AxiosResponse<ActivitySchedule[]> = yield call(
      ActivityScheduleApi.getReScheduleAvailables, action.payload);

    if(result.status === 200 && result.data !== null) {
      let data = result.data as ActivitySchedule[];
      data.forEach(it => {
        it['dateFormat'] = moment(it.date).format('DD/MM/YYYY');
        it['hourFormat'] = moment(it.date).format('HH:mm');
      });

      data = _.sortBy(data, ['date'])
      const reSchedulesGroupped = _.groupBy(data, 'dateFormat');

      yield put(getReScheduleAvailablesSuccess(data, reSchedulesGroupped));
    } else {
      yield put(getReScheduleAvailablesError(result.statusText));
    }
  }catch(err) {
    if(err instanceof Error && err.stack) {
      yield put(getReScheduleAvailablesError(err.stack));
    }else {
      yield put(getReScheduleAvailablesError('An unknown error occured.'));
    }
  }
}

function* handlePostSingleActivitySchedule(action : ReturnType<typeof postSingleActivitySchedule>) {
  let result: AxiosResponse<ActivitySchedule> | null;
  const errorMessage = 'Erro ao criar horário';

  try {
    result = yield call(ActivityScheduleApi.postSingleActivitySchedule, action.payload);
    if (result?.status === 200 || result?.status === 201) {
      success('Horário criada com sucesso!');
      yield put(postSingleActivityScheduleSuccess());
    } else {
      error(errorMessage);
      yield put(postSingleActivityScheduleError());
    }
  } catch(error) {
    error(result.statusText)
    yield put(postSingleActivityScheduleError());
  }
}



function* watchPutScheduleStatus() {
  yield takeEvery(ActivityScheduleActionTypes.PUT_SCHEDULE_STATUS, handlePutScheduleStatus);
}

function* watchPostScheduleRecurrence() {
  yield takeEvery(ActivityScheduleActionTypes.POST_SCHEDULE_RECURRENCE, handlePostScheduleRecurence);
}

function* watchPostScheduleNoRecurrence() {
  yield takeEvery(ActivityScheduleActionTypes.POST_SCHEDULE_NO_RECURRENCE, handlePostScheduleNoRecurence);
}


function* watchGetActiveSchedules() {
  yield takeEvery(ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES, handleGetActiveSchedules);
}

function* watchGetAllSchedules() {
  yield takeEvery(ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES, handleGetAllSchedules);
}

function* watchPostSingleActivitySchedule() {
  yield takeEvery(ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE, handlePostSingleActivitySchedule);
}

function* schedulesSaga() {
  yield all([
      fork(watchGetActiveSchedules),
      fork(watchGetAllSchedules),
      fork(watchPostScheduleRecurrence),
      fork(watchPutScheduleStatus), 
      fork(watchPostScheduleNoRecurrence),
      fork(watchPostSingleActivitySchedule),
      yield takeLatest(ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE, handleGetReScheduleAvailable)
    ]);
}

export default schedulesSaga;
