import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import { action } from 'typesafe-actions';
import { CustomerActivityPlanActionTypes } from './types';
import { PageableResponse } from 'src/Models/pageable';

export const getCustomerActivityPlans = (params?: {
  status?: CustomerActivityStatus;
  size?: number;
  page?: number;
}) =>
  action(CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN, params);

export const getCustomerActivitiesPlansSuccess = (
  customerActivitiesPlan: PageableResponse<CustomerActivityPlan>,
) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN_SUCCESS,
    customerActivitiesPlan,
  );

export const getCustomerActivitiesPlansError = (message: string) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN_ERROR,
    message,
  );

export const getCustomerActivities = (params?: {
  size?: number;
  page?: number;
}) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY,
    params,
  );

export const getCustomerActivitiesSuccess = (
  customerActivities: PageableResponse<CustomerActivityPlan>,
) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY_SUCCESS,
    customerActivities,
  );

export const getCustomerActivitiesError = (message: string) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY_ERROR,
    message,
  );

export const getCustomerActivityPlanById = (id: number) =>
  action(CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID, id);
export const getCustomerActivityPlanByIdSuccess = (
  customerActivityPlan: CustomerActivityPlan,
) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_SUCCESS,
    customerActivityPlan,
  );
export const getCustomerActivityPlanByIdError = (message: string) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_ERROR,
    message,
  );

export const approveEnrolment = (customerActivtiyPlan: CustomerActivityPlan) =>
  action(
    CustomerActivityPlanActionTypes.APPROVE_ENROLMENT,
    customerActivtiyPlan,
  );
export const approveEnrolmentSuccess = () =>
  action(CustomerActivityPlanActionTypes.APPROVE_ENROLMENT_SUCCESS);

export const approveEnrolmentError = (message: string) =>
  action(CustomerActivityPlanActionTypes.APPROVE_ENROLMENT_ERROR, message);

export const resetStatus = () =>
  action(CustomerActivityPlanActionTypes.RESET_STATUS);

export const getCustomersPlanWithPaymentOverdue = (params?: {
  size?: number;
  page?: number;
}) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE,
    params,
  );

export const getCustomersPlanWithPaymentOverdueSuccess = (
  customersPlanWithPaymentOverdue: PageableResponse<CustomerActivityPlan>,
) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_SUCCESS,
    customersPlanWithPaymentOverdue,
  );

export const getCustomersPlanWithPaymentOverdueError = (message: string) =>
  action(
    CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_ERROR,
    message,
  );

export const resetApproveEnrolmentStatus = () =>
  action(CustomerActivityPlanActionTypes.RESET_APPROVE_ENROLMENT_STATUS);

export const resetCustomerActivityPlanStatus = () =>
  action(CustomerActivityPlanActionTypes.RESET_CUSTOMER_ACTIVITY_PLAN_STATUS);
