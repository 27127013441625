import { Reducer } from 'redux';
import { Partner } from 'src/Models/Partner';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import AuthUtils from '../../Utils/AuthUtils';
import { AuthenticationActionTypes, AuthenticationState } from './types';

export const initialState: AuthenticationState = {
  getSessionStatus: HttpRequestStatus.NOOP,
  loginStatus: HttpRequestStatus.NOOP,
  forgotPasswordStatus: HttpRequestStatus.NOOP,
  isAuthenticated: false,
};

const reducer: Reducer<AuthenticationState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN_REQUEST: {
      return { ...state, loginStatus: HttpRequestStatus.ONGOING };
    }
    case AuthenticationActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        loginStatus: HttpRequestStatus.SUCCESS,
        isAuthenticated: true,
      };
    }
    case AuthenticationActionTypes.LOGIN_ERROR: {
      return {
        ...state,
        loginStatus: HttpRequestStatus.ERROR,
        error: action.payload,
      };
    }
    case AuthenticationActionTypes.LOGIN_RESET_STATUS: {
      return { ...state, loginStatus: HttpRequestStatus.NOOP };
    }
    case AuthenticationActionTypes.GET_SESSION_REQUEST: {
      return { ...state, getSessionStatus: HttpRequestStatus.ONGOING };
    }
    case AuthenticationActionTypes.GET_SESSION_SUCCESS: {
      const partner = action.payload as Partner;
      const { user: account = {} } = partner || {};

      return {
        ...state,
        getSessionStatus: HttpRequestStatus.SUCCESS,
        isAuthenticated: true,
        account,
        partner,
      };
    }
    case AuthenticationActionTypes.GET_SESSION_ERROR: {
      return {
        ...state,
        getSessionStatus: HttpRequestStatus.ERROR,
        errors: action.payload,
      };
    }
    case AuthenticationActionTypes.LOGOUT_REQUEST: {
      AuthUtils.removeToken();
      return {
        ...state,
        getSessionStatus: HttpRequestStatus.NOOP,
        loginStatus: HttpRequestStatus.NOOP,
        isAuthenticated: false,
      };
    }
    case AuthenticationActionTypes.FORGOT_PASSWORD: {
      return { ...state, forgotPasswordStatus: HttpRequestStatus.ONGOING };
    }
    case AuthenticationActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return { ...state, forgotPasswordStatus: HttpRequestStatus.SUCCESS };
    }
    case AuthenticationActionTypes.FORGOT_PASSWORD_ERROR: {
      return { ...state, forgotPasswordStatus: HttpRequestStatus.ERROR };
    }
    case AuthenticationActionTypes.FORGOT_PASSWORD_RESET_STATUS: {
      return { ...state, forgotPasswordStatus: HttpRequestStatus.NOOP };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authenticationReducer };
