import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { ConferenceState } from './types';
import { ConferenceSessionTypes } from './types';

export const initialState: ConferenceState = {
  data: {},
  getOrCreateConferenceSessionStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<ConferenceState> = (
  state: ConferenceState = initialState,
  action,
) => {
  switch (action.type) {
    case ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION:
      return {
        ...state,
        getOrCreateConferenceSessionStatus: HttpRequestStatus.ONGOING,
      };
    case ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION_SUCCESS:
      return {
        ...state,
        getOrCreateConferenceSessionStatus: HttpRequestStatus.SUCCESS,
        data: action.payload,
      };
    case ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION_ERROR:
      return {
        ...state,
        getOrCreateConferenceSessionStatus: HttpRequestStatus.ERROR,
      };
    case ConferenceSessionTypes.RESET:
      return { ...initialState };
  }
  return state;
};

export { reducer as conferenceReducer };
