import './styles.scss';
import { Container } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import { styles } from '../BaseMegaCardCss/styles';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ScheduleHistory from '../ScheduleHistoryTable/components/ScheduleHistory';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import OverviewContent from './OverviewContent';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => styles);

export interface FinancesProps extends WithTranslation {}

const Finances = ({ t }) => {
  const [activeTab, setActiveTab] = useState<string>('overview');
  const [correspondentExtract, setCorrespondentExtract] =
    useState<boolean>(false);

  const changeTab = (newActiveTab: string) => setActiveTab(newActiveTab);

  // @ts-ignore
  const classes = useStyles();

  const getTabStyles = () => {
    const activeTabProps = {
      lineDiv: 'green-line-div',
      spanClass: 'select-option-green',
    };

    const disabledTabProps = {
      lineDiv: 'white-line-div',
      spanClass: 'select-option-gray',
    };

    if (activeTab === 'extract') {
      return {
        extractStyles: { ...activeTabProps },
        overviewStyles: { ...disabledTabProps },
      };
    }

    return {
      extractStyles: { ...disabledTabProps },
      overviewStyles: { ...activeTabProps },
    };
  };

  const now = moment().date();

  let fromDate = moment();
  let toDate = moment();

  if (now < 15) {
    fromDate = fromDate.subtract(1, 'month').startOf('month');
    toDate = toDate.subtract(1, 'month').endOf('month');
  } else {
    fromDate = fromDate.startOf('month');
    toDate = toDate.endOf('month');
  }
  const { overviewStyles, extractStyles } = getTabStyles();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const shouldGoToExtract = searchParams.get('shouldGoToExtract') === 'true';
  useEffect(() => {
    if (shouldGoToExtract) {
      setActiveTab('extract');
    }
  }, [shouldGoToExtract]);

  return (
    <div className="Finances">
      <div className="manageTabs">
        <div className="container">
          <div className="tabContainer" onClick={() => changeTab('overview')}>
            <span className={overviewStyles.spanClass}>Visão Geral</span>
            <div className={overviewStyles.lineDiv}></div>
          </div>
          <div className="tabContainer" onClick={() => changeTab('extract')}>
            <span className={extractStyles.spanClass}>Extrato</span>
            <div className={extractStyles.lineDiv}></div>
          </div>
        </div>
      </div>
      <Container>
        {activeTab === 'overview' ? (
          <OverviewContent
            setActiveTab={setActiveTab}
            setCorrespondentExtract={setCorrespondentExtract}
          />
        ) : correspondentExtract ? (
          <ScheduleHistory dateProps={{ fromDate, toDate }} />
        ) : (
          <ScheduleHistory />
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(Finances);
