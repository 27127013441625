import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import './styles.scss';
import VideoTutorial, { VideoType } from '../PartnerComponents/VideoTutorial';
import { HelpOutline } from '@material-ui/icons';
import { Popover } from '@material-ui/core';
import { useWidth } from '../../Hooks/useWidth';

export interface PageTitleProps extends WithTranslation {
  title: string;
  type?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ t, title, type }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const screenWidth = useWidth();

  const alertTitle =
    screenWidth > 600
      ? t('global.vTutorial.activityAlert')
      : t('global.vTutorial.smallActivityAlert');
  return (
    <div className="TitleRow">
      <Container className="header-title-container">
        <div className="Title">{t(`header.${title}`)}</div>
        {type === 'services' && (
          <HelpOutline
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              setPopOverOpen(true);
              setAnchorEl(e.target);
            }}
          />
        )}
        <Popover
          id="mouse-over-popover"
          style={{
            maxWidth: '94vw',
          }}
          open={popOverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={() => setPopOverOpen(false)}
          disableRestoreFocus>
          <VideoTutorial
            alertTitle={alertTitle}
            title={t('global.vTutorial.activityTitle')}
            type={VideoType.ADD_ACTIVITY_VIDEO}
          />
        </Popover>
      </Container>
    </div>
  );
};

export default withTranslation()(PageTitle);
