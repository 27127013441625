import React from 'react';

import './styles.scss';

import bg from 'src/Images/Keiken/Partner/CardCategoria.png';
import { ActivitySubCategory } from 'src/Models/ActivitySubCategory';
import { ActivityCategory } from 'src/Models/ActivityCategory';

export interface CardSubCategoryProps {
  color: string;
  subCategory: ActivitySubCategory;
  handleSelectSubCategory: (
    subCategory: ActivitySubCategory,
    select: boolean,
  ) => void;
  activityCategories?: {
    categories: ActivityCategory[];
    subCategories: ActivitySubCategory[];
  };
}

const CardSubCategory: React.FC<CardSubCategoryProps> = ({
  color,
  activityCategories,
  subCategory,
  handleSelectSubCategory,
}) => {
  function handleChange(select = false) {
    handleSelectSubCategory(subCategory, select);
  }

  const selected =
    activityCategories?.subCategories?.filter(
      (line) => line?.id === subCategory?.id,
    ).length > 0;

  return (
    <div className="CardsSubCategory">
      <div onClick={() => handleChange(selected ? true : false)}>
        <span className="texto">{subCategory?.name}</span>
        <div>
          <div className={`collor ${selected ? color : ''}`}></div>
          <img src={bg} alt="card" className="imagem" />
        </div>
      </div>
    </div>
  );
};

export default CardSubCategory;
