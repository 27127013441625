import { useEffect, useState } from 'react';

const useWaitingMessage = () => {
  const [message, setMessage] = useState('');
  const [counter, setCounter] = useState(0);
  const [currentText, setCurrentText] = useState(0);
  const funTexts = [
    'Aguarde um momento',
    'Estou pensando',
    'Buscando resposta',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter >= 9) {
        setCounter(0);
        setCurrentText((prev) => (prev === 2 ? 0 : prev + 1));
      } else {
        setCounter((prev) => prev + 1);
      }

      setMessage((prev) => {
        const numberOfDots = counter % 3;
        const dots = '.'.repeat(numberOfDots + 1);
        return funTexts[currentText] + dots;
      });
    }, 300);

    return () => clearInterval(interval);
  }, [counter, currentText]);

  return message;
};

export default useWaitingMessage;
