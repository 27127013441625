import './styles.scss';
import { Container } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { NavLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SimpleModal from 'src/Components/SimpleModal';
import {
  deletePlan,
  getPlans,
  resetPostPutStatus as resetPlanStatus,
} from 'src/Stores/plan/actions';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  deleteActivity,
  getActivities,
  resetPostPutStatus as resetActivityStatus,
} from 'src/Stores/activity/actions';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import StringUtils from 'src/Utils/StringUtils';
import { ActivityPlaceTypeUtils } from 'src/Models/Plan';

export interface ManagementProps
  extends WithTranslation,
    StateProps,
    DispatchProps {
  activity: { activities };
}

const deleteActivityBody = {
  openModal: false,
  activity: { id: 0, name: '' },
};

const deletePlanBody = {
  openModal: false,
  plan: { id: 0, name: '' },
};

const Management: React.FC<ManagementProps> = ({
  t,
  plan,
  activity,
  deletePlan,
  requestPlans,
  deleteActivity,
  resetPlanStatus,
  requestActivities,
  postPutPlanStatus,
  resetActivityStatus,
  postPutActivityStatus,
}) => {
  const [activeTab, setActiveTab] = useState<string>('activity');
  const [deleteActivityInfo, setDeleteActivityInfo] =
    useState<typeof deleteActivityBody>(deleteActivityBody);
  const [deletePlanInfo, setDeletePlanInfo] =
    useState<typeof deletePlanBody>(deletePlanBody);

  useEffect(() => {
    requestActivities();
    requestPlans();
  }, [requestPlans, requestActivities]);

  useEffect(() => {
    if (
      postPutPlanStatus === HttpRequestStatus.ERROR ||
      postPutPlanStatus === HttpRequestStatus.SUCCESS
    )
      resetPlanStatus();
  }, [postPutPlanStatus, resetPlanStatus]);

  useEffect(() => {
    if (
      postPutActivityStatus === HttpRequestStatus.ERROR ||
      postPutActivityStatus === HttpRequestStatus.SUCCESS
    )
      resetActivityStatus();
  }, [postPutActivityStatus]);

  const changeTab = (newActiveTab: string) => setActiveTab(newActiveTab);

  const getTabStyles = () => {
    const activeTabProps = {
      lineDiv: 'green-line-div',
      spanClass: 'select-option-green',
    };

    const disabledTabProps = {
      lineDiv: 'white-line-div',
      spanClass: 'select-option-gray',
    };

    const currentConfig = (tab = '') =>
      activeTab === tab ? activeTabProps : disabledTabProps;

    return {
      planStyles: currentConfig('plan'),
      packageStyles: currentConfig('package'),
      activityStyles: currentConfig('activity'),
    };
  };

  const NewActivity = () => {
    return (
      <div className="new-activities-btn">
        <NavLink to={'/activity'}>
          <div className="card-model-green card-model-creation">
            <AddIcon fontSize="large" />
            <span className="font">Criar nova atividade</span>
          </div>
        </NavLink>
      </div>
    );
  };

  const setDeleteInfo = (activityInfo = { id: 0, name: '' }) => {
    setDeleteActivityInfo({ openModal: true, activity: { ...activityInfo } });
  };

  const setPlanInfo = (planInfo = { id: 0, name: '' }) => {
    setDeletePlanInfo({ openModal: true, plan: { ...planInfo } });
  };

  const handleCancelDelete = () => {
    setDeleteActivityInfo(deleteActivityBody);
  };

  const handleDeleteActivity = () => {
    const { activity = { id: 0 } } = deleteActivityInfo;

    deleteActivity(activity?.id);
    handleCancelDelete();
  };

  const handleCancelPlan = () => {
    setDeletePlanInfo(deletePlanBody);
  };

  const handleDeletePlan = () => {
    const { plan = { id: 0 } } = deletePlanInfo;

    deletePlan(plan?.id);
    handleCancelPlan();
  };

  const activityContent = () => {
    const { activities = [] } = activity || {};
    const { name: activityName = '' } = deleteActivityInfo?.activity;

    return (
      <>
        <div className="activities-cards">
          {activities.map(
            ({ id, name, count, price, approved, type, error }) => {
              const title = ActivityPlaceTypeUtils.getLabel(type);
              return (
                <div className="activityy-card" key={id}>
                  <div
                    className="card-content"
                    style={approved ? { opacity: '1' } : { opacity: '0.5' }}>
                    <div className="value-title" title={name}>
                      {name}
                    </div>
                    <span className="retitle">{count} agendamentos</span>
                    <span className="title">{title}</span>
                    <span className="moneyText">
                      {StringUtils.currencyPtBr(price || 0)}
                    </span>
                    {error ? (
                      <span className="waitingApprove">{error}</span>
                    ) : (
                      <span className="moneyText">Atividade aprovada!</span>
                    )}
                    <NavLink to={{ pathname: `/activity/${id}` }}>
                      <div className="footer-title">
                        <span>Editar</span>
                        <ChevronRightIcon className="pointer" />
                      </div>
                    </NavLink>
                    <div
                      className="footer-title"
                      onClick={() => setDeleteInfo({ id, name })}>
                      <span>Excluir</span>
                      <ChevronRightIcon className="pointer" />
                    </div>
                  </div>
                </div>
              );
            },
          )}
          <NewActivity />
        </div>
        <SimpleModal
          isOpen={deleteActivityInfo?.openModal}
          labelConfirm={'Excluir'}
          labelCancel={'Cancelar'}
          modalTitle={'Excluir atividade'}
          onCancel={handleCancelDelete}
          onConfirm={handleDeleteActivity}>
          <div>
            Tem certeza que deseja excluir a atividade "{activityName}"?
          </div>
        </SimpleModal>
      </>
    );
  };

  const NewPlan = () => {
    return (
      <div className="new-activities-btn">
        <NavLink to={'/plans'}>
          <div className="card-model-green card-plan-model-creation">
            <AddIcon fontSize="large" />
            <span className="font">Criar novo plano</span>
          </div>
        </NavLink>
      </div>
    );
  };

  const planContent = () => {
    const { plans = [] } = plan || {};
    const { name: planName = '' } = deletePlanInfo?.plan;

    return (
      <>
        <div className="activities-cards" style={{ justifyContent: 'normal' }}>
          {plans?.map(({ id, name, price, type }, index: any) => {
            const title = ActivityPlaceTypeUtils.getLabel(type);
            return (
              <div className="activityy-card" key={id}>
                <div className="card-content">
                  <div className="value-title" title={name}>
                    {name}
                  </div>
                  {/* <span className="retitle">X agendamentos</span> */}
                  <span className="title">{title}</span>
                  <span className="moneyText">
                    {StringUtils.currencyPtBr(price ?? 0)}
                  </span>
                  <NavLink
                    to={{
                      pathname: `/plans/${id}`,
                      state: { planId: id },
                    }}>
                    <div className="footer-title">
                      <span>Editar</span>
                      <ChevronRightIcon className="pointer" />
                    </div>
                  </NavLink>
                  <div
                    className="footer-title"
                    onClick={() => setPlanInfo({ id, name })}>
                    <span>Excluir</span>
                    <ChevronRightIcon className="pointer" />
                  </div>
                </div>
              </div>
            );
          })}

          <NewPlan />
        </div>
        <SimpleModal
          isOpen={deletePlanInfo?.openModal}
          labelConfirm={'Excluir'}
          labelCancel={'Cancelar'}
          modalTitle={'Excluir plano'}
          onCancel={handleCancelPlan}
          onConfirm={handleDeletePlan}>
          <div>Tem certeza que deseja excluir a atividade "{planName}"?</div>
        </SimpleModal>
      </>
    );
  };

  const ActivityTab = () => {
    return (
      <div className="tabContainer" onClick={() => changeTab('activity')}>
        <span className={activityStyles.spanClass}>
          {t('partner.manage_queries')}
        </span>
        <div className={activityStyles.lineDiv}></div>
      </div>
    );
  };

  const PlanTab = () => {
    return (
      <div className="tabContainer" onClick={() => changeTab('plan')}>
        <span className={planStyles.spanClass}>
          {t('partner.manage_plans')}
        </span>
        <div className={planStyles.lineDiv}></div>
      </div>
    );
  };

  const HandleTabContent = () => {
    const tabs = {
      plan: planContent,
      activity: activityContent,
    };

    const currentTab = tabs[activeTab];

    return currentTab();
  };

  const { planStyles, activityStyles } = getTabStyles();

  return (
    <div style={{ position: 'relative' }} className="Management">
      <div className="manageTabs">
        <div className="container">
          <ActivityTab />
          <PlanTab />
        </div>
      </div>
      <Container>
        <HandleTabContent />
      </Container>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { activity = {}, plan = {} } = state || {};

  return {
    plan,
    activity,
    postPutPlanStatus: plan?.postPutStatus,
    postPutActivityStatus: activity?.postPutStatus,
  };
};

const mapDispatchToProps = {
  deletePlan,
  deleteActivity,
  resetPlanStatus,
  resetActivityStatus,
  requestPlans: getPlans,
  requestActivities: getActivities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Management),
);
