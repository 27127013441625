import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  background: ${({ isSecundary, disabled, text }) =>
    disabled
        ? '#f4f5f6'
        : isSecundary || text
        ? '#FFF'
        : css`linear-gradient(to right, #00c87f, #b3ff00)`};

  width: 220px;
  height: 50px;
  border-radius: 30px;

  &:active {
    background: ${({ isSecundary, disabled, text }) =>
    disabled ? '#f4f5f6' : isSecundary || text ? '#fafafa' : '#16cf6f'};
  }

  *:hover {
    cursor: pointer;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 75px;
    width: 100%;
    height: 100%;
    ${(props) =>
    props.isSecundary &&
    css`
        border: 1px solid #2fb86f;
        box-sizing: border-box;
        border-radius: 30px;
      `};
    ${(props) =>
    props.text &&
    css`
        border: none;
        box-sizing: border-box;
        border-radius: 30px;
      `};
      
    ${(props) =>
    props.disabled &&
    css`
        border: 1px solid #b2bcc2;
      `};
    label {
      font-family: MullerBold;
      font-size: 18px;
      margin: 0;
      text-align: center;
      color: ${({ isSecundary, disabled, text }) =>
        disabled ? '#b2bcc2' : isSecundary||text ? '#2FB86F' : '#fafafa'};
      user-select: none;
    }
  }
`;
