import { AxiosResponse } from 'axios';
import { Activity } from 'src/Models/Activity';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import ActivityApi from 'src/Services/Api/ActivityApi';
import {
  postActivity,
  postActivitySuccess,
  postActivityError,
  getScheduleHistory,
  putActivityError,
  putActivitySuccess,
  getActivitiesSuccess,
  getActivitiesError,
  deleteActivity,
  getActivities,
  getScheduleHistorySuccess,
  getScheduleHistoryError,
  getActivityById,
  getActivityByIdSuccess,
  getActivityByIdError,
} from './actions';
import { ActivityActionTypes } from './types';
import { error, success } from 'src/Services/ToastService';
import { getImageById } from '../image/action';
import { IRootState } from '..';
import { Agenda } from 'src/Models/Agenda';

function* handlePostActivity(action: ReturnType<typeof postActivity>) {
  let result: AxiosResponse<Activity> | null;

  const errorMessage = 'Erro ao cadastrar atividade!';

  try {
    result = yield call(ActivityApi.postActivity, action.payload);
    if (result?.status === 200) {
      success('Atividade cadastrada!');
      yield put(getActivities());
      yield put(postActivitySuccess());
    } else {
      error(errorMessage);
      yield put(postActivityError(errorMessage));
    }
  } catch (err) {
    error(errorMessage);
    yield put(postActivityError(errorMessage));
  }
}

function* handlePutActivity(action: ReturnType<typeof postActivity>) {
  let result: AxiosResponse<Activity> | null;

  const errorMessage = 'Erro ao atualizar atividade!';

  try {
    result = yield call(ActivityApi.putActivity, action.payload);
    if (result?.status === 200) {
      success('Atividade atualizada!');
      yield put(getActivities());
      yield put(putActivitySuccess());
    } else {
      error(errorMessage);
      yield put(putActivityError(errorMessage));
    }
  } catch (err) {
    error(errorMessage);
    yield put(putActivityError(errorMessage));
  }
}

function* handleDeleteActivity(action: ReturnType<typeof deleteActivity>) {
  let result: AxiosResponse<Activity> | null;

  try {
    result = yield call(ActivityApi.deleteActivity, action.payload);
    if (result?.status === 200) {
      yield put(getActivities());
      success('Atividade removida!');
    } else {
      console.log('error on sagas');
      error('Não foi possível remover a atividade!');
    }
  } catch (err) {
    error('Não foi possível remover a atividade!');
  }
}

function* handleGetActivities() {
  try {
    const result: AxiosResponse<Activity[]> = yield call(
      ActivityApi.getActivities,
    );

    if (result.status === 200 && result.data !== null) {
      const agendas: Agenda[] = yield select(
        (state: IRootState) => state.agenda.agendas,
      );

      if (agendas == null) {
        yield put(getActivitiesSuccess(result.data));
        return;
      }

      const activitiesMappedWithAgenda = result.data.map((activity) => {
        const activityAgendas = agendas.filter(
          (agenda) =>
            activity.agendas?.find(
              (activityAgenda) => activityAgenda.id === agenda.id,
            ) !== undefined,
        );
        const hasAnyActivitieAtLeastOneActivitieSchedule = activityAgendas.some(
          (agenda) => agenda.presetAgendaActivitySchedules?.length > 0,
        );

        return {
          ...activity,
          error: !activity.approved
            ? 'Atividade aguardando aprovação'
            : activityAgendas.length === 0
            ? 'Atividade não possui agenda associada!'
            : hasAnyActivitieAtLeastOneActivitieSchedule
            ? undefined
            : 'Agenda não possui horários cadastrados!',
        };
      });

      yield put(getActivitiesSuccess(activitiesMappedWithAgenda));
    } else {
      yield put(getActivitiesError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getActivitiesError(err.stack));
    } else {
      yield put(getActivitiesError('An unknown error occured.'));
    }
  }
}

function* handleGetActivityById(action: ReturnType<typeof getActivityById>) {
  try {
    const result: AxiosResponse<Activity> = yield call(
      ActivityApi.getActivity,
      action.payload,
    );
    const image = result?.data?.image;
    if (image != undefined) {
      if (image?.imageUrl == null && image?.file == null) {
        put(getImageById(image?.id));
      }
    }
    if (result.status === 200 && result.data !== null) {
      yield put(getActivityByIdSuccess(result.data));
    } else {
      yield put(getActivityByIdError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getActivityByIdError(err.stack));
    } else {
      yield put(getActivityByIdError('An unknown error occured.'));
    }
  }
}

function* handleGetScheduleHistory(
  action: ReturnType<typeof getScheduleHistory>,
) {
  try {
    const result: AxiosResponse<Activity[]> = yield call(
      ActivityApi.getScheduleHistory,
      action.payload,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getScheduleHistorySuccess(result.data));
    } else {
      yield put(getScheduleHistoryError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getScheduleHistoryError(err.stack));
    } else {
      yield put(getScheduleHistoryError('An unknown error occured.'));
    }
  }
}

function* activitySaga() {
  yield all([
    takeLatest(
      ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID,
      handleGetActivityById,
    ),
    takeLatest(ActivityActionTypes.GET_ACTIVITIES_REQUEST, handleGetActivities),
    takeLatest(
      ActivityActionTypes.GET_SCHEDULE_HISTORY,
      handleGetScheduleHistory,
    ),
    takeLatest(ActivityActionTypes.POST_ACTIVITY, handlePostActivity),
    takeLatest(ActivityActionTypes.PUT_ACTIVITY, handlePutActivity),
    takeLatest(ActivityActionTypes.DELETE_ACTIVITY, handleDeleteActivity),
  ]);
}

export default activitySaga;
