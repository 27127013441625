import React, { useEffect, useState } from 'react';
import './styles.scss';
import CloseIcon from '@material-ui/icons/Close';
import DateAdapter from '@mui/lab/AdapterMoment';
import { Agenda } from 'src/Models/Agenda';
import { IRootState } from 'src/Stores';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAgenda } from 'src/Stores/agenda/actions';
import {
  Modal,
  Fade,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  TextField,
  Input,
} from '@material-ui/core';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { toast } from 'react-toastify';
import { isEmpty, range } from 'lodash';
import moment from 'moment';
import {
  postSingleActivitySchedule,
  resetActivityScheduleStatus,
} from 'src/Stores/schedule/actions';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import _ from 'lodash';
import { MuiTextFieldStyled } from 'src/Components/ComponentMaterialStyled/MuiTextFieldStyled';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { Activity } from 'src/Models/Activity';

export interface BaseModalSingleScheduleProps
  extends WithTranslation,
    DispatchProps,
    StateProps {
  isOpen?: boolean;
  onCloseModal?: () => void;
}

const BaseModalSingleSchedule: React.FC<BaseModalSingleScheduleProps> = ({
  isOpen,
  onCloseModal,
  getAgenda,
  postSingleActivitySchedule,
  resetActivityScheduleStatus,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState<string>('');
  const [startAt, setStartAt] = useState<string>('');
  const [hour, setHour] = useState<string>('');
  const [minutes, setMinutes] = useState<string>('');
  const [selectedAgenda, setSelectedAgenda] = useState<Agenda>();
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const agendas = useSelector((root: IRootState) => root.agenda.agendas);
  const activityScheduleStatus = useSelector(
    (root: IRootState) => root.schedule.activityScheduleStatus,
  );
  const availableMinutes = [
    '00',
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
  ];

  useEffect(() => {
    if (activityScheduleStatus === HttpRequestStatus.SUCCESS) {
      toast.success('Horário criado com sucesso !');
      resetActivityScheduleStatus();
    }
    if (activityScheduleStatus === HttpRequestStatus.ERROR) {
      toast.success('Erro ao criar horário!');
      resetActivityScheduleStatus();
    }
  }, []);

  const handleOnOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOnCloseModal = () => {
    setDate('');
    setStartAt('');
    setHour('');
    setMinutes('');
    setSelectedAgenda(undefined);
    setIsModalOpen(false);
  };

  const handleOnSubmit = () => {
    const formatedDate = moment(date).format('DD/MM/YYYY');
    const formatedStartAt = hour + ':' + minutes;
    const dateTime = moment(
      formatedDate + ' ' + formatedStartAt,
      'DD/MM/YYYY HH:mm:ss',
    );
    const now = moment();
    const withUtc = dateTime.utcOffset(180);
    if (selectedAgenda == undefined || isEmpty(dateTime) || date == null) {
      return toast.error('Preencha todos os campos !');
    }
    if (dateTime < now) {
      setErrorDate(true);
      return toast.error('Data inferior a data atual !');
    }

    postSingleActivitySchedule({
      agenda: selectedAgenda,
      date: withUtc.toDate(),
      isSingleSchedule: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleOnSelectAgenda = (event) => {
    const value = event?.target?.value;
    setSelectedAgenda({
      id: value,
    });
  };

  const handleOnSelectHour = (event) => {
    const value = event?.target?.value;
    setHour(value);
  };

  const handleOnSelectMinutes = (event) => {
    const value = event?.target?.value;
    setMinutes(value);
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value < moment().format('YYYY-MM-DD')) {
      setErrorDate(true);
    }
    setDate(event.target.value);
  };

  return (
    <>
      <button className="addSingleScheduleButton" onClick={handleOnOpenModal}>
        <CalendarTodayOutlined style={{ width: '18px' }} /> Criar horário único
      </button>
      <div>
        <Modal
          open={isModalOpen}
          className={'modal'}
          onClose={handleOnCloseModal}
          closeAfterTransition>
          <Fade in={isModalOpen}>
            <div className="modal-container">
              <div className="modal-schedule-header">
                <h2>Criar horário único</h2>
                <CloseIcon
                  className="close-icon"
                  onClick={handleOnCloseModal}
                />
              </div>
              <div className="input-container">
                <div className="input-data">
                  <TextField
                    type="date"
                    variant="outlined"
                    onChange={handleChangeDate}
                    value={date}
                    error={errorDate}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="input-startAt">
                    <FormControl variant="outlined">
                      <InputLabel>Horas</InputLabel>
                      <Select
                        value={hour}
                        onChange={handleOnSelectHour}
                        label={'Horas'}>
                        {range(0, 24).map((hour) => (
                          <MenuItem key={`hour-${hour}`} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="input-startAt">
                    <FormControl variant="outlined">
                      <InputLabel>Minutos</InputLabel>
                      <Select
                        value={minutes}
                        onChange={handleOnSelectMinutes}
                        label={'Minutos'}>
                        {availableMinutes.map((minutes) => (
                          <MenuItem value={minutes} key={`minutes-${minutes}`}>
                            {minutes}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="select-agenda-container">
                  <FormControl variant="outlined">
                    <InputLabel>Agendas</InputLabel>
                    <Select
                      value={selectedAgenda}
                      label="Agendas"
                      onChange={handleOnSelectAgenda}>
                      {agendas?.map((item, index) => (
                        <MenuItem
                          value={item.id}
                          key={`agenda-${index}-${item.name}`}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="button-container">
                <button
                  className="create-single-schedule-button"
                  onClick={handleOnSubmit}>
                  Criar
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
};
const mapStateToProps = (state: IRootState) => {
  const { agenda } = state;

  return agenda;
};

const mapDispatchToProps = {
  getAgenda,
  postSingleActivitySchedule,
  resetActivityScheduleStatus,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BaseModalSingleSchedule),
) as React.ComponentType<any>;
