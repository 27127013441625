import { action } from 'typesafe-actions';
import { ActivityScheduleActionTypes } from './types';
import {ManualScheduling, ActivitySchedule} from "src/Models/ActivitySchedule";
import {CustomerRecurrence} from "src/Models/CustomerRecurrence";
import {SuggestionSchedulePayload} from "src/Models/SuggestionSchedulePayload";


export const getActiveActivitiesSchedules = () => action(ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES);
export const getActiveActivitiesSchedulesError = (message: string) => action(ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES_ERROR, message);
export const getActiveActivitiesSchedulesSuccess = (schedules: ActivitySchedule[]) => action(ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES_SUCCESS, schedules);

export const getAllActivitiesSchedules = (activityType?: string, fromDate?: string, toDate?: string) => action(ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES, {activityType, fromDate, toDate});
export const getAllActivitiesSchedulesError = (message: string) => action(ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES_ERROR, message);
export const getAllActivitiesSchedulesSuccess = (schedules: ActivitySchedule[]) => action(ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES_SUCCESS, schedules);

export const postSingleActivitySchedule = (activitySchedule : ActivitySchedule) => action(ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE, activitySchedule);
export const postSingleActivityScheduleSuccess = () => action(ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE_SUCCESS);
export const postSingleActivityScheduleError = () => action(ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE_ERROR);

export const postScheduleRecurrenceSuccess = () => action(ActivityScheduleActionTypes.POST_SCHEDULE_RECURRENCE_SUCCESS);
export const postScheduleRecurrence = (schedule: CustomerRecurrence) => action(ActivityScheduleActionTypes.POST_SCHEDULE_RECURRENCE, schedule);
export const postScheduleRecurrenceError = (message: string) => action(ActivityScheduleActionTypes.POST_SCHEDULE_RECURRENCE_ERROR, message);

export const postScheduleNoRecurrenceSuccess = () => action(ActivityScheduleActionTypes.POST_SCHEDULE_NO_RECURRENCE_SUCCESS);
export const postScheduleNoRecurrence = (schedule: SuggestionSchedulePayload) => action(ActivityScheduleActionTypes.POST_SCHEDULE_NO_RECURRENCE, schedule);
export const postScheduleNoRecurrenceError = (message: string) => action(ActivityScheduleActionTypes.POST_SCHEDULE_NO_RECURRENCE_ERROR, message);

export const postManualSchedulingSuccess = () => action(ActivityScheduleActionTypes.POST_MANUAL_SCHEDULING_SUCCESS);
export const postManualScheduling = (schedule: ManualScheduling, customerId: number) => action(ActivityScheduleActionTypes.POST_MANUAL_SCHEDULING, {schedule, customerId});
export const postManualSchedulingError = (message: string) => action(ActivityScheduleActionTypes.POST_MANUAL_SCHEDULING_ERROR, message);

export const putScheduleStatusSuccess = () => action(ActivityScheduleActionTypes.PUT_SCHEDULE_STATUS_SUCCESS);
export const putScheduleStatus = (scheduleId: number, status: string) => action(ActivityScheduleActionTypes.PUT_SCHEDULE_STATUS, {scheduleId, status});
export const putScheduleStatusError = (message: string) => action(ActivityScheduleActionTypes.PUT_SCHEDULE_STATUS_ERROR, message);

export const getReScheduleAvailablesSuccess = (schedules: ActivitySchedule[], groupped: any) => 
  action(ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE_SUCCESS, {schedules, groupped});

  export const getReScheduleAvailables = (scheduleId: number) => 
  action(ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE, {scheduleId });

  export const getReScheduleAvailablesError = (message: string) => 
  action(ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE_ERROR, message);

export const reset = () => action(ActivityScheduleActionTypes.RESET_RE_SCHEDULE);

export const resetActivityScheduleStatus = () => action(ActivityScheduleActionTypes.RESET_ACTIVITY_SCHEDULE_STATUS);

