export enum PhoneType {
  OTHER = 'OTHER',
  CELLULAR = 'CELLULAR',
  PROFESSIONAL = 'PROFESSIONAL'
}

export interface Phone {
  id?: number;
  number?: string;
  type?: PhoneType;
}

export const defaultValue: Readonly<Phone> = {};
