import { AxiosResponse } from 'axios';
import { EditPasswordParams } from 'src/Models/edit-password';
import { Partner } from 'src/Models/Partner';
import { api } from '.';
import { Authentication } from '../../Models/authentication';
import { User } from '../../Models/user';

export const AuthenticationApi = () => {
  const login = (
    authentication: Authentication,
  ): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/authenticate', authentication);
  };

  const getSession = (): Promise<AxiosResponse<User>> => {
    return api.get<User>('/customers/me');
  };

  const getPartnerSession = (): Promise<AxiosResponse<Partner>> => {
    return api.get<Partner>('/partners/me');
  };

  const acceptTermsAndConditions = (): Promise<AxiosResponse<Partner>> => {
    return api.patch<Partner>('/partners/accept-term');
  };

  const getCompanyUserSession = (): Promise<AxiosResponse<User>> => {
    return api.get<User>('/companies/users/me');
  };

  const getCustomerSession = (): Promise<AxiosResponse<User>> => {
    return api.get<User>('/customers/me');
  };

  const createUser = (user: User): Promise<AxiosResponse<User>> => {
    return api.post<User>('/users/register', user);
  };

  const activateAccountToken = (
    activationKey: string,
  ): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/activate/${activationKey}`);
  };

  const requestPasswordReset = (
    email: string,
  ): Promise<AxiosResponse<void>> => {
    return api.post<void>(`/account/reset-password/${email}/init`);
  };

  const finishPasswordReset = (
    key: string,
    newPassword: string,
  ): Promise<AxiosResponse<void>> => {
    return api.post<void>('/account/reset-password/finish', {
      key,
      newPassword,
    });
  };

  const checkIfEmailExists = (
    email: string,
    checkIfIsAvailable: boolean,
  ): Promise<AxiosResponse<void>> => {
    return api.get<void>(`users/check-email/${email}/${checkIfIsAvailable}`);
  };

  const checkIfResetKeyIsValid = (
    key: string,
  ): Promise<AxiosResponse<number>> => {
    return api.get<number>(`users/check-reset-key/${key}`);
  };

  const checkIfActivationKeyIsValid = (
    key: string,
  ): Promise<AxiosResponse<string>> => {
    return api.get<string>(`users/check-activation-key/${key}`);
  };

  const passwordChange = (
    changeBody: EditPasswordParams,
  ): Promise<AxiosResponse> => {
    return api.put('/partners/password', changeBody);
  };

  const forgotPassword = (email: string): Promise<AxiosResponse<void>> => {
    return api.post<void>('/account/forgot-password/init', { email });
  };

  return {
    login,
    createUser,
    getSession,
    passwordChange,
    getPartnerSession,
    checkIfEmailExists,
    getCustomerSession,
    getCompanyUserSession,
    finishPasswordReset,
    requestPasswordReset,
    activateAccountToken,
    checkIfResetKeyIsValid,
    checkIfActivationKeyIsValid,
    forgotPassword,
    acceptTermsAndConditions,
  };
};

export default AuthenticationApi();
