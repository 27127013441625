import { format } from 'date-fns';
import React from 'react';
import { PreScheduleByPartner } from 'src/Models/Plan';
import StringUtils from 'src/Utils/StringUtils';
import UserSvg from '../../Images/Icons/avatarPaymentsPending.svg';
import DeleteSvg from '../../Images/Icons/DeleteTrash.svg';
import {
  Container,
  ContainerDayAndHour,
  ContainerNameAndEmail,
  ContainerPriceAndPlanName,
  ContainerUser,
  TitleDay,
  TitleEmail,
  TitleHour,
  TitleName,
  TitleNamePlan,
  TitlePrice,
} from './styles';

interface Props {
  payment: PreScheduleByPartner;
  deletePayment: (payment: PreScheduleByPartner) => void;
}

const ContainerInfoPayment = ({ payment, deletePayment }: Props) => {
  const hideEmail = (text: string) => {
    const partToHide = text.substring(1, text.indexOf('@'));
    return text.replace(partToHide, '*'.repeat(partToHide.length));
  };

  return (
    <Container>
      <ContainerDayAndHour>
        <TitleDay>{format(new Date(payment.createdDate), 'dd/MM')}</TitleDay>
        <TitleHour>{format(new Date(payment.createdDate), 'HH:mm')}</TitleHour>
      </ContainerDayAndHour>
      <ContainerUser>
        <img src={UserSvg} />
        <ContainerNameAndEmail>
          <TitleName>{payment?.customer?.name}</TitleName>
          <TitleEmail>{payment?.customer?.user?.email}</TitleEmail>
        </ContainerNameAndEmail>
      </ContainerUser>
      <ContainerPriceAndPlanName>
        <TitleNamePlan>
          {payment?.customerActivity?.activitySchedule?.activity?.name ??
            payment?.customerActivityPlan?.activityPlan?.name}
        </TitleNamePlan>
        <TitlePrice>
          {StringUtils.currencyPtBr(
            payment?.customerActivity?.activitySchedule?.activity?.price ??
              payment?.customerActivityPlan?.activityPlan?.price,
          )}
        </TitlePrice>
      </ContainerPriceAndPlanName>
      <img
        src={DeleteSvg}
        style={{ cursor: 'pointer' }}
        onClick={() => deletePayment(payment)}
      />
    </Container>
  );
};

export default ContainerInfoPayment;
