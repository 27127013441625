import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import ConferenceApi from 'src/Services/Api/ConferenceApi';
import {
  getOrCreateConferenceSessionError,
  getOrCreateConferenceSessionSuccess,
} from './actions';
import { ConferenceSessionTypes } from './types';
import { error } from 'src/Services/ToastService';
import { ConferenceSession } from '../../Models/ConferenceSession';

function* getOrCreateConferenceSession(action) {
  try {
    const result: AxiosResponse<ConferenceSession> = yield call(
      ConferenceApi.getOrCreateConferenceSession,
      action.payload,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getOrCreateConferenceSessionSuccess(result.data));
    } else {
      yield put(getOrCreateConferenceSessionError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getOrCreateConferenceSessionError(err.stack));
    } else {
      yield put(getOrCreateConferenceSessionError('An unknown error occured.'));
    }
    error('Falha na conferencia!');
  }
}

function* conferenceSaga() {
  yield all([
    takeLatest(
      ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION,
      getOrCreateConferenceSession,
    ),
  ]);
}

export default conferenceSaga;
