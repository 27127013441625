import React, { useEffect, useState } from 'react';
import {
  ArrowSvg,
  ButtonSort,
  Container,
  ContainerDropDown,
  ContainerFiltersAndButton,
  ContainerInputAndImg,
  ContainerOptions,
  ContainerSelect,
  ContainerSelectAndDate,
  ContainerTitleAndFilters,
  ContainerTooltip,
  DateFilterContainer,
  DownloadButton,
  InputStyled,
  OrderByTitle,
  StyledOption,
  StyledSelect,
  Title,
  TitleAndTootip,
  TitleButtonSort,
  TitleDropDown,
  TitleOptions,
} from './styles';
import SearchSvg from '../../../../../Images/Icons/search.svg';
import SortSvg from '../../../../../Images/Icons/sortGreen.svg';
import CalendarSvg from '../../../../../Images/Icons/calendarPlan.svg';
import ArrowForward from '../../../../../Images/Icons/ArrowForward.svg';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { pt } from 'date-fns/locale';
import format from 'date-fns/format';
import { ActivityType } from 'src/Models/Enums/ActivityType';
import { Popover } from '@material-ui/core';
import { FilterDateType } from '../ScheduleHistory';
import { Moment } from 'moment';
import { RotateCircleLoading } from 'react-loadingg';
import Images from 'src/Themes/Constants/Images';

export interface DateProps {
  startDate: string;
  endDate: string;
}
export interface FilterHeaderProps extends WithTranslation {
  filterText?: string;
  filterType?: string;
  date?: any;
  formatedDate?: DateProps;
  dateProps?: { fromDate: Moment; toDate: Moment };
  whichDateToFilter?: FilterDateType;
  handleDownload?: () => void;
  isDownloading?: boolean;
  setFormatedDate?: (date?: DateProps) => void;
  setWhichDateToFilter?: (dateType: FilterDateType) => void;
  handleOnChangeDate?: (item?: any) => void;
  handleOnChangeText?: (event?: any) => void;
  handleOnChangeType?: (type?: ActivityType) => void;
}
const FilterHeader: React.FC<FilterHeaderProps> = ({
  t,
  date,
  whichDateToFilter,
  setWhichDateToFilter,
  filterText,
  dateProps,
  filterType,
  formatedDate,
  isDownloading,
  handleDownload,
  setFormatedDate,
  handleOnChangeDate,
  handleOnChangeText,
  handleOnChangeType,
}) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [calendarAnchor, setCalendarAnchor] = useState<HTMLAnchorElement>(null);
  const [showOrderByDropDown, setShowOrderByDropDown] =
    useState<boolean>(false);
  const type =
    filterType === ActivityType.ACTIVITY
      ? 'Atividades'
      : filterType === ActivityType.PLAN
      ? 'Planos'
      : 'Todos';

  const handleChange = (event) => {
    setWhichDateToFilter(event.target.value);
  };

  useEffect(() => {
    if (dateProps?.fromDate && dateProps?.toDate) {
      setFormatedDate({
        startDate: dateProps?.fromDate.format('DD/MM/yyyy'),
        endDate: dateProps?.toDate.format('DD/MM/yyyy'),
      });
    }
  }, [dateProps]);

  return (
    <>
      <ContainerTitleAndFilters>
        <TitleAndTootip>
          <ContainerSelectAndDate>
            <ContainerSelect>
              <StyledSelect
                name="Qual data filtrar?"
                id="dataType"
                value={whichDateToFilter}
                onChange={handleChange}>
                <StyledOption value={FilterDateType.PURCHASE_DATE}>
                  Data de Compra
                </StyledOption>
                <StyledOption value={FilterDateType.SCHEDULE_DATE}>
                  Data de Agendamento
                </StyledOption>
              </StyledSelect>
            </ContainerSelect>

            <DateFilterContainer>
              <img src={CalendarSvg} />
              <OrderByTitle>
                Filtrar por data: {formatedDate?.startDate} até{' '}
                {formatedDate?.endDate}
              </OrderByTitle>
              <ArrowSvg
                src={ArrowForward}
                onClick={(e) => {
                  setCalendarAnchor(e.target);
                  setShowOrderByDropDown(!showOrderByDropDown);
                }}
              />
            </DateFilterContainer>
          </ContainerSelectAndDate>
          <Popover
            anchorEl={calendarAnchor}
            open={showOrderByDropDown}
            onClose={() => setShowOrderByDropDown(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <DateRange
              locale={pt}
              editableDateInputs={true}
              onChange={(item) => handleOnChangeDate(item)}
              moveRangeOnFirstSelection={false}
              ranges={date}
              rangeColors={['#25b379', '#25b379']}
            />
          </Popover>
        </TitleAndTootip>
        <ContainerFiltersAndButton>
          <DownloadButton onClick={() => handleDownload()}>
            {isDownloading ? (
              <>
                <RotateCircleLoading
                  size="small"
                  style={{
                    position: 'static',
                    marginInline: 'auto',
                  }}
                />
                Exportando...
              </>
            ) : (
              <>
                <img src={Images.icons.downloadIcon} alt="export table" />
                Exportar relatório
              </>
            )}
          </DownloadButton>
          <ContainerInputAndImg>
            <InputStyled
              placeholder={t('makePurchase.history.placeholderInputSearch')}
              onChange={handleOnChangeText}
              type="text"
            />
            <img src={SearchSvg} />
          </ContainerInputAndImg>
          <ContainerTooltip
            onMouseEnter={() => {
              setShowDropDown(true);
            }}
            onMouseLeave={() => {
              setShowDropDown(false);
            }}>
            <ButtonSort>
              <img src={SortSvg} />
              <TitleButtonSort>{type}</TitleButtonSort>
            </ButtonSort>
            {showDropDown && (
              <ContainerDropDown>
                <ContainerOptions
                  onClick={() => handleOnChangeType(ActivityType?.ACTIVITY)}>
                  <TitleOptions
                    selected={
                      filterType === ActivityType.ACTIVITY ? true : false
                    }>
                    Atividades
                  </TitleOptions>
                </ContainerOptions>
                <ContainerOptions
                  onClick={() => handleOnChangeType(ActivityType?.PLAN)}>
                  <TitleOptions
                    selected={filterType === ActivityType.PLAN ? true : false}>
                    Planos
                  </TitleOptions>
                </ContainerOptions>
                <ContainerOptions onClick={() => handleOnChangeType(undefined)}>
                  <TitleOptions
                    selected={filterType === undefined ? true : false}>
                    Todos
                  </TitleOptions>
                </ContainerOptions>
              </ContainerDropDown>
            )}
          </ContainerTooltip>
        </ContainerFiltersAndButton>
      </ContainerTitleAndFilters>
    </>
  );
};

export default withTranslation()(FilterHeader);
