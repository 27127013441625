import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryPaymentsUser } from 'src/Models/historyPayments';
import StringUtils from 'src/Utils/StringUtils';
import {
  BarDate,
  Container,
  ContainerDate,
  ContainerInfos,
  ContainerInfosOfPaid,
  ContainerInfosOfPayments,
  Divider,
  TitleDate,
  TitleFirstPlan,
  TitleInfos,
  TitlePlan,
  TitleValueInfo,
  TitleValueReceived,
} from './styles';

interface Props {
  payments: HistoryPaymentsUser;
  index: number;
}

const LastActivities = ({ payments, index }: Props) => {
  const { t } = useTranslation();

  const getPaymentMethod = () => {
    let method = '';
    if (payments.paymentMethod != 'NONE' && payments.billingCoParticipation) {
      method =
        t(`makePurchase.history.${payments.paymentMethod.toLowerCase()}`) +
        ` + ${t(`makePurchase.history.benefit`)}`;
    } else if (payments.billingCoParticipation) {
      method = `${t(`makePurchase.history.benefit`)}`;
    } else if (payments.paymentMethod) {
      method = t(
        `makePurchase.history.${payments.paymentMethod.toLowerCase()}`,
      );
    }
    return method;
  };

  return (
    <Container>
      <ContainerDate>
        <TitleDate>{format(new Date(payments.date), 'dd/MM/yyyy')}</TitleDate>
        <TitleInfos>{format(new Date(payments.date), 'HH:mm')}</TitleInfos>
        <BarDate />
      </ContainerDate>
      <ContainerInfos>
        <TitleFirstPlan>
          {t('makePurchase.history.bought')}{' '}
          <TitlePlan>
            {payments?.activitySchedules[0]?.activity?.name ??
              payments?.activityPlan?.name}
          </TitlePlan>
        </TitleFirstPlan>
        <ContainerInfosOfPayments>
          <TitleInfos>{t('makePurchase.history.paidCollaborator')}</TitleInfos>
          <TitleValueInfo>
            {StringUtils.currencyPtBr(
              payments.billingCoParticipation
                ? payments.billingCoParticipation.subsidyValue + payments.value
                : payments.value,
            )}{' '}
            • 
            {getPaymentMethod()}
          </TitleValueInfo>
        </ContainerInfosOfPayments>
        <Divider />
        <ContainerInfosOfPaid>
          <TitleInfos>{t('makePurchase.history.totalValue')}</TitleInfos>
          <TitleValueReceived>
            {StringUtils.currencyPtBr(
              payments.partnerProfit
                ? payments.partnerProfit
                : payments?.activitySchedules[0]?.activity?.price,
            )}
          </TitleValueReceived>
        </ContainerInfosOfPaid>
      </ContainerInfos>
    </Container>
  );
};

export default LastActivities;
