import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import BusinessChildrenCards from 'src/Components/BusinessChildrenCards';
import AddressData from './AddressData';
import BankData from './BankData';
import EnterpriseData from './EnterpriseData';
import EnterprisePage from './EnterprisePage';
import PasswordData from './PasswordData';
import ResponsibleData from './ResponsibleData';
import ResponsibleFormation from './ResponsibleFormation';
import TermsAndConditions from './TermsAndConditions';
import './styles.scss';

export interface ProfileProps extends WithTranslation {}

const Profile: React.FC<ProfileProps> = ({ t }) => {
  const [activeTab, setActiveTab] = useState<string>('data');

  const changeTab = (newActiveTab: string) => setActiveTab(newActiveTab);

  const getTabStyles = () => {
    const activeTabProps = {
      lineDiv: 'green-line-div',
      spanClass: 'select-option-green',
    };

    const disabledTabProps = {
      lineDiv: 'white-line-div',
      spanClass: 'select-option-gray',
    };

    if (activeTab === 'branches') {
      return {
        branchesStyles: { ...activeTabProps },
        dataStyles: { ...disabledTabProps },
      };
    }

    return {
      branchesStyles: { ...disabledTabProps },
      dataStyles: { ...activeTabProps },
    };
  };

  const dataContent = () => {
    return (
      <div className="top-40-magr">
        <div className="col-12 p-10">
          <EnterpriseData />
        </div>
        <div className="col-12 p-10">
          <EnterprisePage />
        </div>
        <div className="col-12 p-10">
          <AddressData />
        </div>
        <div className="col-12 p-10">
          <ResponsibleData />
        </div>
        <div className="col-12 p-10">
          <ResponsibleFormation />
        </div>
        <div className="col-12 p-10">
          <BankData />
        </div>
        <div className="col-12 p-10">
          <PasswordData />
        </div>
        <div className="col-12 p-10">
          <TermsAndConditions />
        </div>
      </div>
    );
  };

  const branchesContent = () => {
    return (
      <div className="flex top-40-magr">
        <Container className="ExtractContainer" style={{ padding: 0 }}>
          <BusinessChildrenCards />
        </Container>
      </div>
    );
  };

  const HandleTabContent = () => {
    if (activeTab === 'data') return dataContent();

    return branchesContent();
  };

  const { dataStyles } = getTabStyles();

  return (
    <div className="Profile">
      <div className="manageTabs row">
        <div className="container">
          <div className="tabContainer" onClick={() => changeTab('data')}>
            <span className={dataStyles.spanClass}>Dados</span>
            <div className={dataStyles.lineDiv}></div>
          </div>
        </div>
      </div>
      <Container className="mt-40">
        <HandleTabContent />
      </Container>
    </div>
  );
};

export default withTranslation()(Profile);
