import { Reducer } from 'redux';
import { Activity } from 'src/Models/Activity';
import { ActivitySchedule } from 'src/Models/ActivitySchedule';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { ActivityScheduleActionTypes, ActivityScheduleState } from './types';

export const initialState: ActivityScheduleState = {
  allActivitiesSchedules: [],
  activeActivitiesSchedules: [],
  reSchedules: [],
  reSchedulesGroupped: {},
  reScheduleStatus: HttpRequestStatus.NOOP
};

const reducer: Reducer<ActivityScheduleState> = (state = initialState, action) => {
  switch (action.type) {
   case ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES: {
      return {...state, scheduleStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES_SUCCESS:{
     const allActivitiesSchedules = action?.payload as Activity[];

     return {...state, allActivitiesSchedules, scheduleStatus: HttpRequestStatus.SUCCESS};
   }
   case ActivityScheduleActionTypes.GET_ALL_ACTIVITIES_SCHEDULES_ERROR: {
    return { ...state, scheduleStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }

   case ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES: {
      return {...state, activeSchedulesStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES_SUCCESS:{
     const activeActivitiesSchedules = action?.payload as Activity[];

     return {...state, activeActivitiesSchedules, activeSchedulesStatus: HttpRequestStatus.SUCCESS};
   }
   case ActivityScheduleActionTypes.GET_ACTIVE_ACTIVITIES_SCHEDULES_ERROR: {
    return { ...state, activeSchedulesStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }
   case ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE: {
      return {...state, reScheduleStatus: HttpRequestStatus.ONGOING};
   }
   case ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE_SUCCESS:{
     const reSchedules = action?.payload.schedules as ActivitySchedule[];
     const groupped = action?.payload.groupped as Object;
     return {
      ...state, 
      reSchedules, 
      reSchedulesGroupped: groupped, 
      reScheduleStatus: HttpRequestStatus.SUCCESS
    };
   }
   case ActivityScheduleActionTypes.GET_RE_SCHEDULE_AVAILABLE_ERROR: {
    return { ...state, reSchedules: [], reScheduleStatus: HttpRequestStatus.ERROR, error: action?.payload };
   }
   case ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE: {
     return { ...state, activityScheduleStatus: HttpRequestStatus.ONGOING };
   }
   case ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE_SUCCESS: {
     return { ...state, activityScheduleStatus:HttpRequestStatus.SUCCESS } 
   }
   case ActivityScheduleActionTypes.POST_ACTIVITY_SCHEDULE_ERROR: {
     return { ...state, activityScheduleStatus:HttpRequestStatus.ERROR } 
   }

   case ActivityScheduleActionTypes.RESET_RE_SCHEDULE: {
    return { 
      ...state,
      reSchedules: [],
      reSchedulesGroupped: {},
      reScheduleStatus: HttpRequestStatus.NOOP
    };
   }

   case ActivityScheduleActionTypes.RESET_ACTIVITY_SCHEDULE_STATUS: {
    return {...state, activityScheduleStatus: HttpRequestStatus.NOOP};
   }
    
    default: {
      return state;
    }
  }
}

export { reducer as activityScheduleReducer };