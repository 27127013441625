import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ContainerDropDown = styled.div`
  width: 144px;
  ${(props) =>
    props.n ? css`calc(${props.n} * 32px + 10px)` : 'height: 109px;'}
  margin-top: 5px;
  margin-bottom: 7px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
`;

export const ContainerOptions = styled.div`
  width: 135px;
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1ecec;
  ${(props) => props.last && 'border-bottom: none;'}
  cursor: pointer;
`;

export const TitleOptions = styled.span`
  margin: 0 0 0 8px;
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #455561;
  :hover {
    color: #25b379;
  }
`;
