import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Document } from 'src/Models/Document';
import { ResultPage } from 'src/Models/ResultPage';
import ImageApi from 'src/Services/Api/ImageApi';
import { getAgendaById } from '../agenda/actions';
import { getImageById, getImageByIdError, getImageByIdSuccess } from './action';
import { ImageTypes } from './types';

function* handleGetImageById(action: ReturnType<typeof getAgendaById>) {
  try {
    const result: AxiosResponse<Document> = yield call(ImageApi.getImageById, action.payload);

    if (result.status === 200 && result.data != null) {
      yield put(getImageByIdSuccess(result.data));
    } else {
      yield put(getImageByIdError(result.statusText));
    }
  } catch(error) {
    if (error instanceof Error && error.stack) {
      yield put(getImageByIdError(error.stack));
    } else {
      yield put(getImageByIdError('Ocorreu um erro ao carregar a imagem !'));
    }
  }
};

function* watchGetImageById() {
  yield takeLatest(ImageTypes.GET_IMAGE_BY_ID, handleGetImageById);
}

function* imageSaga() {
  yield all(
    [
      fork(watchGetImageById)
    ]
  )
}

export default imageSaga;