import { AxiosResponse } from 'axios';
import { api } from '.';
import {
  ConferenceSession,
  ConferenceSessionGetOrCreateBody,
} from '../../Models/ConferenceSession';

const baseUrl = '/conference';

export const ConferenceApi = () => {
  const getOrCreateConferenceSession = (
    conference: ConferenceSessionGetOrCreateBody,
  ): Promise<AxiosResponse<ConferenceSession>> => {
    return api.post<ConferenceSession, AxiosResponse<ConferenceSession>>(
      `${baseUrl}/activity-schedule/${conference?.activityScheduleId}`,
    );
  };

  return {
    getOrCreateConferenceSession,
  };
};

export default ConferenceApi();
