import React from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { PieDataPartner } from 'src/Models/FinancialDashboard';
import { allColors, generateColor } from 'src/Utils/Utils';
import '../styles.scss';
interface PieDataRow {
  name?: string;
  count?: number;
  color?: string;
}
export interface GraphCardProps extends WithTranslation {
  pieData?: PieDataPartner[];
}

const GraphCard: React.FC<GraphCardProps> = ({ t, pieData }) => {
  const colors = allColors;
  const formatedActivities =
    pieData?.map((item, index) => {
      return {
        name: item.name,
        value: item.count,
        color: item?.color ?? colors[index],
      };
    }) ?? [];
  const data = {
    datasets: [
      {
        data: formatedActivities?.map((item) => item.value),
        backgroundColor: formatedActivities?.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card className="BigCard Graph">
      <div className="BigCard--title">Tipos de Atividades</div>
      <div className="BigCard__Graph">
        <div className="BigCard__Graph--Details">
          {formatedActivities?.map((item) => (
            <div
              key={item.name}
              className="BigCard__Graph--Details__activity"
              style={{ backgroundColor: item.color }}>
              {item.name}
            </div>
          ))}
        </div>
        <div className="BigCard__Graph--Chart">
          <Doughnut data={data} />
        </div>
      </div>
      {/* <Link to="/b/wallet/extract" className="BigCard--link">
        Ver tudo
      </Link> */}
    </Card>
  );
};

export default withTranslation()(GraphCard);
