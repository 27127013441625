import { AxiosResponse } from 'axios';
import { all, call, put, fork, takeLatest, delay } from 'redux-saga/effects';
import { Agenda } from 'src/Models/Agenda';
import { ResultPage } from 'src/Models/ResultPage';
import AgendaApi from 'src/Services/Api/AgendaApi';
import { error, success } from 'src/Services/ToastService';
import { deleteActivitySuccess } from '../activity/actions';
import { getAllActivitiesSchedules } from '../schedule/actions';
import {
  deleteAgenda,
  deleteAgendaError,
  deleteAgendaSuccess,
  getAgenda,
  getAgendaById,
  getAgendabyIdError,
  getAgendaByIdSuccess,
  getAgendaError,
  getAgendaSuccess,
  postAgenda,
  postAgendaError,
  postAgendaSuccess,
  putAgenda,
  putAgendaError,
  putAgendaSuccess,
} from './actions';
import { AgendaActionTypes } from './types';

function* handleGetAgenda() {
  try {
    const result: AxiosResponse<ResultPage<Agenda>> = yield call(
      AgendaApi.getAgenda,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getAgendaSuccess(result.data.content));
    } else {
      yield put(getAgendaError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getAgendaError(error.stack));
    } else {
      yield put(getAgendaError('Um erro inesparado aconteceu'));
    }
  }
}

function* handleGetAgendaById(action: ReturnType<typeof getAgendaById>) {
  try {
    const result: AxiosResponse<any> = yield call(
      AgendaApi.getAgendaById,
      action.payload,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getAgendaByIdSuccess(result?.data));
    } else {
      yield put(getAgendabyIdError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getAgendabyIdError(error.stack));
    } else {
      yield put(getAgendabyIdError('Um erro inesperado aconteceu'));
    }
  }
}

function* handlePostAgenda(action: ReturnType<typeof postAgenda>) {
  let result: AxiosResponse<Agenda> | null;
  const errorMessage = 'Erro ao criar agenda';

  try {
    result = yield call(AgendaApi.postAgenda, action.payload);
    if (result?.status === 200 || result?.status === 201) {
      yield delay(2000);
      success('Agenda criada com sucesso!');
      yield put(getAgenda());
      yield put(postAgendaSuccess());
    } else {
      error(errorMessage);
      yield put(postAgendaError(errorMessage));
    }
  } catch (error) {
    error(result.statusText);
    yield put(postAgendaError(errorMessage));
  }
}

function* handlePutAgenda(action: ReturnType<typeof putAgenda>) {
  let result: AxiosResponse<Agenda> | null;
  const errorMessage = 'Erro ao editar agenda';

  try {
    result = yield call(AgendaApi.putAgenda, action.payload);
    if (result?.status === 200) {
      yield delay(2000);
      success('Agenda editada com sucesso!');
      yield put(getAgenda());
      yield put(putAgendaSuccess());
      yield put(getAllActivitiesSchedules());
    } else {
      error(errorMessage);
      yield put(putAgendaError(errorMessage));
    }
  } catch (error) {
    error(result.statusText);
    yield put(putAgendaError(errorMessage));
  }
}

function* handleDeleteAgenda(action: ReturnType<typeof deleteAgenda>) {
  let result: AxiosResponse<Agenda> | null;

  try {
    result = yield call(AgendaApi.deleteAgenda, action.payload);

    if (result?.status === 200) {
      yield put(getAgenda());
      yield put(deleteAgendaSuccess());
      yield put(getAllActivitiesSchedules());
      success('Agenda excluida com sucesso!');
    } else {
      console.log('error on sagas');
      yield put(deleteAgendaError('Erro ao remover atividade'));
      error('Error ao remover ativiadade');
    }
  } catch (error) {
    error('Erro ao deletar agenda');
  }
}

function* watchGetAgenda() {
  yield takeLatest(AgendaActionTypes.GET_AGENDA, handleGetAgenda);
}

function* watchGetAgendaById() {
  yield takeLatest(AgendaActionTypes.GET_AGENDA_BY_ID, handleGetAgendaById);
}

function* watchPostAgenda() {
  yield takeLatest(AgendaActionTypes.POST_AGENDA, handlePostAgenda);
}

function* watchPutAgenda() {
  yield takeLatest(AgendaActionTypes.PUT_AGENDA, handlePutAgenda);
}

function* watchDeleteAgenda() {
  yield takeLatest(AgendaActionTypes.DELETE_AGENDA, handleDeleteAgenda);
}

function* agendaSaga() {
  yield all([
    fork(watchGetAgenda),
    fork(watchGetAgendaById),
    fork(watchPostAgenda),
    fork(watchPutAgenda),
    fork(watchDeleteAgenda),
  ]);
}

export default agendaSaga;
