import { Partner } from 'src/Models/Partner';
import { Customer } from 'src/Models/Customer';
import { EditPasswordParams } from 'src/Models/edit-password';
import { User } from 'src/Models/user';
import { action } from 'typesafe-actions';
import { Authentication } from '../../Models/authentication';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';
import { AuthenticationActionTypes } from './types';

export const loginRequest = (authenticate: Authentication) =>
  action(AuthenticationActionTypes.LOGIN_REQUEST, authenticate);
export const loginSuccess = () =>
  action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginError = (error: HttpResponseError) =>
  action(AuthenticationActionTypes.LOGIN_ERROR, error);
export const loginResetStatus = () =>
  action(AuthenticationActionTypes.LOGIN_RESET_STATUS);

export const getSessionRequest = () =>
  action(AuthenticationActionTypes.GET_SESSION_REQUEST);
export const getSessionSuccess = (customer: Partner) =>
  action(AuthenticationActionTypes.GET_SESSION_SUCCESS, customer);
export const getSessionError = (message: string) =>
  action(AuthenticationActionTypes.GET_SESSION_ERROR, message);

export const logoutRequest = () =>
  action(AuthenticationActionTypes.LOGOUT_REQUEST);

export const passwordChangeSuccess = () =>
  action(AuthenticationActionTypes.CHANGE_PASSWORD_SUCCESS);
export const passwordChangeError = (message: string) =>
  action(AuthenticationActionTypes.CHANGE_PASSWORD_ERROR, message);
export const passwordChange = (changeBody: EditPasswordParams) =>
  action(AuthenticationActionTypes.CHANGE_PASSWORD, changeBody);

export const forgotPassword = (email?: string) =>
  action(AuthenticationActionTypes.FORGOT_PASSWORD, email);
export const forgotPasswordSuccess = () =>
  action(AuthenticationActionTypes.FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordError = () =>
  action(AuthenticationActionTypes.FORGOT_PASSWORD_ERROR);

export const forgotPasswordResetStatus = () =>
  action(AuthenticationActionTypes.FORGOT_PASSWORD_RESET_STATUS);
