import './App.scss';
import React, { useEffect, useState } from 'react';
import './Themes/_toasts.scss';
import { compose } from 'redux';
import { api } from './Services/Api';
import { IRootState } from './Stores';
import { connect } from 'react-redux';
import {
  getSessionRequest,
  logoutRequest,
} from './Stores/authentication/actions';
import AuthUtils from './Utils/AuthUtils';
import AppRoutes from './Routes/AppRoutes';
import 'react-toastify/dist/ReactToastify.css';
import FixedHeader from './Components/FixedHeader';
import { toast, ToastContainer } from 'react-toastify';
import { HttpRequestStatus } from './Models/Enums/HttpRequestStatus';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import LoadingContainerScreen from './Components/loading-container-screen/loading-container-screen';
import moment from 'moment';
import 'moment/locale/pt-br';

const baseHref = '/';

interface AppProps extends StateProps, DispatchProps {}

const App: React.FC<AppProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  moment.locale('pt-br');

  const { logout, partner, getSession, isAuthenticated, getSessionStatus } =
    props;

  useEffect(() => {
    if (AuthUtils.isAuthenticated()) getSession();
    else setIsLoading(false);
  }, [getSession, setIsLoading]);

  useEffect(() => {
    if (getSessionStatus === HttpRequestStatus.SUCCESS) {
      isLoading && setIsLoading(false);

      api.defaults.headers.partnerId = partner?.id;
    }

    if (getSessionStatus === HttpRequestStatus.ERROR) {
      logout();
      isLoading && setIsLoading(false);
    }
  }, [getSessionStatus, partner, setIsLoading, isLoading, logout]);

  const renderApp = (appProps: RouteComponentProps<any>) => {
    const notAllowedPathname: string[] = ['/register', '/'];
    const checkIfIsAuthenticated =
      isAuthenticated &&
      !notAllowedPathname.includes(appProps.location.pathname);

    return (
      <div className={'App'}>
        <LoadingContainerScreen isLoading={isLoading}>
          {checkIfIsAuthenticated &&
            appProps.location.pathname !== '/makepurchase/history/user' && (
              <FixedHeader {...props} {...appProps} />
            )}

          <Route
            path={'/'}
            component={() => (
              <AppRoutes
                {...appProps}
                isAuthenticated={checkIfIsAuthenticated}
              />
            )}
          />
        </LoadingContainerScreen>
      </div>
    );
  };

  return (
    <div className={'page-container'}>
      <BrowserRouter basename={baseHref}>
        <Route render={renderApp} />
      </BrowserRouter>
      <ToastContainer
        draggable
        rtl={false}
        closeOnClick
        pauseOnHover
        autoClose={5000}
        newestOnTop={false}
        className={'toast-wrapper'}
        position={toast.POSITION.TOP_RIGHT}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  account: state.authentication.account,
  partner: state.authentication.partner,
  isAuthenticated: state.authentication.isAuthenticated,
  getSessionStatus: state.authentication.getSessionStatus,
});

const mapDispatchToProps = {
  logout: logoutRequest,
  getSession: getSessionRequest,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  App,
) as React.ComponentType<any>;
