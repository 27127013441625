import React, { useState } from 'react';
import { Modal, Popover } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

interface Props {
  children: React.ReactNode;
  color?: string;
}

const VideoTutorialIcon = ({ children, color }: Props) => {
  const [anchorEl, setAnchorElement] = useState(null);
  const [popOverOpen, setPopOverOpen] = useState(false);

  return (
    <div className="tutorial-help">
      <HelpOutline
        style={{ cursor: 'pointer', color: color ?? 'black' }}
        onClick={(e) => {
          setAnchorElement(e.currentTarget);
          setPopOverOpen(true);
        }}
      />
      <Popover
        id="mouse-over-popover-tutorial-help"
        style={{
          maxWidth: '94vw',
        }}
        open={popOverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopOverOpen(false)}
        disableRestoreFocus>
        {children}
      </Popover>
    </div>
  );
};

export default VideoTutorialIcon;
