import './styles.scss';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface NameProps extends WithTranslation {
  title: string;
  inputName: string;
  placeholder: string;
  required?: boolean;
  register: (inputName: string, requirements: Object) => void;
}

const Name: React.FC<NameProps> = ({
  t,
  title,
  placeholder,
  register,
  inputName,
  required,
}) => {
  return (
    <div className="col-md-6 col-sm-12 col-xs-12 nameContainer">
      <div className="card-model card-model-x">
        <span className="value-title">{title}</span>
        <div className="setSpace">
          <input
            {...register(inputName, {
              required: required != null ? required : true,
            })}
            type="text"
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Name);
