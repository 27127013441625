import { api } from '.';
import { AxiosResponse } from 'axios';
import { PartnerClient } from '../../Models/PartnerClient';
import { Customer } from 'src/Models/Customer';
import { Address } from 'src/Models/Address';
import { PageableResponse } from 'src/Models/pageable';
import { Filters } from '../Plan.service';
import { HistoryPaymentsUser } from 'src/Models/historyPayments';
import moment from 'moment';

interface Response {
  content: Customer[];
}

export interface FilterParams {
  page?: number;
  size?: number;
  type?: string;
  search?: string;
  fromDate?: string;
  toDate?: string;
  whichDateToFilter?: string;
}

interface SaveEdit {
  id: number;
  cpf?: string;
  address?: Address;
}

export const PartnerClientApi = () => {
  const getPartnerClients = (
    size: number,
    page?: number,
    filters?: Filters,
  ): Promise<AxiosResponse<PageableResponse<Customer>>> => {
    return api.get<PageableResponse<Customer>>(
      `/customer/find-partner-history?size=${size}&page=${page}${
        filters?.order ?? ''
      }`,
    );
  };

  const saveEditPartnerClient = (obj: SaveEdit) => {
    return api.post('/customers/cpf-address', obj);
  };

  const getAddressPartner = (street: string) => {
    return api.get(`/address/geolocalize?address=${street}`);
  };

  const getPaymentsHistory = (
    id: string,
  ): Promise<AxiosResponse<PageableResponse<HistoryPaymentsUser>>> => {
    return api.get<PageableResponse<HistoryPaymentsUser>>(
      `billings/customer/${id}/history`,
    );
  };

  const requestPartnerWithDraw = ({ id, value }): Promise<AxiosResponse> => {
    return api.post(`partner-place-financial-areas/${id}/withdraw`, {
      value,
    });
  };

  const handleExtractParams = (filter?: FilterParams): string => {
    let params = '';

    if (filter?.page) {
      params += `&page=${filter.page}`;
    }

    if (filter?.size) {
      params += `&size=${filter.size}`;
    }

    if (filter?.type) {
      params += `&type=${filter.type}`;
    }

    if (filter?.search) {
      params += `&search=${filter.search}`;
    }

    if (filter?.fromDate != '' && filter.fromDate != 'Data inválida') {
      params += `&fromDate=${filter.fromDate}`;
    }

    if (filter?.toDate != '' && filter.toDate != 'Data inválida') {
      params += `&toDate=${filter.toDate}`;
    }

    if (filter?.whichDateToFilter) {
      params += `&whichDateToFilter=${filter.whichDateToFilter}`;
    }

    return params;
  };

  const getExtractTableInExcel = async (id?: number, filter?: FilterParams) => {
    const paramsString = handleExtractParams(filter);

    const response = await api.get(
      `/partner-place/${id}/extract/xlsx?${paramsString}`,
      {
        responseType: 'blob',
        timeout: 600000,
      },
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/vnd.ms-excel' }),
    );

    const date = moment().format('YYYY-MM-DD_HH:mm:ss');

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', `extrato_${date}.xlsx`);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return {
    getPartnerClients,
    saveEditPartnerClient,
    getAddressPartner,
    getPaymentsHistory,
    requestPartnerWithDraw,
    getExtractTableInExcel,
  };
};

export default PartnerClientApi();
