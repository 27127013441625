import { useDropzone } from 'react-dropzone';
import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { Document } from 'src/Models/Document';
import StringUtils from 'src/Utils/StringUtils';
import { error } from 'src/Services/ToastService';
import './styles.scss';

export interface DropzonePDF extends WithTranslation {
  watch: any;
  setValue: any;
  verifyAndEnableSave?: () => void;
  register: (name: string, requirements: Object) => void;
}

const DropzonePDF: React.FC<DropzonePDF> = ({
  t,
  watch,
  register,
  setValue,
  verifyAndEnableSave = () => null,
}) => {
  const pdfContract = watch('enrolmentContract') as Document;
  const changePdfRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    register('image', { required: true });
  }, [register]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['.pdf', 'image/*'],
    // maxSize: 10485760,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }

        const newFilePDF = acceptedFiles?.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            file: encoded,
          });
        })[0] as File;

        const newObjectTosetValue = {
          ...newFilePDF,
          contentType: newFilePDF.type,
          fileName: newFilePDF.name,
          isEdited: true,
        };

        setValue('enrolmentContract', newObjectTosetValue);

        verifyAndEnableSave();
      };
    },
    onDropRejected: (fileRejections) => {
      error(t('plans.contract.typeError'));
    },
  });

  const handleEditPdf = () => {
    const file = changePdfRef.current?.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }

      const newFilePDF = file;

      const newObjectTosetValue = {
        ...newFilePDF,
        contentType: newFilePDF.type,
        fileName: newFilePDF.name,
        isEdited: true,
      };

      setValue('enrolmentContract', newObjectTosetValue);

      verifyAndEnableSave();
    };
  };

  return (
    <div className="pdf-dropzone-container">
      <span className="value-title inputTitle">
        {t('plans.contract.title')}
      </span>
      {!pdfContract?.fileName ? (
        <>
          <div className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} type="file" />
            <div>
              <div className="">{t('plans.contract.subtitle')}</div>
              <UploadFileIcon fontSize="large" />
            </div>
          </div>
        </>
      ) : (
        <div className="uploaded-pdf">
          <PictureAsPdfIcon fontSize="large" />
          <span>
            {StringUtils.limitString(pdfContract?.fileName || '', 30)}
          </span>
          <input
            ref={changePdfRef}
            type="file"
            accept=".pdf"
            onChange={handleEditPdf}
            style={{ display: 'none' }}
          />
          <button
            type="button"
            className="pdf-btn"
            onClick={() => changePdfRef.current.click()}>
            {t('plans.contract.upload')}
          </button>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(DropzonePDF);
