import { Reducer } from 'redux';
import { ActivityCategory } from 'src/Models/ActivityCategory';
import { ActivitySubCategory } from 'src/Models/ActivitySubCategory';
import { CategoryActionTypes, CategoryState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: CategoryState = {
  categories: [],
};

const reducer: Reducer<CategoryState> = (state = initialState, action) => {
  switch (action.type) {
   case CategoryActionTypes.GET_CATEGORIES_REQUEST: {
      return {...state, categoriesStatus: HttpRequestStatus.ONGOING};
   }
   case CategoryActionTypes.GET_CATEGORIES_REQUEST_SUCCESS:{
     const categories = action?.payload as ActivityCategory[];

     return {...state, categories, categoriesStatus: HttpRequestStatus.SUCCESS};
   }
   case CategoryActionTypes.GET_CATEGORIES_REQUEST_ERROR: {
    return { ...state, categoriesStatus: HttpRequestStatus.ERROR, categoriesError: action?.payload };
   }
   case CategoryActionTypes.GET_SUBCATEGORIES_REQUEST: {
      return {...state, subCategoriesStatus: HttpRequestStatus.ONGOING};
   }
   case CategoryActionTypes.GET_SUBCATEGORIES_REQUEST_SUCCESS:{
     const subCategories = action?.payload as ActivitySubCategory[];

     return {...state, subCategories, subCategoriesStatus: HttpRequestStatus.SUCCESS};
   }
   case CategoryActionTypes.GET_SUBCATEGORIES_REQUEST_ERROR: {
    return { ...state, subCategoriesStatus: HttpRequestStatus.ERROR, subCategoriesError: action?.payload };
   }
    default: {
      return state;
    }
  }
}

export { reducer as categoryReducer };