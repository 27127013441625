import { AxiosResponse } from 'axios';
import {
  FinancialDashboard,
  PieDataPartner,
} from 'src/Models/FinancialDashboard';
import { PartnerDashboard } from 'src/Models/PartnerDashboard';
import { api } from '.';

const PartnerDashboardApi = () => {
  const getPartnerDashboard = (): Promise<AxiosResponse<PartnerDashboard>> => {
    return api.get<PartnerDashboard>(`/partner-dashboard`);
  };

  const getFinancialDashboard = (): Promise<
    AxiosResponse<FinancialDashboard>
  > => {
    return api.get<FinancialDashboard>(`/partner-places/balance`);
  };

  const getPartnerActivitiesDashboard = () => {
    return api.get<PieDataPartner[]>('/partner-places/activities-dashboard');
  };

  return {
    getPartnerDashboard,
    getFinancialDashboard,
    getPartnerActivitiesDashboard,
  };
};

export default PartnerDashboardApi();
