import moment from 'moment';
import { presetScheduleObject } from 'src/Utils/Plan/constants';
import { ActivityCategory } from './ActivityCategory';
import { ActivitySubCategory } from './ActivitySubCategory';
import { Address } from './Address';
import { DaysOfWeek } from './Agenda';
import { Document } from './Document';
import { EnterpriseData } from './Profile';
import { PriceTableActivity } from './PriceTableActivity';

export interface Plan {
  id?: number;
  type?: ActivityPlaceType | string;
  name?: string;
  period: ActivityPlanRenewingPeriod | string;
  status: ActivityStatus | string;
  price?: number;
  slots?: number;
  free?: boolean;
  enabledB2b?: boolean;
  image?: Document;
  teacher?: string;
  duration?: number;
  address?: Address;
  approved?: boolean;
  description?: string;
  enrolmentFee?: number;
  maintenanceFee?: number;
  partnerPlace?: EnterpriseData;
  activityCategories?: ActivityCategory[];
  activitySubCategories?: ActivitySubCategory[];
  presetActivityPlanSchedules?: PresetActivityPlanSchedules[];
  isEnrolmentNeeded?: boolean;
  isRurrenceNeeded?: boolean;
  typeDescription?: string;
  isRecurrenceNeeded?: boolean;
  typeOfRecurrenceOff?: TypeOfRecurrenceOff | null;
  minTimeTurnRecurrenceOff?: number;
  enrolmentContract?: Document;

  // on create plan form to set priceTables
  priceWithDiscount?: number;
  partnerProfit?: number;
  priceWithFee?: number;

  // on create plan submit data
  priceTableActivities?: PriceTableActivity[];
}

export enum TypeOfRecurrenceOff {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}
export class ActivityPlaceTypeUtils {
  // TODO - online
  static getLabel = (type: ActivityPlaceType | string) => {
    if (type == ActivityPlaceType.ONLINE) {
      return 'Atividade online';
    } else if (type == ActivityPlaceType.IN_COMPANY) {
      return 'Atividade na empresa';
    } else if (type === ActivityPlaceType.TO_CUSTOMER) {
      return 'Vou até você';
    } else {
      return 'Atividade presencial';
    }
  };
}

export interface PresetActivityPlanSchedules {
  id?: number;
  zoneId?: string;
  dayOfWeek?: DaysOfWeek | string;
  startAt?: string;
  duration?: number;
}

export enum ActivityStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum ActivityPlaceType {
  IN_COMPANY = 'IN_COMPANY',
  IN_PLACE = 'IN_PLACE',
  ONLINE = 'ONLINE',
  TO_CUSTOMER = 'TO_CUSTOMER',
}
export enum ActivityPlanRenewingPeriod {
  MONTH_PERIOD = 'MONTH_PERIOD',
  TWO_MONTHS_PERIOD = 'TWO_MONTHS_PERIOD',
  THREE_MONTHS_PERIOD = 'THREE_MONTHS_PERIOD',
  SIX_MONTHS_PERIOD = 'SIX_MONTHS_PERIOD',
  YEARLY = 'YEARLY',
}

export enum App {
  DOWNLOAD_APP = 'Download_App',
}

export class PresetActivityPlanSchedulesUtils {
  static transform = (
    presetActivityPlanSchedules: PresetActivityPlanSchedules[],
  ) => {
    const presetObject = presetActivityPlanSchedules?.reduce(
      (obj, item) => ({
        ...obj,
        [item['dayOfWeek'].toLowerCase()]: [
          ...(obj[item['dayOfWeek'].toLowerCase()] || []),
          item,
        ],
      }),
      {},
    );

    let presetFinalObject: typeof presetScheduleObject = {
      ...presetScheduleObject,
    };

    Object?.keys(presetObject)?.forEach((weekDay) => {
      const currentDay = presetObject[weekDay] || [];
      if (currentDay.length > 1) {
        const orderedDays = currentDay.sort(function (a, b) {
          return moment(a.startAt, 'HH:mm').isAfter(moment(b.startAt, 'HH:mm'));
        });

        const first = orderedDays[0] || {};
        const second = orderedDays[1] || {};

        const lunchTime = moment(first?.startAt, 'HH:mm')
          .add(first?.duration, 'minutes')
          .format('HH:mm');

        const endTime = moment(second?.startAt, 'HH:mm')
          .add(second?.duration, 'minutes')
          .format('HH:mm');

        presetFinalObject = {
          ...presetFinalObject,
          [weekDay]: {
            ...first,
            startAt: moment(first?.startAt, 'HH:mm').format('HH:mm'),
            startLunch: lunchTime,
            endLunch: moment(second?.startAt, 'HH:mm').format('HH:mm'),
            endAt: endTime,
            dontWork: false,
          },
        };
      } else {
        presetFinalObject = {
          ...presetFinalObject,
          [weekDay]: {
            ...(currentDay[0] || {}),
            startAt: moment(currentDay[0].startAt, 'HH:mm').format('HH:mm'),
            endAt: moment(currentDay[0]?.startAt, 'HH:mm')
              .add(currentDay[0].duration, 'minutes')
              .format('HH:mm'),
            dontWork: false,
          },
        };
      }
    });

    return presetFinalObject;
  };
}

export interface PriceComposite {
  price: number;
  discountValue: number;
  discountPercentage: number;
  subsidyValue?: number;
  subsidyPercentage?: number;
  priceWithDiscount: number;
  priceWithDiscountAndSubsidy: number;
}

export enum StatusPreScheduleByPartner {
  APPROVED = 'APPROVED',
  IN_PROCESS = 'IN_PROCESS',
  DENIED = 'DENIED',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  NONE = 'NONE',
  PRE_SCHEDULED = 'PRE_SCHEDULED',
  PRE_SCHEDULE_BY_PARTNER = 'PRE_SCHEDULE_BY_PARTNER',
  REQUEST_EDIT_BY_PARTNER = 'REQUEST_EDIT_BY_PARTNER',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum TypeCategory {
  REGULAR = 'REGULAR',
  CHALLENGE = 'CHALLENGE',
}

export enum TypeNotification {
  SCHEDULE_EDITED = 'SCHEDULE_EDITED',
  SCHEDULE_CREATED = 'SCHEDULE_CREATED',
  SCHEDULE_CANCELED = 'SCHEDULE_CANCELED',
  OTHER = 'OTHER',
}

export enum TypeLocal {
  IN_COMPANY = 'IN_COMPANY',
  IN_PLACE = 'IN_PLACE',
  ONLINE = 'ONLINE',
}

export enum Status {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  EXPIRED = 'EXPIRED',
}

export interface PreScheduleByPartner {
  id: number;
  type: string;
  customer: {
    id: number;
    name: string;
    user: {
      id: number;
      email: string;
    };
  };
  customerActivity: {
    id: number;
    customer: {
      id: number;
      name: string;
    };
    status: StatusPreScheduleByPartner;
    activitySchedule: {
      id: number;
      date: Date;
      price: number;
      activity: {
        id: number;
        name: string;
        price: number;
      };
    };
    activityCategory: {
      id: number;
      name: string;
      type: TypeCategory;
      image: {
        id: number;
        imageUrl: string;
        contentType: string;
        fileName: string;
      };
      color: string;
    };
    activitySubCategory: {
      id: number;
      name: string;
      image: {
        id: number;
        imageUrl: string;
        contentType: string;
        fileName: string;
      };
    };
  };
  customerActivityPlan: {
    id: number;
    activityPlan: {
      id: number;
      name: string;
      price: number;
    };
    price: number;
    autoRenewal: boolean;
    status: StatusPreScheduleByPartner;
    activityCategory: {
      id: number;
      name: string;
    };
    activitySubCategories: {};
    startPeriodDate: Date;
  };
  customerActivityRescheduled: {
    id: number;
    price: number;
    token: string;
    tokenUsed: boolean;
    activitySchedule: {
      id: number;
      date: Date;
      price: number;
      activity: {
        id: number;
      };
    };
    activityCategory: {
      id: number;
      name: string;
      type: TypeCategory;
      image: {
        id: number;
        imageUrl: string;
        contentType: string;
        fileName: string;
      };
      color: string;
    };
  };
  notificationType: TypeNotification;
  createdDate: Date;
  presetRenewalActivitySchedule: {
    id: number;
    zoneId: string;
    dayOfWeek: DaysOfWeek;
    startAt: Date;
    lastScheduleDate: Date;
    intervalWeek: number;
    activity: {
      id: number;
      name: string;
      image: {
        imageUrl: string;
        contentType: string;
      };
      partnerPlace: {
        id: number;
        fantasyName: string;
        image: {
          id: number;
          contentType: string;
          fileName: string;
          imageUrl: string;
        };
        customUrl: string;
      };
      address: {
        id: number;
        geoPoint: {
          latitude: number;
          longitude: number;
          distance: number;
        };
        street: string;
        number: string;
        zipcode: string;
        district: string;
        complement: string;
        city: {
          id: number;
          name: string;
          state: {
            id: number;
            name: string;
            acronym: string;
          };
        };
      };
      price: number;
      type: TypeLocal;
      teacher: string;
    };
    activityCategory: {
      id: number;
      name: string;
      type: TypeCategory;
      image: {
        id: number;
        imageUrl: string;
        contentType: string;
        fileName: string;
      };
      color: string;
    };
    activitySubCategory: {
      id: number;
      name: string;
      image: {
        id: number;
        imageUrl: string;
        contentType: string;
        fileName: string;
      };
    };
    status: StatusPreScheduleByPartner;
  };
  status: Status[];
}

export interface ResponsePreScheduleByPartner {
  content: PreScheduleByPartner[];
  totalPages: number;
}
