import React, { useEffect, useState } from 'react';
import GraphCard from 'src/Components/BigCardInfo/GraphCard';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { styled } from '@mui/material/styles';
import PaidIcon from '@mui/icons-material/Paid';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import StringUtils from 'src/Utils/StringUtils';
import _ from 'lodash';
import { PieDataPartner } from 'src/Models/FinancialDashboard';
import SimpleModal from 'src/Components/SimpleModal';
import CurrencyInput from 'react-currency-input';
import './styles.scss';
import PartnerClientService from 'src/Services/PartnerClient.service';
import { toast } from 'react-toastify';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import TopCategoryCard from '../TopCategoryCard';
import InfoTooltip from '../../InfoTooltip';
import CircleLoading from 'src/Components/circleLoading';
import {
  getFinancialDashboard,
  getPieData,
} from 'src/Stores/partnerDashboard/actions';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import moment from 'moment';

interface OverviewContentProps extends StateProps, DispatchProps {
  setActiveTab?: any;
  setCorrespondentExtract?: (value: boolean) => void;
}

const OverviewContent: React.FC<OverviewContentProps> = ({
  financialDashboard,
  setActiveTab,
  profile,
  pieDataList: pieData,
  getPieDataStatus,
  getFinancialDashboard,
  getFinancialDashboardStatus,
  getPieData,
  setCorrespondentExtract,
}) => {
  const [withDrawModal, setWithDrawModal] = useState<boolean>(false);
  const [withDrawValue, setWithDrawValue] = useState<number>(0);
  const [handleRequestLoading, setHandleRequestLoading] =
    useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();

  const {
    waitingWithDrawBalance = 0,
    scheduledWithDrawBalance = 0,
    currentBalance = 0,
    scheduledBalance = 0,
    blockedBalance = 0,
  } = financialDashboard ?? {};

  useEffect(() => {
    getFinancialDashboard();
    getPieData();
  }, []);

  const scheduleWithDraw = waitingWithDrawBalance + scheduledWithDrawBalance;
  const availableBalance = currentBalance - scheduleWithDraw;

  function findTop2Categories() {
    const topCategories: PieDataPartner[] = [];
    //find firstItem

    const firstItem = _.maxBy(pieData, 'count');

    topCategories.push(firstItem);

    //find secondItem
    const secondItem = _.maxBy(pieData, function (o) {
      if (firstItem.id == o.id) return 0;
      return o.count;
    });
    topCategories.push(secondItem);

    return topCategories;
  }

  const top2categories = findTop2Categories();

  useEffect(() => {
    setWithDrawValue(availableBalance);
  }, []);

  const params = new URLSearchParams(search);
  const openModalWithDraw = params.get('openModalWithDraw');
  useEffect(() => {
    setTimeout(() => {
      openModalWithDraw && setWithDrawModal(true);
    }, 150);
  }, []);

  const goToModalPix = () => {
    history.push('/profile?editModalpix=true');
  };

  const handleRequestWithdraw = () => {
    setHandleRequestLoading(true);

    if (withDrawValue === 0) {
      setHandleRequestLoading(false);
      toast.error('Insira um valor válido');
      return;
    }

    if (availableBalance < withDrawValue) {
      setHandleRequestLoading(false);
      toast.error('Saldo inferior ao valor solicitado');
      return;
    }

    const objToSend = {
      id: profile.id,
      value: withDrawValue,
    };

    toast.warn('Solicitar saque indisponível no momento');
    // PartnerClientService.requestPartnerWithDraw(objToSend)
    //   .then(() => {
    //     toast.success('Sua solicitação foi enviada com sucesso');
    //     setHandleRequestLoading(false);
    //     setWithDrawModal(false);
    //   })
    //   .catch((err) => {
    //     if (err?.code === 'pix.account.not.found') {
    //       setTimeout(() => {
    //         setHandleRequestLoading(false);
    //         setWithDrawModal(false);
    //         goToModalPix();
    //       }, 800);
    //     } else {
    //       toast.error('Erro ao solicitar saque');
    //       setWithDrawModal(false);
    //     }
    //   });
  };

  const dayOfToday = moment().toDate().getDate();

  const nextPaymentDate = moment()
    .date(15)
    .add(dayOfToday >= 15 ? 1 : 0, 'months')
    .format('DD/MM');

  const futurePaymentDate = moment()
    .date(15)
    .add(dayOfToday >= 15 ? 2 : 1, 'months')
    .format('DD/MM');

  const handleSeeCorrespondentExtract = () => {
    setCorrespondentExtract(true);
    setActiveTab('extract');
  };

  return (
    <div>
      <div className="cardsContainer">
        <div className="col-6 p-10">
          <div className="card-model">
            <div className="space-beetween">
              <div>
                <span className="moneyText">
                  {t('finances.balance')}
                  <span
                    style={{
                      color: '#8d979e',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}>
                    {` - ( ${nextPaymentDate} )`}
                    <InfoTooltip
                      title={
                        dayOfToday < 15
                          ? t('finances.currentBalanceTooltip', {
                              month: 'passado',
                            })
                          : t('finances.currentBalanceTooltip', {
                              month: 'atual, até o momento',
                            })
                      }
                    />
                  </span>
                </span>

                <span className="value-title">
                  {getFinancialDashboardStatus === HttpRequestStatus.ONGOING ? (
                    <CircleLoading />
                  ) : (
                    StringUtils?.currencyPtBr(availableBalance)
                  )}
                </span>
              </div>
              {scheduleWithDraw > 0 && (
                <HtmlTooltip
                  title={
                    <>
                      <h4 style={{ fontSize: '22px' }}>
                        {t('finances.requestedTooltip')}
                      </h4>
                      <p>{t('finances.p1Tooltip')}</p>
                      <p>{t('finances.p2Tooltip')}</p>
                    </>
                  }>
                  <div>
                    <span className="moneyText">{t('finances.requested')}</span>
                    <span style={{ color: 'orange' }} className="value-title">
                      {StringUtils?.currencyPtBr(scheduleWithDraw)}
                    </span>
                  </div>
                </HtmlTooltip>
              )}
              {/* <div>
                <button className="rescueCreditButton ">
                  <MonetizationOnOutlinedIcon /> &nbsp;Resgatar
                </button>
              </div> */}
            </div>
            <div className="footer-title">
              <div
                className="extract-link"
                onClick={() => handleSeeCorrespondentExtract()}>
                <span>{t('finances.seeCorrespondentExtract')}</span>
                <ChevronRightIcon className="pointer" />
              </div>
              {/* <div className="withdraw" onClick={() => setWithDrawModal(true)}>
                <span>{t('finances.withdraw')}</span>
                <PaidIcon className="pointer" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-3 p-10">
          <div className="card-model">
            <span className="moneyText">
              {t('finances.future')}
              <span
                style={{
                  color: '#8d979e',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}>
                {` - ( ${futurePaymentDate} )`}
              </span>
              <InfoTooltip
                title={
                  dayOfToday < 15
                    ? t('finances.futureBalanceTooltip', {
                        month: 'atual',
                      })
                    : t('finances.futureBalanceTooltip', {
                        month: 'que vem',
                      })
                }
              />
            </span>
            <span className="value-title">
              {getFinancialDashboardStatus === HttpRequestStatus.ONGOING ? (
                <CircleLoading />
              ) : (
                StringUtils?.currencyPtBr(scheduledBalance)
              )}
            </span>
            <div
              className="footer-title"
              onClick={() => setActiveTab('extract')}>
              <div className="extract-link">
                <span>{t('finances.seeExtract')}</span>
                <ChevronRightIcon className="pointer" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-10">
          <div className="card-model">
            <span className="moneyText">{t('finances.blocked')}</span>
            <span className="value-title">
              {getFinancialDashboardStatus === HttpRequestStatus.ONGOING ? (
                <CircleLoading />
              ) : (
                StringUtils?.currencyPtBr(blockedBalance)
              )}
            </span>
            <div className="footer-title">
              <div className="extract-link">
                <span>{t('finances.seeExtract')}</span>
                <ChevronRightIcon className="pointer" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="analysisTitle">
        {t('finances.activities.analisys', {
          month: new Date().toLocaleString('pt-BR', { month: 'long' }),
        })}
      </h2>
      {getPieDataStatus === HttpRequestStatus.ONGOING ? (
        <div
          style={{ display: 'flex', width: 'full', justifyContent: 'center' }}>
          <CircleLoading />
        </div>
      ) : _.isEmpty(pieData) ? (
        <div>
          <h2
            className="analysisTitle"
            style={{
              color: '#8d979e',
              marginTop: '0px',
            }}>
            {t('finances.activities.noActivities')}
          </h2>
        </div>
      ) : (
        <div style={{ display: 'flex', gap: '15px' }}>
          <div className="col-md-6 col-sm-12 col-xs-12 p-10">
            <GraphCard pieData={pieData} />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 p-10">
            <TopCategoryCard category={top2categories[0]} />
            {pieData?.length > 1 && (
              <TopCategoryCard category={top2categories[1]} />
            )}
          </div>
        </div>
      )}
      {/* <SimpleModal
        loading={handleRequestLoading}
        isOpen={withDrawModal}
        onConfirm={() => {
          handleRequestWithdraw();
        }}
        labelConfirm={'Solicitar'}
        modalTitle={'Qual o valor que deseja solicitar ?'}
        onCancel={() => {
          setWithDrawModal(false);
        }}
        labelCancel={'Voltar'}>
        <CurrencyInput
          className={'input'}
          title="Valor"
          placeholder={'Digite aqui o valor você deseja sacar...'}
          value={withDrawValue}
          onChangeEvent={(_, __, value) => setWithDrawValue(value)}
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          precision="2"
        />
      </SimpleModal> */}
    </div>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 260,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '10px 15px',
  },
}));

const mapStateToProps = (state: IRootState) => {
  const { profile } = state?.profile;
  const {
    financialDashboard,
    getFinancialDashboardStatus,
    pieDataList,
    getPieDataStatus,
  } = state.partnerDashboard;

  return {
    profile,
    financialDashboard,
    getFinancialDashboardStatus,
    pieDataList,
    getPieDataStatus,
  };
};

const mapDispatchToProps = {
  getFinancialDashboard,
  getPieData,
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OverviewContent);
