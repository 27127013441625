import { Reducer } from 'redux';
import { CustomerTokenTypes, CustomerTokenState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: CustomerTokenState = {
  error: null,
  status: null,
  tokenObj: null,
  customerActivity: null,
};

const reducer: Reducer<CustomerTokenState> = (state = initialState, action) => {
  switch (action.type) {
    case CustomerTokenTypes.GET_BY_TOKEN:
      return { ...state, status: HttpRequestStatus.ONGOING };
    
    case CustomerTokenTypes.GET_BY_TOKEN_SUCCESS: 
      return {
        ...state,
        customerActivity: action?.payload,
        status: HttpRequestStatus.SUCCESS,
      };
    
    case CustomerTokenTypes.GET_BY_TOKEN_ERROR:
      return {
        ...state,
        error: action?.payload,
        status: HttpRequestStatus.ERROR,
      };
    
    case CustomerTokenTypes.POST_USE_TOKEN:
      return {
        ...state,
        status:  HttpRequestStatus.ONGOING
      }

    case CustomerTokenTypes.POST_USE_TOKEN_SUCCESS:
      return {
        ...state,
        tokenObj: action.payload,
        status:  HttpRequestStatus.SUCCESS
      }

    case CustomerTokenTypes.POST_USE_TOKEN_ERROR:
      return {
        ...state,
        status:  HttpRequestStatus.ERROR
      }

    case CustomerTokenTypes.POST_USE_PLAN_TOKEN:
      return {
        ...state,
        status:  HttpRequestStatus.ONGOING
      }

    case CustomerTokenTypes.POST_USE_PLAN_TOKEN_SUCCESS:
      return {
        ...state,
        tokenObj: action.payload,
        status:  HttpRequestStatus.SUCCESS
      }

    case CustomerTokenTypes.POST_USE_PLAN_TOKEN_ERROR:
      return {
        ...state,
        status:  HttpRequestStatus.ERROR
      }
    default: return state;
  }
};

export { reducer as customerTokenReducer };
