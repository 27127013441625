import { Activity } from 'src/Models/Activity';
import { ActivityCategory } from 'src/Models/ActivityCategory';
import { ActivitySubCategory } from 'src/Models/ActivitySubCategory';

export const presetScheduleBody = {
  dayOfWeek: '',
  startAt: '',
};

export const presetScheduleObject = {
  monday: {
    ...presetScheduleBody,
  },
  tuesday: { ...presetScheduleBody },
  wednesday: { ...presetScheduleBody },
  thursday: { ...presetScheduleBody },
  friday: { ...presetScheduleBody },
  saturday: { ...presetScheduleBody },
  sunday: { ...presetScheduleBody },
};

export const defaultActivityBody: Activity = {
  id: 0,
  name: '',
  teacher: '',
  description: '',
  slots: 0,
  isFree: false,
  approved: true,
  duration: 0,
  activityCategories: [],
  activitySubCategories: [],
  address: {},
  enabledB2b: false,
  safetyTime: 0,
  free: false,
  presetActivitySchedules: { ...presetScheduleObject },
  image: {
    preview: '',
    id: 0,
    imageUrl: '',
    processed: false,
    width: 0,
    contentType: 'contentType',
    file: 'file',
    fileName: 'filename',
  },
  type: '',
  typeDescription: '',
  priceWithFee: 0,
  priceWithDiscount: 0,
  partnerProfit: 0,
};

export interface ActivityCategories {
  categories: ActivityCategory[];
  subCategories: ActivitySubCategory[];
}

export const defaultCategories = {
  categories: [],
  subCategories: [],
};
