import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from 'src/Components/Dropzone';
import './styles.scss';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { putEnterprisePage } from 'src/Stores/profile/actions';
import { useWidth } from 'src/Hooks/useWidth';

export interface ModalProps extends StateProps, DispatchProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const defaultData = {
  description: '',
  coverImage: {},
};

const EnterpriseModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  profile,
  putEnterprisePage,
}) => {
  const [data, setData] = useState(defaultData);

  const { register, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      image: null,
    },
  });
  var widthSize = useWidth();

  useEffect(() => {
    if (!profile?.id) return;

    setData({
      coverImage: profile?.coverImage,
      description: profile?.description,
    });

    setValue('image', {
      file: profile?.coverImage?.file,
      preview: profile?.coverImage?.imageUrl,
      fileName: profile?.coverImage?.fileName,
      contentType: profile?.coverImage?.contentType,
    });
  }, [profile, setValue]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name = '', value = '' } = e?.target;

    setData({ ...data, [name]: value });
  };

  const dropzoneProps = {
    watch,
    register,
    setValue,
    cropAspect: 3,
    minWidth: 1000,
    showSaveButton: false,
    editImageText: 'Trocar imagem de capa',
  };

  const onSubmit = ({ image: imageObj }) => {
    let image = imageObj?.image || imageObj;

    const putObj = {
      id: profile?.id,
      coverImage: {
        file: image?.file,
        fileName: image?.name || image?.fileName,
        contentType: image?.type || image?.contentType,
      },
      description: data?.description,
    };

    putEnterprisePage(profile?.id, putObj);
    handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="paper-editCompany">
          <div className="modalAlign-EditCompany">
            <div>
              <h2
                id="transition-modal-title"
                className="modalTitle-editCompany">
                {widthSize > 450 ? 'Editar página da empresa' : 'Editar'}
              </h2>
            </div>
            <div>
              <CloseIcon className="modalIcon" onClick={handleClose} />
            </div>
          </div>
          <div className="mb-20" />
          <div className="underline__enterprise--page"></div>
          <div className="mb-20" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modalBody__enterprise--page">
              <div className="dropzone">
                <Dropzone {...dropzoneProps} />
              </div>

              <h2 className="modalTitle__enterprise__Page">Sobre</h2>
              <div className="setSpace">
                <textarea
                  placeholder="Descreva aqui o seu trabalho"
                  name="description"
                  value={data?.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="footerContainer">
              <span onClick={handleClose} className="cancel">
                Cancelar
              </span>
              <button type="submit" className="button">
                {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
              </button>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile } = state?.profile;

  return { profile };
};

const mapDispatchToProps = {
  putEnterprisePage,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseModal);
