import { PresetAgendaActivitySchedule } from 'src/Models/Agenda';

export const checkIfIntervalSelectedSuportsAllPresetSchedulesAlreadyChosen = (
  interval: number,
  presetAgendaActivitySchedule: PresetAgendaActivitySchedule[],
) => {
  if (
    presetAgendaActivitySchedule === undefined ||
    presetAgendaActivitySchedule.length === 0
  ) {
    return true;
  }

  const getMinutes = (item: PresetAgendaActivitySchedule) =>
    Number(item?.startAt?.split(':')[1]);

  const minutes = presetAgendaActivitySchedule
    .map(getMinutes)
    .sort((a, b) => a - b);

  const isMultipleOfInterval = (minutes: number[], interval: number) =>
    minutes.every((item) => item % interval === 0);

  return isMultipleOfInterval(minutes, interval);
};
