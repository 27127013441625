import { AxiosResponse } from 'axios';
import { Plan } from 'src/Models/Plan';
import {
  all,
  call,
  delay,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import PlanApi from 'src/Services/Api/PlanApi';
import {
  postPlan,
  getPlansSuccess,
  getPlansError,
  deletePlan,
  getPlans,
  getPlanByIdSuccess,
  getPlanByIdError,
  getPlanById,
  getPlansApprovedSuccess,
  getPlansApprovedError,
} from './actions';
import { PlanActionTypes } from './types';
import { error, success } from 'src/Services/ToastService';
import { postPlanError, putPlanError, putPlanSuccess, postPlanSuccess } from './actions';
import { PageableResponse } from 'src/Models/pageable';
import { getImageById } from '../image/action';


function* handlePostPlan(action: ReturnType<typeof postPlan>) {
  let result: AxiosResponse<Plan> | null;

  const errorMessage = 'Erro ao cadastrar plano!';

  try {
    result = yield call(PlanApi.postPlan, action.payload);
    if (result?.status === 200 || result?.status === 201) {
      success('Plano cadastrado!');
      if (action.payload?.image?.file != null) {
        yield delay(2000);
      };
      yield put(getPlans());
      yield put(postPlanSuccess());
    } else {
      error(errorMessage);
      yield put(postPlanError(errorMessage));
    }
  } catch (err) {
    error(errorMessage);
    yield put(postPlanError(errorMessage));
  }
}

function* handlePutPlan(action: ReturnType<typeof postPlan>) {
  let result: AxiosResponse<Plan> | null;

  const errorMessage = 'Erro ao atualizar plano!';

  try {
    result = yield call(PlanApi.putPlan, action.payload);
    if (result?.status === 200 || result?.status === 201) {
      success('Plano atualizado!');
      if (action.payload?.image?.file != null) {
        yield delay(2000);
      };
      yield put(getPlans());
      yield put(putPlanSuccess());
    } else {
      yield put(putPlanError(errorMessage));
    }
  } catch (err) {
    yield put(putPlanError(errorMessage));
  }
}

function* handleDeletePlan(action: ReturnType<typeof deletePlan>) {
  let result: AxiosResponse<Plan> | null;

  try {
    result = yield call(PlanApi.deletePlan, action.payload);

    if (result?.status === 200) {
      yield put(getPlans());
      success('Plano removido!');
    } else {
      error('Não foi possível remover o plano!');
    }
  } catch (err) {
    error('Não foi possível remover o plano!');
  }
}

function* handleGetPlans() {
  try {
    const result: AxiosResponse<Plan[]> = yield call(PlanApi.getPlans);

    if (result.status === 200 && result.data !== null) {
      yield put(getPlansSuccess(result?.data));
    } else {
      yield put(getPlansError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getPlansError(err.stack));
    } else {
      yield put(getPlansError('An unknown error occured.'));
    }
  }
}

function* handleGetPlansApproved() {
  try{
    const result: AxiosResponse<PageableResponse<Plan>> = yield call(PlanApi.getPlansApproved);

    if(result.status === 200 && result.data.content.length > 0){
      yield put(getPlansApprovedSuccess(result?.data.content));
    } else {
      yield put(getPlansApprovedError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getPlansApprovedError(err.stack));
    } else {
      yield put(getPlansApprovedError('An unknown error occured.'));
    }
  }
}

function* handleGetPlanById(action: ReturnType<typeof getPlanById>) {
  try {
    const result: AxiosResponse<Plan> = yield call(
      PlanApi.getPlanById,
      action.payload,
    );
      const image = result?.data?.image;
      if (image != undefined) {
          if (image?.imageUrl == null && image?.file == null) {
            put(getImageById(image?.id));
         }
      }
    if (result.status === 200 && result.data !== null) {
      yield put(getPlanByIdSuccess(result?.data));
    } else {
      yield put(getPlanByIdError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getPlanByIdError(err.stack));
    } else {
      yield put(getPlanByIdError('An unknown error occured.'));
    }
  }
}

function* planSaga() {
  yield all([
    takeLatest(PlanActionTypes.GET_PLANS_REQUEST, handleGetPlans),
    takeLatest(
      PlanActionTypes.GET_PLANS_APPROVED_REQUEST,
      handleGetPlansApproved,
    ),
    takeLatest(PlanActionTypes.POST_PLAN, handlePostPlan),
    takeLatest(PlanActionTypes.PUT_PLAN, handlePutPlan),
    takeLatest(PlanActionTypes.DELETE_PLAN, handleDeletePlan),
    takeLatest(PlanActionTypes.GET_PLAN_BY_ID_REQUEST, handleGetPlanById),
  ]);
}

export default planSaga;
