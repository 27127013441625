export interface BankAccount {
  id?: number;
  pixKey?: string;
  pixType: PixType;
  partnerPlace?: { id: number };
}

export enum PixType {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  RANDOM_KEY = 'RANDOM_KEY',
  DOCUMENT = 'DOCUMENT',
}
