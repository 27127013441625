import React from 'react';
import './styles.scss';

const LoadingDots: React.FC = () => {
  return (
    <div className="typing-indicator">
      <div className="typing-circle"></div>
      <div className="typing-circle"></div>
      <div className="typing-circle"></div>
      <div className="typing-shadow"></div>
      <div className="typing-shadow"></div>
      <div className="typing-shadow"></div>
    </div>
  );
};

export default LoadingDots;
