import { ConferenceSession } from 'src/Models/ConferenceSession';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';

export enum ConferenceSessionTypes {
  GET_OR_CREATE_CONFERENCE_SESSION = '@@conference/GET_OR_CREATE_CONFERENCE_SESSION',
  GET_OR_CREATE_CONFERENCE_SESSION_ERROR = '@@conference/GET_OR_CREATE_CONFERENCE_SESSION_ERROR',
  GET_OR_CREATE_CONFERENCE_SESSION_SUCCESS = '@@conference/GET_OR_CREATE_CONFERENCE_SESSION_SUCCESS',

  RESET = '@@conference/RESET',
}

export interface ConferenceState {
  readonly data: ConferenceSession;
  readonly getOrCreateConferenceSessionStatus: HttpRequestStatus;
}
