import styled from  'styled-components'

export const ContainerAll = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeaderStyled = styled.div`
width: 100%;
height: 72px;
display: flex;
align-items: center;
background: #25B379;
padding: 20px 222px 20px 175px;
`

export const Container = styled.div`
width: 995px;
display: flex;
flex-direction: column;
margin-top: 40px;
`

export const ContainerTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const ContainerUserData = styled.div`
  width: 100%;
  height: 189px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`

export const ContainerBenefits = styled.div`
  width: 515px;
  height: 189px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`

export const ContainerLastActivities = styled.div`
  width: 100%;
  height: 355px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`

export const ImgHeader = styled.img`
  cursor: pointer;
`

export const TitleHeader = styled.div`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 26px;
  color: #FFFFFF;
  margin-left: 16px;
`

export const ImgUser = styled.img`
  width: 120px;
  height: 120px;
  margin-right: 24px;
`
export const ContainerInfoUser = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
margin-top: 16px;
`

export const TitleName = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 24px;
color: #455561;
`

export const ContainerImgAndStatus = styled.div`
display: flex;
align-items: center;
margin: 12px 0;
`

export const ImgStyled = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const Divider = styled.div`
width: 1px;
height: 20px;
background: #D6D9DB;
margin-right: 10px;
`

export const TitleEmailPhone = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
color: #8D979E;
margin-bottom: 12px;
`

export const TitleInfos = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 100%;
color: #455561;
margin-bottom: 20px;
`

export const DividerInfos = styled.div`
  width: 100%;
  height: 1px;
  background: #F1ECEC;
  margin-bottom: 20px;
`