import { Reducer } from 'redux';
import { ProfileTypes, ProfileState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: ProfileState = {
  error: null,
  status: null,
  profile: null,
  dataStatus: null,
  pageStatus: null,
  responsibleStatus: null,
};

const reducer: Reducer<ProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case ProfileTypes.GET_PROFILE:
      return { ...state, status: HttpRequestStatus.ONGOING };
    
    case ProfileTypes.GET_PROFILE_SUCCESS: 
      return {
        ...state,
        profile: action?.payload,
        status: HttpRequestStatus.SUCCESS,
      };
    
    case ProfileTypes.GET_PROFILE_ERROR:
      return {
        ...state,
        error: action?.payload,
        status: HttpRequestStatus.ERROR,
      };
    
    case ProfileTypes.PUT_ENTERPRISE_DATA:
      return {
        ...state,
        dataStatus:  HttpRequestStatus.ONGOING
      }

    case ProfileTypes.PUT_ENTERPRISE_DATA_SUCCESS:
      return {
        ...state,
        dataStatus:  HttpRequestStatus.SUCCESS
      }

    case ProfileTypes.PUT_ENTERPRISE_DATA_ERROR:
      return {
        ...state,
        dataStatus:  HttpRequestStatus.ERROR
      }

    case ProfileTypes.PUT_ENTERPRISE_PAGE:
      return {
        ...state,
        pageStatus:  HttpRequestStatus.ONGOING
      }

    case ProfileTypes.PUT_ENTERPRISE_PAGE_SUCCESS:
      return {
        ...state,
        pageStatus:  HttpRequestStatus.SUCCESS
      }

    case ProfileTypes.PUT_ENTERPRISE_PAGE_ERROR:
      return {
        ...state,
        pageStatus:  HttpRequestStatus.ERROR
      }

      case ProfileTypes.PUT_RESPONSIBLE_DATA:
      return {
        ...state,
        responsibleStatus:  HttpRequestStatus.ONGOING
      }

    case ProfileTypes.PUT_RESPONSIBLE_DATA_SUCCESS:
      return {
        ...state,
        responsibleStatus:  HttpRequestStatus.SUCCESS
      }

    case ProfileTypes.PUT_RESPONSIBLE_DATA_ERROR:
      return {
        ...state,
        responsibleStatus:  HttpRequestStatus.ERROR
      }
    case ProfileTypes.GET_IMAGE_ADDRESS_SUCCESS:
      const profile = state.profile
      profile['address']['image'] = action.payload
      return {
        ...state,
        profile
      }
    default: return state;
  }
};

export { reducer as profileReducer };
