import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import { PageableResponse } from 'src/Models/pageable';
import { ResultPage } from 'src/Models/ResultPage';
import CustomerActivityPlanApi from 'src/Services/Api/CustomerActivityPlanApi';
import { error } from 'src/Services/ToastService';
import {
  approveEnrolment,
  approveEnrolmentError,
  approveEnrolmentSuccess,
  getCustomerActivitiesPlansError,
  getCustomerActivitiesPlansSuccess,
  getCustomerActivityPlanById,
  getCustomerActivityPlanByIdError,
  getCustomerActivityPlanByIdSuccess,
  getCustomerActivityPlans,
  getCustomerActivities,
  getCustomersPlanWithPaymentOverdue,
  getCustomersPlanWithPaymentOverdueError,
  getCustomersPlanWithPaymentOverdueSuccess,
  getCustomerActivitiesError,
  getCustomerActivitiesSuccess,
} from './actions';
import { CustomerActivityPlanActionTypes } from './types';
import CustomerApi from 'src/Services/Api/CustomerApi';
import CustomerActivityApi from 'src/Services/Api/CustomerActivityApi';
import CustomerActivity from 'src/Models/CustomerActivity';

function* handleGetCustomerActivitiesPlan(
  action: ReturnType<typeof getCustomerActivityPlans>,
) {
  try {
    const result: AxiosResponse<PageableResponse<CustomerActivityPlan>> | null =
      yield call(
        CustomerActivityPlanApi.getCustomerActivitiesPlan,
        action?.payload,
      );

    if (result.status === 200 && result.data !== null) {
      yield put(getCustomerActivitiesPlansSuccess(result.data));
    } else {
      yield put(getCustomerActivitiesPlansError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getCustomerActivitiesPlansError(err.stack));
    } else {
      yield put(getCustomerActivitiesPlansError('An unknown error occured.'));
    }
  }
}

function* handleGetCustomerActivities(
  action: ReturnType<typeof getCustomerActivities>,
) {
  try {
    const result: AxiosResponse<PageableResponse<CustomerActivity>> =
      yield call(
        CustomerActivityApi.getCustomerActivitiesHistory,
        action?.payload,
      );

    if (result.status === 200 && result.data !== null) {
      yield put(getCustomerActivitiesSuccess(result.data));
    } else {
      yield put(getCustomerActivitiesError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getCustomerActivitiesError(err.stack));
    } else {
      yield put(getCustomerActivitiesError('An unknown error occured.'));
    }
  }
}

function* handleCustomerActivityPlanById(
  action: ReturnType<typeof getCustomerActivityPlanById>,
) {
  try {
    const result: AxiosResponse<any> = yield call(
      CustomerActivityPlanApi.getCustomerActivityById,
      action.payload,
    );
    if (result.status === 200 && result.data !== null) {
      yield put(getCustomerActivityPlanByIdSuccess(result?.data));
    } else {
      yield put(getCustomerActivityPlanByIdError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getCustomerActivityPlanByIdError(error.stack));
    } else {
      yield put(
        getCustomerActivityPlanByIdError('Um erro inesperado aconteceu'),
      );
    }
  }
}

function* handlePatchApproveEnrolment(
  action: ReturnType<typeof approveEnrolment>,
) {
  let result: AxiosResponse<CustomerActivityPlan> | null;
  const errorMessage = 'Error ao aprovar/negar matrícula';
  try {
    result = yield call(
      CustomerActivityPlanApi.approveEnrolment,
      action.payload,
    );
    if (result?.status === 200 || result?.status === 201) {
      yield put(approveEnrolmentSuccess());
      yield delay(1500);
      yield put(
        getCustomerActivityPlans({
          status: CustomerActivityStatus.WAITING_APPROVE,
        }),
      );
    } else {
      error(errorMessage);
      yield put(approveEnrolmentError(errorMessage));
    }
  } catch (error) {
    error(result.statusText);
    yield put(approveEnrolmentError(errorMessage));
  }
}

function* handleGetCustomersPlanWithPaymentOverdue(
  action: ReturnType<typeof getCustomersPlanWithPaymentOverdue>,
) {
  try {
    const result: AxiosResponse<PageableResponse<CustomerActivityPlan>> =
      yield call(
        CustomerActivityPlanApi.getCustomersPlanWithPaymentOverdue,
        action.payload,
      );
    if (result.status === 200 && result.data !== null) {
      yield put(getCustomersPlanWithPaymentOverdueSuccess(result.data));
    } else {
      yield put(getCustomersPlanWithPaymentOverdueError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getCustomersPlanWithPaymentOverdueError(err.stack));
    } else {
      yield put(
        getCustomersPlanWithPaymentOverdueError('An unknown error occurred.'),
      );
    }
  }
}

function* customerActivityPlanSaga() {
  yield all([
    takeLatest(
      CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN,
      handleGetCustomerActivitiesPlan,
    ),
    takeLatest(
      CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY,
      handleGetCustomerActivities,
    ),
    takeLatest(
      CustomerActivityPlanActionTypes.APPROVE_ENROLMENT,
      handlePatchApproveEnrolment,
    ),
    takeLatest(
      CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID,
      handleCustomerActivityPlanById,
    ),
    takeLatest(
      CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE,
      handleGetCustomersPlanWithPaymentOverdue,
    ),
  ]);
}

export default customerActivityPlanSaga;
