import { AxiosResponse } from 'axios';
import { ResultPage } from 'src/Models/ResultPage';
import { api } from '.';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import qs from 'qs';

const baseUrl = '/customer-activity-plan';

export const CustomerActivityPlanApi = () => {
  const getCustomerActivitiesPlan = (payload: {
    status?: CustomerActivityStatus;
    size?: number;
    page?: number;
  }): Promise<AxiosResponse<ResultPage<CustomerActivityPlan>>> => {
    const { status, size, page } = payload;
    const queryParams = qs.stringify({
      status,
      size,
      page,
    });

    const url = `${baseUrl}${queryParams ? `?${queryParams}` : ''}`;
    return api.get<ResultPage<CustomerActivityPlan>>(url);
  };

  const getCustomerActivityById = (
    id: number,
  ): Promise<AxiosResponse<CustomerActivityPlan>> => {
    return api.get<CustomerActivityPlan>(`${baseUrl}/${id}`);
  };

  const approveEnrolment = (
    customerActivityPlan: CustomerActivityPlan,
  ): Promise<AxiosResponse<CustomerActivityPlan>> => {
    return api.patch<CustomerActivityPlan>(
      `${baseUrl}/enrolment`,
      customerActivityPlan,
    );
  };

  const getCustomersPlanWithPaymentOverdue = (params?: {
    size?: number;
    page?: number;
  }): Promise<AxiosResponse<ResultPage<CustomerActivityPlan>>> => {
    return api.get<ResultPage<CustomerActivityPlan>>(
      `${baseUrl}/payment-overdue`,
      {
        params,
      },
    );
  };

  return {
    getCustomerActivitiesPlan,
    approveEnrolment,
    getCustomerActivityById,
    getCustomersPlanWithPaymentOverdue,
  };
};

export default CustomerActivityPlanApi();
