import {Plan} from "src/Models/Plan";
import { action } from 'typesafe-actions';
import { PlanActionTypes } from './types';

export const getPlans = () => action(PlanActionTypes.GET_PLANS_REQUEST);
export const getPlansApproved = () => action(PlanActionTypes.GET_PLANS_APPROVED_REQUEST);
export const getPlansSuccess = (activities: Plan[]) => action(PlanActionTypes.GET_PLANS_REQUEST_SUCCESS, activities);
export const getPlansApprovedSuccess = (activities: Plan[]) => action(PlanActionTypes.GET_PLANS_APPROVED_REQUEST_SUCCESS, activities);
export const getPlansError = (message: string) => action(PlanActionTypes.GET_PLANS_REQUEST_ERROR, message);
export const getPlansApprovedError = (message: string) => action(PlanActionTypes.GET_PLANS_APPROVED_REQUEST_ERROR, message);

export const getPlanById = (planId: number) => action(PlanActionTypes.GET_PLAN_BY_ID_REQUEST, planId);
export const getPlanByIdSuccess = (activityPlan: Plan) => action(PlanActionTypes.GET_PLAN_BY_ID_REQUEST_SUCCESS, activityPlan);
export const getPlanByIdError = (message: string) => action(PlanActionTypes.GET_PLAN_BY_ID_REQUEST_ERROR, message);

export const postPlan = (plan: Plan) => action(PlanActionTypes.POST_PLAN, plan);
export const postPlanSuccess = () => action(PlanActionTypes.POST_PLAN_SUCCESS);
export const postPlanError = (message: string) => action(PlanActionTypes.POST_PLAN_ERROR, message);

export const putPlan = (plan: Plan) => action(PlanActionTypes.PUT_PLAN, plan);
export const putPlanSuccess = () => action(PlanActionTypes.PUT_PLAN_SUCCESS);
export const putPlanError = (message: string) => action(PlanActionTypes.PUT_PLAN_ERROR, message);

export const deletePlanSuccess = () => action(PlanActionTypes.DELETE_PLAN_SUCCESS);
export const deletePlan = (planId: number) => action(PlanActionTypes.DELETE_PLAN, planId);
export const deletePlanError = (message: string) => action(PlanActionTypes.DELETE_PLAN_ERROR, message);

export const resetPostPutStatus = () => action(PlanActionTypes.RESET_POST_PUT_STATUS);
