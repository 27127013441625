import CustomerActivity from 'src/Models/CustomerActivity';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { PageableResponse } from 'src/Models/pageable';

export type ApiResponse = Record<string, any>;

export enum CustomerActivityPlanActionTypes {
  GET_CUSTOMER_ACTIVITES_PLAN = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITIES_PLAN',
  GET_CUSTOMER_ACTIVITES_PLAN_ERROR = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITIES_PLAN_ERROR',
  GET_CUSTOMER_ACTIVITES_PLAN_SUCCESS = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITIES_PLAN_SUCCESS',

  GET_CUSTOMER_ACTIVITY_PLAN_BY_ID = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITY_PLAN_BY_ID',
  GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_ERROR = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_ERROR',
  GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_SUCCESS = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_SUCCESS',

  GET_CUSTOMERS_WITH_PAYMENT_OVERDUE = '@@customerActivityPlan/GET_CUSTOMERS_WITH_PAYMENT_OVERDUE',
  GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_ERROR = '@@customerActivityPlan/GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_ERROR',
  GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_SUCCESS = '@@customerActivityPlan/GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_SUCCESS',

  APPROVE_ENROLMENT = '@@customerActivityPlan/APPROVE_ENROLMENT',
  APPROVE_ENROLMENT_SUCCESS = '@@customerActivityPlan/APPROVE_ENROLMENT_SUCCESS',
  APPROVE_ENROLMENT_ERROR = '@@customerActivityPlan/APPROVE_ENROLMENT_ERROR',

  GET_CUSTOMER_ACTIVITIES_HISTORY = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITIES',
  GET_CUSTOMER_ACTIVITIES_HISTORY_SUCCESS = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITIES_SUCCESS',
  GET_CUSTOMER_ACTIVITIES_HISTORY_ERROR = '@@customerActivityPlan/GET_CUSTOMER_ACTIVITIES_ERROR',

  RESET_STATUS = '@@customerActivityPlan/RESET_STATUS',
  RESET_CUSTOMER_ACTIVITY_PLAN_STATUS = '@@customerActivityPlan/RESET_CUSTOMER_ACTIVITY_PLAN_STATUS',
  RESET_APPROVE_ENROLMENT_STATUS = '@@customerActivityPlan/RESET_APPROVE_ENROLMENT_STATUS',
}

export interface CustomerActivtiyPlanState {
  readonly customerActivitiesPlan?: PageableResponse<CustomerActivityPlan>;
  readonly customerActivityPlan?: CustomerActivityPlan;
  readonly approveEnrolmentStatus?: HttpRequestStatus;
  readonly status?: HttpRequestStatus;
  readonly customerActivityPlanStatus?: HttpRequestStatus;
  readonly customersPlanWithPaymentOverdue: PageableResponse<CustomerActivityPlan> | null;
  readonly customersPlanWithPaymentOverdueStatus?: HttpRequestStatus;
  readonly customerActivityHistory?: PageableResponse<CustomerActivity>;
  readonly customerActivityHistoryStatus?: HttpRequestStatus;
}
