export const cnpjMask = (v) => {
  v = v?.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v?.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  v = v?.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v?.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  return v;
};

export const cpfMask = (v) => {
  v = v?.replace(/\D/g, '');
  v = v?.replace(/(\d{3})(\d)/, '$1.$2');
  v = v?.replace(/(\d{3})(\d)/, '$1.$2');
  v = v?.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return v;
};

export const dateMask = (date) => {
  if ((date.length >= 8 && date.includes(!'/')) || date.length > 10) {
    date = new Date(date);
    return (
      date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    );
  }
};
