import React from 'react';
import { Oval } from 'react-loader-spinner';

type LoadingProps = {
  size?: number;
  inverted?: boolean;
};

const CircleLoading = ({ size = 50, inverted = false }: LoadingProps) => {
  return (
    <Oval
      height={size}
      width={size}
      color={!inverted ? '#25b379' : '#ffffff'}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={!inverted ? '#25b379' : '#ffffff'}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default CircleLoading;
