import React, { useEffect, useState } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import Schedules from 'src/Components/PartnerComponents/SchedulesEmpty';
import NameAndColor from 'src/Components/PartnerComponents/NameAndColor';
import {
  getAgendaById,
  getAgendaByIdSuccess,
  postAgenda,
  putAgenda,
  resetAgenda,
  resetCreateAgenda,
  resetEditAgenda,
} from 'src/Stores/agenda/actions';
import { IRootState } from 'src/Stores';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PresetAgendaActivitySchedule } from 'src/Models/Agenda';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { CommonLoading } from 'react-loadingg';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { isEmpty, isNumber } from 'lodash';
import SelectActivities from 'src/Components/PartnerComponents/SelectActivities';
import { Activity } from 'src/Models/Activity';
import AgendaMinutesIntervalController from 'src/Components/PartnerComponents/AgendaMinutesIntervalController';
import SimpleModal from 'src/Components/SimpleModal';
import { checkIfIntervalSelectedSuportsAllPresetSchedulesAlreadyChosen } from 'src/Utils/Agenda/AgendaUtils';

export interface NewScheduleProps
  extends WithTranslation,
    DispatchProps,
    StateProps {
  location: { state };
}

interface Intervals {
  [key: string]: number;
}

const intervals: Intervals = {
  'De 5 em 5': 5,
  'De 10 em 10': 10,
  'De 15 em 15': 15,
  'De 30 em 30': 30,
};

const NewSchedule: React.FC<NewScheduleProps> = ({
  t,
  agenda,
  location,
  createAgenda,
  editAgenda,
  getAgendaId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const route = useRouteMatch();
  const params = useParams();

  const agendaStatus = useSelector((root: IRootState) => root.agenda.status);
  const agendaEditStatus = useSelector(
    (root: IRootState) => root.agenda.editStatus,
  );
  const agendaCreateStatus = useSelector(
    (root: IRootState) => root.agenda.createStatus,
  );
  const [color, setColor] = useState('');
  const [disableAdd, setDisableAdd] = useState(true);
  const [editState, setEditState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [intervalSelected, setIntervalSelected] = useState<number>(5);
  const [presetAgendaActivitySchedule, setPresetAgendaActivitySchedule] =
    useState<PresetAgendaActivitySchedule[]>();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [intervalErrorModal, setIntervalErrorModal] = useState<{
    open: boolean;
    interval: number;
  }>({ open: false, interval: null });

  const handleOnPresetAgendaChange = (item: PresetAgendaActivitySchedule[]) => {
    if (!isEmpty(item)) {
      setDisableAdd(false);
    }

    setPresetAgendaActivitySchedule(item);
  };

  const handleOnSelectActivity = (item: Activity[]) => {
    setActivities(item);
  };

  const handleOnChangeName = (name: string) => {
    if (!isEmpty(name)) {
      setDisableAdd(false);
    }
    if (name.length < 19) {
      setName(name);
    }
  };

  const handleOnChangeColor = (color: string) => {
    if (!isEmpty(color)) {
      setDisableAdd(false);
    }
    setColor(color);
  };

  const handleOnSubmitSchedule = () => {
    if (loading == true) {
      return;
    }

    if (editState == false) {
      dispatch(
        postAgenda({
          name: name,
          color: color,
          presetAgendaActivitySchedules: presetAgendaActivitySchedule,
          activities: activities,
        }),
      );
    }
    if (editState == true) {
      dispatch(
        putAgenda({
          ...agenda,
          id: agenda?.id,
          name: name,
          color: color,
          presetAgendaActivitySchedules: presetAgendaActivitySchedule,
          activities: activities,
        }),
      );
    }
  };

  useEffect(() => {
    // @ts-ignore
    const routeId = route?.params?.id;

    if (routeId != null && isNumber(parseInt(routeId))) {
      getAgendaId(parseInt(routeId));
      setEditState(true);
    }
  }, [getAgendaId, route]);

  useEffect(() => {
    setColor(agenda?.color ?? '#25B379');
    setName(agenda?.name ?? 'Agenda');
    setActivities(agenda?.activities);
  }, [agenda]);

  useEffect(() => {
    if (agendaStatus == HttpRequestStatus.SUCCESS) {
      setLoading(false);
      dispatch(resetAgenda());
    }

    if (agendaStatus == HttpRequestStatus.ERROR) {
      setLoading(false);
      dispatch(resetAgenda());
    }
  }, [agendaStatus]);

  useEffect(() => {
    if (agendaCreateStatus == HttpRequestStatus.SUCCESS) {
      dispatch(resetCreateAgenda());
      setLoading(false);
      history.push('/overview');
    }

    if (agendaCreateStatus == HttpRequestStatus.ERROR) {
      setLoading(false);
      dispatch(resetCreateAgenda());
    }
    if (agendaCreateStatus == HttpRequestStatus.ONGOING) {
      setLoading(true);
    }
  }, [agendaCreateStatus]);

  useEffect(() => {
    if (agendaEditStatus == HttpRequestStatus.SUCCESS) {
      dispatch(resetEditAgenda());
      setLoading(false);
      history.push('/overview');
    }

    if (agendaEditStatus == HttpRequestStatus.ERROR) {
      setLoading(false);
      dispatch(resetEditAgenda());
    }
    if (agendaEditStatus == HttpRequestStatus.ONGOING) {
      setLoading(true);
    }
  }, [agendaEditStatus]);

  const handleChangeInterval = (interval: string) => {
    const intervalSupportsAllPresetSchedulesAlreadyChosen =
      checkIfIntervalSelectedSuportsAllPresetSchedulesAlreadyChosen(
        intervals[interval],
        presetAgendaActivitySchedule,
      );

    if (intervalSupportsAllPresetSchedulesAlreadyChosen) {
      return setIntervalSelected(intervals[interval]);
    } else {
      setIntervalErrorModal({
        open: true,
        interval: intervals[interval],
      });
    }
  };

  const changeIntervalAndExcludeUnsuportedSchedules = () => {
    const newPresetAgendaActivitySchedule =
      presetAgendaActivitySchedule?.filter((item) => {
        const parts = item?.startAt?.split(':');
        const minutes = Number(parts[1]);

        return minutes % intervalErrorModal.interval == 0;
      });

    handleOnPresetAgendaChange(newPresetAgendaActivitySchedule);
    setIntervalSelected(intervalErrorModal.interval);
    setIntervalErrorModal({ open: false, interval: null });
  };

  if (loading == true) {
    return (
      <div className="ScheduleComponentEmpty">
        <CommonLoading />
      </div>
    );
  }

  return (
    <div className="NewSchedule">
      <BaseHeaderBenefits
        step="create"
        color="green"
        title={!editState ? 'Criar nova Agenda' : 'Editar agenda'}
        to="/overview"
      />
      <div className="mt-100">
        <NameAndColor
          colorOf="Cor da agenda"
          nameType="Nome da agenda"
          agendaColor={color}
          agendaName={name}
          onChangeName={handleOnChangeName}
          onChangeColor={handleOnChangeColor}
        />
        <AgendaMinutesIntervalController
          handleChangeInterval={handleChangeInterval}
          intervals={intervals}
          intervalSelected={intervalSelected}
        />
        <Schedules
          presetAgendaActivitySchedules={agenda?.presetAgendaActivitySchedules}
          onChange={handleOnPresetAgendaChange}
          color={color}
          isEdit={editState}
          intervalSelected={intervalSelected}
          presetAgendaActivitySchedule={presetAgendaActivitySchedule}
        />
        <SelectActivities
          values={agenda?.activities}
          onSubmit={handleOnSelectActivity}
        />
      </div>
      <BaseFooterBenefits
        onClick={handleOnSubmitSchedule}
        hasBackButton="true"
        disabled={disableAdd || loading}
        text={!editState ? 'Adicionar agenda' : 'Editar agenda'}
        textBack="Cancelar"
      />
      {/* Modal erro interval selected */}
      <SimpleModal
        isOpen={intervalErrorModal.open}
        labelConfirm={'Excluir horários'}
        labelCancel={'Manter horários/intervalo'}
        modalTitle={'Aviso!'}
        onCancel={() => {
          setIntervalErrorModal({ open: false, interval: null });
        }}
        onConfirm={() => changeIntervalAndExcludeUnsuportedSchedules()}>
        <div>
          Existem horários selecionados que o intervalo escolhido não suporta,
          caso deseje realmente trocar de intervalo, os horários NÃO SUPORTADOS
          serão excluídos.
        </div>
      </SimpleModal>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { agenda } = state;

  return agenda;
};

const mapDispatchToProps = {
  createAgenda: postAgenda,
  editAgenda: putAgenda,
  getAgendaId: getAgendaById,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NewSchedule),
) as React.ComponentType<any>;
