import { Plan } from 'src/Models/Plan';

export const defaultPlanBody: Plan = {
  activitySubCategories: [],
  description: '',
  enrolmentFee: 0,
  free: false,
  enabledB2b: false,
  image: {
    contentType: 'contentType',
    file: 'file',
    fileName: 'filename',
  },
  maintenanceFee: 0,
  name: '',
  partnerPlace: {},
  period: 'MONTH_PERIOD',
  presetActivityPlanSchedules: [
    {
      zoneId: 'America/Sao_Paulo',
      dayOfWeek: 'MONDAY',
      startAt: '10:30:00',
      duration: 30,
    },
  ],
  price: 0,
  slots: 1,
  status: 'ENABLED',
  type: 'IN_PLACE',
};

export const presetScheduleBody = {
  endtAt: '',
  duration: 0,
  startAt: '',
  endLunch: '',
  dayOfWeek: '',
  startLunch: '',
  dontWork: true,
  zoneId: 'America/Sao_Paulo',
};

export const presetScheduleObject = {
  monday: {
    ...presetScheduleBody,
  },
  tuesday: { ...presetScheduleBody },
  wednesday: { ...presetScheduleBody },
  thursday: { ...presetScheduleBody },
  friday: { ...presetScheduleBody },
  saturday: { ...presetScheduleBody },
  sunday: { ...presetScheduleBody },
};

export const useFormDefaultValues = {
  id: null,
  name: '',
  slots: 1,
  free: false,
  enabledB2b: false,
  teacher: '',
  image: null,
  period: 'MONTH_PERIOD',
  description: '',
  enrolmentFee: 0,
  maintenanceFee: 0,
  presetActivitySchedules: presetScheduleObject,
  type: 'IN_PLACE',
  isEnrolmentNeeded: false,
  isRecurrenceNeeded: false,
  typeOfRecurrenceOff: null,
  minTimeTurnRecurrenceOff: null,
  typeDescription: '',
  enrolmentContract: null,
  priceWithDiscount: 0,
  partnerProfit: 0,
  priceWithFee: 0,
};
