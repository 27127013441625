import { AxiosResponse } from 'axios';
import {
  put,
  all,
  call,
  takeLatest,
} from 'redux-saga/effects';
import PartnerClientApi from 'src/Services/Api/PartnerClientApi';
import {
  getPartnerClientsError,
  getPartnerClientsSuccess,
} from './actions';
import { PartnerClientTypes } from './types';
import { PartnerClient } from '../../Models/PartnerClient';

function* handleGetPartnerClients(size = 20) {
  try {
    const result: AxiosResponse<PartnerClient[]> = yield call(
      PartnerClientApi.getPartnerClients, size
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getPartnerClientsSuccess(result.data));
    } else {
      yield put(getPartnerClientsError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getPartnerClientsError(err.stack));
    } else {
      yield put(getPartnerClientsError('An unknown error occured.'));
    }
  }
}

function* partnerClientSaga() {
  yield all([
    takeLatest(PartnerClientTypes.GET_PARTNER_CLIENTS, handleGetPartnerClients),
  ]);
}

export default partnerClientSaga;
