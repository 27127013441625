import { AxiosResponse } from 'axios';
import { all, call, put, fork, takeLatest, delay } from 'redux-saga/effects';
import {
  FinancialDashboard,
  PieDataPartner,
} from 'src/Models/FinancialDashboard';
import { PartnerDashboard } from 'src/Models/PartnerDashboard';
import PartnerDashboardApi from 'src/Services/Api/PartnerDashboardApi';
import {
  getFinancialDashboardError,
  getFinancialDashboardSuccess,
  getPartnerDashboardError,
  getPartnerDashboardSuccess,
  getPieDataError,
  getPieDataSuccess,
} from './actions';
import { PartnerDashboardActionTypes } from './types';
import { tr } from 'date-fns/locale';

function* handleGetPartnerDashboard() {
  try {
    const result: AxiosResponse<PartnerDashboard> = yield call(
      PartnerDashboardApi.getPartnerDashboard,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getPartnerDashboardSuccess(result.data));
    } else {
      yield put(getPartnerDashboardError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getPartnerDashboardError(error.stack));
    } else {
      yield put(getPartnerDashboardError('Um erro inesparado aconteceu'));
    }
  }
}

function* handlGetPartnerActivitiesDashboard() {
  try {
    const result: AxiosResponse<PieDataPartner[]> = yield call(
      PartnerDashboardApi.getPartnerActivitiesDashboard,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getPieDataSuccess(result.data));
    } else {
      yield put(getPartnerDashboardError(result.statusText));
    }
  } catch (error) {
    yield put(getPieDataError(error.stack));
  }
}

function* handleGetFinancialDashboard() {
  try {
    const result: AxiosResponse<FinancialDashboard> = yield call(
      PartnerDashboardApi.getFinancialDashboard,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getFinancialDashboardSuccess(result.data));
    } else {
      yield put(getFinancialDashboardError(result.statusText));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      yield put(getFinancialDashboardError(error.stack));
    } else {
      yield put(getFinancialDashboardError('Um erro inesparado aconteceu'));
    }
  }
}

function* watchGetPartnerDashboard() {
  yield takeLatest(
    PartnerDashboardActionTypes.GET_PARTNER_DASHBOARD,
    handleGetPartnerDashboard,
  );
}

function* watchGetPartnerActivitiesDashboard() {
  yield takeLatest(
    PartnerDashboardActionTypes.GET_PIE_DATA,
    handlGetPartnerActivitiesDashboard,
  );
}

function* watchGetFinancialDashboard() {
  yield takeLatest(
    PartnerDashboardActionTypes.GET_FINANCIAL_DASHBOARD,
    handleGetFinancialDashboard,
  );
}

function* partnerDashboardSaga() {
  yield all([
    fork(watchGetPartnerDashboard),
    fork(watchGetFinancialDashboard),
    fork(watchGetPartnerActivitiesDashboard),
  ]);
}

export default partnerDashboardSaga;
