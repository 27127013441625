export const suggestions = [
  {
    id: 1,
    content: 'Como atrair e reter membros para o meu serviço?',
    resume: 'Como atrair novos clientes?',
  },
  {
    id: 2,
    content: 'Como melhorar a eficiência operacional do meu serviço?',
    resume: 'Como melhorar a eficiência operacional?',
  },
  {
    id: 3,
    content:
      'Quais estratégias de marketing e vendas posso usar para aumentar a receita do meu serviço?',
    resume: 'Novas estratégias de marketing',
  },
  {
    id: 5,
    content:
      'Como treinar e desenvolver minha equipe para fornecer um excelente atendimento ao cliente?',
    resume: 'Como treinar minha equipe?',
  },
];

export const initialMessage =
  'Olá, Bem vindo ao ChatBot da Keiken, um aliado digital especializada em suporte para os usuários. Sinta se a vontade para escolher um agente especializado na área da sua dúvida e fazer uma pergunta! Estou aqui para ajudar você!';
