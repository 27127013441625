import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import InputClassic from '../../../PlanFormat/InputClassic';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import './styles.scss';
import { AcademicEducation, ResponsibleData } from 'src/Models/Profile';
import StringUtils from 'src/Utils/StringUtils';
import moment from 'moment';
import { PartnerCreate } from 'src/Models/Partner';
import { putResponsibleData } from 'src/Stores/profile/actions';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import Button from '@mui/material/Button';
import { useWidth } from 'src/Hooks/useWidth';
import { cpfMask, dateMask } from 'src/Utils/Masks';

export interface ModalProps extends StateProps, DispatchProps {
  open: boolean;
  partner: PartnerCreate;
  setOpen: (open: boolean) => void;
}

const defaultData = {
  birthDate: '',
  cpf: '',
  id: 0,
  name: '',
  email: '',
  concilType: 'crp',
  concilNumber: '',
  partnerPlaces: [],
};

const defaultError = {
  cpf: false,
  email: false,
  birthDate: false,
  concilNumber: false,
};

const defaultFormation = {
  index: 0,
  course: '',
  institution: '',
  conclusionYear: '',
};

const ResponsibleModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  profile,
  partner,
  putResponsibleData,
}) => {
  const [errors, setErrors] = useState(defaultError);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [data, setData] = useState<ResponsibleData>(defaultData);
  const [newFormations, setNewFormations] = useState<AcademicEducation[]>([]);

  const { email, cpf, birthDate } = data;
  let widthSize = useWidth();

  const setError = (field, value) => {
    setErrors({ ...errors, [field]: value });
  };

  useEffect(() => {
    let concilType = '';
    let concilNumber = '';

    if (profile?.id) {
      if (profile?.hasOwnProperty('crp')) concilType = 'crp';
      else if (profile?.hasOwnProperty('crn')) concilType = 'crn';
      else if (profile?.hasOwnProperty('cref')) concilType = 'cref';
      else if (profile?.hasOwnProperty('crefito')) concilType = 'crefito';
      else if (profile?.hasOwnProperty('otherRecord'))
        concilType = 'otherRecord';
      else concilType = 'crp'; // first item in the select options, without this, it shows in the screen the option 'crp', but the value is empty

      concilNumber = profile[concilType] || '';

      setErrors({
        ...errors,
        cpf: !partner.cpf || errors.cpf,
        birthDate: !partner.birthDate || errors.birthDate,
        concilNumber: !partnerHasAConcilNumber || errors.concilNumber,
      });
    }

    const dataObj = {
      ...data,
      concilType,
      concilNumber,
      cpf: partner?.cpf,
      name: partner.name,
      email: partner?.user?.email,
      birthDate: partner?.birthDate,
    };

    if (concilType === '') setData(dataObj);
    else setData({ ...dataObj, [concilType]: profile[concilType] || '' });
  }, [partner, profile]);

  useEffect(() => {
    if (profile?.academicEducation?.length === 0) return;

    let formations = [];

    profile?.academicEducation?.forEach((formation, index) => {
      formations = [...formations, { index, ...formation }];
    });

    setNewFormations(formations);
  }, [profile]);

  useEffect(() => {
    if (!email || email?.length === 0) return;

    if (StringUtils.isEmailInvalid(email) && !errors?.email)
      return setError('email', true);

    errors?.email && setError('email', false);
  }, [email]);

  useEffect(() => {
    if (!cpf || cpf?.length === 0) return;

    const isInvalid = !cpfValidator.isValid(cpf);

    if (isInvalid && !errors?.cpf) return setError('cpf', true);
    else if (!isInvalid && errors?.cpf) setError('cpf', false);
  }, [cpf]);

  useEffect(() => {
    if (!birthDate || birthDate?.length === 0) return;

    if (!moment(birthDate).isValid() && !errors?.birthDate)
      return setError('birthDate', true);

    errors?.birthDate && setError('birthDate', false);
  }, [birthDate]);

  useEffect(() => {
    if (data?.concilNumber?.length > 4) {
      errors?.concilNumber && setError('concilNumber', false);
    };

  }, [data?.concilNumber]);

  useEffect(() => {
    if (!errors?.cpf && !errors?.email && !errors?.birthDate && !errors?.concilNumber) {
      setEnableSave(true);
    }
  }, [errors]);

  const handleClose = () => {
    setData({
      ...data,
      name: partner.name,
      cpf: partner?.cpf,
      birthDate: partner?.birthDate,
      email: partner?.user?.email,
    });

    let formations = [];

    profile?.academicEducation?.forEach((formation, index) => {
      formations = [...formations, { index, ...formation }];
    });

    setNewFormations(formations);

    handleAfterSave();
  };

  const handleAfterSave = () => {
    setOpen(false);
    setEnableSave(false);
  };

  const handleChange = (e, formation?) => {
    const { name = '', value = '' } = e?.target;

    const formatter = ['cpf'];

    if (formatter.includes(name)) handleChangeWithFormatter(e);
    else if (defaultFormation.hasOwnProperty(name))
      handleChangeFormation(e, formation);
    else setData({ ...data, [name]: value });

    setEnableSave(true);
  };

  const handleChangeWithFormatter = (e) => {
    const { value, name } = e?.target;

    const formatterFunc = {
      cpf: StringUtils.cpfFormatter,
    };

    setData({ ...data, [name]: formatterFunc[name](value) });
  };

  const handleChangeFormation = (e, formation) => {
    const { name, value } = e?.target;

    let updatedFormations = [];

    newFormations?.forEach((currentFormation) => {
      if (currentFormation?.index === formation?.index) {
        updatedFormations = [
          ...updatedFormations,
          { ...currentFormation, [name]: value },
        ];
      } else
        updatedFormations = [...updatedFormations, { ...currentFormation }];
    });

    setNewFormations(updatedFormations);
  };

  const handleSave = () => {
    const validFormations = newFormations?.filter(
      (formation) => formation?.course?.length > 0,
    );

    const putObj = {
      ...data,
      id: profile?.id,
      partnerPlaces: [
        {
          [data?.concilType]: data?.concilNumber,
          academicEducation: [...validFormations],
        },
      ],
    };

    putResponsibleData(putObj);
    handleClose();
  };

  const disabledSave = !enableSave || errors?.cpf || errors?.email || errors?.birthDate || errors?.concilNumber;
  const saveButtonColor = !disabledSave ? '#25B379 !important' : 'light-grey';

  const partnerHasAConcilNumber = !!partner?.partnerPlace?.crp
    || !!partner?.partnerPlace?.cref
    || !!partner?.partnerPlace?.crn
    || !!partner?.partnerPlace?.crefito
    || !!partner?.partnerPlace?.other_record;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className="paper-ResponsibleData">
          <div className="modalAlign-ResponsibleData">
            <div>
              <h2
                id="transition-modal-title"
                className="modalTitle-ResponsibleData">
                Editar dados do responsável
              </h2>
            </div>
            <div>
              <CloseIcon className="modalIcon" onClick={handleClose} />
            </div>
          </div>
          <div className="mb-20" />
          <div className="underline" />
          <div className="mb-20" />
          <div className="flex">
            <div className="col-12 p-0">
              <div
                className={`${widthSize > 450 ? 'mb-20 col-12' : 'mb-20 col-16'
                  }`}>
                <InputClassic
                  title="Nome"
                  placeholder="Nome"
                  name="name"
                  value={data?.name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex-ResponsibleData">
                <div
                  className={`${widthSize > 450 ? 'mb-20 col-4' : 'mb-20 col-16'
                    }`}>
                  <InputClassic
                    title="Data Nascimento"
                    placeholder="DD/MM/AAAA"
                    type="date"
                    maxLength={10}
                    value={data?.birthDate}
                    onChange={(e) =>
                      setData({ ...data, birthDate: e.target.value })
                    }
                    disabled={!!partner.birthDate}
                  />
                  {errors.birthDate && <p className="error"> Data Inválida</p>}
                </div>
                <div
                  className={`${widthSize > 450 ? 'mb-20 col-5' : 'mb-20 col-16'
                    }`}>
                  <InputClassic
                    title="Número do conselho (CRP, CREF ou CRN)"
                    placeholder="Número do conselho (CRP, CREF ou CRN)"
                    maxLength={14}
                    name="concilNumber"
                    value={data?.concilNumber}
                    onChange={handleChange}
                    disabled={partnerHasAConcilNumber}
                  />
                  {errors.concilNumber && <p className="error"> Número do conselho inválido</p>}
                </div>
                <div
                  className={`${widthSize > 450 ? 'mb-20 col-3' : 'mb-20 col-16'
                    }`}>
                  <select
                    name="concilType"
                    placeholder="Mensalidade"
                    value={data?.concilType}
                    className="customInputMaster--select--concilType"
                    onChange={handleChange}
                    style={{ background: 'white' }}
                    disabled={true}
                  >
                    <option value="crp">CRP</option>
                    <option value="cref">CREF</option>
                    <option value="crn">CRN</option>
                    <option value="crefito">CREFITO</option>
                    <option value="otherRecord">OUTRO</option>
                  </select>
                </div>
              </div>
              <div className="flex-ResponsibleData">
                <div
                  className={`${widthSize > 450 ? 'mb-20 col-7' : 'mb-20 col-16'
                    }`}>
                  <InputClassic
                    title="Email"
                    placeholder="Email"
                    name="email"
                    value={data?.email}
                    onChange={handleChange}
                  />
                  {errors.email && <p className="error"> Email Inválido</p>}
                </div>
                <div
                  className={`${widthSize > 450 ? 'mb-20 col-5' : 'mb-20 col-16'
                    }`}>
                  <InputClassic
                    title="CPF"
                    placeholder="CPF"
                    maxLength={14}
                    name="cpf"
                    value={cpfMask(data?.cpf)}
                    onChange={handleChange}
                    disabled={!!partner.cpf}
                  />
                  {errors.cpf && <p className="error"> CPF Inválido</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-20" />
          <div className="mb-20" />

          <div className="col-12 formations__bigger--container">
            <div className="footerContainer">
              <span onClick={handleClose} className="cancel">
                Cancelar
              </span>
              <Button
                sx={{ background: saveButtonColor, borderRadius: '22px' }}
                variant="contained"
                onClick={handleSave}
                disabled={disabledSave}>
                {widthSize > 450 ? 'Salvar alterações' : 'Salvar'}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { profile } = state?.profile;
  const { partner } = state?.authentication;

  return { profile, partner };
};

const mapDispatchToProps = {
  putResponsibleData,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleModal);
