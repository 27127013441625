import { Activity } from 'src/Models/Activity';
import { action } from 'typesafe-actions';
import { ActivityActionTypes } from './types';

export const getActivities = () => action(ActivityActionTypes.GET_ACTIVITIES_REQUEST);
export const getActivitiesSuccess = (activities: Activity[]) => action(ActivityActionTypes.GET_ACTIVITIES_REQUEST_SUCCESS, activities);
export const getActivitiesError = (message: string) => action(ActivityActionTypes.GET_ACTIVITIES_REQUEST_ERROR, message);

export const getActivityById = (id: number) => action(ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID, id);
export const getActivityByIdSuccess = (activity: Activity) => action(ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID_SUCCESS, activity);
export const getActivityByIdError = (message: string) => action(ActivityActionTypes.GET_ACTIVITIES_REQUEST_BY_ID_ERROR, message);

export const getScheduleHistory = (paginationOffset = 0) => action(ActivityActionTypes.GET_SCHEDULE_HISTORY, paginationOffset);
export const getScheduleHistorySuccess = (scheduleHistory: any[]) => action(ActivityActionTypes.GET_SCHEDULE_HISTORY_SUCCESS, scheduleHistory);
export const getScheduleHistoryError = (message: string) => action(ActivityActionTypes.GET_SCHEDULE_HISTORY_ERROR, message);

export const postActivity = (activity: Activity) => action(ActivityActionTypes.POST_ACTIVITY, activity);
export const postActivitySuccess = () => action(ActivityActionTypes.POST_ACTIVITY_SUCCESS);
export const postActivityError = (error: string) => action(ActivityActionTypes.POST_ACTIVITY_ERROR, error);

export const deleteActivitySuccess = () => action(ActivityActionTypes.DELETE_ACTIVITY_SUCCESS);
export const deleteActivity = (activityId: number) => action(ActivityActionTypes.DELETE_ACTIVITY, activityId);
export const deleteActivityError = (message: string) => action(ActivityActionTypes.DELETE_ACTIVITY_ERROR, message);

export const putActivity = (activity: Activity) => action(ActivityActionTypes.PUT_ACTIVITY, activity);
export const putActivitySuccess = () => action(ActivityActionTypes.PUT_ACTIVITY_SUCCESS);
export const putActivityError = (error: string) => action(ActivityActionTypes.PUT_ACTIVITY_ERROR, error);

export const resetPostPutStatus = () => action(ActivityActionTypes.RESET_POST_PUT_STATUS);