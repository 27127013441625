import { Reducer } from 'redux';
import { BillingTypes, BillingState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: BillingState = {};

const billingReducer: Reducer<BillingState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case BillingTypes.GET_BILLING_HISTORY: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case BillingTypes.GET_BILLING_HISTORY_SUCCESS: {
      return {
        ...state,
        page: action.payload,
        status: HttpRequestStatus.SUCCESS,
      };
    }
    case BillingTypes.GET_BILLING_HISTORY_ERROR: {
      return {
        ...state,
        status: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case BillingTypes.RESET_STATE: {
      return {
        ...state,
        status: HttpRequestStatus.NOOP,
      };
    }

    default: {
      return state;
    }
  }
};

export default billingReducer;
