import { PhoneType } from 'src/Models/Phone';

export const defaultImage = {
  defaultValues: {
    image: null,
  },
};

export const defaultData = {
  id: 0,
  fantasyName: '',
  socialReason: '',
  cnpj: '',
  customUrl: '',
  address: {
    id: 0,
    street: '',
    number: '',
    zipcode: '',
    district: '',
    complement: '',
    city: {
      name: '',
      state: {
        name: '',
      },
    },
  },
  phone: { id: null, number: '', type: PhoneType.PROFESSIONAL },
  image: { id: 0, contentType: '', file: '', fileName: '' },
};

export const defaultError = {
  cep: false,
  cnpj: false,
  customUrl: false,
};

export const getEnterpriseData = (profile) => {
  return {
    id: profile?.id,
    cpf: profile?.cpf || undefined,
    cnpj: profile?.cnpj || defaultData?.cnpj,
    phone: profile?.phone || defaultData?.phone,
    image: profile?.image || defaultData?.image,
    address: profile?.address || defaultData?.address,
    fantasyName: profile?.fantasyName || defaultData?.fantasyName,
    socialReason: profile?.socialReason || defaultData?.socialReason,
    customUrl: profile?.customUrl || defaultData?.customUrl,
  };
};
