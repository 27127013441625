import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const MuiTextFieldStyled = styled(TextField)`
  & label.Mui-focused {
    color: #bfbfbf;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #bfbfbf;
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #bfbfbf;
    }
    &:hover fieldset {
      border-color: #25b379;
    }
    &.Mui-focused fieldset {
      border-color: #25b379;
    }
  }
`;
