import { AxiosResponse } from 'axios';
import { Agenda } from 'src/Models/Agenda';
import { ResultPage } from 'src/Models/ResultPage';
import { api } from '.';

export const AgendaApi = () => {
  const getAgenda = (): Promise<AxiosResponse<ResultPage<Agenda[]>>> => {
    return api.get<ResultPage<Agenda[]>>(`/agendas/current-user`);
  };
    
  const getAgendaById = (agendaId: number): Promise<AxiosResponse<Agenda>> => {
    return api.get<Agenda>(`/agendas/${agendaId}`);
  };
  const postAgenda = (agenda: Agenda): Promise<AxiosResponse<Agenda>> => {
    return api.post<Agenda>('/agendas',agenda);
  }

  const putAgenda = (agenda: Agenda): Promise<AxiosResponse<Agenda>> => {
    return api.put<Agenda>('/agendas',agenda);
  }

  const deleteAgenda = (agendaId: number): Promise<AxiosResponse<any>> => {
    return api.delete<any>(`/agendas/${agendaId}`);
  };


  return {
    getAgendaById,
    putAgenda,
    postAgenda,
    getAgenda,
    deleteAgenda
  };
};

export default AgendaApi();