import React, { ChangeEvent, useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WifiIcon from '@material-ui/icons/Wifi';

import img from '../../../Images/Keiken/Business/user.png';
import wifi from '../../../Images/Keiken/Partner/wifi.png';

import './styles.scss';
import BaseModalTokenStyles from './BaseModalTokenStyles';
import {
  getByToken,
  postUseToken,
  postUseTokenPlan,
} from 'src/Stores/customerToken/actions';
import { IRootState } from 'src/Stores';
import { connect } from 'react-redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import moment from 'moment';
import { brasilMoneyMask } from 'src/Utils/NumericUtils';
import { isEmpty } from 'lodash';

const useStyles = BaseModalTokenStyles;

export interface BaseModal extends WithTranslation, DispatchProps, StateProps {
  text: string;
}

const BaseModal: React.FC<BaseModal> = ({
  t,
  text,
  customerActivity,
  status,
  getByToken,
  postUseToken,
  postUseTokenPlan,
}) => {
  // @ts-ignore
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [validateToken, setValidateToken] = useState('initial');
  const [openDetails, setOpenDetails] = useState(false);
  const [formData, setFormData] = useState({
    token: '',
    tokenUsed: false,
  });

  useEffect(() => {
    if (status === HttpRequestStatus.SUCCESS) {
      setValidateToken('true');
    } else if (status === HttpRequestStatus.ERROR) {
      return setValidateToken('false');
    }
  }, [status, customerActivity]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setValidateToken('initial');
      setFormData({
        token: '',
        tokenUsed: undefined,
      });
    }, 500);
  };

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleValidateToken = (token: string) => {
    getByToken(token);
    if (customerActivity?.tokenUsed) {
      setFormData({ ...formData, tokenUsed: customerActivity?.tokenUsed });
    }
  };

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  const handleTokenValidation = () => {
    if (!isEmpty(customerActivity?.activitySchedule)) {
      postUseToken(customerActivity?.id);
    } else {
      postUseTokenPlan(customerActivity?.id);
      setFormData({ ...formData, tokenUsed: true });
    }
    setFormData({ ...formData, tokenUsed: true });
  };

  const getValidationConfig = () => {
    if (customerActivity?.tokenUsed) {
      return {
        tokenText: 'Token Validado',
        tokenBackground: '#D3D3D3',
      };
    }
    return {
      tokenText: 'Validar Token',
      tokenBackground: '#25B379',
    };
  };

  const { tokenText, tokenBackground } = getValidationConfig();

  return (
    <div>
      <span onClick={handleOpen}>{text}</span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            {validateToken === 'initial' && (
              <div>
                <div className={classes.align}>
                  <h2 className={classes.title} id="transition-modal-title">
                    {t('partner.insert_activity_token')}
                  </h2>
                  <CloseIcon onClick={handleClose} className={classes.icon} />
                </div>
                <div className={classes.input}>
                  <span className={classes.spanInput}>
                    {t('partner.activity_token')}
                  </span>
                  <div className="inputCustomPlus">
                    <input
                      type="text"
                      placeholder="12345"
                      id="token"
                      name="token"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className={classes.center}>
                  <button
                    className="PartnerDefaultButton"
                    type="button"
                    onClick={() => {
                      handleValidateToken(formData.token);
                    }}>
                    <CheckIcon className="iconSize" />
                    Validar
                  </button>
                </div>
              </div>
            )}
            {validateToken === 'false' && (
              <div>
                <div className={classes.align}>
                  <h2 className={classes.title} id="transition-modal-title">
                    {t('partner.activity_token')}
                  </h2>
                  <CloseIcon onClick={handleClose} className={classes.icon} />
                </div>
                <span className={classes.spanModal}>
                  {t('partner.no_activity_token')}
                </span>
                <div className={classes.center}>
                  <button
                    className="PartnerDefaultButton"
                    type="button"
                    onClick={() => {
                      setValidateToken('initial');
                    }}>
                    <CheckIcon className="iconSize" />
                    {t('partner.try_new_token')}
                  </button>
                </div>
              </div>
            )}
            {validateToken === 'true' && (
              <div>
                <div className={classes.align}>
                  <div>
                    <h2 className={classes.title} id="transition-modal-title">
                      {customerActivity?.activitySchedule?.activity?.name ??
                        customerActivity?.activityPlan?.name}
                    </h2>
                    <div className={classes.spanTokenHeader}>
                      <span style={{ color: '#8D979E' }}>Token:</span>
                      <span>{formData?.token}</span>
                    </div>
                  </div>

                  <CloseIcon onClick={handleClose} className={classes.icon} />
                </div>
                <div className={classes.underline} />
                <div className="flex mt-20 mb-30">
                  {/*Todo : Quando customer tiver imagem adicionar aqui
                  <div className="mr-15">
                    <img src={img} alt="Pessoa" className="width-70" />
                  </div> */}
                  <div>
                    <span className={classes.modalTitle}>
                      {customerActivity?.customer?.name}
                    </span>
                    <br />
                    <span className={classes.modalSubtitle}>
                      {customerActivity?.customer?.user?.email}
                    </span>
                    {/*
                      <span className={classes.modalSubtitle}>
                        (41)99999-9999
                      </span> 
                      */}
                  </div>
                </div>
                <div>
                  <span className={classes.modalTitle}>
                    {isEmpty(customerActivity?.activityPlan)
                      ? t('partner.schedules')
                      : 'Datas de Início | Término'}
                  </span>
                  <div className={classes.Schedule}>
                    {/*
                                            <div>
                      <CalendarTodayIcon className="mr-5px" />
                      <span>Quinzenal</span>
                    </div>
                      */}
                    <div>
                      {customerActivity?.activitySchedule?.activity
                        ?.duration && (
                        <>
                          <ScheduleIcon className="mr-5px" />
                          <span>
                            {
                              customerActivity?.activitySchedule?.activity
                                ?.duration
                            }
                            min
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className={classes.fitCards}>
                    {/*
                                            <div className={classes.disabledCard}>
                      <span>qua 21 Jan 12h15</span>
                    </div>
                      */}
                    <div
                      className={classes.enabledCard}
                      onClick={handleOpenDetails}>
                      <span>
                        {customerActivity?.startPeriodDate == null
                          ? moment(customerActivity?.date).format(
                              'ddd D MMM h:mm',
                            )
                          : moment(customerActivity?.startPeriodDate).format(
                              'ddd D MMM YYYY',
                            )}
                      </span>
                    </div>
                    {customerActivity?.endPeriodDate != null && (
                      <div
                        className={classes.enabledCard}
                        onClick={handleOpenDetails}
                        style={{ borderColor: 'red', color: 'red' }}>
                        <span>
                          {moment(customerActivity?.endPeriodDate).format(
                            'ddd D MMM  YYYY',
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  {/*
                        {openDetails && (
                    <div>
                      <br />
                      <span className={classes.modalTitle}>
                        {t('partner.plafce')}
                      </span>
                      <br />
                      <div className={classes.externalDiv}>
                        <div>
                          <img
                            src={wifi}
                            alt="online"
                            className={classes.sizeImage}
                          />
                        </div>
                        <div className={classes.divContentCard}>
                          <span className={classes.divContentCardTitle}>
                            Online
                          </span>
                          <br />
                          <span className={classes.divContentCardSub}>
                            {t('partner.use_token')}
                          </span>
                          <br />
                          <button className={classes.divButton}>
                            <WifiIcon />
                            &nbsp; {t('partner.accssess_activity')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )} 
                    */}
                </div>
                <br />
                <div>
                  <span className={classes.modalTitle}>Pagamento</span>
                  <br />
                  <div className={classes.paymentAlign}>
                    {/*
                                              <div className={classes.flexColumn}>
                      <span className={classes.modalSubtitle}>
                        {t('partner.payed_by_enterprise')}
                      </span>
                      <span className={classes.modalSubtitleGreen}>
                        RS100,00 - Boleto
                      </span>
                    </div>
                      */}
                    <div className={classes.flexColumn}>
                      <span className={classes.modalSubtitle}>
                        {t('partner.payed_by_customer')}
                      </span>
                      <span className={classes.modalSubtitleGreenBig}>
                        {brasilMoneyMask(customerActivity?.pricePaid)}
                      </span>
                    </div>
                    <div className={classes.flexColumn}>
                      <span className={classes.modalSubtitle}>
                        {t('partner.value_to_receive')}
                      </span>
                      <span className={classes.modalSubtitleGreenBig}>
                        {brasilMoneyMask(
                          customerActivity?.activitySchedule?.activity?.price ??
                            customerActivity?.activityPlan?.price,
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.validationButton}>
                  <button
                    type="button"
                    className={classes.divButton}
                    disabled={customerActivity?.tokenUsed}
                    style={{ background: tokenBackground }}
                    onClick={() => handleTokenValidation()}>
                    {tokenText}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { customerActivity, tokenObj, status } = state?.customerToken;

  return { customerActivity, tokenObj, status };
};

const mapDispatchToProps = {
  getByToken,
  postUseToken,
  postUseTokenPlan,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(BaseModal));
