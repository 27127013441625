import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from 'reactstrap';
import './styles.scss';

import { styles } from '../BaseMegaCardCss/styles';
import {
  Agenda,
  AgendaUtils,
  DaysOfWeek,
  DaysOfWeekPortuguese,
  PresetAgendaActivitySchedule,
} from 'src/Models/Agenda';
import _ from 'lodash';
import moment from 'moment';
import { IconButton, Tooltip } from '@material-ui/core';
import { AddCircle, CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => styles);

const daysOfWeek = [
  DaysOfWeek.MONDAY,
  DaysOfWeek.TUESDAY,
  DaysOfWeek.WEDNESDAY,
  DaysOfWeek.THURSDAY,
  DaysOfWeek.FRIDAY,
  DaysOfWeek.SATURDAY,
  DaysOfWeek.SUNDAY,
];

export interface SchedulesProps extends WithTranslation {
  presetAgendaActivitySchedules?: PresetAgendaActivitySchedule[];
  onChange?: (item: PresetAgendaActivitySchedule[]) => void;
  isEdit?: boolean;
  color?: string;
  intervalSelected?: number;
  presetAgendaActivitySchedule?: PresetAgendaActivitySchedule[];
}

const Schedules: React.FC<SchedulesProps> = ({
  t,
  presetAgendaActivitySchedules,
  onChange,
  color,
  intervalSelected,
  presetAgendaActivitySchedule,
}) => {
  // @ts-ignore
  const classes = useStyles();
  const [
    newPresetAgendaActivitySchedules,
    setNewPresetAgendaActivitySchedules,
  ] = useState(presetAgendaActivitySchedules ?? []);
  const handleOnClickSlot = (hour: string, day: string) => {
    const startAt = moment(hour, 'HH:mm').format('HH:mm:ss');
    setNewPresetAgendaActivitySchedules((prevState) => {
      if (
        prevState?.some((it) => it.startAt == startAt && it.dayOfWeek == day)
      ) {
        return prevState?.filter(
          (it) => it.startAt != startAt || it.dayOfWeek != day,
        );
      }

      return prevState.concat({
        startAt: startAt,
        dayOfWeek: day,
        zoneId: 'America/Sao_Paulo',
      });
    });
  };

  const handleOnCreateSchedule = (hour: string, day: string) => {
    if (day == null) {
      daysOfWeek.forEach((it) => {
        handleOnClickSlot(hour, it);
      });
      return;
    }
    handleOnClickSlot(hour, day);
  };

  useEffect(() => {
    if (presetAgendaActivitySchedules != null) {
      setNewPresetAgendaActivitySchedules(presetAgendaActivitySchedules);
    }
  }, [presetAgendaActivitySchedules]);

  useEffect(() => {
    if (presetAgendaActivitySchedule != null) {
      setNewPresetAgendaActivitySchedules(presetAgendaActivitySchedule);
    }
  }, [intervalSelected, presetAgendaActivitySchedule]);

  useEffect(() => {
    onChange?.(newPresetAgendaActivitySchedules);
  }, [newPresetAgendaActivitySchedules]);

  const mapDaysOfWeek = () => {
    return (
      <div className="days">
        <div className="filler" />
        <div className="filler" />
        {daysOfWeek?.map((day, index) => (
          <div className="day" key={`schedule-dayOfWeek-${day}-${index}`}>
            <div>
              <span>{t(`daysOfWeek.${day}`)}</span>
              <br />
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div>
      <Container>
        <div className={classes.baseBorder}>
          <div className="ScheduleComponentEmpty">
            <div className="calendar">
              <div className="title"></div>
              {mapDaysOfWeek()}
            </div>
            <div className="scrollable-content">
              <CreateSlotsForSchedule
                data={newPresetAgendaActivitySchedules}
                onClick={handleOnCreateSchedule}
                color={color}
                intervalSelected={intervalSelected}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const CreateSlotsForSchedule = ({ data, onClick, color, intervalSelected }) => {
  const hourOfWeek = () => {
    const startAt = _.range(0, 24).flatMap((hours) =>
      _.range(0, 60, intervalSelected).map(
        (minutes) =>
          `${hours.toString().padStart(2, '0 ')}:${minutes
            .toString()
            .padStart(2, '0')}`,
      ),
    );
    return startAt;
  };

  const hour = useMemo(() => hourOfWeek(), [data, intervalSelected]);
  const isSelected = (hour, day) => {
    const hourTemp = moment(hour, 'HH:mm').format('HH:mm:ss');
    return data?.some((it) => it.startAt == hourTemp && it.dayOfWeek == day);
  };

  function calculateIntervals(intervalSelected) {
    return Math.floor((24 * 60) / intervalSelected);
  }
  const numRepeatsBasedOnInterval = calculateIntervals(intervalSelected);
  const rowStyles = `repeat(${numRepeatsBasedOnInterval}, 3em)`;

  return (
    <div className="content" style={{ gridTemplateRows: rowStyles }}>
      <div className="filler-col" />
      <div className="filler-col" />
      {hour?.map((hour, index) => (
        <>
          <Tooltip title="Clique para preencher os horários" arrow>
            <div
              className="time"
              style={{ gridRow: index }}
              key={`schedule-hour-${hour}-${index}`}
              onClick={() => onClick?.(hour)}>
              <span className="hourColumn">{hour}</span>
              <AddCircle fontSize="small" color="secondary" />
            </div>
          </Tooltip>
          {daysOfWeek?.map((day, dayIndex) => (
            <div
              key={`schedule-slot-dayOfWeek-${day}-${dayIndex}`}
              className="column-item"
              style={{ gridRow: index }}
              onClick={() => onClick?.(hour, day)}>
              {!isSelected(hour, day) ? (
                <div className="empty-item"></div>
              ) : (
                <div
                  className="selected-item"
                  style={{ color: color, borderColor: color }}>
                  Selecionado
                </div>
              )}
            </div>
          ))}
        </>
      ))}
    </div>
  );
};

export default withTranslation()(Schedules);
