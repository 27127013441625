import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ModalProps } from 'reactstrap';
import GradientButtonSC from 'src/Components/GradientButtonSC';
import {
  SimpleModalBody,
  SimpleModalHeader,
  SimpleModalFooter,
  SimpleModalContainer,
} from './styles';
import Loading from '../loading/loading';

export interface SimpleModalProps extends ModalProps, WithTranslation {
  onCancel: () => void;
  onConfirm: () => void;
  labelConfirm: string;
  labelCancel: string;
  loading?: boolean;
  // type?: string;
}

const SimpleModal: React.FC<SimpleModalProps> = (props) => {
  const {
    confirmEnabled,
    backdrop,
    keyboard,
    isOpen,
    onCancel,
    onConfirm,
    modalTitle,
    ComponentTitle,
    labelCancel,
    labelConfirm,
    children,
    ComponentFooter,
    onToggle,
    width = '700px',
    loading,
  } = props;

  function handleConfirm() {
    onConfirm && onConfirm();
  }

  function handleCancel() {
    onCancel && onCancel();
  }

  function handleToggle() {
    onToggle && onToggle();
  }

  const hasFunctions = onConfirm || onCancel;

  const confirmDisabled = confirmEnabled != null ? !confirmEnabled : false;
  return (
    <SimpleModalContainer
      modalClassName="simple-modal-content"
      isOpen={isOpen}
      toggle={handleToggle}
      backdrop={backdrop ?? 'static'}
      keyboard={keyboard ?? true}
      style={{ width }}
      {...props}>
      {!ComponentTitle ? (
        <SimpleModalHeader>{modalTitle}</SimpleModalHeader>
      ) : (
        <ComponentTitle />
      )}
      <SimpleModalBody>{children}</SimpleModalBody>
      {hasFunctions && !ComponentFooter ? (
        <SimpleModalFooter>
          {onCancel && (
            <GradientButtonSC
              isSecundary
              text
              onClick={handleCancel}
              title={labelCancel}
            />
          )}
          {onConfirm && (
            <>
              {loading ? (
                <Loading />
              ) : (
                <GradientButtonSC
                  buttonDisabled={confirmDisabled}
                  onClick={handleConfirm}
                  title={labelConfirm}
                />
              )}
            </>
          )}
        </SimpleModalFooter>
      ) : (
        ComponentFooter && <ComponentFooter />
      )}
    </SimpleModalContainer>
  );
};

export default withTranslation()(SimpleModal);
