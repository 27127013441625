import { action } from 'typesafe-actions';
import { ConferenceSession } from '../../Models/ConferenceSession';
import { ConferenceSessionTypes } from './types';

export const getOrCreateConferenceSession = (activityScheduleId: number) =>
  action(ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION, {
    activityScheduleId,
  });

export const getOrCreateConferenceSessionSuccess = (
  payload: ConferenceSession,
) => {
  return action(
    ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION_SUCCESS,
    payload,
  );
};

export const getOrCreateConferenceSessionError = (message: string) =>
  action(
    ConferenceSessionTypes.GET_OR_CREATE_CONFERENCE_SESSION_ERROR,
    message,
  );

export const reset = () => action(ConferenceSessionTypes.RESET);
