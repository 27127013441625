import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import Images from '../../../Themes/Constants/Images';
import AuthUtils from '../../../Utils/AuthUtils';

interface PageNotFoundProps extends WithTranslation, RouteComponentProps {}

const PageNotFound: React.FC<PageNotFoundProps> = ({ history, t }) => {
  const defaultPath = 'pageNotFound';

  const redirectToMainPath = () => {
    history.replace(AuthUtils.getMainPath());
  };

  return (
    <Row className="base-full-height">
      <Col md={12} className="base-centered-column">
        <div className="base-access-denied-wrapper">
          <img
            onClick={redirectToMainPath}
            src={Images.icons.pageNotFound}
            alt="Não encontrada"
          />
          <Alert color="info">
            <span>{t(`${defaultPath}.title`)}</span>
          </Alert>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(withRouter(PageNotFound));
