import { PixType } from '../../Models/BankAccount';

class PixUtils {
  static isPixTypeCpfOrCnpj = (pixNumber: string) => {
    if (pixNumber === null || pixNumber === undefined || pixNumber === '') {
      return undefined;
    }
    const cleanedNumber = pixNumber.replace(/\D/g, '');

    if (cleanedNumber.length < 13 && cleanedNumber.length > 10) {
      return PixType.CPF;
    } else if (cleanedNumber.length > 13 && cleanedNumber.length < 15) {
      return PixType.CNPJ;
    } else {
      return undefined;
    }
  };
}

export default PixUtils;
