import { Address } from './Address';
import { Company } from './Company';
import { Phone } from './Phone';
import { User } from './user';

export interface CustomerDependent {
  id?: number;
  email?: string;
}

export enum PartnerStatus {
  Active = 'active',
  Pending = 'pending',
  Inactive = 'inactive',
}

export interface Customer {
  id?: number;
  name?: string;
  customerPartnerHistoryStatus: PartnerStatus;
  user?: User;
  phones?: Phone[];
  company?: Company;
  customerDependents?: CustomerDependent[];
  cpf?: string;
  address?: Address;
  birthDate?: string;
  payrollDiscountEnabled: boolean;
}
