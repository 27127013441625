import React, { useEffect, useState } from 'react';
import ContainerInfoPayment from '../ContainerInfoPaymentsPending';
import {
  ButtonContinue,
  ButtonSort,
  Container,
  ContainerAll,
  ContainerDropDown,
  ContainerFiltersAndButton,
  ContainerInputAndImg,
  ContainerNumber,
  ContainerNumbers,
  ContainerOptions,
  ContainerPagination,
  ContainerPaymentsPending,
  ContainerTitleAndFilters,
  ContainerTooltip,
  Divider,
  ImageTooltip,
  InputStyled,
  Title,
  TitleAndTootip,
  TitleButtonContinue,
  TitleButtonSort,
  TitleDropDown,
  TitleNumber,
  TitleOptions,
  TitleTooltip,
  Tooltip,
} from './styles';

import ArrowLeftGrey from '../../Images/Icons/arrowLeftGrey.svg';
import ArrowLeftGreen from '../../Images/Icons/arrowLeftGreen.svg';
import ArrowRightGreen from '../../Images/Icons/arrowRightGreen.svg';
import ArrowRightGrey from '../../Images/Icons/arrowRightGrey.svg';
import TootipDoubtGreen from '../../Images/Icons/tootipDoubtGreen.svg';
import SearchSvg from '../../Images/Icons/search.svg';
import SortSvg from '../../Images/Icons/sortGreen.svg';
import ArrowRightWhiteSvg from '../../Images/Icons/arrowRightWhite.svg';
import ArrowDownSortSvg from '../../Images/Icons/ArrowDownSort.svg';
import ArrowUpSortSvg from '../../Images/Icons/ArrowUpSort.svg';
import {
  useTranslation,
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import PlanService from 'src/Services/Plan.service';
import {
  PreScheduleByPartner,
  ResponsePreScheduleByPartner,
} from 'src/Models/Plan';
import PlanApi from 'src/Services/Api/PlanApi';
import { element } from 'prop-types';

enum SortTo {
  Date = 'date',
  Name = 'name',
  Plan = 'plan',
}

const PaymentsPending = () => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [sortTo, setSortTo] = useState<SortTo>();
  const [isDescending, setIsDescending] = useState<boolean>(false);
  const [payments, setPayments] = useState<ResponsePreScheduleByPartner>();
  const [searchEmailOrName, setSearchEmailOrName] = useState<string>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [auxNumber, setAuxNumber] = useState<number>(0);
  const [pageToSearch, setPageToSearch] = useState<number>(0);

  const handleChooseSortTo = async (type: SortTo) => {
    if (type === sortTo) {
      setSortTo(undefined);
      const res = await PlanService.getPreScheduleByPartner(10, pageToSearch);
      setPayments(res);
      setTotalPages(res.totalPages);
    } else {
      setSortTo(type);
      const res = await PlanService.getPreScheduleByPartner(10, pageToSearch, {
        order: searchEmailOrName
          ? `&search=${searchEmailOrName}&${
              isDescending ? 'orderByDesc=' : 'orderByAsc='
            }${type}`
          : `&${isDescending ? 'orderByDesc=' : 'orderByAsc='}${type}`,
      });
      setPayments(res);
      setTotalPages(res.totalPages);
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    const getPreScheduleByPartnerSearch = async () => {
      const res = await PlanService.getPreScheduleByPartner(10, pageToSearch, {
        order:
          searchEmailOrName && sortTo
            ? `&search=${searchEmailOrName}&${
                isDescending ? 'orderByDesc=' : 'orderByAsc='
              }${sortTo}`
            : searchEmailOrName !== '' && searchEmailOrName
            ? `&search=${searchEmailOrName}`
            : `&${isDescending ? 'orderByDesc=' : 'orderByAsc='}${sortTo}`,
      });
      setPayments(res);
      setTotalPages(res.totalPages);
    };

    const getPreScheduleByPartner = async () => {
      const res = await PlanService.getPreScheduleByPartner(10, pageToSearch);
      setPayments(res);
      setTotalPages(res.totalPages);
    };

    if (searchEmailOrName) {
      getPreScheduleByPartnerSearch();
    } else if (sortTo) {
      getPreScheduleByPartnerSearch();
    } else {
      getPreScheduleByPartner();
    }
  }, [pageToSearch, searchEmailOrName]);

  const handleNextPage = () => {
    if (
      page < totalPages - 3 ||
      (totalPages <= 9 && page < 9 && totalPages > page)
    ) {
      setPage(page + 1);
    } else if (auxNumber <= totalPages - 2 && totalPages > 9) {
      setAuxNumber(
        auxNumber === 2 ? auxNumber + totalPages - 3 : auxNumber + 1,
      );
    }
  };

  useEffect(() => {
    if (auxNumber === 1) {
      setPageToSearch(totalPages - auxNumber - 2);
    } else if (auxNumber === 2) {
      setPageToSearch(totalPages - auxNumber);
    } else if (auxNumber > 2) {
      setPageToSearch(totalPages - 1);
    } else {
      setPageToSearch(page - 1);
    }
  }, [page, auxNumber]);

  const handleBackPage = () => {
    if (auxNumber >= 1) {
      setAuxNumber(
        auxNumber === totalPages - 1
          ? auxNumber - (totalPages - 3)
          : auxNumber - 1,
      );
    } else if (page > 1) {
      setPage(page - 1);
    }
  };

  const deletePayment = async (payment: PreScheduleByPartner) => {
    const res = await PlanService.getCancelGetPreScheduleByPartner({
      id: payment.customerActivityPlan.id,
      type: payment.type,
    });
    setPageToSearch(0);
    setAuxNumber(0);
    setPage(1);
    window.location.reload();
  };

  return (
    <ContainerAll>
      <Container>
        <ContainerTitleAndFilters>
          <TitleAndTootip>
            <Title>{t('makePurchase.paymentsPending.title')}</Title>
            <ContainerTooltip>
              <ImageTooltip
                src={TootipDoubtGreen}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
              {showTooltip && (
                <Tooltip>
                  <TitleTooltip>
                    {t('makePurchase.paymentsPending.tooltip')}
                  </TitleTooltip>
                </Tooltip>
              )}
            </ContainerTooltip>
          </TitleAndTootip>
          <ContainerFiltersAndButton>
            <ContainerInputAndImg>
              <InputStyled
                placeholder={t(
                  'makePurchase.paymentsPending.placeholderInputSearch',
                )}
                onChange={(e) => setSearchEmailOrName(e.target.value)}
              />
              <img src={SearchSvg} />
            </ContainerInputAndImg>
            <ContainerTooltip>
              <ButtonSort onClick={() => setShowDropDown(!showDropDown)}>
                <img src={SortSvg} />
                <TitleButtonSort>
                  {t('makePurchase.paymentsPending.order')}{' '}
                  {sortTo
                    ? `${t('makePurchase.paymentsPending.by')} ${t(
                        `makePurchase.paymentsPending.${sortTo}`,
                      )}`
                    : ''}
                </TitleButtonSort>
              </ButtonSort>
              {showDropDown && (
                <ContainerDropDown>
                  <ContainerOptions>
                    <TitleDropDown>
                      {t('makePurchase.paymentsPending.OrderBy')}
                    </TitleDropDown>
                    <img
                      src={isDescending ? ArrowDownSortSvg : ArrowUpSortSvg}
                      onClick={() => setIsDescending(!isDescending)}
                    />
                  </ContainerOptions>
                  <ContainerOptions
                    onClick={() => handleChooseSortTo(SortTo.Date)}>
                    <TitleOptions>
                      {t('makePurchase.paymentsPending.date')}
                    </TitleOptions>
                  </ContainerOptions>
                  <ContainerOptions
                    onClick={() => handleChooseSortTo(SortTo.Name)}>
                    <TitleOptions>
                      {t('makePurchase.paymentsPending.name')}
                    </TitleOptions>
                  </ContainerOptions>
                  <ContainerOptions
                    onClick={() => handleChooseSortTo(SortTo.Plan)}>
                    <TitleOptions>
                      {t('makePurchase.paymentsPending.plan')}
                    </TitleOptions>
                  </ContainerOptions>
                </ContainerDropDown>
              )}
            </ContainerTooltip>
          </ContainerFiltersAndButton>
        </ContainerTitleAndFilters>
        <Divider />
        <ContainerPaymentsPending>
          {payments?.content &&
            payments?.content?.map((element, index) => {
              return (
                <ContainerInfoPayment
                  payment={element}
                  deletePayment={deletePayment}
                  key={index}
                />
              );
            })}
        </ContainerPaymentsPending>
        <ContainerPagination>
          <img
            src={page === 1 ? ArrowLeftGrey : ArrowLeftGreen}
            onClick={() => handleBackPage()}
          />
          <ContainerNumbers>
            {totalPages < 10
              ? Array(totalPages)
                  .fill('a')
                  .map((element, index) => {
                    return (
                      <ContainerNumber
                        isSelect={index === page - 1}
                        key={index}>
                        <TitleNumber isSelect={index === page - 1}>
                          {index + 1}
                        </TitleNumber>
                      </ContainerNumber>
                    );
                  })
              : Array(totalPages)
                  .fill('a')
                  .map((element, index) => {
                    if (index >= 3 && index < 4 && page != totalPages - 3) {
                      return (
                        <ContainerNumber key={index}>
                          <TitleNumber>...</TitleNumber>
                        </ContainerNumber>
                      );
                    } else if (index === totalPages - 1 || index < 3) {
                      return (
                        <ContainerNumber
                          isSelect={
                            page < totalPages - 3
                              ? index === page - page
                              : index === auxNumber
                          }
                          key={index}>
                          <TitleNumber
                            isSelect={
                              page < totalPages - 3
                                ? index === page - page
                                : index === auxNumber
                            }>
                            {index === totalPages - 1
                              ? index + 1
                              : index + page}
                          </TitleNumber>
                        </ContainerNumber>
                      );
                    }
                  })}
          </ContainerNumbers>
          <img
            src={
              page === totalPages || auxNumber === totalPages - 1
                ? ArrowRightGrey
                : ArrowRightGreen
            }
            onClick={() => handleNextPage()}
          />
        </ContainerPagination>
      </Container>
    </ContainerAll>
  );
};

export default PaymentsPending;
