import React from 'react';
import '../../styles.scss';

import WifiIcon from '@mui/icons-material/Wifi';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BuildingIcon from '../../../../../Images/Icons/Mask.png';
import { Tooltip } from '@mui/material';
import hexRgb, { RgbaObject } from 'hex-rgb';
import { convertRGBAtoRGB } from 'src/Utils/Utils';

interface Props {
  schedule: any;
  onClick: () => void;
  color?: string;
  title?: string;
  tooltip?: string;
  scheduled?: boolean;
}

const ScheduleGridCell: React.FC<Props> = ({
  schedule,
  color,
  onClick,
  title,
  tooltip,
  scheduled = false,
}) => {
  const renderScheduled = (): JSX.Element => {
    let colors = convertRGBAtoRGB(hexRgb(`${color}33`));

    const backgroundStyle =
      scheduled == false
        ? {
            backgroundColor: `rgb(${colors.red},${colors.green},${colors.blue})`,
            borderRadius: 15,
            opacity: 1,
          }
        : {};
    return (
      <div
        className={'event available'}
        style={{
          gridColumn: schedule?.column,
          gridRow: schedule?.scheduleRow,
          border: schedule.multiSchedules
            ? `1px solid ${schedule.agendaColor}`
            : '',
          marginLeft: schedule?.marginLeft,
          marginTop: schedule?.marginLeft,
          color: color,
          ...backgroundStyle,
        }}
        onClick={onClick}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className="event-card">
          <span style={{ fontSize: '13px' }}>{title}</span>
          <span style={{ fontSize: '13px' }}>
            {(schedule?.scheduledHour > 9
              ? schedule?.scheduledHour
              : '0' + schedule?.scheduledHour) +
              ':' +
              schedule?.finalMinutes}
          </span>
        </div>
      </div>
    );
  };
  if (scheduled == false) {
    if (tooltip != null) {
      return <Tooltip title={tooltip}>{renderScheduled()}</Tooltip>;
    }
    return renderScheduled();
  }

  const { customerActivities = [] } = schedule || {};

  const { status = '' } = customerActivities[0] || {};

  const dottedBorder =
    status === 'IN_PROCESS' || status === 'PRE_SCHEDULE_BY_PARTNER'
      ? `2px dotted ${schedule.agendaColor}`
      : `1px solid ${schedule.agendaColor}`;

  const background =
    status !== 'IN_PROCESS' && status !== 'PRE_SCHEDULE_BY_PARTNER'
      ? 'linear-gradient(0deg, rgba(116, 205, 76, 0.1), rgba(116, 205, 76, 0.1)), #FFFFFF'
      : 'white';

  return (
    <div
      className={'event calendar2'}
      style={{
        background: background,
        border: dottedBorder,
        gridColumn: schedule?.column,
        gridRow: schedule?.scheduleRow,
        marginLeft: schedule?.marginLeft,
        marginTop: schedule?.marginLeft,
      }}>
      <div
        className="event-card"
        onClick={onClick}
        title={schedule?.activity?.name}>
        <span className="event-title">
          {' '}
          {schedule?.customerActivities[0]?.customer?.name}
        </span>
        <br />
        <div className="eventTimeIcon">
          {displayScheduleIcon(schedule)}
          <span className="event-subtitle">
            {schedule?.scheduledHour + ':' + schedule?.finalMinutes}-
            {schedule?.scheduleEnd}
          </span>
        </div>
      </div>
    </div>
  );
};

const displayScheduleIcon = (schedule) => {
  const type = schedule?.activity?.type;

  if (type?.toLowerCase() === 'online')
    return (
      <WifiIcon
        style={{
          color: '#25B379',
        }}
      />
    );
  if (type?.toLowerCase() === 'in_company')
    return (
      <ApartmentIcon
        style={{
          color: '#25B379',
        }}
      />
    );
  return (
    <img
      src={BuildingIcon}
      alt="Imagem"
      style={{
        width: '14px',
        height: '14px',
        color: '#25B379',
      }}
    />
  );
};
export default ScheduleGridCell;
