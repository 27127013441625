import './styles.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Popover from '@mui/material/Popover';
import WifiIcon from '@material-ui/icons/Wifi';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import SimpleModal from 'src/Components/SimpleModal';
import { brasilMoneyMask } from 'src/Utils/NumericUtils';
import attention from '../../../Images/Icons/attention.png';
import { ActivitySchedule, ScheduleDetails } from 'src/Models/ActivitySchedule';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { getCustomerNameLetters } from 'src/Utils/Schedule/utils';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { MuiTextFieldStyled } from 'src/Components/ComponentMaterialStyled/MuiTextFieldStyled';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IRootState } from 'src/Stores';
import {
  getReScheduleAvailables,
  getAllActivitiesSchedules,
  reset,
} from 'src/Stores/schedule/actions';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import { isEmpty } from 'lodash';
import ActivityScheduleApi from 'src/Services/Api/ActivitySchedule';
import { toast } from 'react-toastify';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import StringUtils from 'src/Utils/StringUtils';
import {
  getOrCreateConferenceSession,
  reset as conferenceReset,
} from 'src/Stores/conference/actions';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import CircleLoading from 'src/Components/circleLoading';
import InfoTooltip from '../InfoTooltip';
import CustomerActivityService from 'src/Services/CustomerActivityService';

export interface ScheduleDetailsModal
  extends DispatchProps,
  StateProps,
  WithTranslation {
  scheduleDetails?: ScheduleDetails;
  handleCancelScheduleDetails?: () => void;
  openScheduleDetailsModal?: { open: boolean; schedule: {} };
}

const ScheduleDetailsModal: React.FC<ScheduleDetailsModal> = ({
  t,
  partner,
  scheduleDetails,
  openScheduleDetailsModal,
  handleCancelScheduleDetails,
  getAllActivitiesSchedules,
  reSchedulesGroupped,
  getReScheduleAvailables,
  reset,
  getOrCreateConferenceSession,
  conference,
  conferenceReset,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [waitingApproval, setWaitingApproval] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmExcludeCustomerActivityModal, setConfirmExcludeCustomerActivityModal] = useState(false);
  const now = moment().subtract(90, 'minutes');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const displayParticipantNotApproved = (customerActivity): JSX.Element => {
    const { name = '' } = customerActivity?.customer;

    const letters = getCustomerNameLetters(name);

    if (!waitingApproval) setWaitingApproval(true);

    return (
      <div
        className="participant-no-img"
        key={`part-no-approved-${customerActivity.id}`}
        title={name}>
        <img src={attention} alt="attention" className="attentionImg" />
        <span>{letters}</span>
      </div>
    );
  };

  const displayParticipantNoImage = (customerActivity): JSX.Element => {
    const { name = '' } = customerActivity?.customer;

    const letters = getCustomerNameLetters(name);

    return (
      <div
        className="participant-no-img"
        key={`part-no-img-${customerActivity.id}`}>
        <Typography
          aria-owns={open && 'mouse-over-popover'}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className="no-img-typography">
          {letters}
        </Typography>
        <Popover
          id="mouse-over-popover"
          open={open}
          sx={{
            pointerEvents: 'none',
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <Typography sx={{ p: 1 }}>{name}</Typography>
        </Popover>
      </div>
    );
  };

  const displayParticipantByStatus = (customerActivity): JSX.Element => {
    const { status = '' } = customerActivity || {};

    if (status === 'APPROVED')
      return displayParticipantNoImage(customerActivity);

    return displayParticipantNotApproved(customerActivity);
  };

  const displayUnavailableConference = (): JSX.Element => {
    return (
      <a className="accessActivity">
        <WifiIcon />
        Atividade Indisponível
      </a>
    );
  };

  const getUrlWhatsappCustomer = (): JSX.Element => {
    const customer = scheduleDetails?.customerActivities?.[0]?.customer;
    let phoneNumber = customer?.phones?.[0]?.number;
    let customerName = customer?.name;
    phoneNumber = phoneNumber?.replace(/\D/g, '');
    if (!phoneNumber?.startsWith('55')) {
      phoneNumber = '55' + phoneNumber;
    }
    const text = encodeURI(
      t('conference.labels.partnerDefaultText', {
        customerName,
        fantasyName: partner?.fantasyName,
        date: moment().format('DD/MM/YYYY'),
        hour: moment().format('HH:mm'),
      }),
    );
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;

    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="accessActivity">
        <WhatsAppIcon />
        Entrar em contato
      </a>
    );
  };

  const handleGetOrCreateConference = (activityScheduleId: number) => {
    if (activityScheduleId !== null) {
      getOrCreateConferenceSession(activityScheduleId);
    }
  };

  useEffect(() => {
    if (
      conference?.getOrCreateConferenceSessionStatus ==
      HttpRequestStatus.SUCCESS &&
      conference?.data?.roomUrl
    ) {
      window.open(conference?.data?.roomUrl, '_blank');
      conferenceReset();
    }
  }, [conference?.getOrCreateConferenceSessionStatus]);

  const getUrlConference = (): JSX.Element => {
    const activityScheduleId = scheduleDetails?.scheduleId;
    const diffInMinutes = moment(scheduleDetails.scheduleDate).diff(
      moment(),
      'minutes',
    );

    const isActivityUpcomingOrOngoing =
      diffInMinutes <= 5 && diffInMinutes >= -180;

    const activityAlreadyOccurred = diffInMinutes < -180;

    return (
      activityScheduleId && (
        <>
          {isActivityUpcomingOrOngoing ? (
            <button
              onClick={() => handleGetOrCreateConference(activityScheduleId)}
              className="accessActivity">
              {conference?.getOrCreateConferenceSessionStatus ===
                HttpRequestStatus.ONGOING ? (
                <CircleLoading size={25} inverted />
              ) : (
                <>
                  <WifiIcon />
                  Acessar Atividade
                </>
              )}
            </button>
          ) : (
            <div className="accessActivity disabled">
              <WifiIcon />
              Indisponível
              <InfoTooltip
                title={
                  activityAlreadyOccurred
                    ? 'Essa atividade já foi realizada.'
                    : 'Acesso liberado 5 minutos antes do início da atividade. Caso esteja no horário e o botão não esteja disponível, atualize a página.'
                }
              />
            </div>
          )}
        </>
      )
    );
  };

  const detailsModalTitle: JSX.Element = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '530px',
        marginLeft: '22px',
      }}>
      <div style={{ fontSize: '27px' }}>
        {openEditModal == true
          ? 'Sugerir alteração de horário'
          : scheduleDetails?.name}
      </div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleCancelScheduleDetails();
          setOpenEditModal(false);
        }}>
        <CloseIcon />
      </div>
    </div>
  );
  const displayType = () => {
    return t(`activityType.${scheduleDetails?.type}`);
  };

  const [dateSelected, setDateSelected] = useState();
  const [activityScheduleSelected, setActivityScheduleSelected] =
    useState<ActivitySchedule>({});
  const [dateOptions, setDateOptions] = useState([]);
  const [hourOptions, setHourOptions] = useState([]);
  const [groupped, setReSchedulesGroupped] = useState({});

  useEffect(() => {
    if (!isEmpty(reSchedulesGroupped)) {
      setOpenEditModal(true);
      setReSchedulesGroupped(reSchedulesGroupped);
      setDateOptions(Object.keys(reSchedulesGroupped));
      return () => {
        reset();
      };
    }
  }, [reSchedulesGroupped]);

  const handleOpenReschedule = () => {
    getReScheduleAvailables(scheduleDetails?.scheduleId);
  };

  const handleDateSelect = (e) => {
    e.preventDefault();
    const selected = e.target.value;
    setDateSelected(selected);
    const newOptions = groupped?.[selected]?.map((it) => it) ?? [];
    setHourOptions(newOptions);
  };

  const handleTimeSelect = (e) => {
    e.preventDefault();
    const selected = e.target.value;
    setActivityScheduleSelected(selected);
  };

  // TODO: api waiting activitySchedule more then one customerActivity
  const customerAcitivty = scheduleDetails.customerActivities?.[0];
  const customer = customerAcitivty?.customer;
  const isOldSchedule = moment(scheduleDetails.scheduleDate).isBefore(now);

  const handleConfirmReSchedule = () => {
    if (activityScheduleSelected?.id == null) {
      toast.error('Falha ao selecionar novo horário.');
      return;
    }

    if (
      customerAcitivty.status == CustomerActivityStatus.PRE_SCHEDULE_BY_PARTNER
    ) {
      toast.warn(
        'Não é possivel editar atividades sem confirmação do cliente !',
      );
      return setOpenEditModal(false);
    }

    ActivityScheduleApi.postReSchedule({
      activityScheduleId: activityScheduleSelected?.id,
      customerActivityId: customerAcitivty?.id,
    })
      .then(() => {
        toast.success('Agendamento remarcado com sucesso.');
        getAllActivitiesSchedules();
      })
      .catch(() => {
        toast.error(
          'Agendamento já possui uma solicitação de alteração de data',
        );
      })
      .finally(() => {
        setTimeout(() => {
          setOpenEditModal(false);
          handleCancelScheduleDetails();
        }, 500);
      });
  };

  const handleExcludeCustomerActivity = (id: number) => {
    CustomerActivityService.deleteCustomerActivity(id)
      .then(() => {
        toast.success('Agendamento excluído com sucesso.', {
          autoClose: 2000,
          onClose: () => {
            window.location.reload();
          },
        });
        setConfirmExcludeCustomerActivityModal(false);
        setOpenEditModal(false);
      })
      .catch(() => {
        toast.error('Falha ao excluir agendamento.');
      });
  };

  return (
    <SimpleModal
      isOpen={openScheduleDetailsModal?.open}
      labelCancel={'Cancelar'}
      labelConfirm={'Confirmar'}
      modalTitle={detailsModalTitle}
      onConfirm={openEditModal == true ? handleConfirmReSchedule : null}
      onCancel={openEditModal == true ? () => setOpenEditModal(false) : null}
      width="600px">
      {openEditModal == true ? (
        <div className="rescheduleModal">
          {/* todo: 18n */}
          {`De ${customer.name}, em:`}
          <FormControl className="schedule-date-time">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <MobileDatePicker
                readOnly
                label="Data"
                className="schedule-date-time-item"
                onChange={() => null}
                value={scheduleDetails?.scheduleDate}
                renderInput={(params) => <MuiTextFieldStyled {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <MobileTimePicker
                readOnly
                className="schedule-date-time-item"
                label="Horário"
                value={scheduleDetails?.scheduleDate}
                onChange={() => null}
                renderInput={(params) => <MuiTextFieldStyled {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <div>
            {/* todo: 18n */}
            {'Para: '}
          </div>
          <div className="schedule-date-time">
            <MuiTextFieldStyled
              select
              className="schedule-date-time-item"
              value={dateSelected}
              onChange={(e) => handleDateSelect(e)}
              label="Nova data">
              <MenuItem value="" disabled />
              {dateOptions?.map((it, index) => (
                <MenuItem key={`menu-item-${index}`} value={it}>
                  {moment(it, 'DD/MM/YYYY').format('ddd - DD/MM/YYYY')}
                </MenuItem>
              ))}
            </MuiTextFieldStyled>
            <MuiTextFieldStyled
              select
              value={activityScheduleSelected}
              className="schedule-date-time-item"
              onChange={handleTimeSelect}
              disabled={dateSelected == null}
              label="Novo horário">
              <MenuItem value="" />
              {hourOptions?.map((it, index) => (
                <MenuItem key={`menu-item-time-${index}`} value={it}>
                  {it?.hourFormat}
                </MenuItem>
              ))}
            </MuiTextFieldStyled>
          </div>

          <div className="exclude--schedule--container">
            <button
              onClick={() => setConfirmExcludeCustomerActivityModal(true)}
              className="exclude--schedule--button">
              Excluir agendamento
            </button>
          </div>
        </div>
      ) : (
        <div className="detailsModal">
          <div className="schedules">
            <div className="schedules__title--container">
              <div>
                <span className="schedules-title--details--modal">
                  Agendamentos
                </span>

                {scheduleDetails?.customerActivities && (
                  <>
                    <br />
                    <p className="category">
                      {
                        scheduleDetails?.customerActivities[0].activityCategory
                          .name
                      }
                    </p>
                    <p className="subcategory">
                      {
                        scheduleDetails?.customerActivities[0]
                          ?.activitySubCategory?.name
                      }
                    </p>
                  </>
                )}
              </div>
              {isOldSchedule ? (
                <></>
              ) : (
                <div className="schedules-edit" onClick={handleOpenReschedule}>
                  <CreateOutlinedIcon /> &nbsp; Editar
                </div>
              )}
            </div>
            <div className="timeBox">
              <WatchLaterOutlinedIcon className="watchIcon" />
              <span>{scheduleDetails?.duration}min</span>
            </div>
            <div className="schedulesBox">
              <div className="scheduleBoxRow">
                {/*<div className="scheduleGreyBox">qua 6 Jan 12h15</div>*/}
                <div className="scheduleBox">{scheduleDetails?.date}</div>
              </div>
            </div>
          </div>
          <div className="place">
            <span className="place-title">Local</span>
            <span className="text">{displayType()}</span>

            <span className="tokenText">
              {isOldSchedule
                ? ''
                : 'Use o token para acessar a atividade pelo computador'}
            </span>
            {getUrlConference()}
            {getUrlWhatsappCustomer()}
          </div>
          <div className="payment">
            <span className="payment-title">Pagamento</span>
            <div className="paymentContainer">
              <div className="paymentBox">
                <span className="colorGrey">Pago pelo cliente</span>
                <span>{brasilMoneyMask(scheduleDetails?.price)}</span>
              </div>
              <div className="paymentValue">
                <span className="colorGrey">Valor a receber</span>
                <span className="paymentGreen">
                  {brasilMoneyMask(scheduleDetails?.valueToReceive)}
                </span>
              </div>
            </div>
          </div>
          <div className="participants">
            <span className="participants-title">Participantes</span>
            <div className="participantsContainer">
              {scheduleDetails?.customerActivities?.map((customerActivity) =>
                displayParticipantByStatus(customerActivity),
              )}
              <span className="customerName">{customer?.name}</span>
              {/*<div className="more-participants">+20</div>*/}
            </div>
            <div className="participantsPhone">
              {customer?.phones?.[0] != null && (
                <span>
                  {'Telefone: ' +
                    customer?.phones?.map((it) =>
                      StringUtils.newPhoneFormatter(it?.number),
                    )}
                </span>
              )}
            </div>
            <div className="participantsPhone">
              {customer?.user?.email != null && (
                <span>{'Email: ' + customer?.user?.email}</span>
              )}
            </div>
            {waitingApproval && (
              <div className="waiting-approval">
                <img src={attention} alt="attention" className="attentionImg" />
                <span>Aguardando aprovação</span>
              </div>
            )}
          </div>
        </div>
      )}

      <SimpleModal
        isOpen={confirmExcludeCustomerActivityModal}
        labelCancel={'Cancelar'}
        labelConfirm={'Confirmar'}
        modalTitle={'Confirmação de exclusão de agendamento'}
        onConfirm={() =>
          handleExcludeCustomerActivity(
            scheduleDetails.customerActivities?.[0].id,
          )
        }
        onCancel={() => setConfirmExcludeCustomerActivityModal(false)}
        width="550px">
        <p className="modal-confirm--exclude--title">
          {'Tem certeza que deseja excluir o agendamento?'}
        </p>
      </SimpleModal>
    </SimpleModal>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    reSchedules: state.schedule.reSchedules,
    reSchedulesGroupped: state.schedule.reSchedulesGroupped,
    partner: state.profile?.profile,
    conference: state.conference,
  };
};

const mapDispatchToProps = {
  getReScheduleAvailables,
  getAllActivitiesSchedules,
  reset,
  getOrCreateConferenceSession,
  conferenceReset,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleDetailsModal),
) as React.ComponentType<any>;
