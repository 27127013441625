import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import './styles.scss';

export interface DescriptionProps extends WithTranslation {
  title: string;
  register: (inputName: string, requirements: Object) => void;
}

const Description: React.FC<DescriptionProps> = ({ t, title, register }) => {
  return (
    <Container className="mt-40 center-cards-name-description">
      <div className="col-12">
        <div className="card-model">
          <span className="value-title">{title}</span>
          <div className="setSpace">
            <textarea
              {...register('description', { required: true })}
              name="description"></textarea>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(Description);
