import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import {
  CustomerActivityPlanActionTypes,
  CustomerActivtiyPlanState,
} from './types';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { PageableResponse } from 'src/Models/pageable';
import CustomerActivity from 'src/Models/CustomerActivity';

export const initialState: CustomerActivtiyPlanState = {
  customerActivitiesPlan: null,
  customerActivityHistory: null,
  customerActivityHistoryStatus: HttpRequestStatus.NOOP,
  customersPlanWithPaymentOverdue: null,
  customersPlanWithPaymentOverdueStatus: HttpRequestStatus.NOOP,
  status: HttpRequestStatus.NOOP,
  approveEnrolmentStatus: HttpRequestStatus.NOOP,
  customerActivityPlanStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<CustomerActivtiyPlanState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN_SUCCESS: {
      const customerActivitiesPlan =
        action?.payload as PageableResponse<CustomerActivityPlan>;
      return {
        ...state,
        customerActivitiesPlan,
        status: HttpRequestStatus.SUCCESS,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE: {
      return {
        ...state,
        customersPlanWithPaymentOverdueStatus: HttpRequestStatus.ONGOING,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_SUCCESS: {
      const customersPlanWithPaymentOverdue =
        action?.payload as PageableResponse<CustomerActivityPlan>;
      return {
        ...state,
        customersPlanWithPaymentOverdue,
        customersPlanWithPaymentOverdueStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMERS_WITH_PAYMENT_OVERDUE_ERROR: {
      return {
        ...state,
        customersPlanWithPaymentOverdueStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITES_PLAN_ERROR: {
      return {
        ...state,
        status: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID: {
      return {
        ...state,
        customerActivityPlanStatus: HttpRequestStatus.ONGOING,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_SUCCESS: {
      const customerActivityPlan = action?.payload as CustomerActivityPlan;
      return {
        ...state,
        customerActivityPlan,
        customerActivityPlanStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITY_PLAN_BY_ID_ERROR: {
      return {
        ...state,
        customerActivityPlanStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY: {
      return {
        ...state,
        customerActivityHistoryStatus: HttpRequestStatus.ONGOING,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY_SUCCESS: {
      const customerActivityHistory =
        action?.payload as PageableResponse<CustomerActivity>;
      return {
        ...state,
        customerActivityHistory,
        customerActivityHistoryStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case CustomerActivityPlanActionTypes.GET_CUSTOMER_ACTIVITIES_HISTORY_ERROR: {
      return {
        ...state,
        customerActivityHistoryStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case CustomerActivityPlanActionTypes.APPROVE_ENROLMENT: {
      return {
        ...state,
        approveEnrolmentStatus: HttpRequestStatus.ONGOING,
      };
    }
    case CustomerActivityPlanActionTypes.APPROVE_ENROLMENT_SUCCESS: {
      return {
        ...state,
        approveEnrolmentStatus: HttpRequestStatus.SUCCESS,
      };
    }
    case CustomerActivityPlanActionTypes.APPROVE_ENROLMENT_ERROR: {
      return {
        ...state,
        approveEnrolmentStatus: HttpRequestStatus.ERROR,
      };
    }
    case CustomerActivityPlanActionTypes.RESET_STATUS: {
      return {
        ...state,
        status: HttpRequestStatus.NOOP,
      };
    }
    case CustomerActivityPlanActionTypes.RESET_APPROVE_ENROLMENT_STATUS: {
      return {
        ...state,
        approveEnrolmentStatus: HttpRequestStatus.NOOP,
      };
    }
    case CustomerActivityPlanActionTypes.RESET_CUSTOMER_ACTIVITY_PLAN_STATUS: {
      return {
        ...state,
        customerActivityPlanStatus: HttpRequestStatus.NOOP,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as customerActivityPlanReducer };
