import { Container } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { styles } from '../BaseMegaCardCss/styles';
import './styles.scss';
import moment from 'moment';
import StringUtils from 'src/Utils/StringUtils';
import { IRootState } from 'src/Stores';
import { CommonLoading } from 'react-loadingg';
import { getBillingHistory } from 'src/Stores/billing/actions';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import {
  approveEnrolment,
  getCustomerActivityPlanById,
  getCustomerActivityPlans,
  resetApproveEnrolmentStatus,
} from 'src/Stores/customerActivityPlan/actions';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import { Tab, Tabs } from '@material-ui/core';
import Enrolments from './components/Enrolments';
import FilterHeader, { DateProps } from './components/FilterHeader';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import { ActivityType } from 'src/Models/Enums/ActivityType';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StudentsWithPaymentOverdue from './components/StudentsWithPaymentOverdue/indesx';
import { PageableResponse } from 'src/Models/pageable';
import { Billing } from 'src/Models/Billing';
import SmileKeiken from 'src/Images/Keiken/smileKeiken.png';
import {
  getCustomersPlanWithPaymentOverdue,
  getCustomerActivities,
} from 'src/Stores/customerActivityPlan/actions';
import i18n from 'src/Config/I18n';
import ServiceHistory from './components/ServiceHistory';
import CustomerActivity from 'src/Models/CustomerActivity';
import { FilterDateType } from './components/ScheduleHistory';
import PartnerClientApi from 'src/Services/Api/PartnerClientApi';

export interface ExtractTableProps
  extends WithTranslation,
    DispatchProps,
    StateProps {}

const ScheduleHistoryTable: React.FC<ExtractTableProps> = ({
  t,
  historyStatus,
  billingsHistory,
  status,
  profile,
  getBillingHistory: propsGetHistory,
  getCustomerActivityPlans: propsGetCustomerActivityPlans,
  getCustomerActivities: propsGetCustomerActivities,
  customerActivitiesPlan,
  getCustomersPlanWithPaymentOverdue,
  customersPlanWithPaymentOverdue,
  customersPlanWithOverdueStatus,
  customerActivitiesHistory,
}) => {
  const [pagination, setPagination] = useState(1);
  const [customerWithPaymentOverduePage, setCustomerWithPaymentOverduePage] =
    useState(1);
  const [whichDateToFilter, setWhichDateToFilter] = useState<FilterDateType>(
    FilterDateType.SCHEDULE_DATE,
  );
  const [enrolmentsPagination, setEnrolmentsPagination] = useState(1);
  const [activitiesHistoryPage, setActivitiesHistoryPage] = useState(1);
  const PAGE_SIZE = 20;
  const [value, setValue] = useState(1);
  const useStyles = makeStyles((theme: Theme) => styles);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleGetCustomerActivities = () => {
    propsGetCustomerActivities({
      page: activitiesHistoryPage - 1,
      size: 10,
    });
  };

  useEffect(() => {
    handleGetCustomerActivities();
  }, [activitiesHistoryPage]);

  const [filterText, setFilterText] = useState<string>();
  const [sortTo, setSortTo] = useState<string>();
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);
  const [formatedDate, setFormatedDate] = useState<DateProps>({
    startDate: null,
    endDate: null,
  });
  const [debouncedFilterText, setDebouncedFilterText] = useState<string>();

  const handleOnChangeDate = (item) => {
    setDate([item.selection]);
    setFormatedDate({
      startDate: format(item.selection.startDate, 'dd/MM/yyyy'),
      endDate: format(item.selection.endDate, 'dd/MM/yyyy'),
    });
    setPagination(0);
  };
  const handleOnChangeText = (event) => {
    setFilterText(event?.target?.value);
    setPagination(0);
  };
  const handleOnChangeType = (type?: ActivityType) => {
    setSortTo(type);
    setPagination(0);
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    const startDateToSend = moment(
      formatedDate?.startDate,
      'DD/MM/YYYY',
    ).format('YYYY-MM-DD');
    const endDateToSend = moment(formatedDate?.endDate, 'DD/MM/YYYY').format(
      'YYYY-MM-DD',
    );

    await PartnerClientApi.getExtractTableInExcel(profile.profile.id, {
      page: 0,
      size: billingsHistory?.totalElements,
      search: debouncedFilterText,
      type: sortTo,
      fromDate: startDateToSend,
      toDate: endDateToSend,
      whichDateToFilter: whichDateToFilter,
    });

    setIsDownloading(false);
  };

  useEffect(() => {
    getCustomersPlanWithPaymentOverdue({
      page: customerWithPaymentOverduePage - 1 ?? 0,
      size: PAGE_SIZE,
    });
  }, [customerWithPaymentOverduePage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [filterText]);

  useEffect(() => {
    propsGetHistory({
      page: pagination - 1 ?? 0,
      size: billingsHistory?.size ?? 20,
      fromDate:
        formatedDate.endDate !== null
          ? moment(formatedDate?.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : undefined,
      search: debouncedFilterText,
      toDate:
        formatedDate.endDate !== null
          ? moment(formatedDate?.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : undefined,
      type: sortTo,
      whichDateToFilter: whichDateToFilter,
    });
  }, [
    pagination,
    propsGetHistory,
    formatedDate,
    debouncedFilterText,
    sortTo,
    whichDateToFilter,
  ]);

  useEffect(() => {
    propsGetCustomerActivityPlans({
      status: CustomerActivityStatus.WAITING_APPROVE,
      page: enrolmentsPagination - 1 ?? 0,
      size: 10,
    });
  }, [enrolmentsPagination, propsGetCustomerActivityPlans]);

  const handleOnChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // @ts-ignore
  const classes = useStyles();

  const displayEmptyHistory = () => {
    return (
      <tr>
        <td className="noHistory">
          {i18n.t('billings.history.noSalesHistory')}
        </td>
      </tr>
    );
  };

  const displayHistory = () => {
    return billingsHistory?.content?.map?.((billing) => {
      const billingDate = billing.date;

      const customerActivity = billing.customerActivities?.[0];
      const activityDates = billing.customerActivities?.map(
        (it) => it?.activitySchedule?.date,
      );

      const customerActivityPlan = billing.customerActivityPlan;
      const start = customerActivityPlan?.startPeriodDate;
      const end = customerActivityPlan?.endPeriodDate;

      const customer =
        customerActivity?.customer ?? customerActivityPlan?.customer;

      const activityName =
        customerActivity?.activitySchedule?.activity?.name ??
        customerActivityPlan?.activityPlan?.name;

      const isWithDraw = billing?.paymentMethod === 'WITHDRAW_BY_PARTNER';

      const isPlan = !customerActivity;
      const scheduleDate = moment(customerActivity?.activitySchedule?.date)
        .add(1, 'month')
        .date(15)
        .format('DD/MM/YY');
      const planScheduleDate = moment(customerActivityPlan?.startPeriodDate)
        .add(1, 'month')
        .date(15)
        .format('DD/MM/YY');

      return (
        <tr key={customerActivity?.id} style={{ height: '90px' }}>
          <td>
            <div className="c1">
              <span className="c1-title">
                {moment(billingDate).format('DD/MM')}
              </span>
              <span className="c1-sub">
                {moment(billingDate).format('HH:mm')}
              </span>
            </div>
          </td>
          <td>
            <span className="text-c2">
              {isWithDraw ? 'Retirada' : activityName}
            </span>
            <span className="c1-title">
              {isWithDraw
                ? 'Pix'
                : customerActivity
                ? activityDates.map((it) => (
                    <>
                      {t('billings.history.date.activity', {
                        time: moment(it).format('DD/MM/YY - HH:mm'),
                      })}
                    </>
                  ))
                : t('billings.history.date.activityPlan', {
                    start: moment(start).format('DD/MM/YY'),
                    end: moment(end).format('DD/MM/YY'),
                  })}
            </span>
          </td>
          <td>
            <div className="c3">
              <div className="c1">
                <span className="text-c2">
                  {isWithDraw ? '' : customer?.name}
                </span>
                <Tooltip title="Clique para falar com o usuário no WhatsApp">
                  <div className="c3-phone-container">
                    {isWithDraw ? (
                      ''
                    ) : (
                      <WhatsAppIcon style={{ color: '#25D366' }} />
                    )}
                    <a
                      className="c3-sub"
                      href={`https://wa.me/${customer?.phones[0]?.number}`}
                      target="_blank">
                      {isWithDraw ? '' : customer?.phones[0]?.number}
                    </a>
                  </div>
                </Tooltip>
                <div className="c3-sub">
                  <span>
                    {isWithDraw
                      ? profile.profile.fantasyName
                      : customer?.user?.email}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="c1">
              <div className="c3-sub" style={{ color: '#005fa3' }}>
                {StringUtils.currencyPtBr(billing?.pricePaid)}
              </div>
            </div>
          </td>
          <td>
            <div className="c1">
              <div
                className="c3-sub"
                style={{ color: 'rgb(233, 0, 58)', whiteSpace: 'nowrap' }}>
                {'- ' + StringUtils.currencyPtBr(billing?.keikenTax)}
              </div>
            </div>
          </td>
          <td>
            <div className="c1">
              <div className="c3-sub-price">
                <span
                  style={{
                    width: isWithDraw && '100%',
                    textAlign: (isWithDraw && 'left') || null,
                  }}>
                  {isWithDraw ? (
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                      }}>
                      {StringUtils.currencyPtBr(0)}
                    </span>
                  ) : (
                    StringUtils.currencyPtBr(billing?.partnerProfit)
                  )}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div className="c1 alignCenter">
              <div className="c3-sub-price">
                <BillingStatus status={billing?.status} />
              </div>
            </div>
          </td>
          <td>
            <div className="c1 alignCenter">
              {!isWithDraw ? (
                <span className="c1-title textAlignCenter">
                  {billing?.partnerProfitTransaction?.scheduledDate
                    ? moment(
                        billing?.partnerProfitTransaction?.scheduledDate,
                      ).format('DD/MM/YY')
                    : isPlan
                    ? planScheduleDate
                    : scheduleDate}
                </span>
              ) : (
                <span className="c1-title textAlignCenter">
                  {i18n.t('billings.history.table.waitingApproval')}
                </span>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const LoadingContainer = () => {
    return (
      <tr style={{ position: 'relative', height: '800px' }}>
        <td>
          <CommonLoading />
        </td>
      </tr>
    );
  };

  const BillingStatus = ({ status }) => {
    const { border, color, text, backgroundColor } = getStatusStyles(status);

    return (
      <span
        style={{
          border,
          color,
          backgroundColor,
          borderRadius: '8px',
          padding: '1px 16px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
        }}>
        {text}
      </span>
    );
  };

  const getStatusStyles = (status) => {
    const statusMap = {
      PAID: {
        border: '2px solid #25b379',
        color: 'white',
        text: 'Pago',
        backgroundColor: '#25b379',
      },
      FUTURE_CHARGE_ERROR: {
        border: '1px solid rgb(233, 0, 58)',
        color: 'white',
        text: 'Erro',
        backgroundColor: 'rgb(233, 0, 58)',
      },
      default: {
        border: '1px solid orange',
        color: 'white',
        text: 'Pendente',
        backgroundColor: 'orange',
      },
    };

    return statusMap[status] || statusMap.default;
  };

  const handleHistory = () => {
    if (historyStatus === HttpRequestStatus.ONGOING) return LoadingContainer();

    if (billingsHistory?.content?.length === 0) return displayEmptyHistory();

    return (
      <>
        <tr>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.date')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.activityName')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.userData')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.value')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.keikenTax')}
          </td>
          <td className="c1-title-table">
            {i18n.t('billings.history.table.toReceive')}
          </td>
          <td className="c1-title-table textAlignCenter">
            {i18n.t('billings.history.table.status')}
          </td>
          <td className="c1-title-table textAlignCenter">
            {i18n.t('billings.history.table.paymentDate')}
          </td>
        </tr>
        {displayHistory()}
      </>
    );
  };
  const handleChangePagination = (event: React.MouseEvent, page: number) => {
    switch (value) {
      case 1:
        setPagination(page);
        break;
      case 2:
        setEnrolmentsPagination(page);
        break;
      case 3:
        setCustomerWithPaymentOverduePage(page);
        break;
      case 4:
        setActivitiesHistoryPage(page);
        break;
      default:
        break;
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#25b379',
      },
    },
  });

  const displayPaginationNumbers = (
    whatAmITryingToPaginate:
      | PageableResponse<Billing>
      | PageableResponse<CustomerActivityPlan>
      | PageableResponse<CustomerActivity>,
  ) => {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Pagination
            shape="rounded"
            size="large"
            color="primary"
            variant="outlined"
            page={
              value === 1
                ? pagination
                : value === 2
                ? enrolmentsPagination
                : value === 4
                ? activitiesHistoryPage
                : customerWithPaymentOverduePage
            }
            count={
              whatAmITryingToPaginate?.totalPages <= 10
                ? whatAmITryingToPaginate?.totalPages
                : 10
            }
            onChange={handleChangePagination}
          />
        </ThemeProvider>
      </>
    );
  };

  const displayPagination = (
    whatAmITryingToPaginate:
      | PageableResponse<Billing>
      | PageableResponse<CustomerActivityPlan>
      | PageableResponse<CustomerActivity>,
  ) => {
    //if (billingsHistory?.content?.length === 0) return;

    return (
      <div className="pagination center mb-10">
        {displayPaginationNumbers(whatAmITryingToPaginate)}
      </div>
    );
  };

  const ContentTab = () => {
    return (
      <Tabs
        value={value}
        centered
        onChange={handleOnChangeTab}
        className="tabs"
        textColor="secondary">
        <Tab value={1} label={i18n.t('contentTab.extract')} />
        <Tab value={2} label={i18n.t('contentTab.pendentEnrollments')} />
        <Tab
          value={3}
          label={i18n.t('contentTab.studentsWithPaymentOverdue')}
        />
        <Tab value={4} label={i18n.t('contentTab.serviceHistory')} />
      </Tabs>
    );
  };

  const headerProps = {
    filterText: filterText,
    filterType: sortTo,
    formatedDate: formatedDate,
    date: date,
    handleOnChangeDate: handleOnChangeDate,
    whichDateToFilter: whichDateToFilter,
    handleDownload: handleDownload,
    isDownloading: isDownloading,
    setWhichDateToFilter: setWhichDateToFilter,
    handleOnChangeText: handleOnChangeText,
    handleOnChangeType: handleOnChangeType,
  };

  return (
    <Container className="HistoryContainer">
      <div className={classes.baseBorder}>
        <ContentTab />
        <div className={classes.underline}></div>
        {value === 1 ? (
          <div>
            <FilterHeader {...headerProps} />
            <table>
              <tbody>{handleHistory()}</tbody>
            </table>
          </div>
        ) : value === 2 ? (
          status === HttpRequestStatus.ONGOING ? (
            <div style={{ height: '800px', position: 'relative' }}>
              <CommonLoading />
            </div>
          ) : (
            <Enrolments />
          )
        ) : customersPlanWithOverdueStatus === HttpRequestStatus.ONGOING ? (
          <div style={{ height: '800px', position: 'relative' }}>
            <CommonLoading />
          </div>
        ) : value === 3 ? (
          customersPlanWithPaymentOverdue?.content?.length != 0 ? (
            <StudentsWithPaymentOverdue
              customersPlanWithPaymentOverdue={customersPlanWithPaymentOverdue}
            />
          ) : (
            <div
              style={{
                minHeight: '35vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <p
                style={{
                  fontSize: '1.5rem',
                  fontFamily: 'Muller',
                  color: 'green',
                }}>
                {i18n.t('tableStudentsWithPaymentOverdue.noStudents')}
              </p>
              <img
                style={{ marginTop: '15px' }}
                src={SmileKeiken}
                alt="Foto sorriso keiken"
              />
            </div>
          )
        ) : (
          <ServiceHistory
            handleGetCustomerActivities={handleGetCustomerActivities}
          />
        )}
        {value === 2 ? (
          customerActivitiesPlan?.content?.length !== 0 && (
            <div className="footer-title">
              {displayPagination(customerActivitiesPlan)}
            </div>
          )
        ) : (
          <div>
            {value === 3 ? (
              customersPlanWithPaymentOverdue?.content?.length !== 0 && (
                <div className="footer-title">
                  {displayPagination(customersPlanWithPaymentOverdue)}
                </div>
              )
            ) : value === 4 ? (
              <div className="footer-title">
                {displayPagination(customerActivitiesHistory)}
              </div>
            ) : (
              <div className="footer-title">
                {displayPagination(billingsHistory)}
              </div>
            )}

            {value === 1 && (
              <div className="footer-see-more">
                <NavLink
                  className="mr-20 overviewLink"
                  activeClassName="link-is-active"
                  to="/finances?shouldGoToExtract=true">
                  {i18n.t('global.seeMore')}
                </NavLink>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state: IRootState) => {
  const customerActivitiesPlan =
    state.customerActivityPlan.customerActivitiesPlan;
  const enrolmentStatus = state.customerActivityPlan.approveEnrolmentStatus;
  const customerActivityPlan = state.customerActivityPlan.customerActivityPlan;
  const status = state.customerActivityPlan.status;
  const billingsHistory = state.billings.page;
  const profile = state.profile;
  const customersPlanWithPaymentOverdue =
    state.customerActivityPlan.customersPlanWithPaymentOverdue;
  const customersPlanWithOverdueStatus =
    state.customerActivityPlan.customersPlanWithPaymentOverdueStatus;
  const customerActivitiesHistory =
    state.customerActivityPlan.customerActivityHistory;
  return {
    historyStatus: state.billings.status,
    customerActivitiesPlan,
    enrolmentStatus,
    customerActivityPlan,
    billingsHistory,
    customerActivitiesHistory,
    customersPlanWithPaymentOverdue,
    customersPlanWithOverdueStatus,
    status,
    profile,
  };
};

const mapDispatchToProps = {
  getBillingHistory,
  approveEnrolment,
  getCustomerActivityPlans,
  resetApproveEnrolmentStatus,
  getCustomerActivityPlanById,
  getCustomersPlanWithPaymentOverdue,
  getCustomerActivities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleHistoryTable),
) as React.ComponentType<any>;
