import { api } from '.';
import { AxiosResponse } from 'axios';
import {
  EnterpriseData,
  EnterprisePage,
  ResponsibleData,
} from 'src/Models/Profile';
import { City } from 'src/Models/Address';
import { PageableResponse } from 'src/Models/pageable';

export const ProfileApi = () => {
  const getPartnerProfile = (): Promise<AxiosResponse> => {
    return api.get<EnterpriseData>('/partner-places/perfil');
  };

  const getCityByName = (
    city: City,
  ): Promise<AxiosResponse<PageableResponse<City>>> => {
    const params = {
      name: city?.name,
      ['state.acronym']: city?.state?.acronym,
    };
    return api.get('/cities', { params });
  };

  const putEnterpriseData = (partnerPlace: EnterpriseData) => {
    return api.put<EnterpriseData>('/partner-places', partnerPlace);
  };

  const putResponsibleData = (education: ResponsibleData) => {
    return api.put<ResponsibleData>('/partners/perfil', education);
  };

  const putEnterprisePage = ({ id, enterprisePage }) => {
    return api.put<EnterprisePage>(
      `/partner-places/${id}/profile`,
      enterprisePage,
    );
  };

  return {
    getCityByName,
    getPartnerProfile,
    putEnterpriseData,
    putEnterprisePage,
    putResponsibleData,
  };
};

export default ProfileApi();
