import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';

import './styles.scss';
import BaseModal from '../BaseModalToken';

export interface DefaultButton extends WithTranslation {
  title: string;
  type: string;
  linkTo?: string;
  action?: string;
  hasIcon?: boolean;
  iconModel?: string;
}

const DefaultButton: React.FC<DefaultButton> = ({
  t,
  title,
  type,
  linkTo,
  action,
  hasIcon,
  iconModel,
}) => {
  return (
    <>
      {type === 'button' && (
        <div>
          <button className="PartnerDefaultButton">
            {hasIcon && iconModel === 'check' && (
              <CheckIcon className="iconSize" />
            )}
            {hasIcon && iconModel === 'add' && <AddIcon className="iconSize" />}
            {action === 'isModal' && <BaseModal text={title} />}
            {action !== 'isModal' && <span>{title}</span>}
          </button>
        </div>
      )}

      {type === 'link' && (
        <div>
          <Link to={linkTo} className="PartnerDefaultButton">
            {hasIcon && iconModel === 'check' && (
              <CheckIcon className="iconSize" />
            )}
            {hasIcon && iconModel === 'add' && <AddIcon className="iconSize" />}
            {title}
          </Link>
        </div>
      )}
    </>
  );
};

export default withTranslation()(DefaultButton);
