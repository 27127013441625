import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import LoadingContainerScreen from '../../Components/loading-container-screen/loading-container-screen';
import { logoutRequest } from '../../Stores/authentication/actions';

interface LogoutProps extends DispatchProps {}

const Logout: React.FC<LogoutProps> = ({ logout }) => {
  useEffect(() => {
    logout();
    window.location.replace('/');
  }, [logout]);

  return (
    <Row className="base-full-height">
      <Col md={12} className={'base-centered-column'}>
        <LoadingContainerScreen isLoading children={void 0} />
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  logout: logoutRequest,
};

type DispatchProps = typeof mapDispatchToProps;

export default compose(connect(null, mapDispatchToProps))(Logout);
