import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from 'reactstrap';
import './styles.scss';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';

import { styles } from '../BaseMegaCardCss/styles';
import NewScheduleModal from '../NewScheduleModal';
import ScheduleDetailsModal from '../ScheduleDetailsModal';
import moment from 'moment';
import DeleteTimeModal from '../DeleteScheduleModal';
import { IRootState } from 'src/Stores';
import { putScheduleStatus } from 'src/Stores/schedule/actions';
import { connect } from 'react-redux';
import { getActivities } from 'src/Stores/activity/actions';
import {
  ScheduleDetails,
  AvailableSchedule,
} from 'src/Models/ActivitySchedule';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import {
  defaultMultiScheduleModal,
  defaultCustomSchedule,
  defaultAvailableSchedule,
  defaultScheduleDetails,
  defaultOpenScheduleDetails,
} from 'src/Utils/Schedule/constants';
import ScheduleTable from './ScheduleTable';
import { getAgenda, putAgenda } from 'src/Stores/agenda/actions';
import SelectActivities from '../SelectActivities';
import i18n from 'src/Config/I18n';
import Button from '@mui/material/Button';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { CommonLoading } from 'react-loadingg';

const useStyles = makeStyles((theme: Theme) => styles);
export interface SchedulesProps
  extends WithTranslation,
    DispatchProps,
    StateProps {}

const Schedules: React.FC<SchedulesProps> = ({
  t,
  activity,
  agenda,
  getAgenda,
  putAgenda,
  getActivities,
  putScheduleStatus,
}) => {
  // @ts-ignore
  const classes = useStyles();

  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails>(
    defaultScheduleDetails,
  );
  const [openScheduleModal, setOpenScheduleModal] = useState({
    open: false,
    schedule: {},
  });
  const [openAvailableSchedule, setOpenAvailableSchedule] =
    useState<AvailableSchedule>(defaultAvailableSchedule);
  const [openMultiSchedulesModal, setOpenMultiSchedulesModal] = useState(
    defaultMultiScheduleModal,
  );
  const [openScheduleDetailsModal, setOpenScheduleDetailsModal] = useState(
    defaultOpenScheduleDetails,
  );

  const [openDeleteTimeModal, setOpenDeleteTimeModal] = useState(false);

  useEffect(() => {
    if (!openScheduleDetailsModal?.open) return;

    const { activities = [] } = activity || {};
    const { schedule } = openScheduleDetailsModal || {};

    const currentActivity = activities?.filter(
      (activity) => activity.id === schedule?.activity?.id,
    )[0] || { price: 0, type: '' };

    const customerActivitiesLength = schedule?.customerActivities?.length;

    const scheduleBody = {
      id: schedule?.activity?.id,
      type: currentActivity?.type,
      price: schedule?.pricePaid,
      name: schedule?.activity?.name,
      conference: schedule?.conferenceSession,
      duration: schedule?.activity?.duration,
      customerActivities: schedule?.customerActivities,
      valueToReceive: currentActivity?.price * customerActivitiesLength,
      date: moment(schedule?.date).format('ddd D MMM HH:mm'),
      scheduleDate: schedule?.date,
      scheduleId: schedule?.id,
      dateFormatted: moment(schedule?.date).format('ddd D MMM HH:mm'),
    };

    setScheduleDetails(scheduleBody);
  }, [openScheduleDetailsModal, activity]);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  useEffect(() => {
    getAgenda();
  }, [getAgenda]);

  useEffect(() => {
    if (agenda.editStatus == HttpRequestStatus.SUCCESS) {
      setOpenAvailableSchedule({ ...openAvailableSchedule, open: false });
    } else if (agenda.editStatus == HttpRequestStatus.ERROR) {
      setOpenAvailableSchedule({ ...openAvailableSchedule, open: false });
    }
  }, [agenda.editStatus]);

  const resetAvailableSchedule = () =>
    setOpenAvailableSchedule(defaultAvailableSchedule);

  const handleOpenNewSchedule = () => {
    const { currentSchedule } = openAvailableSchedule;

    setOpenScheduleModal({ open: true, schedule: currentSchedule });
    resetAvailableSchedule();
  };

  const handleOpenNewScheduleInMultipleSchedules = (schedule) => {
    setOpenScheduleModal({ open: true, schedule: schedule });
    setOpenMultiSchedulesModal({ open: false, scheduleBoxes: [] });
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteTimeModal(true);
    setOpenAvailableSchedule({ ...openAvailableSchedule, open: false });
  };

  const handleCancelScheduleDetails = () => {
    setOpenScheduleDetailsModal({
      open: false,
      schedule: defaultCustomSchedule,
    });
  };

  const handleConfirmScheduleDetails = () => {
    setOpenScheduleDetailsModal({
      open: false,
      schedule: defaultCustomSchedule,
    });
  };

  const handleCancelDeleteTime = () => {
    setOpenDeleteTimeModal(false);
  };

  const handleConfirmDeleteTime = () => {
    const { currentSchedule } = openAvailableSchedule;
    const { status } = currentSchedule;

    let scheduleStatus = status === 'BLOCK' ? 'AVAILABLE' : 'BLOCK';

    setOpenDeleteTimeModal(false);
    putScheduleStatus(currentSchedule?.id, scheduleStatus);
  };

  const handleCloseMultiSchedules = () => {
    setOpenMultiSchedulesModal({ open: false, scheduleBoxes: [] });
  };

  const handleOpenMultiDetails = (schedule) => {
    setOpenMultiSchedulesModal({ open: false, scheduleBoxes: [] });
    setOpenScheduleDetailsModal({ open: true, schedule });
  };

  const scheduleModalProps = {
    scheduleDetails,
    openScheduleModal,
    setOpenScheduleModal,
    openDeleteTimeModal,
    handleCancelDeleteTime,
    handleConfirmDeleteTime,
    openScheduleDetailsModal,
    handleCancelScheduleDetails,
    handleConfirmScheduleDetails,
    currentSchedule: openAvailableSchedule?.currentSchedule,
  };

  const DisplayScheduleBoxes = () => {
    const { scheduleBoxes = [] } = openMultiSchedulesModal || {};

    return scheduleBoxes?.map((schedule, index) => {
      const now = moment().subtract(90, 'minutes');
      const { customerActivities = [] } = schedule || {};
      const containsPreScheduled = customerActivities?.some(
        (it) => it.status == CustomerActivityStatus.PRE_SCHEDULE_BY_PARTNER,
      );
      const containsActivity =
        schedule?.activity != undefined
          ? () => handleOpenMultiDetails(schedule)
          : () => handleOpenNewScheduleInMultipleSchedules(schedule);

      const dottedBorder =
        containsPreScheduled == true
          ? `2px dotted ${schedule.agendaColor}`
          : `2px solid ${schedule.agendaColor}`;

      if (moment(schedule?.date).isBefore(now)) {
        return (
          <div
            className="event calendar2"
            key={schedule?.date + index}
            onClick={
              schedule?.activity != undefined
                ? () => handleOpenMultiDetails(schedule)
                : () => {}
            }
            style={{ border: dottedBorder }}>
            <div className="event-card">
              <span className="event-title" style={{ marginLeft: '0px' }}>
                {schedule?.activity?.name ?? 'Indisponível'}
              </span>
              <br />
              <span className="event-subtitle" style={{ marginLeft: '0px' }}>
                {schedule?.scheduledHour + ':' + schedule?.finalMinutes}{' '}
                {schedule.duration == 0 ? '' : 'até '}
                {schedule?.duration == 0 ? '' : schedule.scheduleEnd}
              </span>
            </div>
          </div>
        );
      }

      return (
        <div
          className="event calendar2"
          key={schedule?.date + index}
          onClick={
            schedule?.activity != undefined
              ? () => handleOpenMultiDetails(schedule)
              : () => handleOpenNewScheduleInMultipleSchedules(schedule)
          }
          style={{ border: dottedBorder }}>
          <div className="event-card">
            <span className="event-title" style={{ marginLeft: '0px' }}>
              {schedule?.activity?.name ?? 'Disponível'}
            </span>
            <br />
            <span className="event-subtitle" style={{ marginLeft: '0px' }}>
              {schedule?.scheduledHour + ':' + schedule?.finalMinutes}{' '}
              {schedule.duration == 0 ? '' : 'até '}
              {schedule?.duration == 0 ? '' : schedule.scheduleEnd}
            </span>
          </div>
        </div>
      );
    });
  };

  const DisplayAvailableScheduleBoxes = () => {
    const { currentSchedule } = openAvailableSchedule || {};
    const { status } = currentSchedule || {};

    const [selectedsToAdd, setSelectedsToAdd] = useState([]);
    const handleSubmitUpdateAgenda = () => {
      putAgenda({
        ...currentSchedule.agenda,
        activities: selectedsToAdd,
      });
    };

    if (agenda.editStatus === HttpRequestStatus.ONGOING) {
      return (
        <div style={{ height: '500px' }}>
          <CommonLoading />
        </div>
      );
    }

    if (currentSchedule?.status == 'PRESET') {
      return (
        <div className="flex-column modal-select-activities">
          <div className="modal-select-activities-container w-80">
            <SelectActivities onSubmit={(itens) => setSelectedsToAdd(itens)} />
          </div>
          <div className="d-flex p-1 mt-1">
            <Button
              className="ml-auto"
              type="button"
              onClick={handleSubmitUpdateAgenda}
              sx={{
                background: '#25B379 !important;',
                borderRadius: '22px',
                marginTop: 2,
                marginBottom: 2,
              }}
              variant="contained">
              Salvar
            </Button>
          </div>
        </div>
      );
    }

    const now = moment();
    if (moment(currentSchedule.date).isBefore(now)) {
      return (
        <div className="event" key="block">
          <div className="event-card2" style={{ backgroundColor: '#8D979E' }}>
            <span>Horário indisponível</span>
            <br />
          </div>
        </div>
      );
    }
    if (status !== 'BLOCK') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
            <span
              style={{
                color: 'black',
              }}>
              {`Data: ${moment(currentSchedule?.date).format(
                'DD/MM/YYYY',
              )} - ${moment(currentSchedule?.date).format('HH:mm')}`}
            </span>
          </div>
          <div
            style={{
              borderBottom: '1px solid gray',
              display: 'flex',
              width: '90%',
              marginTop: '3px',
              marginBottom: '5px',
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              width: '100%',
            }}>
            <div
              className="event"
              key="newSchedule"
              onClick={() => handleOpenNewSchedule()}>
              <div className="event-card2">
                <span>Agende um atendimento</span>
                <br />
              </div>
            </div>
            <div
              className="event"
              key="block"
              onClick={() => handleOpenDeleteDialog()}>
              <div className="event-card2">
                <span>Bloquear Horário</span>
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="event"
        key="block"
        onClick={() => handleOpenDeleteDialog()}>
        <div className="event-card2">
          <span>Desbloquear Horário</span>
          <br />
        </div>
      </div>
    );
  };

  const DisplayModals = () => {
    if (openScheduleModal?.open)
      return <NewScheduleModal {...scheduleModalProps} />;

    if (openScheduleDetailsModal?.open)
      return <ScheduleDetailsModal {...scheduleModalProps} />;

    if (openDeleteTimeModal) return <DeleteTimeModal {...scheduleModalProps} />;

    return <> </>;
  };

  const scheduleTableProps = {
    setOpenAvailableSchedule,
    setOpenScheduleDetailsModal,
    setOpenMultiSchedulesModal,
  };
  return (
    <div>
      <DisplayModals />
      <Container>
        <div className={classes.baseBorder}>
          <ScheduleTable {...scheduleTableProps} agendas={agenda?.agendas} />
        </div>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openMultiSchedulesModal?.open}
        onClose={handleCloseMultiSchedules}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openMultiSchedulesModal?.open}>
          <div className="modal-activity-actions">
            <div className="modal-activity-actions-inner-title">
              <div />
              <CloseIcon
                onClick={() =>
                  setOpenMultiSchedulesModal((prev) => ({
                    ...prev,
                    open: false,
                  }))
                }
              />
            </div>
            <div className="modal-activity-actions-inner">
              {DisplayScheduleBoxes()}
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAvailableSchedule?.open}
        onClose={resetAvailableSchedule}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openAvailableSchedule?.open}>
          <div className="modal-activity-actions">
            <div className="modal-activity-actions-inner-title">
              {openAvailableSchedule?.currentSchedule?.status == 'PRESET' ? (
                <h5 className="p-2">
                  {openAvailableSchedule?.currentSchedule?.agenda?.activities
                    ?.length > 0
                    ? i18n.t('home.schedules.preset.withActivity')
                    : i18n.t('home.schedules.preset.withoutActivity')}
                </h5>
              ) : (
                <div />
              )}
              <CloseIcon
                onClick={() =>
                  setOpenAvailableSchedule((prev) => ({ ...prev, open: false }))
                }
              />
            </div>
            <div className="modal-activity-actions-inner">
              {DisplayAvailableScheduleBoxes()}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { schedule = {}, activity = {}, agenda = {} } = state || {};

  return { schedule, activity, agenda };
};

const mapDispatchToProps = {
  getActivities,
  getAgenda,
  putScheduleStatus,
  putAgenda,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Schedules),
) as React.ComponentType<any>;
