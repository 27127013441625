import { Tooltip, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React from 'react';

interface IntoTooltipProps {
  title: string;
}

const InfoTooltip: React.FC<IntoTooltipProps> = ({ title }) => {
  const useStyles = makeStyles((theme) => ({
    tooltipFont: {
      fontSize: 14,
    },
    icon: {
      height: '14px',
      width: '14px',
    },
  }));

  const classes = useStyles();

  return (
    <span style={{ marginLeft: '10px' }}>
      <Tooltip classes={{ tooltip: classes.tooltipFont }} title={title}>
        <Info fontSize="small" />
      </Tooltip>
    </span>
  );
};

export default InfoTooltip;
