import { Document } from 'src/Models/Document';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';

export type ApiResponse = Record<string, any>;

export enum ImageTypes {
  GET_IMAGE_BY_ID = '@@image/GET_IMAGE_BY_ID',
  GET_IMAGE_BY_ID_SUCCESS = '@@image/GET_IMAGE_BY_ID_SUCCESS',
  GET_IMAGE_BY_ID_ERROR = '@@image/GET_IMAGE_BY_ID_ERROR',

  RESET_IMAGE_STATUS = '@@image/RESET_IMAGE_STATUS',
}

export interface ImageState {
  readonly image?: Document;
  readonly imageStatus?: HttpRequestStatus;
}