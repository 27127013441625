import styled from  'styled-components'


export const Container = styled.div`
width: 955px;
height: 64px;
display: flex;
align-items: center;
border-bottom: 1px solid #F1ECEC;
`

export const ContainerDayAndHour = styled.div`
width: 40px;
margin: 0 138px 0 28px;
display: flex;
flex-direction: column;
`

export const TitleDay = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #455561;
`

export const TitleHour = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 14px;
color: #8D979E;
`

export const ContainerUser = styled.div`
width: 246px;
height: 35px;
margin-right: 154px;
display: flex;
`

export const ContainerNameAndEmail = styled.div`
width: 198px;
height: 35px;
display: flex;
flex-direction: column;
margin-left: 16px;
`

export const TitleName = styled.div`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #455561;
`

export const TitleEmail = styled.div`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #8D979E;
`

export const ContainerPriceAndPlanName = styled.div`
min-width: 270px;
display: flex;
flex-direction: column;
align-items: flex-start;
`

export const TitleNamePlan = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 14px;
color: #455561;
`

export const TitlePrice = styled.span`
font-family: 'Muller';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-align: left;
color: #25B379;
`


