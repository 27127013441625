export enum HttpResponseTypeError {
  LOGIN_ERROR_USER = 'LOGIN_ERROR_USER',
  LOGIN_ERROR_USER_AND_PASSWORD = 'LOGIN_ERROR_USER_AND_PASSWORD',
  LOGIN_ERROR_PASSWORD = 'LOGIN_ERROR_PASSWORD',
  UNKNOWN = 'UNKNOWN',
  INVALID_AUTH = 'INVALID_AUTH',
}

export interface HttpResponseError {
  status?: number;
  type?: HttpResponseTypeError;
  message?: string;
  redirect?: string;
}
