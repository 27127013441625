import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #fff',
    borderRadius: 15,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    width: '30rem',
  },
  enrolmentModal: {
    backgroundColor: '#fff',
    border: '2px solid #fff',
    borderRadius: 15,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    width: '32rem',
  },
  title: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '26px',
    color: '#4A4A4A',
    margin: 0,
  },
  align: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    border: '1px solid #8D979E',
    borderRadius: 3,
    display: 'grid',
    padding: 4,
    marginTop: 18,
  },
  spanInput: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: '18px',
    color: '#8D979E',
    paddingBottom: '0 !important',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
  },
  spanModal: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#8D979E',
    marginTop: '18px',
  },
  spanHeader: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#4A4A4A',
  },
  spanTokenHeader: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#25B379',
  },
  underline: {
    borderBottom: '2px solid #F1ECEC',
    width: '100%',
    marginTop: '15px',
  },
  modalTitle: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '100%',
    color: '#455561',
  },
  modalSubtitle: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: '#8D979E',
  },
  modalSubtitleGreen: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: '#25B379',
  },
  modalSubtitleGreenBig: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#25B379',
  },
  paymentAlign: {
    display: 'flex',
    gap: '18px',
    marginTop: '10px',
    flexWrap: 'wrap',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  Schedule: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: '#455561',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  disabledCard: {
    background: '#E9EBEC',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  enabledCard: {
    background: '#fff',
    borderRadius: '4px',
    padding: '6px',
    border: '2px solid #25B379',
    color: '#25B379',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fitCards: {
    display: 'flex',
    gap: '8px',
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    flexWrap: 'wrap',
  },
  sizeImage: {
    width: 165,
    borderRadius: '14px 0px 0px 14px',
  },
  divContentCard: {
    padding: 5,
  },
  externalDiv: {
    border: '1px solid #E7E9EA',
    borderRadius: 15,
    display: 'flex',
    marginTop: 10,
  },
  divContentCardTitle: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#455561',
  },
  divContentCardSub: {
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '5px',
    color: '#8D979E',
  },
  divButton: {
    border: '0',
    borderRadius: '15px',
    color: '#fff',
    fontFamily: 'Muller',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    width: '40%',
    height: '30px',
  },
  validationButton: {
    display: 'flex',
    paddingTop: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default styles;
