export interface PageableResponse<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  sort: PageableSort;
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
}

export interface Pageable {
  sort?: PageableSort;
  pageSize?: number;
  pageNumber?: number;
  offset?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface PageableSort {
  sorted: boolean;
  unsorted: boolean;
}

export interface PageableRequest {
  size?: number;
  page?: number;
}

export const defaultPageable = {
  size: 5,
  page: 0,
  offset: 0,
};
