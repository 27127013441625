import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
`;

export const ContainerDate = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const TitleDate = styled.span`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  color: #25b379;
`;

export const TitleInfos = styled.span`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #8d979e;
`;

export const BarDate = styled.div`
  width: 1px;
  height: 16px;
  background: #25b379;
`;

export const ContainerInfos = styled.div`
  width: 861px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  border-bottom: 1px solid #f1ecec;
`;

export const TitleFirstPlan = styled.span`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #455561;
  width: 400px;
  margin-right: 100px;
  text-align: left;
`;

export const TitlePlan = styled.span`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  color: #455561;
`;

export const ContainerInfosOfPayments = styled.div`
  min-width: 100px;
  height: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleValueInfo = styled.span`
  width: 200px;
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #25b379;
  text-align: left;
`;

export const Divider = styled.div`
  width: 1px;
  height: 42px;
  background: #d6d9db;
`;

export const ContainerInfosOfPaid = styled.div`
  min-width: 93px;
  height: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
`;

export const TitleValueReceived = styled.span`
  font-family: 'Muller';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  color: #25b379;
`;
