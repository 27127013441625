import React from 'react';
import './styles.scss';

interface AgendaMinutesIntervalControllerProps {
  intervals: { [key: string]: number };
  intervalSelected: number;
  handleChangeInterval: (interval: string) => void;
}

const AgendaMinutesIntervalController = ({
  intervalSelected,
  intervals,
  handleChangeInterval,
}: AgendaMinutesIntervalControllerProps) => {
  return (
    <div className="agenda__minutes__interval__controller__container">
      <h3 className="intervalsTitle">
        Escolha o intervalo de tempo para sua agenda:
      </h3>
      <div className="intervalsContainer">
        {Object.keys(intervals).map((interval) => (
          <button
            className="intervalButton"
            style={{
              color:
                intervalSelected === intervals[interval] ? 'white' : '#25b379',
              backgroundColor:
                intervalSelected === intervals[interval] ? '#25b379' : 'white',
            }}
            key={interval}
            onClick={() => handleChangeInterval(interval)}>
            {interval}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AgendaMinutesIntervalController;
