import { IRootState } from 'src/Stores';
import { AxiosResponse } from 'axios';
import {
  put,
  all,
  call,
  fork,
  delay,
  takeEvery,
  select,
} from 'redux-saga/effects';
import {
  getPartnerProfile,
  putEnterpriseData,
  putEnterprisePage,
  putResponsibleData,
  putEnterprisePageError,
  getPartnerProfileError,
  putEnterpriseDataError,
  putResponsibleDataError,
  getPartnerProfileSuccess,
  putEnterprisePageSuccess,
  putEnterpriseDataSuccess,
  putResponsibleDataSuccess,
  getAddressImageSuccess,
} from './actions';
import { ProfileTypes } from './types';
import { EnterpriseData } from 'src/Models/Profile';
import ProfileApi from 'src/Services/Api/ProfileApi';
import { error, info, success } from 'src/Services/ToastService';
import { getSessionRequest } from '../authentication/actions';
import ImageApi from 'src/Services/Api/ImageApi';
import { Document } from 'src/Models/Document';

export function* handleGetPartnerProfile() {
  try {
    const result: AxiosResponse<EnterpriseData> = yield call(
      ProfileApi.getPartnerProfile,
    );

    if (result.status === 200 && result.data !== null)
      return yield put(getPartnerProfileSuccess(result.data));

    yield put(getPartnerProfileError(result.statusText));
  } catch (err) {
    if (err instanceof Error && err.stack)
      return yield put(getPartnerProfileError(err.stack));

    yield put(getPartnerProfileError('An unknown error occured.'));
  }
}

export function* getAddressImageToPartner(action) {
  const MAX_RETRIED = 10;
  try {
    const result: AxiosResponse<EnterpriseData> = yield call(
      ProfileApi.getPartnerProfile,
    );
    const profile = result?.data;
    const image = profile?.address?.image;
    if (image.imageUrl != null) {
      return;
    }

    let i = 0;
    do {
      delay(2000);
      const result: AxiosResponse<Document> = yield call(
        ImageApi.getImageById,
        image?.id,
      );
      if (result.status === 200 && result.data !== null) {
        if (result?.data?.imageUrl != null) {
          i = MAX_RETRIED;
          return yield put(getAddressImageSuccess(result.data));
        }
      }
    } while (i++ < MAX_RETRIED);
  } catch (err) {}
}

export const selectProfile = (state: IRootState) => state.profile.profile;
export function* handlePutEnterpriseData(
  action: ReturnType<typeof putEnterpriseData>,
) {
  try {
    const result: AxiosResponse<EnterpriseData> = yield call(
      ProfileApi.putEnterpriseData,
      action.payload,
    );

    if (result.status === 200 && result.data !== null) {
      yield delay(2000);
      yield put(getPartnerProfile());

      const profile = yield select(selectProfile);
      if (profile?.id) {
        yield call(getAddressImageToPartner, { payload: profile?.id });
      }

      yield put(putEnterpriseDataSuccess());
      return success('Dados alterados com sucesso!');
    }

    yield put(putEnterpriseDataError(result.statusText));
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(putEnterpriseDataError(err.stack));
      return error('Erro ao alterar os dados!');
    }

    yield put(putEnterpriseDataError('An unknown error occured.'));
    error('Erro ao alterar os dados!');
  }
}

export function* handlePutEnterprisePage(
  action: ReturnType<typeof putEnterprisePage>,
) {
  try {
    const result: AxiosResponse<EnterpriseData> = yield call(
      ProfileApi.putEnterprisePage,
      action.payload,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getPartnerProfile());
      yield put(putEnterprisePageSuccess());
      success('Dados alterados com sucesso!');
    } else {
      yield put(putEnterprisePageError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(putEnterprisePageError(err.stack));
    } else {
      yield put(putEnterprisePageError('An unknown error occured.'));
    }
    error('Falha ao alterar dados!');
  }
}

export function* handlePutResponsibleData(
  action: ReturnType<typeof putResponsibleData>,
) {
  try {
    const result: AxiosResponse<EnterpriseData> = yield call(
      ProfileApi.putResponsibleData,
      action.payload,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getPartnerProfile());
      yield put(getSessionRequest());
      yield put(putResponsibleDataSuccess());
      success('Dados alterados com sucesso!');
    } else {
      yield put(putResponsibleDataError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(putResponsibleDataError(err.stack));
    } else {
      yield put(putResponsibleDataError('An unknown error occured.'));
    }

    error('Falha ao alterar dados!');
  }
}

function* watchGetProfile() {
  yield takeEvery(ProfileTypes.GET_PROFILE, handleGetPartnerProfile);
}

function* watchPutEntepriseData() {
  yield takeEvery(ProfileTypes.PUT_ENTERPRISE_DATA, handlePutEnterpriseData);
}

function* watchPutEnterprisePage() {
  yield takeEvery(ProfileTypes.PUT_ENTERPRISE_PAGE, handlePutEnterprisePage);
}

function* watchPutResponsibleData() {
  yield takeEvery(ProfileTypes.PUT_RESPONSIBLE_DATA, handlePutResponsibleData);
}

function* profileSaga() {
  yield all([
    fork(watchGetProfile),
    fork(watchPutEntepriseData),
    fork(watchPutEnterprisePage),
    fork(watchPutResponsibleData),
  ]);
}

export default profileSaga;
