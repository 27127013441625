import React from 'react';
import './styles.scss';
import moment from 'moment';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { PageableResponse } from 'src/Models/pageable';
import StringUtils from 'src/Utils/StringUtils';
import { Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import i18n from 'src/Config/I18n';

interface IStudentsWithPaymentOverdueProps {
  customersPlanWithPaymentOverdue: PageableResponse<CustomerActivityPlan>;
}

const StudentsWithPaymentOverdue = ({
  customersPlanWithPaymentOverdue,
}: IStudentsWithPaymentOverdueProps) => {
  return (
    <table className="table">
      <tbody>
        {customersPlanWithPaymentOverdue?.content?.map(
          (customerWithPaymentOverdue) => {
            const finalDate = moment(
              new Date(customerWithPaymentOverdue.endPeriodDate),
            ).format('DD/MM');
            const startDate = moment(
              new Date(customerWithPaymentOverdue.startPeriodDate),
            ).format('DD/MM');

            return (
              <tr key={customerWithPaymentOverdue.id}>
                <td>
                  <div className="c1">
                    <span className="c1-title">
                      {moment(
                        new Date(customerWithPaymentOverdue?.startPeriodDate),
                      ).format('DD/MM/YY')}
                    </span>
                    <span className="c1-sub">
                      {moment(
                        new Date(customerWithPaymentOverdue?.startPeriodDate),
                      ).format('HH:mm')}
                    </span>
                  </div>
                </td>
                <td>
                  <span style={{ fontWeight: 'bold' }} className="text-c2">
                    {customerWithPaymentOverdue?.activityPlan?.name}
                  </span>
                  <span className="c1-title">
                    {i18n.t('tableStudentsWithPaymentOverdue.planValidity', {
                      startDate,
                      finalDate,
                    })}
                  </span>
                </td>
                <td>
                  <div className="c3" style={{ marginTop: '2px' }}>
                    <div className="c1">
                      <span className="text-c2">
                        {customerWithPaymentOverdue?.customer?.name}
                      </span>
                      <Tooltip title={i18n.t('whatsappContact')}>
                        <div className="c3-phone-container">
                          <WhatsAppIcon style={{ color: '#25D366' }} />
                          <a
                            className="c3-sub"
                            href={`https://wa.me/${customerWithPaymentOverdue?.customer?.phones[0]?.number}`}
                            target="_blank">
                            {
                              customerWithPaymentOverdue?.customer?.phones[0]
                                ?.number
                            }{' '}
                          </a>
                        </div>
                      </Tooltip>
                      <div className="c3-sub">
                        <span>
                          {customerWithPaymentOverdue?.customer?.user?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    fontFamily: 'Muller',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    alignItems: 'center',
                    color: 'rgb(233, 0, 58)',
                  }}>
                  {StringUtils.currencyPtBr(customerWithPaymentOverdue?.price)}
                </td>
              </tr>
            );
          },
        )}
      </tbody>
    </table>
  );
};

export default StudentsWithPaymentOverdue;
