import { Agenda } from 'src/Models/Agenda';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { ResultPage } from 'src/Models/ResultPage';

export type ApiResponse = Record<string, any>;

export enum AgendaActionTypes {
  GET_AGENDA = '@@agendas/GET_AGENDA',
  GET_AGENDA_SUCCESS = '@@agendas/GET_AGENDA_SUCCESS',
  GET_AGENDA_ERROR = '@@agendas/GET_AGENDA_ERROR',

  GET_AGENDA_BY_ID = '@@agendas/GET_AGENDA_BY_ID ',
  GET_AGENDA_BY_ID_SUCCESS = '@@agendas/GET_AGENDA_BY_ID_SUCCESS',
  GET_AGENDA_BY_ID_ERROR = '@@agendas/GET_AGENDA_BY_ID_ERROR',
  
  POST_AGENDA = '@@agendas/POST_AGENDA',
  POST_AGENDA_SUCCESS = '@@agendas/POST_AGENDA_SUCCESS',
  POST_AGENDA_ERROR = '@@agendas/POST_AGENDA_ERROR',

  PUT_AGENDA = '@@agendas/PUT_AGENDA',
  PUT_AGENDA_SUCCESS = '@@agendas/PUT_AGENDA_SUCCESS',
  PUT_AGENDA_ERROR = '@@agendas/PUT_AGENDA_ERROR',

  DELETE_AGENDA = '@@agendas/DELETE_AGENDA',
  DELETE_AGENDA_SUCCESS = '@@agendas/DELETE_AGENDA_SUCCESS',
  DELETE_AGENDA_ERROR = '@@agendas/DELETE_AGENDA_ERROR',

  RESET_AGENDA_STATUS = '@@agendas/RESET_AGENDA_ERROR',
  RESET_CREATE_AGENDA_STATUS = '@@agendas/RESET_CREATE_AGENDA_STATUS',
  RESET_EDIT_AGENDA_STATUS = '@@agendas/RESET_EDIT_AGENDA_STATUS',
}

export interface AgendaState {
  readonly agenda?: Agenda;
  readonly agendas?: Agenda[];
  readonly status?: HttpRequestStatus;
  readonly editStatus?: HttpRequestStatus;
  readonly createStatus?: HttpRequestStatus;
  readonly deleteStatus?: HttpRequestStatus;
}
