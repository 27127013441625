import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './styles.scss';

export interface BaseFooterBenefits extends WithTranslation {
  text?: string;
  textBack?: string;
  hasBackButton?: string;
  linkTo?: string;
  disabled?: boolean;
  typeSubmit?: boolean;
  onClick?: () => void;
}

const BaseFooterBenefits: React.FC<BaseFooterBenefits> = ({
  t,
  text,
  textBack,
  hasBackButton,
  onClick,
  linkTo,
  disabled = false,
  typeSubmit = false,
}) => {
  const disabledColor = disabled ? '#DCDCDC' : '#25B379';

  const handleOnClick = (e) => {
    e?.preventDefault?.();
    return disabled == false && onClick?.();
  };

  return (
    <div>
      <div className="footerBenefits">
        {hasBackButton && (
          <Link to="/activities" className="back">
            {textBack}
          </Link>
        )}
        {typeSubmit ? (
          <button
            className="button positionButton"
            disabled={disabled}
            style={{
              backgroundColor: disabledColor,
              borderColor: disabledColor,
            }}
            type="submit">
            {text}
          </button>
        ) : (
          <div className="positionButton" onClick={handleOnClick}>
            {linkTo != null ? (
              <Link to={linkTo} className="button">
                {text}
              </Link>
            ) : (
              <button
                disabled={disabled}
                className="button"
                style={{
                  backgroundColor: disabledColor,
                  borderColor: disabledColor,
                }}>
                {text}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(BaseFooterBenefits);
