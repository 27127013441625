import { BankAccount } from 'src/Models/BankAccount';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { HttpResponseError } from '../../Models/Utils/HttpReponseError';

export type ApiResponse = Record<string, any>;

export enum BankAccountTypes {
  GET_BANK_DATA = '@@bank/GET_BANK_DATA',
  GET_BANK_DATA_ERROR = '@@bank/GET_BANK_DATA_ERROR',
  GET_BANK_DATA_SUCCESS = '@@bank/GET_BANK_DATA_SUCCESS',

  POST_BANK_DATA = '@@bank/POST_BANK_DATA',
  POST_BANK_DATA_ERROR = '@@bank/POST_BANK_DATA_ERROR',
  POST_BANK_DATA_SUCCESS = '@@bank/POST_BANK_DATA_SUCCESS',

}

export interface BankAccountState {
  readonly bankData?: BankAccount;
  readonly error?: HttpResponseError;
  readonly status?: HttpRequestStatus;
}
