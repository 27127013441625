import { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getBankData,
  postBankData,
  postBankDataError,
  getBankDataSuccess,
  getBankDataError,
  postBankDataSuccess
} from './actions';
import { BankAccountTypes } from './types';
import BankAccountApi from 'src/Services/Api/BankAccountApi';
import { BankAccount } from 'src/Models/BankAccount';
import { error, success } from 'src/Services/ToastService';

export function* handleGetBankData() {
  try {
    const result: AxiosResponse<BankAccount> = yield call(
      BankAccountApi.getBankData,
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getBankDataSuccess(result.data));
    } else {
      yield put(getBankDataError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getBankDataError(err.stack));
    } else {
      yield put(getBankDataError('An unknown error occured.'));
    }
  }
}

export function* handlePostBankData(action: ReturnType<typeof postBankData>) {
  try {
    const result: AxiosResponse<BankAccount> = yield call(
      BankAccountApi.postBankData, action.payload
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getBankData());
      yield put(postBankDataSuccess());
      success("Dados alterados com sucesso!");
    } else {
      yield put(postBankDataError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(postBankDataError(err.stack));
    } else {
      yield put(postBankDataError('An unknown error occured.'));
    }

    error("Erro ao alterar os dados!");
  }
};


function* watchGetBankData() {
  yield takeEvery(BankAccountTypes.GET_BANK_DATA, handleGetBankData);
}

function* watchPostBankData() {
  yield takeEvery(BankAccountTypes.POST_BANK_DATA, handlePostBankData);
}



function* bankSaga() {
  yield all([
    fork(watchGetBankData),
    fork(watchPostBankData),
  ]);
}

export default bankSaga;
