import { AxiosResponse } from 'axios';
import { CategoryActionTypes } from './types';
import CategoryApi from 'src/Services/Api/CategoryApi';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getCategoriesSuccess, getCategoriesError, getSubCategoriesError, getSubCategoriesSuccess } from './actions';

function* handleGetCategories() {
  try{
    const result: AxiosResponse<any> = yield call(CategoryApi.getCategories);

    if(result.status === 200 && result.data !== null){
      yield put(getCategoriesSuccess(result?.data?.content));
    } else {
      yield put(getCategoriesError(result.statusText));
    }
  }catch(err) {
    if(err instanceof Error && err.stack) {
      yield put(getCategoriesError(err.stack));
    }else {
      yield put(getCategoriesError('An unknown error occured.'));
    }
  }
}

function* handleGetSubCategories() {
  try{
    const result: AxiosResponse<any> = yield call(CategoryApi.getSubcategories);

    if(result.status === 200 && result.data !== null){
      yield put(getSubCategoriesSuccess(result?.data?.content));
    } else {
      yield put(getSubCategoriesError(result.statusText));
    }
  }catch(err) {
    if(err instanceof Error && err.stack) {
      yield put(getSubCategoriesError(err.stack));
    }else {
      yield put(getSubCategoriesError('An unknown error occured.'));
    }
  }
}

function* watchGetCategories() {
  yield takeEvery(CategoryActionTypes.GET_CATEGORIES_REQUEST, handleGetCategories);
}

function* watchGetSubCategories() {
  yield takeEvery(CategoryActionTypes.GET_SUBCATEGORIES_REQUEST, handleGetSubCategories);
}

function* categorySaga() {
  yield all([ fork(watchGetCategories), fork(watchGetSubCategories)]);
}

export default categorySaga;
