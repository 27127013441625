import './styles.scss';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import {
  Container,
  ContainerDropDown,
  ContainerOptions,
  TitleOptions,
} from './styles';

interface HeaderProps extends RouteComponentProps, WithTranslation {}

const Header: React.FC<HeaderProps> = ({ t }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <div className="header">
      <NavLink
        className="mr-20 overviewLink"
        activeClassName="link-is-active"
        to="/overview">
        {t('header.overview')}
      </NavLink>
      <NavLink
        className="mr-20 overviewLink"
        activeClassName="link-is-active"
        to="/activities">
        {t('header.activity')}
      </NavLink>
      <NavLink
        className="mr-20 overviewLink"
        activeClassName="link-is-active"
        to="/finances">
        {t('header.finances')}
      </NavLink>
      {/* <NavLink
        className="mr-20 overviewLink"
        activeClassName="link-is-active"
        to="/chat">
        {'Chat'}
      </NavLink>
      */}
      <Container
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}>
        <NavLink
          className="mr-20 overviewLink"
          activeClassName="link-is-active"
          to="/makepurchase/history">
          {t('header.makepurchase')}
        </NavLink>
        {showTooltip && (
          <ContainerDropDown n={2}>
            {/* <ContainerOptions>
              <NavLink to="/makepurchase">
                <TitleOptions>{t('header.sellPlan')}</TitleOptions>
              </NavLink>
            </ContainerOptions> */}
            <ContainerOptions>
              <NavLink to="/makepurchase/pending">
                <TitleOptions>{t('header.pending')}</TitleOptions>
              </NavLink>
            </ContainerOptions>
            <ContainerOptions last>
              <NavLink to="/makepurchase/history">
                <TitleOptions>{t('header.history')}</TitleOptions>
              </NavLink>
            </ContainerOptions>
          </ContainerDropDown>
        )}
      </Container>
      <a
        className="mr-20 overviewLink"
        href="https://api.whatsapp.com/send/?phone=5541992183415&text=Olá+Keiken,+sou+um+parceiro+e+preciso+de+um+suporte!&type=phone_number&app_absent=0"
        target="_blank">
        {t('header.support')}
      </a>
    </div>
  );
};

export default withTranslation()(withRouter(Header));
