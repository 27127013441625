import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import DevTools from './Config/DevTools';
import i18n from './Config/I18n';
import './index.css';
import ErrorBoundary from './Routes/Components/Error/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import configureStore from './Stores/configureStore';
import 'moment/locale/pt-br';
import moment from 'moment';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

//const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(initialState);

const rootEl = document.getElementById('root');

const theme = createTheme({
  palette: {
    secondary: {
      main: '#25B379',
    },
  },
});

moment.locale('pt-br');

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <>
          {/* If this slows down the app in dev disable it and enable when required  */}
          {/* {devTools} */}
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </>
      </I18nextProvider>
    </Provider>
  </ErrorBoundary>,
  rootEl,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
