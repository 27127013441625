export const defaultCustomSchedule = {
  id: 0,
  activity: { id: 0, name: '', duration: 0 },
  date: '',
  pricePaid: 0,
  customerActivities: [],
  conferenceSession: { sessionId: '', partnerToken: '', id: 0 },
};

export const defaultAvailableSchedule = {
  open: false,
  currentSchedule: { id: 0, date: '', status: '' },
};

export const defaultScheduleDetails = {
  id: 0,
  name: '',
  type: '',
  date: '',
  price: 0,
  duration: 0,
  valueToReceive: 0,
  conference: { sessionId: '', partnerToken: '', id: 0 },
};

export const defaultMultiScheduleModal = {
  open: false,
  scheduleBoxes: [],
};

export const defaultOpenScheduleDetails = {
  open: false,
  schedule: defaultCustomSchedule,
};