const config = {
  VERSION: process.env.VERSION,
};

export const whatsappSuport =
  'https://api.whatsapp.com/send?phone=5541992555590&text=Ol%C3%A1%2C%20n%C3%A3o%20encontrei%20a%20categoria%20na%20plataforma%20de%20parceiros%2C%20pode%20me%20ajudar%3F';

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;
export const CUSTOMER_APP = process.env.REACT_APP_CUSTOMER_APP;
export const COMPANY_APP = process.env.REACT_APP_COMPANY_APP;
export const GEOCODE_API = process.env.REACT_APP_GOOGLE_KEY;
export const PARTNER_LINK = process.env.REACT_APP_LANDING_PAGE_URL;

export const AUTHORITIES = {
  CUSTOMER: 'ROLE_CUSTOMER',
  COMPANY: 'ROLE_COMPANY',
  PARTNER: 'ROLE_PARTNER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_IMAGE_SIZE_LIMIT = 15555555;
