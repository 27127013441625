import { useEffect, useState } from 'react';

const useTypingEffect = ({ stringToType }: { stringToType: string }) => {
  const [typedString, setTypedString] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const typingTimer = setInterval(() => {
      setTypedString((prevString) => {
        if (!stringToType[currentIndex]) {
          return prevString;
        }
        setIsTyping(true);
        const newString = prevString + stringToType[currentIndex];
        return newString;
      });
      currentIndex++;

      if (currentIndex === stringToType.length) {
        setIsTyping(false);
        clearInterval(typingTimer);
      }
    }, 15);

    return () => {
      setIsTyping(false);
      setTypedString('');
      clearInterval(typingTimer);
    };
  }, [stringToType]);

  return [typedString, isTyping] as const;
};

export default useTypingEffect;
