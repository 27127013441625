import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import BaseHeaderBenefits from 'src/Components/BaseHeaderBenefits';
import BaseFooterBenefits from 'src/Components/BaseFooterBenefits';

import './styles.scss';
import NameAndColor from 'src/Components/PartnerComponents/NameAndColor';
import Schedules from 'src/Components/PartnerComponents/SchedulesEmpty';

export interface NewGrid extends WithTranslation {}

const NewGrid: React.FC<NewGrid> = ({ t }) => {
  return (
    <div className="NewGrid">
      <BaseHeaderBenefits
        step="create"
        color="green"
        title="Criar nova grade"
      />
      <div className="mt-100">
        <div className="mt-100">
          <NameAndColor colorOf="Cor da grade" nameType="Nome da grade" />
          <Schedules />
        </div>
      </div>
      <BaseFooterBenefits
        text="Adicionar grade"
        hasBackButton="true"
        linkTo="overview"
        textBack="Cancelar"
      />
    </div>
  );
};

export default withTranslation()(NewGrid);
