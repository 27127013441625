import { CheckCircle, Cancel, Visibility } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CustomerActivityPlan } from 'src/Models/CustomerActivityPlan';
import { CustomerActivityStatus } from 'src/Models/Enums/CustomerActivityStatus';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { error, success } from 'src/Services/ToastService';
import { IRootState } from 'src/Stores';
import {
  approveEnrolment,
  resetApproveEnrolmentStatus,
} from 'src/Stores/customerActivityPlan/actions';
import StringUtils from 'src/Utils/StringUtils';
import '../styles.scss';
import EnrolmentModal from './EnrolmentModal';
import { CommonLoading } from 'react-loadingg';

export interface EnrolmentsProps
  extends WithTranslation,
    DispatchProps,
    StateProps {}

const Enrolments: React.FC<EnrolmentsProps> = ({
  t,
  customerActivitiesPlan,
  enrolmentStatus,
  approveEnrolment,
  resetApproveEnrolmentStatus,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statusForToastSuccess, setStatusForToastSuccess] =
    useState<CustomerActivityStatus>();
  const [plan, setPlan] = useState<CustomerActivityPlan>();

  useEffect(() => {
    if (enrolmentStatus === HttpRequestStatus.SUCCESS) {
      const message =
        statusForToastSuccess === CustomerActivityStatus.APPROVED
          ? 'Plano aprovado!'
          : 'Plano negado !';
      success(message);
      setOpenModal(false);
      resetApproveEnrolmentStatus();
    } else if (enrolmentStatus === HttpRequestStatus.ERROR) {
      error('Error ao aprovar/negar plano');
      resetApproveEnrolmentStatus();
    } else {
      return;
    }
  }, [enrolmentStatus]);

  const handleOnApproveCustomerActivityPlan = (id?: number) => {
    const customerActivityPlan: CustomerActivityPlan = {
      id: id,
      status: CustomerActivityStatus.APPROVED,
    };
    setStatusForToastSuccess(CustomerActivityStatus.APPROVED);
    approveEnrolment(customerActivityPlan);
  };

  const handleOnDenyCustomerActivityPlan = (id?: number) => {
    const customerActivityPlan: CustomerActivityPlan = {
      id: id,
      status: CustomerActivityStatus.DENIED,
    };
    setStatusForToastSuccess(CustomerActivityStatus.DENIED);
    approveEnrolment(customerActivityPlan);
  };

  const handleOnOpenModal = (plan: CustomerActivityPlan) => {
    setPlan(plan);
    setOpenModal(true);
  };
  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  if (customerActivitiesPlan?.content?.length === 0) {
    return (
      <table>
        <tbody>
          <tr>
            <td className="noHistory">{t('enrolment.noHistory')}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  if (enrolmentStatus === HttpRequestStatus.ONGOING) {
    return (
      <table>
        <tbody>
          <tr style={{ height: '50px' }}>
            <td style={{ position: 'relative' }}>
              <CommonLoading />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table>
      <tbody>
        {customerActivitiesPlan?.content?.map((line, index) => (
          <tr key={`enrolments-${index}`}>
            <td>
              <div className="c1">
                <span className="c1-title">
                  {moment(line?.startPeriodDate).format('DD/MM')}
                </span>
                <span className="c1-sub">
                  {moment(line?.endPeriodDate).format('HH:mm')}
                </span>
              </div>
            </td>
            <td>
              <span className="text-c2">{line?.activityPlan.name}</span>
            </td>
            <td>
              <div className="c3">
                <div className="c1">
                  <span className="text-c2">{line?.customer?.name}</span>
                  <span className="c3-sub">
                    {StringUtils.currencyPtBr(line?.activityPlan.price)}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div className="c1">
                <div className="c3-sub">
                  <span style={{ marginLeft: '7px' }}>
                    {line?.customer?.user?.email}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div className="c1">
                <div className="c3-sub-enrolment">
                  <CheckCircle
                    color="secondary"
                    className="approve"
                    onClick={() =>
                      handleOnApproveCustomerActivityPlan(line?.id)
                    }
                  />
                  <Cancel
                    color="error"
                    className="deny"
                    onClick={() => handleOnDenyCustomerActivityPlan(line?.id)}
                  />
                  <Visibility
                    className="showModal"
                    onClick={() => {
                      handleOnOpenModal(line);
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <EnrolmentModal
        customerPlan={plan}
        openModal={openModal}
        closeModal={handleOnCloseModal}
        approveCustomerActivityPlan={handleOnApproveCustomerActivityPlan}
        denyCustomerActivityPlan={handleOnDenyCustomerActivityPlan}
      />
    </table>
  );
};

const mapStateToProps = (state: IRootState) => {
  const customerActivitiesPlan =
    state.customerActivityPlan.customerActivitiesPlan;
  const enrolmentStatus = state.customerActivityPlan.approveEnrolmentStatus;
  const status = state.customerActivityPlan.status;
  return { customerActivitiesPlan, enrolmentStatus, status };
};

const mapDispatchToProps = {
  approveEnrolment,
  resetApproveEnrolmentStatus,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Enrolments),
) as React.ComponentType<any>;
