import { Activity } from './Activity';

export interface Agenda {
  id?: number;
  name?: string;
  color?: string;
  activities?: Activity[];
  presetAgendaActivitySchedules?: PresetAgendaActivitySchedule[];
  status?: AgendaStatus;
}
export enum AgendaStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export interface PresetAgendaActivitySchedule {
  id?: number;
  zoneId?: string;
  dayOfWeek?: string;
  startAt?: string;
}

export enum DaysOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY', 
  SUNDAY = 'SUNDAY',
}


export const arrayDaysOfWeek = [
  DaysOfWeek.MONDAY,
  DaysOfWeek.TUESDAY,
  DaysOfWeek.WEDNESDAY,
  DaysOfWeek.THURSDAY,
  DaysOfWeek.FRIDAY,
  DaysOfWeek.SATURDAY,
  DaysOfWeek.SUNDAY,
];

export enum DaysOfWeekPortuguese {
  Segunda = 'Segunda',
  Terça = 'Terça',
  Quarta = 'Quarta',
  Quinta = 'Quinta',
  Sexta = 'Sexta',
  Sabádo = 'Sabádo', 
  Domingo = 'Domingo',
}


export class DaysOfWeekUtils {
  static toDay = (dayOfWeek: DaysOfWeek) => {
    return {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 0,
    }[dayOfWeek]
  }
  static fromDay = (day: number) => {
    return {
      1: DaysOfWeek.MONDAY,
      2: DaysOfWeek.TUESDAY,
      3: DaysOfWeek.WEDNESDAY,
      4: DaysOfWeek.THURSDAY,
      5: DaysOfWeek.FRIDAY,
      6: DaysOfWeek.SATURDAY,
      0: DaysOfWeek.SUNDAY,
    }[day]
  }
}

const mapTransformDayOfWeek = {
  "Segunda": DaysOfWeek.MONDAY,
  "Terça": DaysOfWeek.TUESDAY,
  "Quarta": DaysOfWeek.WEDNESDAY,
  "Quinta": DaysOfWeek.THURSDAY,
  "Sexta": DaysOfWeek.FRIDAY,
  "Sabádo": DaysOfWeek.SATURDAY,
  "Domingo": DaysOfWeek.SUNDAY
  }
const mapReverseDayOfWeek = {
  "MONDAY": DaysOfWeekPortuguese.Segunda,
  "TUESDAY": DaysOfWeekPortuguese.Terça,
  "WEDNESDAY": DaysOfWeekPortuguese.Quarta,
  "THURSDAY": DaysOfWeekPortuguese.Quinta,
  "FRIDAY": DaysOfWeekPortuguese.Sexta,
  "SATURDAY": DaysOfWeekPortuguese.Sabádo,
  "SUNDAY": DaysOfWeekPortuguese.Domingo
  }
export class AgendaUtils {

  static transformDaysOfWeek = (item: PresetAgendaActivitySchedule[]) => {
    return item?.map(it => ({
      ...it,
      dayOfWeek: mapTransformDayOfWeek[it.dayOfWeek]
    }))
  };

  static reverseDaysOfWeek = (item : PresetAgendaActivitySchedule[]) => {
    return item?.map(it => ({
      ...it,
      dayOfWeek: mapReverseDayOfWeek[it.dayOfWeek]
    }))
  };

}