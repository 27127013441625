import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { Reducer } from 'redux';
import { AgendaActionTypes, AgendaState } from './types';
import { Agenda } from 'src/Models/Agenda';

export const initialState: AgendaState = {
  agenda: null,
  agendas: null,
  editStatus: HttpRequestStatus.NOOP,
  createStatus: HttpRequestStatus.NOOP,
  status: HttpRequestStatus.NOOP,
};

const reducer: Reducer<AgendaState> = (state = initialState, action) => {
  switch (action.type) {
    case AgendaActionTypes.GET_AGENDA: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case AgendaActionTypes.GET_AGENDA_SUCCESS: {
      const agendas = action?.payload as Agenda[];

      return { ...state, agendas, status: HttpRequestStatus.SUCCESS };
    }
    case AgendaActionTypes.GET_AGENDA_ERROR: {
      return {
        ...state,
        status: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case AgendaActionTypes.GET_AGENDA_BY_ID: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case AgendaActionTypes.GET_AGENDA_BY_ID_SUCCESS: {
      const agenda = action?.payload as Agenda;

      return { ...state, agenda, status: HttpRequestStatus.SUCCESS };
    }
    case AgendaActionTypes.GET_AGENDA_BY_ID_ERROR: {
      return {
        ...state,
        status: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case AgendaActionTypes.PUT_AGENDA: {
      return { ...state, editStatus: HttpRequestStatus.ONGOING };
    }
    case AgendaActionTypes.PUT_AGENDA_SUCCESS: {
      return { ...state, editStatus: HttpRequestStatus.SUCCESS };
    }
    case AgendaActionTypes.PUT_AGENDA_ERROR: {
      return { ...state, editStatus: HttpRequestStatus.ERROR };
    }

    case AgendaActionTypes.POST_AGENDA: {
      return { ...state, createStatus: HttpRequestStatus.ONGOING };
    }
    case AgendaActionTypes.POST_AGENDA_SUCCESS: {
      return { ...state, createStatus: HttpRequestStatus.SUCCESS };
    }
    case AgendaActionTypes.POST_AGENDA_ERROR: {
      return { ...state, createStatus: HttpRequestStatus.ERROR };
    }

    case AgendaActionTypes.DELETE_AGENDA: {
      return { ...state, deleteStatus: HttpRequestStatus.ONGOING };
    }
    case AgendaActionTypes.DELETE_AGENDA_SUCCESS: {
      return { ...state, deleteStatus: HttpRequestStatus.SUCCESS };
    }
    case AgendaActionTypes.DELETE_AGENDA_ERROR: {
      return { ...state, deleteStatus: HttpRequestStatus.ERROR };
    }

    case AgendaActionTypes.RESET_EDIT_AGENDA_STATUS: {
      return { ...state, editStatus: HttpRequestStatus.NOOP, agenda: null };
    }

    case AgendaActionTypes.RESET_CREATE_AGENDA_STATUS: {
      return { ...state, createStatus: HttpRequestStatus.NOOP, agenda: null };
    }

    case AgendaActionTypes.RESET_AGENDA_STATUS: {
      return {
        ...state,
        status: HttpRequestStatus.NOOP,
        deleteStatus: HttpRequestStatus.NOOP,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as agendaReducer };
