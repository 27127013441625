import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Close, OndemandVideo } from '@material-ui/icons';
import './styles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';

export enum VideoType {
  ADD_ACTIVITY_VIDEO = 'ADD_ACTIVITY_VIDEO',
  EDIT_SCHEDULE_VIDEO = 'EDIT_SCHEDULE_VIDEO',
  APROVE_REGISTRATION_VIDEO = 'APROVE_REGISTRATION_VIDEO',
  OVERVIEW_CONFIG = 'OVERVIEW_CONFIG',
}

const videosUrls = {
  ADD_ACTIVITY_VIDEO: 'https://www.youtube.com/embed/MIspMC7HB8w',
  EDIT_SCHEDULE_VIDEO: 'https://www.youtube.com/embed/12cuhc7Mk2w',
  APROVE_REGISTRATION_VIDEO: 'https://www.youtube.com/embed/EwZppnH4ltA',
};

interface VideoTutorialProps extends WithTranslation {
  type: VideoType;
  alertTitle?: string;
  title?: string;
  style?: React.CSSProperties;
}

const VideoTutorial = ({
  t,
  type,
  alertTitle,
  title,
  style,
}: VideoTutorialProps) => {
  const [open, setOpen] = useState(false);

  alertTitle = alertTitle || t(`global.vTutorial.${type}.alert`);
  title = title || t(`global.vTutorial.${type}.title`);
  const videoUrl = videosUrls?.[type] || t(`global.vTutorial.${type}.video`);

  return (
    <>
      <div style={style} className="video-suggestion">
        <button onClick={() => setOpen(true)}>
          {alertTitle}
          &nbsp;&nbsp;
          <OndemandVideo />
        </button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropProps={{ timeout: 800 }}>
        <div className="video-tutorial-container">
          <div className="video-tutorial__title">
            <h2>{title}</h2>
          </div>
          <div className="video-tutorial__content">
            <iframe
              width="800"
              height="449"
              src={videoUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withTranslation()(VideoTutorial);
