import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import './styles.scss';

import { CirclePicker } from 'react-color';
import { isEmpty } from 'lodash';

export interface NameAndColorProps extends WithTranslation {
  nameType: string;
  colorOf: string;
  agendaColor?: string;
  agendaName?: string;
  onChangeName?: (name: string) => void;
  onChangeColor?: (color: string) => void;
}

const NameAndColor: React.FC<NameAndColorProps> = ({
  t,
  nameType,
  colorOf,
  agendaColor = '#25B379',
  agendaName,
  onChangeName,
  onChangeColor,
}) => {
  const colors = [
    '#f44336',
    '#e91e63',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#4caf50',
    '#25B379',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ff5722',
  ];

  const handleOnChangeAgendaColor = (colorChoice, event) => {
    onChangeColor?.(colorChoice?.hex ?? '#25B379');
  };

  const colorSelectedRef = useRef<HTMLElement>();
  return (
    <div>
      <Container className="mt-40 center-cards-name">
        <div className="col-6 pl-0">
          <div className="card-model">
            <span className="value-title">{nameType}</span>
            <div className="setSpace">
              <input
                value={agendaName}
                type="text"
                placeholder="Nome da agenda"
                onChange={(e) => onChangeName?.(e?.target?.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-6 ">
          <div className="card-model">
            <span className="value-title">{colorOf}</span>
            <div className="setSpace">
              <CirclePicker
                className="circle-picker-selected"
                onChangeComplete={handleOnChangeAgendaColor}
                onChange={(c, e) => {
                  if (c == agendaColor) {
                    return;
                  }
                  if (e?.target?.style) {
                    e.target.style.transform = 'scale(1.3)';
                    e.target.style.border = '3px solid #111';
                  }
                  if (
                    colorSelectedRef?.current != e.target &&
                    colorSelectedRef?.current?.style
                  ) {
                    colorSelectedRef['current']['style']['border'] = 'none';
                    colorSelectedRef['current']['style']['transform'] = '';
                  }
                  colorSelectedRef.current = e.target;
                }}
                width="100%"
                circleSize={32}
                colors={colors}
                circleSpacing={15}
                value={agendaColor}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withTranslation()(NameAndColor);
