export const BRAZIL_DIAL_CODE = '+55';
export const OTHER_DIAL_CODE = '+';

const Countries = [
  {
    ru: 'Бразилия',
    lt: 'Brazilija',
    tr: 'Brezilya',
    en: 'Brazil',
    br: 'Brasil',
    flag: '🇧🇷',
    code: 'BR',
    dialCode: BRAZIL_DIAL_CODE,
    mask: '(99) 9 9999 9999',
  },
  {
    ru: 'Соединенные Штаты',
    lt: 'Jungtinės Valstijos',
    tr: 'Amerika Birleşik Devletleri',
    en: 'United States',
    br: 'Estados Unidos',
    flag: '🇺🇸',
    code: 'US',
    dialCode: '+1',
    mask: '(999) 999-9999',
  },
  {
    ru: 'объединенное Королевство',
    lt: 'Jungtinė Karalystė',
    tr: 'Birleşik Krallık',
    en: 'United Kingdom',
    br: 'Reino Unido',
    flag: '🇬🇧',
    code: 'GB',
    dialCode: '+44',
    mask: '99999 999999',
  },
  {
    ru: 'Аргентина',
    lt: 'Argentina',
    tr: 'Arjantin',
    en: 'Argentina',
    br: 'Argentina',
    flag: '🇦🇷',
    code: 'AR',
    dialCode: '+54',
    mask: '999 99-9999-9999',
  },
  {
    ru: 'Австралия',
    lt: 'Australija',
    tr: 'Avustralya',
    en: 'Australia',
    br: 'Austrália',
    flag: '🇦🇺',
    code: 'AU',
    dialCode: '+61',
    mask: '9999 999 999',
  },
  {
    ru: 'Австрия',
    lt: 'Austrijoje',
    tr: 'Avusturya',
    en: 'Austria',
    br: 'Aústria',
    flag: '🇦🇹',
    code: 'AT',
    dialCode: '+43',
    mask: '9999 999999',
  },
  {
    ru: 'Бельгия',
    lt: 'Belgija',
    tr: 'Belçika',
    en: 'Belgium',
    br: 'Bélgica',
    flag: '🇧🇪',
    code: 'BE',
    dialCode: '+32',
    mask: '9999 99 99 99',
  },
  {
    ru: 'Боливия, Многонациональное Государство Боливия',
    lt: 'Bolivija, Bolivijos daugiakalbė valstybė',
    tr: 'Bolivya, Bolivya Çokuluslu Devleti',
    en: 'Bolivia, Plurinational State of bolivia',
    br: 'Bolívia',
    flag: '🇧🇴',
    code: 'BO',
    dialCode: '+591',
    mask: '99999999',
  },
  {
    ru: 'Болгария',
    lt: 'Bulgarija',
    tr: 'Bulgaristan',
    en: 'Bulgaria',
    br: 'Bulgária',
    flag: '🇧🇬',
    code: 'BG',
    dialCode: '+359',
    mask: '999 999 999',
  },
  {
    ru: 'Канада',
    lt: 'Kanada',
    tr: 'Kanada',
    en: 'Canada',
    br: 'Canadá',
    flag: '🇨🇦',
    code: 'CA',
    dialCode: '+1',
    mask: '(999) 999-9999',
  },
  {
    ru: 'Чили',
    lt: 'Čilė',
    tr: 'Şili',
    en: 'Chile',
    br: 'Chile',
    flag: '🇨🇱',
    code: 'CL',
    dialCode: '+56',
    mask: '(9) 9999 9999',
  },
  {
    ru: 'Колумбия',
    lt: 'Kolumbija',
    tr: 'Kolombiya',
    en: 'Colombia',
    br: 'Colômbia',
    flag: '🇨🇴',
    code: 'CO',
    dialCode: '+57',
    mask: '999 9999999',
  },
  {
    ru: 'Коста Рика',
    lt: 'Kosta Rika',
    tr: 'Kosta Rika',
    en: 'Costa Rica',
    br: 'Costa Rica',
    flag: '🇨🇷',
    code: 'CR',
    dialCode: '+506',
    mask: '9999 9999',
  },
  {
    ru: 'Хорватия',
    lt: 'Kroatija',
    tr: 'Hırvatistan',
    en: 'Croatia',
    br: 'Croácia',
    flag: '🇭🇷',
    code: 'HR',
    dialCode: '+385',
    mask: '999 999 9999',
  },
  {
    ru: 'Куба',
    lt: 'Kuba',
    tr: 'Küba',
    en: 'Cuba',
    br: 'Cuba',
    flag: '🇨🇺',
    code: 'CU',
    dialCode: '+53',
    mask: '99 9999999',
  },
  {
    ru: 'Дания',
    lt: 'Danija',
    tr: 'Danimarka',
    en: 'Denmark',
    br: 'Dinamarca',
    flag: '🇩🇰',
    code: 'DK',
    dialCode: '+45',
    mask: '99 99 99 99',
  },
  {
    ru: 'Эквадор',
    lt: 'Ekvadoras',
    tr: 'Ekvador',
    en: 'Ecuador',
    br: 'Equador',
    flag: '🇪🇨',
    code: 'EC',
    dialCode: '+593',
    mask: '999 999 9999',
  },
  {
    ru: 'Эстония',
    lt: 'Estija',
    tr: 'Estonya',
    en: 'Estonia',
    br: 'Estônia',
    flag: '🇪🇪',
    code: 'EE',
    dialCode: '+372',
    mask: '9999 9999',
  },
  {
    ru: 'Фолклендские (Мальвинские) острова',
    lt: 'Folklando salos (Malvinai)',
    tr: 'Falkland Adaları (Malvinas)',
    en: 'Falkland Islands (Malvinas)',
    br: 'Ilhas Malvinas',
    flag: '🇫🇰',
    code: 'FK',
    dialCode: '+500',
    mask: '99999',
  },
  {
    ru: 'Финляндия',
    lt: 'Suomija',
    tr: 'Finlandiya',
    en: 'Finland',
    br: 'Finlândia',
    flag: '🇫🇮',
    code: 'FI',
    dialCode: '+358',
    mask: '999 9999999',
  },
  {
    ru: 'Франция',
    lt: 'Prancūzijoje',
    tr: 'Fransa',
    en: 'France',
    br: 'França',
    flag: '🇫🇷',
    code: 'FR',
    dialCode: '+33',
    mask: '99 99 99 99 99',
  },
  {
    ru: 'Французская Гвиана',
    lt: 'Prancūzijos Gviana',
    tr: 'Fransız Guyanası',
    en: 'French Guiana',
    br: 'Guiana Francesa',
    flag: '🇬🇫',
    code: 'GF',
    dialCode: '+594',
    mask: '9999 99 99 99',
  },
  {
    ru: 'Германия',
    lt: 'Vokietija',
    tr: 'Almanya',
    en: 'Germany',
    br: 'Alemanha',
    flag: '🇩🇪',
    code: 'DE',
    dialCode: '+49',
    mask: '99999 9999999',
  },
  {
    ru: 'Венгрия',
    lt: 'Vengrija',
    tr: 'Macaristan',
    en: 'Hungary',
    br: 'Hungria',
    flag: '🇭🇺',
    code: 'HU',
    dialCode: '+36',
    mask: '(99) 999 9999',
  },
  {
    ru: 'Исландия',
    lt: 'Islandija',
    tr: 'İzlanda',
    en: 'Iceland',
    br: 'Islândia',
    flag: '🇮🇸',
    code: 'IS',
    dialCode: '+354',
    mask: '999 9999',
  },
  {
    ru: 'Ирландия',
    lt: 'Airija',
    tr: 'İrlanda',
    en: 'Ireland',
    br: 'Irlanda',
    flag: '🇮🇪',
    code: 'IE',
    dialCode: '+353',
    mask: '999 999 9999',
  },
  {
    ru: 'Италия',
    lt: 'Italijoje',
    tr: 'İtalya',
    en: 'Italy',
    br: 'Itália',
    flag: '🇮🇹',
    code: 'IT',
    dialCode: '+39',
    mask: '999 999 9999',
  },
  {
    ru: 'Мексика',
    lt: 'Meksika',
    tr: 'Meksika',
    en: 'Mexico',
    br: 'México',
    flag: '🇲🇽',
    code: 'MX',
    dialCode: '+52',
    mask: '999-999 9999',
  },
  {
    ru: 'Нидерланды',
    lt: 'Nyderlandai',
    tr: 'Hollanda',
    en: 'Netherlands',
    br: 'Holanda',
    flag: '🇳🇱',
    code: 'NL',
    dialCode: '+31',
    mask: '99 99999999',
  },
  {
    ru: 'Панама',
    lt: 'Panama',
    tr: 'Panama',
    en: 'Panama',
    br: 'Panamá',
    flag: '🇵🇦',
    code: 'PA',
    dialCode: '+507',
    mask: '9999-9999',
  },
  {
    ru: 'Парагвай',
    lt: 'Paragvajus',
    tr: 'Paraguay',
    en: 'Paraguay',
    br: 'Paraguai',
    flag: '🇵🇾',
    code: 'PY',
    dialCode: '+595',
    mask: '9999 999999',
  },
  {
    ru: 'Перу',
    lt: 'Peru',
    tr: 'Peru',
    en: 'Peru',
    br: 'Peru',
    flag: '🇵🇪',
    code: 'PE',
    dialCode: '+51',
    mask: '999 999 999',
  },
  {
    ru: 'Польша',
    lt: 'Lenkija',
    tr: 'Polonya',
    en: 'Poland',
    br: 'Polônia',
    flag: '🇵🇱',
    code: 'PL',
    dialCode: '+48',
    mask: '999 999 999',
  },
  {
    ru: 'Португалия',
    lt: 'Portugalija',
    tr: 'Portekiz',
    en: 'Portugal',
    br: 'Portugal',
    flag: '🇵🇹',
    code: 'PT',
    dialCode: '+351',
    mask: '999 999 999',
  },
  {
    ru: 'Румыния',
    lt: 'Rumunija',
    tr: 'Romanya',
    en: 'Romania',
    br: 'Romênia',
    flag: '🇷🇴',
    code: 'RO',
    dialCode: '+40',
    mask: '9999 999 999',
  },
  {
    ru: 'Россия',
    lt: 'Rusija',
    tr: 'Rusya',
    en: 'Russia',
    br: 'Rússia',
    flag: '🇷🇺',
    code: 'RU',
    dialCode: '+7',
    mask: '(999) 999-99-99',
  },
  {
    ru: 'Сербия',
    lt: 'Serbija',
    tr: 'Sırbistan',
    en: 'Serbia',
    br: 'Sérvia',
    flag: '🇷🇸',
    code: 'RS',
    dialCode: '+381',
    mask: '999 9999999',
  },
  {
    ru: 'Словакия',
    lt: 'Slovakija',
    tr: 'Slovakya',
    en: 'Slovakia',
    br: 'Eslováquia',
    flag: '🇸🇰',
    code: 'SK',
    dialCode: '+421',
    mask: '9999 999 999',
  },
  {
    ru: 'Словения',
    lt: 'Slovėnija',
    tr: 'Slovenya',
    en: 'Slovenia',
    br: 'Eslovênia',
    flag: '🇸🇮',
    code: 'SI',
    dialCode: '+386',
    mask: '999 999 999',
  },
  {
    ru: 'Испания',
    lt: 'Ispanija',
    tr: 'ispanya',
    en: 'Spain',
    br: 'Espanha',
    flag: '🇪🇸',
    code: 'ES',
    dialCode: '+34',
    mask: '999 99 99 99',
  },
  {
    ru: 'Швеция',
    lt: 'Švedija',
    tr: 'İsveç',
    en: 'Sweden',
    br: 'Suécia',
    flag: '🇸🇪',
    code: 'SE',
    dialCode: '+46',
    mask: '999-999 99 99',
  },
  {
    ru: 'Швейцария',
    lt: 'Šveicarija',
    tr: 'İsviçre',
    en: 'Switzerland',
    br: 'Suíça',
    flag: '🇨🇭',
    code: 'CH',
    dialCode: '+41',
    mask: '999 999 99 99',
  },
  {
    ru: 'Украина',
    lt: 'Ukraina',
    tr: 'Ukrayna',
    en: 'Ukraine',
    br: 'Ucrânia',
    flag: '🇺🇦',
    code: 'UA',
    dialCode: '+380',
    mask: '999 999 9999',
  },
  {
    ru: 'Уругвай',
    lt: 'Urugvajus',
    tr: 'Uruguay',
    en: 'Uruguay',
    br: 'Uruguai',
    flag: '🇺🇾',
    code: 'UY',
    dialCode: '+598',
    mask: '999 999 999',
  },
  {
    ru: 'Венесуэла, Боливарианская Республика Венесуэла',
    lt: 'Venesuela, Venesuelos Bolivaro Respublika',
    tr: 'Venezuela, Bolivarcı Venezuela Cumhuriyeti',
    en: 'Venezuela, Bolivarian Republic of Venezuela',
    br: 'Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    dialCode: '+58',
    mask: '9999-9999999',
  },
  {
    flag: '🚩',
    br: 'Outro',
    code: 'Outro',
    dialCode: OTHER_DIAL_CODE,
    mask: '999999999999999',
  },
];

export default Countries;
