import { Reducer } from 'redux';
import { PartnerClientTypes, PartnerClientState } from './types';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';

export const initialState: PartnerClientState = {
  error: null,
  status: null,
  partnerClients: [],
};

const reducer: Reducer<PartnerClientState> = (state = initialState, action) => {
  switch (action.type) {
    case PartnerClientTypes.GET_PARTNER_CLIENTS: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case PartnerClientTypes.GET_PARTNER_CLIENTS_ERROR: {
      return {
        ...state,
        error: action?.payload,
        status: HttpRequestStatus.ERROR,
      };
    }
    case PartnerClientTypes.GET_PARTNER_CLIENTS_SUCCESS: {
      return {
        ...state,
        partnerClients: action?.payload,
        status: HttpRequestStatus.SUCCESS,
      };
    }
    default: return state;
  }
};

export { reducer as partnerClientReducer };
