import { Billing } from 'src/Models/Billing';
import { ActivityType } from 'src/Models/Enums/ActivityType';
import { PageableRequest, PageableResponse } from 'src/Models/pageable';
import { action } from 'typesafe-actions';
import { BillingTypes } from './types';
import { FilterDateType } from 'src/Components/PartnerComponents/ScheduleHistoryTable/components/ScheduleHistory';

export const getBillingHistory = (params?: {
  size?: number;
  page?: number;
  type?: string;
  search?: string;
  fromDate?: string;
  toDate?: string;
  whichDateToFilter?: FilterDateType;
}) => action(BillingTypes.GET_BILLING_HISTORY, params);
export const getBillingHistorySuccess = (
  pageBillings: PageableResponse<Billing>,
) => action(BillingTypes.GET_BILLING_HISTORY_SUCCESS, pageBillings);
export const getBillingHistoryError = () =>
  action(BillingTypes.GET_BILLING_HISTORY_ERROR);

export const reset = () => action(BillingTypes.RESET_STATE);
