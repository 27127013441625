import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import 'toasted-notes/src/styles.css';
import '../../App.scss';
import { HttpRequestStatus } from '../../Models/Enums/HttpRequestStatus';
import { HttpResponseTypeError } from '../../Models/Utils/HttpReponseError';
import { IRootState } from 'src/Stores';
import {
  forgotPassword,
  forgotPasswordResetStatus,
  getSessionRequest,
  loginRequest,
  loginResetStatus,
  logoutRequest,
} from '../../Stores/authentication/actions';
import Images from '../../Themes/Constants/Images';
import AuthUtils from '../../Utils/AuthUtils';
import LogoImage from '../../Images/Keiken/logo.png';

import CustomTextField from 'src/Components/CustomTextField';
import { toast } from 'react-toastify';
import StringUtils from 'src/Utils/StringUtils';
import {
  DataContainer,
  LogoBox,
  LostPassword,
  StyledButton,
  StyledDescription,
  StyledForm,
  StyledTitle,
  StyledScreenContainer,
  IconLogin,
} from './styles';

import SideImage from './SideImage';
import CircleLoading from 'src/Components/circleLoading';

interface ILoginProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    RouteComponentProps<{}> {}

export interface ILoginState {
  login: string;
  password: string;
  loading: boolean;
  isLoadingSend: boolean;
  hasErrorLogin: boolean;
  authError?: boolean;
  usernameError?: boolean;
  passwordError?: boolean;
  isLoadingAction: boolean;
  usernameErrorText: string;
  passwordErrorText: string;
  loginResetPassword: string;
  isResetPasswordOpen: boolean;
  getSessionEventPrevent: boolean;
  loginResetPasswordError: boolean;
  loginResetPasswordErrorText: string;
  passwordResetEmail: string;
  passwordResetEmailError: boolean;
  passwordResetEmailTextError: string;
}

class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      loading: true,
      isLoadingSend: false,
      hasErrorLogin: false,
      loginResetPassword: '',
      isLoadingAction: false,
      isResetPasswordOpen: false,
      getSessionEventPrevent: false,
      loginResetPasswordError: false,
      loginResetPasswordErrorText: '',
      usernameErrorText: this.props.t('login.error.username'),
      passwordErrorText: this.props.t('login.error.password'),
      passwordResetEmail: '',
      passwordResetEmailError: false,
      passwordResetEmailTextError: this.props.t('login.forgotPassword.error'),
    };
  }

  componentDidMount() {
    if (this.props?.location?.state?.['from'] != null) {
      window.history.replaceState(
        null,
        '',
        this.props.location?.state?.['from'],
      );
    }

    if (AuthUtils.isAuthenticated()) {
      this.redirectToPath();
    } else {
      this.loading(false);
    }
  }

  componentWillReceiveProps(newProps: Readonly<ILoginProps>) {
    if (newProps.getSessionStatus === HttpRequestStatus.SUCCESS) {
      this.redirectToPath();
    }

    if (newProps.loginStatus === HttpRequestStatus.SUCCESS) {
      this.props.loginResetStatus();
    }

    if (newProps.loginStatus === HttpRequestStatus.ERROR) {
      if (newProps.loginError?.type === HttpResponseTypeError.INVALID_AUTH) {
        this.setState({
          loading: false,
          passwordError: false,
          usernameError: false,
          authError: true,
        });
        toast.warn(this.props.t('login.redirect'), {
          onClose: () => {
            window.location.href = newProps.loginError.redirect;
          },
        });
      }
      if (
        newProps.loginError?.type === HttpResponseTypeError.LOGIN_ERROR_USER
      ) {
        this.setState({
          loading: false,
          passwordError: false,
          usernameError: true,
        });
      }
      if (
        newProps.loginError?.type === HttpResponseTypeError.LOGIN_ERROR_PASSWORD
      ) {
        this.setState({
          loading: false,
          usernameError: false,
          passwordError: true,
        });
      }
      if (
        newProps.loginError?.type === HttpResponseTypeError.UNKNOWN ||
        newProps.loginError?.type ===
          HttpResponseTypeError.LOGIN_ERROR_USER_AND_PASSWORD
      ) {
        this.setState({
          loading: false,
          passwordError: true,
          usernameError: true,
        });
      }
      this.props.loginResetStatus();
    }
    if (newProps.forgotPasswordStatus === HttpRequestStatus.ONGOING) {
      this.setState({ loading: true });
    }
    if (newProps.forgotPasswordStatus === HttpRequestStatus.SUCCESS) {
      this.setState({ loading: false });
      this.props.resetForgotPasswordStauts();
    }
    if (newProps.forgotPasswordStatus === HttpRequestStatus.ERROR) {
      this.setState({ loading: false, passwordResetEmailError: false });
      this.props.resetForgotPasswordStauts();
    }
  }

  redirectToPath = () => {
    const redirectPath =
      this.props.location.state?.['from'] ?? AuthUtils.getMainPath();
    this.props.history.replace(redirectPath);
  };

  loading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  isLoadingAction = () => {
    this.setState({
      isLoadingAction: !this.state.isLoadingAction,
    });
  };

  isLoadingSend = () => {
    this.setState({
      isLoadingSend: !this.state.isLoadingSend,
    });
  };

  handleSubmit = (e?: any) => {
    e?.preventDefault();
    if (this.state.login.length === 0 || this.state.password.length === 0) {
      this.setState({
        usernameError: this.state.login.length === 0,
        passwordError: this.state.password.length === 0,
        usernameErrorText: this.props.t('login.error.usernameLength'),
        passwordErrorText: this.props.t('login.error.passwordLength'),
      });
    } else {
      this.setState({
        loading: true,
        passwordError: false,
        usernameError: false,
      });
      this.props.login({
        username: this.state.login,
        password: this.state.password,
      });
    }
  };

  onChangeUserName = (value: string) => {
    this.setState({
      login: value,
    });
  };

  onChangePassword = (value: string) => {
    this.setState({
      password: value,
    });
  };

  onSetErrorLogin = (value: boolean) => {
    this.setState({
      hasErrorLogin: value,
    });
  };

  onBlurUsername = () => {
    this.setState({
      usernameError: this.state.usernameError
        ? this.state.login.length === 0
        : false,
    });
  };

  onBlurPassword = () => {
    this.setState({
      passwordError: this.state.passwordError
        ? this.state.password.length === 0
        : false,
    });
  };

  onBlurForgotPassword = () => {
    this.setState({
      passwordResetEmailError: this.state.passwordResetEmailError
        ? this.state.passwordResetEmail.length === 0
        : false,
    });
  };

  onChangeEmailPasswordReset = (value: string) => {
    this.setState({
      passwordResetEmail: value,
    });
  };

  onSubmitForgotPasswordReset = (e?: any) => {
    e?.preventDefault();
    if (this.state.passwordResetEmail.length === 0) {
      this.setState({
        passwordResetEmailError: this.state.passwordResetEmail.length === 0,
      });
    }
    if (StringUtils.isEmailInvalid(this.state.passwordResetEmail)) {
      this.setState({
        passwordResetEmailError: true,
      });
    } else {
      this.setState({
        passwordResetEmailError: false,
      });
      this.props.forgotPasswordRequest(this.state.passwordResetEmail);
    }
  };

  onGoBack = () => {
    this.setState({
      isResetPasswordOpen: false,
      passwordResetEmail: '',
    });
  };

  render() {
    const {
      login,
      password,
      usernameError,
      usernameErrorText,
      passwordError,
      passwordErrorText,
      passwordResetEmail,
      passwordResetEmailError,
      passwordResetEmailTextError,
    } = this.state;
    const { t } = this.props;

    return (
      <StyledScreenContainer
        theme={{
          width: '100vw',
          maxWidth: '100vw',
          minWidth: '100vw',
          height: '100vh',
          minHeight: '100vh',
          maxHeight: '100vh',
          flexDirection: 'row',
          margin: '0px',
          padding: '0px',
          backgroundColor: '#fff',
          justifyContent: 'center',
          alingItems: 'center',
        }}>
        <DataContainer>
          <LogoBox>
            <img src={LogoImage} alt="logo" />
          </LogoBox>
          <StyledTitle>{this.props.t('login.title')}</StyledTitle>
          {!this.state.isResetPasswordOpen ? (
            <>
              <StyledDescription>
                {this.props.t('login.welcome')}
              </StyledDescription>
              <div>
                <StyledForm>
                  <CustomTextField
                    style={{ marginBottom: '40px' }}
                    onChange={this.onChangeUserName}
                    id="username"
                    isEmail
                    value={login}
                    label={'E-mail'}
                    placeholder={'E-mail'}
                    error={usernameError}
                    errorText={usernameErrorText}
                    onBlur={this.onBlurUsername}
                    leftIcon={<IconLogin src={Images.icons.mailGrey} />}
                  />
                  <CustomTextField
                    onChange={this.onChangePassword}
                    id="password"
                    isPassword
                    value={password}
                    label={'Senha'}
                    placeholder={'Senha'}
                    onEnterPress={this.handleSubmit}
                    error={passwordError}
                    errorText={passwordErrorText}
                    onBlur={this.onBlurPassword}
                    InputProps={{
                      autoComplete: 'off',
                    }}
                    leftIcon={<IconLogin src={Images.icons.lockGrey} />}
                  />
                </StyledForm>
                <LostPassword
                  onClick={() => {
                    this.setState({
                      isResetPasswordOpen: true,
                      passwordResetEmailError: false,
                    });
                  }}>
                  {this.props.t('login.form.forgotPassword')}
                </LostPassword>
              </div>
              <StyledButton
                disable={this.state.loading}
                onClick={this.handleSubmit}>
                {this.state.loading ? (
                  <CircleLoading inverted size={40} />
                ) : (
                  t('login.confirm')
                )}
              </StyledButton>
            </>
          ) : (
            <>
              <StyledDescription>
                {this.props.t('login.forgotPassword.label')}
              </StyledDescription>
              <div>
                <StyledForm
                  onSubmit={this.onSubmitForgotPasswordReset}
                  id="forgetPassword">
                  <CustomTextField
                    onChange={this.onChangeEmailPasswordReset}
                    id="username"
                    isEmail
                    value={passwordResetEmail}
                    label={'e-mail'}
                    placeholder={'e-mail'}
                    error={passwordResetEmailError}
                    errorText={passwordResetEmailTextError}
                    onBlur={this.onBlurForgotPassword}
                    leftIcon={<IconLogin src={Images.icons.mailGrey} />}
                  />
                </StyledForm>
                <LostPassword onClick={this.onGoBack}>
                  {this.props.t('login.forgotPassword.back')}
                </LostPassword>
              </div>
              <StyledButton type="submit" form="forgetPassword">
                {this.props.t('login.forgotPassword.send')}
              </StyledButton>
            </>
          )}
        </DataContainer>
        <SideImage />
      </StyledScreenContainer>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginStatus: authentication.loginStatus,
  loginError: authentication.error,
  isAuthenticated: authentication.isAuthenticated,
  getSessionStatus: authentication.getSessionStatus,
  forgotPasswordStatus: authentication.forgotPasswordStatus,
});

const mapDispatchToProps = {
  login: loginRequest,
  logout: logoutRequest,
  getSession: getSessionRequest,
  loginResetStatus: loginResetStatus,
  forgotPasswordRequest: forgotPassword,
  resetForgotPasswordStauts: forgotPasswordResetStatus,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Login) as React.ComponentType<{}>;
