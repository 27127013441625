import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { IRootState } from 'src/Stores';
import { getCategories } from 'src/Stores/category/actions';
import { ActivityCategory } from 'src/Models/ActivityCategory';
import { ActivitySubCategory } from 'src/Models/ActivitySubCategory';
import CardsCategory from './CardsCategory';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import './styles.scss';
import { whatsappSuport } from 'src/Config/Constants';
import CardSubCategory from './CardSubCategory.tsx';

export interface PlanCategoryProps extends WithTranslation {
  title: string;
  requestCategories: () => null;
  categories: ActivityCategory[];
  verifyAndEnableSave?: () => void;
  activityCategories: { categories; subCategories };
  setActivityCategories: ({ categories, subCategories }) => void;
  subtitle: string;
}

const PlanCategory: React.FC<PlanCategoryProps> = ({
  title,
  categories,
  subtitle,
  requestCategories,
  activityCategories,
  verifyAndEnableSave = () => null,
  setActivityCategories,
}) => {
  useEffect(() => {
    requestCategories();
  }, [requestCategories]);

  const handleSelect = useCallback(
    (
      category: ActivityCategory,
      select = false,
      subCategories: ActivitySubCategory[],
    ) => {
      if (!select) {
        setActivityCategories({
          ...activityCategories,
          categories: [...activityCategories?.categories, category],
        });
      } else if (select) {
        const filteredCategories: ActivityCategory[] =
          activityCategories?.categories?.filter(
            (activityCategory) => activityCategory?.id !== category?.id,
          );
        setActivityCategories({
          ...activityCategories,
          categories: [...filteredCategories],
          subCategories: activityCategories.subCategories.filter(
            (it) => !subCategories.some((sub) => sub.id === it.id),
          ),
        });
      }

      verifyAndEnableSave();
    },
    [setActivityCategories, verifyAndEnableSave, activityCategories],
  );

  const handleSelectSubCategory = useCallback(
    (subCategory: ActivitySubCategory, select = false) => {
      if (!select) {
        setActivityCategories({
          ...activityCategories,
          subCategories: [...activityCategories?.subCategories, subCategory],
        });
      } else if (select) {
        const filteredSubCategories = activityCategories?.subCategories?.filter(
          (activitySubCategory) => activitySubCategory?.id !== subCategory?.id,
        );

        setActivityCategories({
          ...activityCategories,
          subCategories: [...filteredSubCategories],
        });
      }

      verifyAndEnableSave();
    },
    [setActivityCategories, verifyAndEnableSave, activityCategories],
  );

  const memoizedCategory = useMemo(() => {
    return categories?.map((category, index) => {
      const { activitySubCategories = [] } = category;

      return (
        <div className="" key={index}>
          <CardsCategory
            handleSelect={(cat, flag) =>
              handleSelect(cat, flag, activitySubCategories)
            }
            activityCategories={activityCategories}
            handleSelectSubCategory={handleSelectSubCategory}
            category={category}
            color={'color-default'}
            subcategories={activitySubCategories}
          />
        </div>
      );
    });
  }, [categories, activityCategories, handleSelect, handleSelectSubCategory]);

  const expandedCategories = activityCategories.categories;

  return (
    <div className="bigger--container">
      <Container className="mt-40 center-cards-name-PlanCategory">
        <div className="col-12">
          <div className="card-model">
            <span className="value-title">{title}</span>
            <div className="setSpace cardsContiner--bigger">
              <div
                className="flexit mb-20 cardsContainer"
                style={{ marginBottom: '30px' }}>
                {memoizedCategory}
              </div>
              <a className="categoryNotFound" href={whatsappSuport}>
                <HelpOutlineOutlinedIcon /> Não encontrei minha categoria
              </a>
            </div>
          </div>
        </div>
      </Container>

      <Container className="center-cards-name-PlanCategory">
        <div className="card-model-subcategory">
          <span className="value-title">{subtitle}</span>
          <div className="setSpace cardsContiner--bigger">
            <Container className="">
              <div className="subcategories--container ">
                {expandedCategories
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((expCategory, index) => {
                    const categorySelected = categories.filter(
                      (mainCat) => mainCat.id === expCategory.id,
                    )[0];

                    return (
                      <div
                        className="container-subcategories--title--map"
                        key={expCategory.name + index}>
                        <h4>{expCategory.name}</h4>
                        <div className="sub-categories-Container">
                          {categorySelected?.activitySubCategories
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((item, i2) => {
                              return (
                                <div
                                  className="col-md-4 col-sm-12 col-xs-12"
                                  key={index + i2}>
                                  <CardSubCategory
                                    subCategory={item}
                                    activityCategories={activityCategories}
                                    handleSelectSubCategory={
                                      handleSelectSubCategory
                                    }
                                    color={'color-default'}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Container>
            <a className="categoryNotFound" href={whatsappSuport}>
              <HelpOutlineOutlinedIcon /> Não encontrei minha subcategoria
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { category } = state || {};

  const { categories = [] } = category;

  return { categories };
};

const mapDispatchToProps = {
  requestCategories: getCategories,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PlanCategory),
);
