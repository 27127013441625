import React from 'react';
import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import './styles.scss';

interface Props extends WithTranslation {
  isOpen: boolean;
  toggle: () => void;
}

const AcceptTermsModal: React.FC<Props> = ({ t, isOpen, toggle }) => {
  const [hasAgreed, setHasAgreed] = React.useState(false);

  const handleCheckboxChange = (e) => {
    setHasAgreed(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasAgreed) {
      toggle();
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 800 }}>
      <Fade in={isOpen}>
        <div className="AcceptTerms--modal">
          <header className="AcceptTerms--header">
            <h2>{t('global.terms.title')}</h2>
          </header>
          <hr />
          <form onSubmit={handleSubmit} className="AcceptTerms--form">
            <p>{t('global.terms.p1')}</p>
            <div className="pdf-container">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer fileUrl="/Termo_de_Adesão_Keiken.pdf" />
              </Worker>
            </div>
            <br />
            <label>
              <input
                checked={hasAgreed}
                type="checkbox"
                name="agree"
                required
                onChange={handleCheckboxChange}
              />
              &nbsp; {t('global.terms.check')}
            </label>
            <br />
            <button disabled={!hasAgreed} type="submit">
              {t('global.terms.btn')}
            </button>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default withTranslation()(AcceptTermsModal);
