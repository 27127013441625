import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import styled from 'styled-components';

export const SimpleModalContainer = styled(Modal)`
  margin: 10rem auto;
  border-radius: 15px !important;
  box-sizing: border-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  div.modal-content {
    border-radius: 15px !important;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  }
`;

export const SimpleModalHeader = styled(ModalHeader)`
  margin-top: 10px;
  font-weight: 400;
  font-family: MullerBold;
  padding: 12px 32px 12px 32px;
`;

export const SimpleModalBody = styled(ModalBody)`
  font-weight: 400;
  font-family: Muller;
  text-align: center;
  padding: 20px 28px;
`;

export const SimpleModalFooter = styled(ModalFooter)`
  justify-content: flex-end !important;
  padding: 12px 32px 24px 32px;
`;
