export enum OperatingSystem {
  WINDOWS_PHONE = 'Windows Phone',
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'Unknown',
}
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = (): OperatingSystem => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OperatingSystem.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return OperatingSystem.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OperatingSystem.IOS;
  }

  return OperatingSystem.UNKNOWN;
};

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    email != null && email.length > 0 && re.test(String(email).toLowerCase())
  );
};

export const validateCreditCard = (cardNumber: string): boolean => {
  // const cards = [
  //     /^4[0-9]{12}(?:[0-9]{3})/, // visa
  //     /^5[1-5][0-9]{14}/, // Mastercard
  //     /^3[47][0-9]{13}/, // Amex
  //     /^3(?:0[0-5]|[68][0-9])[0-9]{11}/, // DinersClub
  //     /^6(?:011|5[0-9]{2})[0-9]{12}/, // Discover
  //     /^(?:2131|1800|35\d{3})\d{11}/ // JCB
  // ];
  const cards = [
    /^4[0-9]{12}(?:[0-9]{3})/,
    /^5[1-5][0-9]{14}/,
    /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    /^3[47][0-9]{13}/,
    /^6(?:011|5[0-9]{2})[0-9]{12}/,
    /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    /^(?:2131|1800|35\d{3})\d{11}/,
    /^(5078\d{2})(\d{2})(\d{11})$/,
  ];

  // console.log('card filter', { card: cards.filter(it => cardNumber.match(it))[0], teste: cardNumber.match(cards[0]) });
  if (
    cards.filter((it) =>
      cardNumber.match(it)
        ? cardNumber.match(it)![0].length === cardNumber.length
        : false,
    ).length > 0
  ) {
    return true;
  }
  return false;
};

export const isValidCPF = (cpf) => {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split('');
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el);
  const toValidate = (pop) =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};
interface IGenerateColor {
  color?: string;
  colors?: string[];
  arrayLength?: number;
}
export const allColors = [
  '#FF99CC',
  '#FFCC99',
  '#99FFCC',
  '#CC99FF',
  '#FFCC99',
  '#CCFF99',
  '#99CCFF',
  '#FF9999',
  '#99FF99',
  '#9999FF',
  '#FFB6C1',
  '#FFC0CB',
  '#C1E1FF',
  '#B5E4FF',
  '#FFF5EE',
  '#F0FFFF',
  '#E0FFFF',
  '#AFEEEE',
  '#ADD8E6',
  '#B0E0E6',
  '#98FB98',
  '#90EE90',
  '#00FF7F',
  '#3CB371',
  '#2E8B57',
  '#228B22',
  '#008000',
  '#006400',
  '#9ACD32',
  '#6B8E23',
  '#808000',
  '#556B2F',
  '#66CDAA',
  '#8FBC8F',
  '#20B2AA',
  '#008B8B',
  '#008080',
  '#00FFFF',
  '#00FFFF',
  '#E0FFFF',
  '#AFEEEE',
  '#7FFFD4',
  '#40E0D0',
  '#48D1CC',
  '#00CED1',
  '#5F9EA0',
  '#4682B4',
  '#B0C4DE',
  '#B0E0E6',
  '#ADD8E6',
  '#87CEEB',
  '#87CEFA',
];
export const generateColor = ({
  color,
  colors,
  arrayLength,
}: IGenerateColor) => {
  if (color) return color;
  const selectColor = () => {
    return allColors[Math.floor(Math.random() * 25)];
  };
  if (arrayLength) {
    const colorsArray = [];
    for (let i = 0; i < arrayLength; i++) {
      colorsArray.push(generateColor({ colors: colorsArray }));
    }
    return colorsArray;
  }
  const checkArray = (color: string) => {
    if (!colors) return color;
    if (colors.find((alreadyHave) => alreadyHave === color))
      return checkArray(selectColor());
    return color;
  };
  return checkArray(selectColor());
};

export const convertRGBAtoRGB = ({ red, blue, green, alpha }) => {
  return {
    red: (1 - alpha) * 255 + alpha * red,
    blue: (1 - alpha) * 255 + alpha * blue,
    green: (1 - alpha) * 255 + alpha * green,
  };
};
